import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Steps, Form, Row, Col, Input, Button, Radio, Space, message, Divider } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';

import { ServiceAction } from '../../store/root-action';
import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';


const { Step } = Steps;

const ServiceBooking = () => {
    const currentUser = COMMON.getCurrentUser();
    const [form] = Form.useForm();
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });
    const [method, setMethod] = useState('bank_transfer');
    const history = useHistory();
    const dispatch = useDispatch();
    const service = useSelector(state => state.serviceReducer.detail);
    const [processing, setProcessing] = useState(false);
    const [orderPrice, setOrderprice] = useState({ fee: 0, vat: 0, total: 0 });
    const [voucher, setVoucher] = useState([]);
    const [discount, setDiscount] = useState({
        voucher_code: "",
        type: "",
        value_type: "",
        value: 0
    });
    const [messageVoucher, setMessageVoucher] = useState(null);
    const [dataBookingService, setDataBookingService] = useState({});
    // phí đi lại
    const [transpotFee, setTranspotFee] = useState(0);
    //phí ngoài giờ
    const [overtimeFee, setOvertimeFee] = useState(0);
    useEffect(() => {
        let _dataBookingService = localStorage.getItem(CONSTANT.STORAGE_DATA_BOOKING_SERVICE);
        if (_dataBookingService) {
            _dataBookingService = JSON.parse(_dataBookingService);
            setDataBookingService(_dataBookingService);
        }
        else {
            history.push(`/${CONSTANT.SLUG_SERVICE}`);
        }
    }, []);


    // load service
    const loadService = useCallback(async () => {
        if (dataBookingService.service)
            await dispatch(ServiceAction.getDetail(dataBookingService.service));
    }, [dispatch, dataBookingService]);


    // load voucher
    const getVoucher = useCallback(async () => {
        if (dataBookingService?.service) {
            let voucher = await Services.post(`${CONSTANT.API.SERVICE.VOUCHER}/check-service-voucher`, {
                service_apply: dataBookingService.service
            });
            if (voucher) {
                setVoucher(voucher.data.data);
                if (voucher.data.data.type === 'count_max') {
                    setDiscount({
                        type: 'count_max',
                        value_type: voucher.data.data.value_type,
                        value: voucher.data.data.value,
                    });
                }
            }

        }
    }, [dataBookingService]);

    // get service

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            loadService();
            getVoucher();
        }
        return () => mounted = false;

    }, [loadService]);

    useEffect(() => {
        // tính toán giá và các loại phí cho đơn hàng
        if (service?.price) {
            // phí dịch vụ
            let fee = service.price * CONSTANT.FEE.SERVICE_MEDIC;
            let total = service.price + fee;
            //phí đi lại
            let _transpotFee = dataBookingService?.area_apply?.fee || 0;
            //phí ngoài giờ
            let _overtime_fee = 0;
            // kiểm tra xem nếu ngoài giờ hành chính thì tính thêm phí
            if (service?.overtime_fee > 0) {
                let _time = moment(moment(dataBookingService.time).format('HH:mm'), 'HH:mm');
                if (service?.time_apply?.length > 0) {
                    let count = 0;
                    for (const time of service.time_apply) {
                        const _start = moment(moment(time.start_time).format('HH:mm'), 'HH:mm');
                        const _end = moment(moment(time.end_time).format('HH:mm'), 'HH:mm:ss');
                        if (_time.isSameOrAfter(_start) && _time.isBefore(_end)) {
                            count += 1;
                        }
                    }
                    if (count === 0) {
                        _overtime_fee = parseInt(service.overtime_fee);
                    }
                }
                setOvertimeFee(_overtime_fee);
            }
            setTranspotFee(_transpotFee);
            total += (_transpotFee + _overtime_fee);
            // nếu có voucher count_max
            if (voucher.type === 'count_max') {
                if (voucher.value_type === 'cash') {
                    total -= parseInt(voucher.value);
                }
                else if (voucher.value_type === 'percent') {
                    total -= parseInt(total * voucher.value / 100);
                }
            }
            setOrderprice({ fee, total });
        }
    }, [service])

    const onChangeMethod = e => {
        setMethod(e.target.value);
    };

    const onSubmit = async (values) => {
        setProcessing(true);
        let dataOrder = {
            order_id: "",
            service_id: service._id,
            quantity: 1,
            agency: service.created_by,
            created_by: currentUser ? currentUser._id : '',
            contact: {
                fullname: values.fullname,
                email: values.email,
                phone: values.phone.replace(phones.dial_code, ''),
                dial_code: phones.dial_code,
                country_code: phones.country_code
            },
            work_place: dataBookingService.work_place,
            place_name: dataBookingService.place_name,
            address_work: '',
            location: values.location,
            date: dataBookingService.date,
            time: dataBookingService.time,
            discount: discount,
            areaId: dataBookingService?.area_apply?._id,
            payment_method: method
        }

        if (dataBookingService.work_place === 'at_guest') dataOrder.address_work = values.address_work;
        else dataOrder.address_work = dataBookingService.address_work;
        let save = await Services.post(CONSTANT.API.SERVICE.ORDER_ITEM, dataOrder);
        if (save.status === 200) {
            if (method === 'bank_transfer') {
                message.success('Đặt lịch dịch vụ thành công! Vui lòng chờ trong giây lát', 6).then(() => {
                    history.push(`/${CONSTANT.SLUG_SERVICE}/booking/bank-transfer?token=${save.data.data._id}`);
                });
            }
            else {
                // Nếu thanh toán online thì tiếp tục gọi api thanh toán
                let dataPayment = {
                    platform: 'web',
                    payment_method: method
                };
                if (method === 'onepayDomestic') {
                    dataPayment.payment_method_title = "OnePay Nội địa";
                }
                else if (method === 'onepayInternational') {
                    dataPayment.payment_method_title = "OnePay Quốc tế";
                }
                else {
                    dataPayment.payment_method_title = "Chuyển khoản ngân hàng";
                }
                // call payment
                Services.post(`${CONSTANT.API.SERVICE.CHECKOUT}/${save.data.data._id}/payment`, dataPayment)
                    .then(res => {

                        if (res && res.data && res.data.code === 200) {
                            message.success('Vui lòng chờ trong giây lát. Bạn sẽ được chuyển đến trang thanh toán trực tuyến!', 5).then(() => {
                                window.location.href = res.data.data.url;
                            })
                        }
                        else {
                            message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!', 8)
                            setProcessing(false);
                        }
                    })
            }


        }
        else {
            message.error('Đặt lịch hẹn không thành công! Quý khách vui lòng quay lại sau, cảm ơn!', 7);
            setProcessing(false);
        }
    }

    const onFinishFailed = () => {
        message.warning('Vui lòng nhập đầy đủ dữ liệu!');
    }

    // xu ly su kien nhap ma giam gia
    const onChangeDiscount = (e) => {
        let discountCheck = { ...discount };
        discountCheck.voucher_code = e.target.value;
        discountCheck.type = '';
        discountCheck.value_type = '';
        discountCheck.value = 0;
        setDiscount(discountCheck);
        setOrderprice({ total: service.price + orderPrice.fee + transpotFee + overtimeFee, fee: orderPrice.fee });
    }
    // xu ly ap dung ma giam gia
    const applyDiscount = async () => {
        await setMessageVoucher(null);
        if (discount?.voucher_code === '') return message.warning('Vui lòng nhập mã giảm giá!');
        else if (discount?.voucher_code && discount?.voucher_code.length <= 5) return message.error('Mã giảm giá không áp dụng cho dịch vụ này hoặc không đúng hoặc đã hết hạn vui lòng kiểm tra lại !')
        let voucher = await Services.post(`${CONSTANT.API.SERVICE.VOUCHER}/check-voucher`, {
            voucher_code: discount.voucher_code,
            service_apply: service._id
        });
       
        let total = service.price + transpotFee + overtimeFee + orderPrice.fee;
        if (voucher?.data?.code === 200) {
            setDiscount({
                voucher_code: discount.voucher_code,
                type: 'voucher',
                value_type: voucher.data.data.value_type,
                value: voucher.data.data.value
            });
            if (voucher.data.data.value_type === 'cash') {
                if (total >= voucher.data.data.min_total_order) {
                    total -= parseInt(voucher.data.data.value);
                    setOrderprice({ total, fee: orderPrice.fee });
                    message.success('Đã áp dụng mã giảm giá thành công !', 4);
                }
                else if (total < voucher.data.data.min_total_order && voucher.data.data.value_condition_false && voucher.data.data.value_condition_false > 0) {
                    total -= parseInt(total * voucher.data.data.value_condition_false / 100);
                    setDiscount({
                        voucher_code: discount.voucher_code,
                        type: 'voucher',
                        value_type: voucher.data.data.value_type_condition_false,
                        value: voucher.data.data.value_condition_false
                    });
                    setOrderprice({ total, fee: orderPrice.fee });
                    setMessageVoucher(`Tổng giá trị dịch vụ chỉ được áp dụng khuyến mãi ${voucher.data.data.value_condition_false}%. Để được khuyến mãi ${COMMON.currencyFormat(voucher.data.data.value)}₫ yêu cầu tổng giá trị đặt dịch vụ phải lớn hơn hoặc bằng ${COMMON.currencyFormat(voucher.data.data.min_total_order)}₫`);
                    message.warning(`Tổng giá trị dịch vụ chỉ được áp dụng khuyến mãi ${voucher.data.data.value_condition_false}%. Để được khuyến mãi ${COMMON.currencyFormat(voucher.data.data.value)}₫ yêu cầu tổng giá trị đặt dịch vụ phải lớn hơn hoặc bằng ${COMMON.currencyFormat(voucher.data.data.min_total_order)}₫`, 5);
                }
                else {
                    setOrderprice({ total, fee: orderPrice.fee });
                    message.warning(`Tổng giá trị dịch vụ không đủ điều kiện áp dụng khuyến mãi ! Giá trị dịch vụ phải lớn hơn hoặc bằng ${voucher.data.data.min_total_order}₫`, 5);
                }
            }
            else if (voucher.data.data.value_type === 'percent') {
                if (total <= voucher.data.data.max_total_order) {
                    total -= parseInt(total * voucher.data.data.value / 100);
                    setOrderprice({ total, fee: orderPrice.fee });
                    message.success('Đã áp dụng mã giảm giá thành công !', 4);
                }
                else {
                    setOrderprice({ total, fee: orderPrice.fee });
                    message.warning(`Tổng giá trị dịch vụ không đủ điều kiện áp dụng khuyến mãi ! Giá trị dịch vụ phải bé hơn hoặc bằng ${voucher.data.data.max_total_order}₫`, 5);
                }
            }

        }
        else {
            voucher = await Services.post(`${CONSTANT.API.SERVICE.VOUCHER}/check-bonus`, {
                code_bonus_extra: discount.voucher_code,
                service_apply: service._id
            });
            if (voucher?.data?.code === 200) {
                setDiscount({
                    voucher_code: discount.voucher_code,
                    type: 'bonus',
                    value_type: voucher.data.data.bonus_extra_type,
                    value: voucher.data.data.bonus_extra_value
                });
                if (voucher.data.data.bonus_extra_type === 'cash') {
                    if (total >= voucher.data.data.min_total_order) {
                        total -= parseInt(voucher.data.data.bonus_extra_value);
                        setOrderprice({ total, fee: orderPrice.fee });
                        message.success('Đã áp dụng mã giảm giá thành công !', 4);
                    }
                    else {
                        setOrderprice({ total, fee: orderPrice.fee });                        
                        message.warning(`Tổng giá trị dịch vụ không đủ điều kiện áp dụng khuyến mãi ! Giá trị dịch vụ phải lớn hơn hoặc bằng ${voucher.min_total_order}₫`, 5);
                    }
                }
                else if (voucher.data.data.bonus_extra_type === 'percent') {
                    if (total <= voucher.data.data.max_total_order) {
                        total -= parseInt(total * voucher.data.data.bonus_extra_value / 100);
                        setOrderprice({ total, fee: orderPrice.fee });
                        message.success('Đã áp dụng mã giảm giá thành công !', 4);
                    }
                    else {
                        setOrderprice({ total, fee: orderPrice.fee });
                        message.warning(`Tổng giá trị dịch vụ không đủ điều kiện áp dụng khuyến mãi ! Giá trị dịch vụ phải bé hơn hoặc bằng ${voucher.max_total_order}₫`, 5);
                    }
                }
            }

            else {
                setDiscount({
                    voucher_code: discount.voucher_code,
                    type: '',
                    value_type: '',
                    value: 0
                });
                setOrderprice({ total, fee: orderPrice.fee });
                message.error('Mã giảm giá không áp dụng cho dịch vụ này hoặc không đúng hoặc đã hết hạn vui lòng kiểm tra lại !', 10);
            }
        }

    }

    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={1}>
                        <Step title="Chọn dịch vụ" />
                        <Step title="Thông tin" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Row gutter={[{ xs: 0, lg: 48 }, { xs: 0, lg: 48 }]}>
                    {/* Begin col info */}
                    <Col xs={24} lg={15} className="col-info" style={{ borderRight: '1px solid #e5e5e5' }} >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onSubmit}
                            onFinishFailed={onFinishFailed}
                        >

                            <div className="fw-bold form-header">Thông tin khách hàng</div>
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Họ tên"
                                        name="fullname"
                                        rules={[{ required: true, message: 'Vui lòng nhập Họ Tên' }]}
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, type: "email", message: 'Vui lòng nhập email' }]}
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item label="Số điện thoại" name="phone" required
                                        tooltip="Chúng tôi có thể liên hệ với bạn về đơn đặt hàng của bạn"
                                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                                    >
                                        <PhoneInput
                                            country='vn'
                                            value={phones.phone}
                                            onChange={(value, data) => {
                                                setPhones({
                                                    phone: value,
                                                    dial_code: data.dialCode,
                                                    country_code: data.countryCode
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} lg={24}>
                                    {
                                        dataBookingService.work_place === 'at_guest' ? <Form.Item
                                            label="Địa chỉ của bạn"
                                            name="address_work"
                                            tooltip="Dịch vụ thực hiện tại nhà, vui lòng nhập địa chỉ của bạn"
                                            rules={[{ required: true, message: 'Vui lòng nhập địa chỉ của bạn' }]}
                                        >
                                            <Input className="rounded-pill" size="large" />
                                        </Form.Item> : dataBookingService.work_place === 'at_agency' ?
                                            <Form.Item
                                                label="Địa điểm thực hiện"
                                            >
                                                <strong>Đơn vị:</strong>  {dataBookingService.place_name} <br></br>
                                                <strong>Địa chỉ:</strong> {dataBookingService.address_work}
                                            </Form.Item> : null
                                    }
                                </Col>
                            </Row>

                            <div className="pay-method">
                                <div className="pay-method-title">Phương thức thanh toán</div>
                                <div style={{ fontSize: 13, marginBottom: 10 }}>Chúng tôi sử dụng cổng thanh toán an toàn để bảo mật thông tin của bạn</div>

                                <Radio.Group onChange={onChangeMethod} value={method}>
                                    <Space direction="vertical">
                                        <div className="method">
                                            <Radio value='onepayDomestic' disabled>
                                                <div className="fw-medium">Thẻ ATM nội địa - (TẠM THỜI CHƯA ÁP DỤNG)</div>
                                                <div>Quý khách sẽ được chuyển đến OnePay để thanh toán</div>
                                                <img className="logo-onepay" src="https://tatinta.com/static/images/logo-onepay.png" alt="logo onepay" />
                                            </Radio>
                                        </div>
                                        <div className="method">
                                            <Radio value='onepayInternational' disabled>
                                                <div className="fw-medium">Thẻ tín dụng (VISA, Master Card) - (TẠM THỜI CHƯA ÁP DỤNG)</div>
                                                <div>Quý khách sẽ được chuyển đến OnePay để thanh toán</div>
                                                <img className="logo-onepay" src="https://tatinta.com/static/images/logo-onepay-visa.png" alt="logo onepay" />
                                            </Radio>
                                        </div>
                                        <div className="method">
                                            <Radio value='bank_transfer'>
                                                <div className="fw-medium">Chuyển khoản ngân hàng</div>
                                                <div>Quý khách có thể chuyển khoản tại ngân hàng, chuyển qua thẻ ATM, Internet Banking.</div>
                                                {method === 'bank_transfer' ?
                                                    <div className="bank">
                                                        <strong className="text-uppercase">Thông tin tài khoản</strong>
                                                        <Row gutter={[{ xs: 16, lg: 48 }, { xs: 16, lg: 48 }]} align="middle" style={{ marginTop: 20 }}>
                                                            <Col xs={6} lg={6}>
                                                                <img src={`${CONSTANT.STATIC_PATH}images/logo-acb.png`} alt="logo" className="logo-bank" />
                                                            </Col>
                                                            <Col xs={18} lg={18}>
                                                                <p><strong>{CONSTANT.BANK.BANK_NAME}</strong></p>
                                                                <p>Số tài khoản: <strong>{CONSTANT.BANK.NUMBER}</strong></p>
                                                                <p>Người thụ hưởng: <strong>{CONSTANT.BANK.NAM_CARD}</strong></p>
                                                                <p>Nội dung chuyển tiền: <strong>#Mã lịch hẹn</strong></p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    : null}
                                            </Radio>
                                        </div>
                                    </Space>
                                </Radio.Group>
                            </div>

                            <div className="group-btn">
                                <Button className="btn-global bg" icon={<ArrowLeftOutlined />} onClick={history.goBack}>Quay lại</Button>
                                <Button className="btn-global bg" loading={processing} htmlType="submit">Tiếp tục <ArrowRightOutlined /></Button>
                            </div>
                        </Form>

                    </Col>
                    {/* Begin col cart */}
                    <Col xs={24} lg={9} className="col-right">
                        <div className="fw-bold block-title">Dịch vụ đặt</div>
                        <div className="list-product">
                            <div className="item">
                                <div className="box-cover">
                                    <img src={service && service.images && service.images.length > 0 ? COMMON.getImage(service.images[0].filename, 'service') : COMMON.getImage('')} alt={service.slug} />
                                </div>
                                <div className="info">
                                    <div className="title">
                                        {service?.name}
                                    </div>
                                    <div style={{ color: '#555' }}>
                                        <span>Giá: </span>
                                        <span className="price fw-medium">{service?.price && COMMON.currencyFormat(service.price)} {COMMON.getCurrency(service.currency)}</span>
                                    </div>
                                    <div style={{ color: '#555', paddingLeft: 87 }}>
                                        <span>Ngày thực hiện: </span>
                                        <span className="price fw-medium">{moment(dataBookingService.date).format("DD-MM-YYYY")}</span>
                                    </div>
                                    <div style={{ color: '#555', paddingLeft: 87 }}>
                                        <span>Thời gian: </span>
                                        <span className="price fw-medium">{moment(dataBookingService.time).format("HH:mm")}</span>
                                    </div>
                                </div>
                            </div>

                            {
                                voucher?._id && voucher.type === 'generate_code' ?
                                    <React.Fragment>
                                        <Divider />
                                        <div style={{ color: 'red', margin: '10px 0px' }}>
                                            <div><strong>Gói khám đang được áp dụng mã ưu đãi giảm giá. Nhập mã giảm giá nếu bạn đã sở hữu nó từ Vivmedic hoặc đơn vị cung cấp dịch vụ phát hành.</strong></div>
                                            <div>{voucher.description}</div>
                                        </div>
                                        <Input.Search
                                            placeholder="Nhập mã giảm giá"
                                            onChange={onChangeDiscount}
                                            onSearch={() => applyDiscount()}
                                            enterButton="Áp dụng"
                                            size="large"
                                            className="discount mb-0"
                                        />
                                    </React.Fragment>
                                    : voucher.type === 'count_max' ? <div className='ant-alert-success ant-alert-with-description'>
                                        <div className="ant-alert-content">
                                            <p><strong>Gói khám đang được áp dụng chương trình ưu đãi {voucher.name}</strong></p>
                                            <p>
                                                Thời gian ưu đãi: Từ ngày {moment(voucher.from_date).format('DD/MM/YYYY')} đến {moment(voucher.to_date).format('DD/MM/YYYY')}.
                                            </p>
                                            <div style={{ color: 'red' }}>
                                                {
                                                    voucher.quantity_used < voucher.quantity ?
                                                        <div>
                                                            Đã có <strong>{voucher.quantity_used}</strong> đặt. Vẫn còn <strong>{voucher.quantity - voucher.quantity_used}</strong> lượt ưu đãi nữa!
                                                        </div>
                                                        :
                                                        <div>Ưu đãi đã hết hiệu lực do đã có <strong>{voucher.quantity_used}</strong> đặt.</div>
                                                }

                                            </div>

                                        </div>
                                    </div> : <></>
                            }
                            <Divider />
                            <Row>
                                <Col xs={16} md={14}>Thành tiền:</Col>
                                <Col xs={8} md={10} className="text-end fw-medium">{COMMON.currencyFormat(service.price)} {COMMON.getCurrency(service?.currency)}</Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col xs={16} md={8}>Phí dịch vụ ({CONSTANT.FEE.SERVICE_MEDIC * 100}%)</Col>
                                <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(orderPrice?.fee)} {COMMON.getCurrency(service?.currency)}</Col>
                            </Row>
                            {
                                overtimeFee > 0 &&
                                <Row>
                                    <Col xs={16} md={8}>Phí ngoài giờ</Col>
                                    <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(overtimeFee)} {COMMON.getCurrency(service?.currency)}</Col>
                                </Row>
                            }
                            {
                                transpotFee > 0 &&
                                <Row>
                                    <Col xs={16} md={8}>Phí đi lại</Col>
                                    <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(transpotFee)} {COMMON.getCurrency(service?.currency)}</Col>
                                </Row>
                            }

                            {
                                voucher?._id && voucher?.quantity_used < voucher?.quantity &&
                                <React.Fragment>
                                    {
                                        discount.value > 0 && discount.type !== 'count_max' ?
                                            <React.Fragment>
                                                <Divider />
                                                <Row>
                                                    <Col xs={16} md={14}>Giá trị voucher{discount && discount.value_type === 'percent' && discount.value > 0 ? ' (' + 'giảm {value}'.replace('{value}', discount.value + '%') + ')' : ''}:</Col>
                                                    <Col xs={8} md={10} className="text-end fw-medium">{COMMON.currencyFormat(discount.value_type === 'percent' ? (service.price + orderPrice.fee + transpotFee + overtimeFee) * discount.value / 100 : discount.value)} {COMMON.getCurrency(service?.currency)}</Col>
                                                    {
                                                        messageVoucher && <Col xs={24} md={24} style={{marginTop:20, color:"#faad14"}}><strong>{messageVoucher}</strong></Col>
                                                    }
                                                </Row>
                                            </React.Fragment>
                                            : discount.value > 0 && discount.type === 'count_max' ?
                                                <React.Fragment>
                                                    <Divider />
                                                    <Row>
                                                        <Col xs={16} md={14}>Giá trị ưu đãi:</Col>
                                                        <Col xs={8} md={10} className="text-end fw-medium">{COMMON.currencyFormat(discount.value_type === 'percent' ? (service.price + orderPrice.fee + transpotFee + overtimeFee) * discount.value / 100 : discount.value)} {COMMON.getCurrency(service?.currency)}</Col>
                                                    </Row>
                                                </React.Fragment>
                                                : <></>
                                    }
                                </React.Fragment>
                            }

                            <div className="order-total">
                                <span>Tổng cộng</span>
                                <span className="price-total">{service && COMMON.currencyFormat(orderPrice?.total)} {COMMON.getCurrency(service.currency)}</span>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default ServiceBooking;

