import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Steps, message, Row, Col, Divider } from 'antd';
import moment from 'moment';

import * as Services from "../../store/services";
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';

const DoctorPaymentOnSpot = () => {
    const [order, setOrder] = useState({});

    // lấy token từ query url
    const urlQueryParams = useLocation().search;
    const _token = new URLSearchParams(urlQueryParams).get('token');

    useEffect(() => {
        // load detail order
        const loadOrder = async () => {
            if (typeof _token !== 'undefined') {
                return await Services.get(`${CONSTANT.API.DOCTOR.ORDER}/${_token}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin lịch hẹn!');
                    });
            }
        }
        let mounted = true;

        loadOrder().then(data => {
            if (mounted) {
                if (data) {
                    setOrder(data);
                }
            }
        });

        return () => mounted = false;
    }, [_token]);

    return (
        <div className="page-checkout">
            <MetaSeo />
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Steps.Step title="Chọn danh mục" />
                        <Steps.Step title="Thanh toán" />
                        <Steps.Step title="Hoàn thành" />
                    </Steps>
                </div>
                <div className="bank-transfer">
                    <div className="fw-bold">Cảm ơn Quý khách đã sử dụng dịch vụ của Vivmedic</div>
                    <div>Lịch hẹn của Quý khách đã được tạo.</div>
                    <div className="fw-bold block-title" style={{ marginTop: 8 }}>Thông tin lịch hẹn</div>
                    <div className="list-product" style={{ marginBottom: 0 }}>
                        {
                            order?.items?.length > 0 &&
                            <Row className="item">
                                <Col xs={8} lg={4}>
                                    <div className="box-cover">
                                        <img src={order.items[0]?.doctor?.image ? COMMON.getImage(order.items[0].doctor.image, 'doctor') : COMMON.getImage('')} alt={order.items[0].doctor.slug} />
                                    </div>
                                </Col>
                                <Col xs={16} lg={20} className="info">
                                    <div className="title">
                                        {COMMON.createDoctorName(order.items[0]?.doctor?.academic_rank, order.items[0]?.doctor?.degree, order.items[0]?.doctor?.category?.short_name)} {order.items[0]?.doctor?.fullname}
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Dịch vụ: </span>
                                        <span className="fw-medium">{order.items[0]?.service?.name}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Giá: </span>
                                        <span className="fw-medium">{COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Ngày khám bệnh: </span>
                                        <span className="fw-medium">{`${order?.date?.day}/${order?.date?.month}/${order?.date?.year}`}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Giờ: </span>
                                        <span className="fw-medium">{moment(`${order?.time?.time_h}:${order?.time?.time_m}`, "HH:mm").format("HH:mm")}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Địa điểm khám: </span>
                                        <span className="fw-medium">
                                            {order?.location?.name} - {order?.location?.address}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Divider />
                        <Row>
                            <Col xs={16} md={8}>Phí dịch vụ</Col>
                            <Col xs={8} md={16} className="fw-medium text-end">
                                {
                                    `0 ${COMMON.getCurrency(order?.currency)}`
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={16} md={14}>VAT 10% (Giá + Phí dịch vụ)</Col>
                            <Col xs={8} md={10} className="text-end fw-medium">
                                {
                                    order?.items?.length > 0 && COMMON.currencyFormat(0)} {COMMON.getCurrency(order?.currency)}</Col>
                        </Row>
                        <div className="order-total">
                            <span>Tổng cộng</span>
                            <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                        </div>
                    </div>

                    <div>Phương thức thanh toán: <span className="fw-medium">Thanh toán tại nơi khám</span></div>

                    <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                        <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DoctorPaymentOnSpot;