import produce from 'immer';
import { 
    GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE, GET_DETAIL_SUCCESS
} from './type';

const initialState = {
    list: [],
    detail:{},
    loading: false,
    error: false
}

function destinationReducer(state = initialState, action){
    
    const {payload} = action;
    return produce(state, draft => {
        switch (action.type) {
            // List
            case GET_LIST:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_LIST_SUCCESS:
                draft.list = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_LIST_FAILURE:
                draft.loading = false;
                draft.list = [];
                draft.error = payload;
                break;
            // Detail city
            case GET_DETAIL_SUCCESS:
                draft.detail = payload;
                draft.loading = false;
                draft.error = false;
                break;
            
            default:
                break;
        }
    })
    
}

export default destinationReducer;