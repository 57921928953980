import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Empty, Button, Form } from 'antd';
import { VideoCameraOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import moment from 'moment';

import * as COMMON from '../../../helper/common';
import ModalBookServiceOnline from './modal-book-online';

const ItemServiceOnline = (props) => {
    const [form] = Form.useForm();

    const [calendars, setCalendars] = useState([]);
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedTime, setSelectedTime] = useState({});
    const [selectedTimes, setSelectedTimes] = useState([]);

    const [isModalOnlineVisible, setIsModalOnlineVisible] = useState(false);

    useEffect(() => {
        if (props?.data?.calendars?.length > 0) {
            let calendars = [...props.data.calendars];
            let currentDate = moment(new Date()).format('YYYY-MM-DD');
            calendars = calendars.filter(c => moment(currentDate).isSameOrBefore(moment(`${c.year}-${c.month}-${c.day}`, 'YYYY-MM-DD')));

            calendars.sort((a, b) => (a.month > b.month) ? 1 : (a.month === b.month) ? ((a.day > b.day) ? 1 : -1) : -1);
            setCalendars(calendars);
            if (calendars.length > 0) {
                let calendar = { ...calendars[0] };
                let calendarTimes = [...calendar.times];
                calendarTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
                setSelectedTimes(calendarTimes);
                delete calendar.times;
                setSelectedDate({ ...calendar });
                form.setFieldsValue({ date: calendar._id });
            }
        }
    }, [props]);
    // Xử lý sự kiện khi chọn ngày
    const onChangeDate = (value, option) => {
        if (value) {
            let calendar = JSON.parse(option?.key);
            let calendarTimes = [...calendar.times];
            calendarTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
            setSelectedTimes(calendarTimes);
            delete calendar.times;
            setSelectedDate({ ...calendar });
        }
    }
    const bookServiceOnline = data => () => {
        setIsModalOnlineVisible(true);
        setSelectedTime({ ...data })
    }
    return (
        <>
            {
                props?.data.is_online &&
                <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]} className="item">
                    <Col xs={24} lg={9} key={props.data._id} className="col-info">
                        <div className="title">{props.data.name}</div>
                        {
                            (props.data?.is_free === false) ?
                                <div className="price">
                                    <strong>Giá:</strong> {COMMON.currencyFormat(props.data.price)} {COMMON.getCurrency('VND')}
                                </div> :
                                <div className="price">
                                    <strong>Tư vấn miễn phí</strong>
                                </div>
                        }

                        {
                            props.data?.price_note &&
                            <div style={{ fontStyle: 'italic', color: "#777", fontSize: '13px', marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: `${props.data?.price_note.replace(/(?:\r\n|\r|\n)/g, '<br />')}` }}></div>
                        }

                        <div className="description d-none d-md-block">{props?.data?.summary && parse(props?.data?.summary?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}</div>
                    </Col>
                    <Col xs={24} lg={15}>
                        <div className="text-uppercase header-calendar">
                            <VideoCameraOutlined /> Lịch tư vấn qua video
                        </div>
                        <Form
                            name="basic"
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item
                                name="date"
                            >
                                <Select
                                    placeholder="Chọn ngày"
                                    className="select-global select-date"
                                    size='large'
                                    allowClear={false}
                                    onChange={onChangeDate}
                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
                                >
                                    {
                                        calendars.length > 0 &&
                                        calendars.map(c => (
                                            <Select.Option key={JSON.stringify(c)} value={c._id}>{`${COMMON.getDayOfWeek(c.day_of_week.toString())} - ${c.day}/${c.month}/${c.year}`}</Select.Option>
                                        ))
                                    }

                                </Select>
                            </Form.Item>
                        </Form>
                        <Row className="group-btn-time" gutter={[{ xs: 16, md: 16 }, { xs: 16, md: 16 }]}>
                            {
                                selectedTimes.length > 0 && selectedTimes.map(time => (
                                    <Col xs={12} md={6} key={time._id}>
                                        <Button className="btn-time"
                                            type="primary"
                                            disabled={
                                                time.is_order || (
                                                    moment(new Date(), "YYYY-MM-DD").isSameOrAfter(moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`, "YYYY-MM-DD")) &&
                                                    moment(new Date(), "HH:mm").isSameOrAfter(moment(`${time.time_h}:${time.time_m}`, "HH:mm"))
                                                )
                                            }
                                            key={time._id} shape="round" icon={<VideoCameraAddOutlined />}
                                            onClick={bookServiceOnline(time)}
                                        >
                                            {moment(`${time.time_h}:${time.time_m}`, "HH:mm").format("HH:mm")} - {COMMON.calculateTime(`${time.time_h}:${time.time_m}`, time.duration)}
                                        </Button>
                                    </Col>
                                ))
                            }
                        </Row>
                        <div className="description d-block d-md-none">
                            <div className="fw-medium">Mô tả:</div>
                            {props?.data?.summary && parse(props?.data?.summary?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                        </div>
                    </Col>

                    <ModalBookServiceOnline
                        visible={isModalOnlineVisible}
                        cancel={() => setIsModalOnlineVisible(false)}
                        data={props.data}
                        dataDoctor={props.dataDoctor}
                        date={selectedDate}
                        time={selectedTime}
                    />
                </Row>
            }
        </>
    )
}

export default ItemServiceOnline;