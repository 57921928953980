import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row, Col, Breadcrumb, Card, Tabs, Table, Space, Form, Input, Button, Modal, Tag, Popconfirm,
} from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
// import common
import * as COMMON from '../../../helper/common';
import CONSTANT from '../../../config/constant';
import * as Services from "../../../store/services";
// import component
import AccountMenu from './components/menu';
import './account.css';


const arrStatus = {
    'wait-paid': { text: "Chờ thanh toán", color: "orange" },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'packing': { text: 'Đang đóng gói', color: 'orange' },
    'delivery-in-progress': { text: 'Đang giao hàng', color: 'orange' },
    'customer-received': { text: 'Khách đã nhận hàng', color: 'green' },
    'completed': { text: 'Đã hoàn thành', color: 'green' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Bạn đã hủy đơn này', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
    'trash': { text: 'Đang đặt mua', color: 'black' },
};
const { TabPane } = Tabs;
const UserOrder = () => {
    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
            render: (uid) => 'VIVMEDIC-P' + uid
        },
        {
            title: 'Địa chỉ nhận',
            dataIndex: 'shipping',
            render: (shipping) =>
            (
                <div>
                    {shipping.first_name + ' ' + shipping.last_name} - {shipping.phone} <br></br>
                    {shipping.address_1 + ',' + shipping.address_2 + ',' + shipping.city + ',' + shipping.country}
                </div>
            )
        },
        {
            title: 'Tổng cộng', dataIndex: 'total', align: 'right',
            render: (total, record) => COMMON.currencyFormat(total) + COMMON.getCurrency(record.currency)
        },
        {
            title: 'Ngày mua', dataIndex: 'created_at', sorter: true, width: '170px',
            render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
        },
        {
            title: 'Hành động', width: '120px',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => { openModal(record) }}><EyeOutlined /> Chi tiết</Button>
                </Space>
            )
        }

    ];
    let currentUser = localStorage.getItem(CONSTANT.STORAGE_USER);
    if (currentUser) currentUser = JSON.parse(currentUser);
    const [user, setUser] = useState(currentUser || {});
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('all');
    const [visible, setVisible] = useState(false);
    const [order, setOrder] = useState(null);
    const openModal = (orderDetail) => {
        setOrder(orderDetail);
        setVisible(true);
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        loadOrders(status, pagination.current, pagination.pageSize, sorter);
    }
    const changeStatusOrder = async (statusOrder) => {
        if (statusOrder !== status) {
            await setOrders([]);
            await setStatus(statusOrder);
            await loadOrders(statusOrder, 1, 10, null);
        }
    }
    const handleSearch = async (values) => {
        loadOrders('all', 1, 10, null, values.keySearch);
    }
    const loadOrders = async (statusGet, current, pageSize, sorter, idSearch) => {
        setLoading(true);
        //debugger;
        let parameters = { created_by: user._id };
        parameters.page = current;
        parameters.perPage = pageSize;
        if (statusGet !== 'all') parameters.status = statusGet;
        if (idSearch) parameters.uid = idSearch.replace('VIVMEDIC-P', '');

        await Services.get(`${CONSTANT.API.PRODUCT.ORDER}`, parameters)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    const { docs, total } = res.data.data;
                    await setOrders(docs);
                    let paging = pagination;
                    paging.total = total;
                    paging.current = current;
                    paging.pageSize = pageSize;
                    setPagination(paging);
                }
                setLoading(false);
            })
    }
    // effect
    useEffect(() => {
        loadOrders('all', 1, 10, null);
    }, []);
    return (
        <>
            <div className="wrap-breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/user/order">Đơn hàng của bạn</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="page-profile">
                <div className="container">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                        {/* Begin col menu */}
                        <Col xs={24} md={5}>
                            <AccountMenu data={user} />
                        </Col>

                        {/* Begin col info */}
                        <Col xs={24} md={19}>
                            <Card title={
                                <>
                                    <div>Quản lý đơn hàng của bạn</div>
                                    <div style={{ fontSize: 14, fontWeight: 400 }}>Xem chi tiết đơn hàng đã đặt mua</div>
                                </>
                            }>
                                <Tabs defaultActiveKey="all" onChange={(key) => {
                                    changeStatusOrder(key);
                                }}>
                                    <TabPane tab="Tất cả" key="all">
                                        <Form
                                            name="customized_form_controls"
                                            layout="inline"
                                            onFinish={handleSearch}
                                            initialValues={{
                                                keySearch: ''
                                            }}
                                        >
                                            <Form.Item name="keySearch" label="Tìm kiếm">
                                                <Input placeholder="Nhập mã số đơn hàng" />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Tìm kiếm
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </TabPane>
                                    <TabPane tab="Chờ thanh toán" key="wait-paid">
                                    </TabPane>
                                    <TabPane tab="Chờ xác nhận" key="paid">
                                    </TabPane>
                                    <TabPane tab="Đã xác nhận " key="confirmed">
                                    </TabPane>
                                    <TabPane tab="Đóng gói" key="packing">
                                    </TabPane>
                                    <TabPane tab="Đang giao" key="delivery-in-progress">
                                    </TabPane>
                                    <TabPane tab="Hoàn thành" key="completed">
                                    </TabPane>
                                    <TabPane tab="Đã hủy" key="cancelled">
                                    </TabPane>
                                </Tabs>
                                <Table
                                    columns={columns}
                                    dataSource={orders}
                                    loading={loading}
                                    pagination={pagination}
                                    rowKey={record => record._id}
                                    onChange={handleTableChange}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                title={'Chi tiết đơn hàng ' + (order ? 'VIVMEDIC-P' + order.uid : '')}
                visible={visible}
                okText="Đóng"
                closable={false}
                centered
                onOk={() => setVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={800}
            >                
                {
                    order && <div className="ant-table-content">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xs={12} md={6} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                Họ tên người nhận:
                            </Col>
                            <Col xs={12} md={8}>
                                {order.shipping.first_name + ' ' + order.shipping.last_name}
                            </Col>
                            <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                Điện thoại:
                            </Col>
                            <Col xs={12} md={5}>
                                {order.shipping.phone}
                            </Col>
                            <Col xs={12} md={6} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                Địa chỉ người nhận:
                            </Col>
                            <Col xs={12} md={18}>
                                {order.shipping.address_1 + ',' + order.shipping.address_2 + ',' + order.shipping.city + ',' + order.shipping.country}
                            </Col>
                            <Col xs={12} md={6} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                Ngày đặt mua:
                            </Col>
                            <Col xs={12} md={8}>
                                {moment(new Date(order.created_at)).format(CONSTANT.DATE_FORMAT)}
                            </Col>
                            <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                Trạng thái:
                            </Col>
                            <Col xs={12} md={5}>
                                {arrStatus[order.status].text}
                            </Col>
                            <Col xs={12} md={12}>
                                {
                                    <Tag color={arrStatus[order.status].color}>{arrStatus[order.status].text}</Tag>
                                }
                            </Col>
                            <Col xs={12} md={12}>
                                {
                                    (order.status === 'wait_paid' || order.status === 'paid') &&
                                    <div style={{ textAlign: 'right', padding: '10px 20px', width: '100%' }}>
                                        <Popconfirm title="Bạn muốn hủy đơn hàng này này?" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => { }}>
                                            <Button danger><DeleteOutlined /> Hủy đơn hàng</Button>
                                        </Popconfirm>
                                    </div>
                                }
                            </Col>

                        </Row>
                        <table style={{ tableLayout: "auto", width: "100%" }}>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell">Hình ảnh</th>
                                    <th className="ant-table-cell">Sản phẩm</th>
                                    <th className="ant-table-cell">Số lượng</th>
                                    <th className="ant-table-cell">Đơn giá</th>
                                    <th className="ant-table-cell">Thành tiền</th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {
                                    order.shop && order.shop.map((shop) => (
                                        shop && shop.items && shop.items.length > 0 && shop.items.map((item, index1) => (
                                            <tr className="ant-table-row" key={index1}>
                                                <td className="ant-table-cell" style={{ width: '150px' }}>
                                                    <img src={COMMON.getImageVariant(item)} style={{ width: '100%' }}
                                                        alt={item.product.name}
                                                    />
                                                </td>
                                                <td className="ant-table-cell" style={{ width: '250px' }}>
                                                    <h4 className="product-title">{item.product.name}</h4>
                                                    <p className="product-attribute">
                                                        {
                                                            item.variant && item.variant.attributes.map((attr, indexAttr) => {
                                                                let str = attr.options[0].name;
                                                                if (indexAttr < item.variant.attributes.length - 1)
                                                                    str += " / ";
                                                                return str + ' ';
                                                            })
                                                        }
                                                    </p>
                                                    {
                                                        shop.status_order ?
                                                            <Tag color={arrStatus[shop.status_order].color}>{arrStatus[shop.status_order].text}</Tag>:'Không xác định'
                                                    }

                                                </td>
                                                <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                    {item.quantity}
                                                </td>
                                                <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                    {COMMON.currencyFormat(parseFloat(item.price))} {COMMON.getCurrency(order.currency)}
                                                </td>
                                                <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                    {COMMON.currencyFormat(parseFloat(item.price * item.quantity))} {COMMON.getCurrency(order.currency)}
                                                </td>
                                            </tr>
                                        )
                                        )
                                    )
                                    )
                                }
                            </tbody>
                        </table>
                        <div className="ant-table-footer">
                            <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}>
                                <span style={{ float: 'left', fontWeight: 700 }}>Tổng tiền hàng</span>
                                <span style={{ float: 'right', fontWeight: 700 }}>
                                    {(order.total && `${COMMON.currencyFormat(order.total)} ${COMMON.getCurrency(order.currency)}`) || 0}
                                </span>
                            </div>
                        </div>
                    </div>

                }

            </Modal>
        </>

    )
}
export default UserOrder;
