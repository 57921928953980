//get areas
export const GET_AREAS = 'GET_AREAS'
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS'
export const GET_AREAS_FAILURE= 'GET_AREAS_FAILURE'
//get countries
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAILURE= 'GET_COUNTRIES_FAILURE'
//get cities
export const GET_CITIES = 'GET_CITIES'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILURE= 'GET_CITIES_FAILURE'
//get wards
export const GET_WARDS = 'GET_WARDS'
export const GET_WARDS_SUCCESS = 'GET_WARDS_SUCCESS'
export const GET_WARDS_FAILURE= 'GET_WARDS_FAILURE'