import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Breadcrumb, Form, Input, Button, message, Row, Col, DatePicker, Select, Modal
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import * as Services from '../../store/services';
import CONSTANT from '../../config/constant';

import './user-style.css';
const Register = () => {
    let history = useHistory();
    const [waiting, setWaiting] = useState(false);
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });

    useEffect(() => {
        let user = localStorage.getItem(CONSTANT.STORAGE_USER);
        if (user) {
            history.push('/');
        }
    })

    const onFinish = (values) => {
        setWaiting(true);
        let dataRegister = {
            email: values.email,
            password: values.password,
            firstname: values.firstname,
            lastname: values.lastname,
            dob: values.dob,
            sex: values.gender,
            dial_code: phones.dial_code,
            phone: values.phone ? values.phone.replace(phones.dial_code, '') : '',
            role: "user",
        };

        Services.post(`${CONSTANT.API.AUTH.REGISTER}`, dataRegister)
            .then(res => {
                if (res && res.data.code === 200) {
                    Modal.success({
                        title: "Đăng ký tài khoản thành công",
                        content: `Tatinta đã gửi email kích hoạt tài khoản vào email của bạn. Vui lòng kiểm tra email và kích hoạt tài khoản của bạn! Xin cảm ơn!`,
                        okText: "Đóng",
                        onOk: () => history.push(`/${CONSTANT.SLUG_LOGIN}`)
                    });
                    setWaiting(false);
                }
                else if (res.data.code === 409) {
                    message.warning('Email này đã được đăng ký! Vui lòng nhập Email khác!', 5);
                }
                setWaiting(false);
            })
            .catch(error => {
                message.error('Đăng ký không thành công! Vui lòng thử lại sau!', 5).then(() => {
                    history.push(`/${CONSTANT.SLUG_LOGIN}`);
                });
                setWaiting(false);
            })

    }
    const onFinishFailed = () => {
        message.warning('Vui lòng kiểm tra lại dữ liệu nhập', 5);
    }
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Đăng ký tài khoản</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                <div className="block-header">
                    <div className="text-uppercase title">Đăng ký tài khoản</div>
                    <div className="line"></div>
                </div>
                <div className="box-register">
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Row gutter={32}>
                            <Col lg={12}>
                                <Form.Item
                                    label="Họ"
                                    name="firstname"
                                    rules={[{ required: true, message: 'Vui lòng nhập Họ!' }]}
                                >
                                    <Input className="rounded-pill" size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Tên"
                                    name="lastname"
                                    rules={[{ required: true, message: 'Vui lòng nhập Tên!' }]}
                                >
                                    <Input className="rounded-pill" size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={32}>
                            <Col lg={12}>
                                <Form.Item
                                    label="Ngày sinh"
                                    name="dob"
                                >
                                    <DatePicker className="select-global rounded-pill" size="large" placeholder="Ngày sinh" style={{ width: '100%' }} format={"DD-MM-YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Giới tính"
                                    name="gender"
                                >
                                    <Select className="select-global rounded-pill" size="large" placeholder="Chọn giới tính">
                                        <Select.Option value="male">Nam</Select.Option>
                                        <Select.Option value="female">Nữ</Select.Option>
                                        <Select.Option value="other">Khác</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Email"
                            name="email"

                            rules={[
                                {
                                    type: 'email',
                                    message: 'Email chưa đúng định dạng',
                                },
                                {
                                    required: true,
                                    message: 'Vui lòng nhập E-mail!',
                                }
                            ]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>

                        <Form.Item label="Số điện thoại" name="phone">
                            <PhoneInput
                                country='vn'
                                value={phones.phone}
                                onChange={(value, data) => {
                                    setPhones({
                                        phone: value,
                                        dial_code: data.dialCode,
                                        country_code: data.countryCode
                                    })
                                }}

                            />
                        </Form.Item>

                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            rules={[
                                { required: true, message: 'Vui lòng nhập mật khẩu!' },
                                {
                                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                                    message: "Mật khẩu phải có ít nhất 6 ký tự bao gồm ký tự Hoa, thường, số và ký tự đặc biệt."
                                }
                            ]}
                        >
                            <Input.Password className="rounded-pill" size="large" />
                        </Form.Item>

                        <Form.Item
                            label="Nhập lại mật khẩu"
                            name="repassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập lại mật khẩu ',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Mật khẩu nhập lại chưa khớp!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password className="rounded-pill" size="large" />
                        </Form.Item>

                        <div className="text-center" style={{ marginBottom: 20, color: '#777' }}>
                            Khi nhấn "Đăng ký", bạn xác nhận đồng ý với Điều khoản và điều kiện và Chính sách bảo mật
                        </div>

                        <Form.Item className="text-center">
                            <Button type="primary" loading={waiting} className="btn-global bg btn-register" htmlType="submit">
                                Đăng ký
                            </Button>
                        </Form.Item>

                        <div className="text-center" style={{ marginTop: 10, marginBottom: 40 }} >
                            Bạn đã có tài khoản?
                            <Link className="link-register" style={{ paddingLeft: 7 }} to="/dang-nhap">
                                Đăng nhập!
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Register;