import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Spin, Avatar } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-multi-lang';

import { DoctorAction } from '../../../store/root-action';
import * as COMMON from '../../../helper/common';
import CONSTANT from '../../../config/constant';


const SectionMedicalOnline = () => {
    const t = useTranslation();
    const listDoctors = useSelector(state => state.doctorReducer.list);
    const loading = useSelector(state => state.doctorReducer.loading);

    const dispatch = useDispatch();

    // load product theo category id
    const loadDoctors = useCallback(async () => {
        await dispatch(DoctorAction.getList({
            perPage: 4, page: 1, status: 'publish', is_online: true, is_service: 0
        }));
    }, [dispatch])

    useEffect(() => {
        loadDoctors();
    }, [loadDoctors]);

    return (
        <div className="container section-doctor-online">
            <div className="block-header">
                <div className="text-uppercase title">{t('medical_online')}</div>
                <div className="sapo">{t('medical_online_sapo')}</div>
                <div className="line"></div>
            </div>

            <Spin spinning={loading}>
                <div className="list-doctor">
                    <Row gutter={[{ xs: 16, lg: 24 }, { xs: 16, lg: 24 }]}>
                        {
                            listDoctors?.docs?.length > 0 && listDoctors?.docs.map(item => (
                                <Col xs={12} lg={6} key={item._id}>
                                    <Link to={COMMON.createLinkDoctorDetail(`${item?.academic_rank || ''} ${item?.degree || ''} ${item?.category?.short_name || ''} ${item.slug}`, item.uid)}>
                                        <Card
                                            hoverable
                                            cover={<div className="box-cover"><img alt={`${COMMON.createDoctorName(item.academic_rank, item.degree, item.fullname)}`} src={COMMON.getImage(item.image, 'doctor')} /></div>}
                                            className="doctor"
                                        >
                                            <Card.Meta
                                                title={
                                                    <>
                                                        <h2 className="title">{item.fullname}</h2>
                                                        <h3 className="degree">{COMMON.createDoctorName(item.academic_rank, item.degree, item?.category?.short_name)}</h3>
                                                    </>
                                                }
                                            />
                                            <div className="location">
                                                <ion-icon name="location-sharp"></ion-icon>
                                                {item.city && item.city.name}
                                            </div>
                                            <div className="specialty">
                                                <Avatar src={item?.specialties?.length > 0 && COMMON.getImage(item.specialties[0].avatar, 'specialty')} />
                                                <div className="name">
                                                    {item?.specialties?.length > 0 ? item.specialties.map(elem => elem.name).join(', ') : "Đang cập nhật..."}
                                                </div>
                                            </div>
                                        </Card>
                                    </Link>
                                </Col>
                            ))
                        }

                    </Row>
                </div>

                <div className="text-end view-more">
                    <Link to={`/${CONSTANT.SLUG_MEDICAL_ONLINE}`} title={`Xem thêm Tư vấn từ xa`}>Xem thêm <RightOutlined /></Link>
                </div>
            </Spin>
        </div>
    )
}

export default SectionMedicalOnline;