import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Breadcrumb, Row, Col, Button, Avatar, Tabs, Divider, Select
} from 'antd';
import {
    HomeOutlined, FileDoneOutlined, ClockCircleOutlined, UserOutlined, AppstoreOutlined, PhoneOutlined, EnvironmentOutlined
} from '@ant-design/icons';
import parse from 'html-react-parser';
import moment from 'moment';
// import lybrary image gallery
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import CONSTANT from '../../config/constant';
import ModalBookFacility from './components/modal-book-facility';
import ModalShowMap from '../common/modal-show-map';
import * as COMMON from '../../helper/common';
import { HospitalAction } from '../../store/root-action';
import { useSelector, useDispatch } from 'react-redux';
import MetaSeo from '../../component/meta-seo';
import ItemExaminationPackage from './components/item-examination-package';
import ItemService from './components/item-service';
import DoctorSpecialty from './components/doctor-specialty';
import * as Services from '../../store/services';

const FacilityDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalMapVisible, setIsModalMapVisible] = useState(false);
    const [dataMap, setDataMap] = useState({});
    const bookFacility = () => (data) => {
        setIsModalVisible(true);
    }
    const [images, setImages] = useState([]);
    const [services, setServices] = useState([]);
    const showMap = async () => {
        await setDataMap({
            title: hospital.name,
            address: hospital.address,
            position: {
                lat: hospital.loc.coordinates[0],
                lng: hospital.loc.coordinates[1]
            }
        });
        setIsModalMapVisible(true);
    }
    const hospital = useSelector(state => state.hospitalReducer.detail);
    useEffect(() => {
        const loadSpots = async () => {
            await dispatch(HospitalAction.getDetail(id));
        };
        loadSpots();
    }, [dispatch, id]);

    // load danh sach dich vu cua co so y te
    const loadServices = useCallback(async () => {
        await Services.get(`${CONSTANT.API.SERVICE.SERVICE}?perPage=30&page=1&status=publish&is_deleted=false&sort=%7B%22created_at%22%3A+-1%7D&created_by=${hospital.created_by}`)
            .then(res => {
                if (res?.data?.code === 200) {
                    setServices(res?.data?.data?.docs);
                }
            })
    }, [hospital]);

    useEffect(() => {
        // raw images
        if (hospital && hospital.images && hospital.images.length > 0) {
            let _images = [];
            for (let element of hospital.images) {
                let original = COMMON.getImage(element.filename, 'hospital');
                //let thumbnail = original;
                /*if (original) {
                    let ext = original.substring(original.lastIndexOf("."))
                    thumbnail = original.replace(ext, '-small') + ext;
                }*/
                _images.push({
                    original: original,
                    thumbnail: original
                });
                setImages(_images);
            }

            loadServices();
        }
    }, [id, hospital, loadServices]);

    return (
        <React.Fragment>
            <MetaSeo
                title={hospital?.name}
                description={hospital?.description && COMMON.splitString(hospital?.description.replace(/<[^>]+>/g, ''), 50)}
                keyword={`${hospital?.name}, cơ sở y tế, ${CONSTANT.PAGE_KEYWORD_EXT}`}
                extra={<script async src="https://sp.zalo.me/plugins/sdk.js"></script>}
                image={hospital?.images?.length > 0 && COMMON.getImage(hospital.images[0].filename, 'hospital')}
            />
            <div className="facility-detail">

                {/* Begin breadcrumb */}
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={`/${CONSTANT.SLUG_FACILITY}`}>Cơ sở y tế</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{hospital?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <div className="container">
                    <Row gutter={[{ xs: 0, md: 32 }, { xs: 0, md: 32 }]}>
                        {/* Begin col gallery */}
                        <Col xs={24} lg={11} className="col-gallery">
                            <ImageGallery items={images} showPlayButton={false} />
                        </Col>

                        {/* Begin code info product */}
                        <Col xs={24} lg={13} className="col-info">
                            <h1 className="product-title">{hospital?.name}</h1>
                            <div className="specialty">
                                {
                                    hospital && hospital.specialties && hospital.specialties.length > 0 && hospital.specialties.map((item, index) => (
                                        <Avatar key={index} size={22} src={item?.specialty?.avatar && COMMON.getImage(item.specialty.avatar, 'hospital')} title={item?.specialty?.name} />
                                    ))
                                }
                            </div>
                            <div className="other-info">
                                <Row>
                                    <Col xs={2} md={1}><HomeOutlined color="#fff" /> </Col>
                                    <Col xs={22} md={23}>Địa chỉ: {hospital?.address}</Col>
                                </Row>
                                {
                                    hospital?.phone &&
                                    <Row>
                                        <Col xs={2} md={1}><PhoneOutlined /> </Col>
                                        <Col xs={22} md={23}>Điện thoại: {hospital.phone}</Col>
                                    </Row>
                                }
                                {
                                    hospital?.timeserving &&
                                    <Row>
                                        <Col xs={2} md={1}><ClockCircleOutlined /></Col>
                                        <Col xs={22} md={23}>Thời gian làm việc: {hospital.timeserving}</Col>
                                    </Row>
                                }
                                {
                                    hospital?.license_operating &&
                                    <Row>
                                        <Col xs={2} md={1}><FileDoneOutlined /></Col>
                                        <Col xs={22} md={23}>Giấy phép: {hospital.license_operating}. Ngày cấp: {moment(hospital?.license_issue_date).format("DD/MM/YYYY")}</Col>
                                    </Row>
                                }
                                {
                                    hospital?.person_in_charge &&
                                    <Row>
                                        <Col xs={2} md={1}><UserOutlined /></Col>
                                        <Col xs={22} md={23}>Người phụ trách chuyên môn: {hospital?.person_in_charge}</Col>
                                    </Row>
                                }
                                {
                                    hospital?.scope_of_specialty &&
                                    <Row>
                                        <Col xs={2} md={1}><AppstoreOutlined /></Col>
                                        <Col xs={22} md={23}>Phạm vi chuyên môn: {hospital?.scope_of_specialty}</Col>
                                    </Row>
                                }
                                <Row>
                                    <Col xs={2} md={1}><EnvironmentOutlined style={{ marginTop: '8px' }} /></Col>
                                    <Col xs={22} md={23}>
                                        <Button type="link" className="btn-view-map" onClick={() => showMap()}>Xem bản đồ</Button>
                                        <ModalShowMap
                                            visible={isModalMapVisible}
                                            cancel={() => setIsModalMapVisible(false)}
                                            data={dataMap}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="contact">
                                <div className="label">Liên hệ:</div>
                                <div
                                    style={{ borderRadius: '50%', overflow: 'hidden', height: 32, width: 32, background: '#16A6FA', marginRight: 5 }}
                                    dangerouslySetInnerHTML={{ __html: `<div class="zalo-share-button" style="margin-top:1px; margin-left:1px" data-href="${`http://localhost:7000/co-so-y-te/benh-vien-tam-duc/1`}" data-oaid="490313190496970851" data-layout="3" data-color="blue" data-customize=false></div>` }}
                                />
                                <div className="facebook">
                                    <img src="/static/images/facebook.svg" alt="" />
                                </div>
                                <div className="messenger">
                                    <img src="/static/images/messenger.svg" alt="" />
                                </div>
                            </div>
                            <div style={{ display: 'none' }}>
                                <Button className="btn-global bg btn-book" onClick={bookFacility({})} >Đặt hẹn</Button>
                                <ModalBookFacility
                                    visible={isModalVisible}
                                    hospitalId={hospital._id}
                                    hospitalName={hospital.name}
                                    cancel={() => setIsModalVisible(false)}
                                    specialties={hospital.specialties}
                                />
                            </div>
                            {/*
                                hospital.created_by && (
                                    <div className="specialty post-by">
                                        <Avatar size={50} src={hospital?.created_by?.avatar ? hospital.created_by.avatar : ''} />
                                        Đăng bởi &nbsp; <a href="/">{(hospital?.created_by?.firstname ? hospital.created_by.firstname : '') + ' ' + (hospital.created_by.lastname ? hospital.created_by.lastname : '')}</a>
                                    </div>
                                )*/
                            }

                        </Col>
                    </Row>

                    <Tabs type="card" size="large" className="tabs-info" defaultActiveKey="bsck">
                        <Tabs.TabPane tab="Giới thiệu" key="gt">
                            {
                                hospital?.introduction &&
                                <>
                                    <Divider className="about-head" orientation="left">Giới thiệu chung</Divider>
                                    {parse(`${hospital.introduction}`)}
                                </>
                            }

                            {
                                hospital?.strengths &&
                                <>
                                    <Divider className="about-head" orientation="left">Thế mạnh chuyên môn</Divider>
                                    {parse(`${hospital.strengths}`)}
                                </>
                            }


                            {
                                hospital?.equipment &&
                                <>
                                    <Divider className="about-head" orientation="left">Trang thiết bị</Divider>
                                    {parse(`${hospital.equipment}`)}
                                </>
                            }

                            {
                                hospital?.ground_plan &&
                                <>
                                    <Divider className="about-head" orientation="left">Sơ đồ mặt bằng</Divider>
                                    {parse(`${hospital.ground_plan}`)}
                                </>
                            }

                            {
                                hospital?.process_medical_examination &&
                                <>
                                    <Divider className="about-head" orientation="left">Quy trình khám bệnh</Divider>
                                    {parse(`${hospital.process_medical_examination}`)}
                                </>
                            }


                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Bác sĩ chuyên khoa" key="bsck" className="service-specialty doctor-detail">
                            <div className="tabs-detail">
                                <div className="service">
                                    <DoctorSpecialty
                                        specialties={hospital?.specialties}
                                        doctors={hospital?.doctors}
                                        hospital={{
                                            _id: hospital._id,
                                            address: hospital.address,
                                            name: hospital.name
                                        }}
                                    />
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Gói dịch vụ" key="dvck" className="service-specialty doctor-detail" >
                            <div className="tabs-detail">
                                <div className="service">
                                    <div className="list-product">
                                        <Row gutter={[{ xs: 16, lg: 32 }, { xs: 16, lg: 32 }]}>
                                            {
                                                services.map(item => (
                                                    <ItemService data={item} key={item.uid} />
                                                ))
                                            }
                                        </Row>
                                    </div>

                                </div>
                            </div>

                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FacilityDetail;