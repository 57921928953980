import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { Breadcrumb, Spin, Empty, message, Collapse, List, Row, Col, Card, Avatar } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./travel-medical.css";
import { axiosInstance } from "../../config/api";
import { useTranslation } from 'react-multi-lang';

import MetaSeo from "../../component/meta-seo";
import CONSTANT from "../../config/constant";
import * as COMMON from "../../helper/common";

function TravelMedicalEmergency() {
    const history = useHistory();
    const t = useTranslation();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageSize: 20,
        total: 0,
        hideOnSinglePage: true,
        showLessItems: true,
    });


    useEffect(() => {
        let mounted = true;
        if (mounted) {

        }
        return () => (mounted = false);
    }, []);



    return (
        <React.Fragment>
            <MetaSeo
                title="Cấp cứu"
                keyword={`cấp cứu, cap cuu, emergency, ${CONSTANT.PAGE_KEYWORD_EXT}`}
                description="Các du khách đến Việt Nam khi có vấn đề cần cấp cứu rất bối rối. Thông thường, họ sẽ liên hệ Hướng dẫn viên hoặc Lễ tân khách sạn nơi họ ở để có sự hỗ trợ."
            />

            <div className="page-travel-medical">
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">Trang chủ</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}`}>{t('travel_medical')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Cấp cứu</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <div className="container">
                    <div className="block-header">
                        <div className="text-uppercase title">Cấp cứu</div>
                        <div className="line"></div>
                    </div>
                    <div className="emergency-content">
                        <p>Các du khách đến Việt Nam khi có vấn đề cần cấp cứu rất bối rối. Thông thường, họ sẽ liên hệ Hướng dẫn viên hoặc Lễ tân khách sạn nơi họ ở để có sự hỗ trợ.</p>
                        <p>Để quá trình thực hiện cấp cứu cho du khách sẽ được tiến hành nhanh chóng thuận lợi, Vivmedic tổ chức kết nối với các trung tâm cấp cứu chất lượng uy tín gần nhất và hỗ trợ giải tỏa các rào cản về ngôn ngữ, pháp lý, và hồ sơ thanh toán cho du khách.</p>
                        <div className="step">Các bước</div>
                        <div><strong>Bước 1:</strong> Nhận cuộc gọi cấp cứu - Nối máy đến Trung tâm cấp cứu các Bệnh viện</div>
                        <div><strong>Bước 2:</strong> Trung tâm Cấp cứu các Bệnh viện thực hiện quá trình Cấp cứu cùng với dịch vụ hỗ trợ từ VIVMEDIC</div>
                        <div><strong>Bước 3:</strong> VIVMEDIC hỗ trợ thu thập hồ sơ cấp cứu, ghi nhận diễn tiến, tổng hợp chi phí để bệnh nhân có chứng từ về thanh toán lại với Bảo hiểm hay các bên có liên quan.</div>
                        <Row gutter={[{ xs: 16, lg: 0 }, 24]} justify="center" className="group-hotline">
                            <Col xs={24} md={8} className="text-center">
                                <strong>Hotline 1</strong>
                                <div className="hotline text-center">(+84) 818 889 668</div>
                            </Col>

                            <Col xs={24} md={8} className="text-center">
                                <strong>Hotline 2</strong>
                                <div className="hotline text-center">(+84) 981 627 415</div>
                            </Col>
                            { /*
                            <Col xs={24} md={8} className="text-center">
                                <strong>Hotline 3</strong>
                                <div className="hotline text-center">(+84) 818 889 668</div>
                            </Col>
                            */}
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TravelMedicalEmergency;
