import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-multi-lang';

import CONSTANT from '../../../config/constant';


const SectionGroupService = () => {
    const t = useTranslation();
    return (
        <div className="section-group-service">
            <div className="container">
                <Row gutter={[{ xs: 0, md: 32 }, { xs: 24, md: 32 }]} justify="center">
                    <Col xs={8} md={3} className='item travel-medical'>
                        <Link to={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}`} title={t('travel_medical')}>
                            <div className="box-avatar">
                                <img src={`${CONSTANT.STATIC_PATH}images/travel_medical_blue.png`} className='blue' />
                                <img src={`${CONSTANT.STATIC_PATH}images/travel_medical_white.png`} className='white' />
                            </div>
                            <div className="title">{t('travel_medical')}</div>
                        </Link>
                    </Col>
                    <Col xs={8} md={3} className="item">
                        <Link to={`/${CONSTANT.SLUG_MEDICAL_ONLINE}`} title={t('medical_online')}>
                            <div className="box-avatar">
                                <i className="fas fa-video icon"></i>
                            </div>
                            <div className="title">{t('medical_online')}</div>
                        </Link>
                    </Col>
                    <Col xs={8} md={3} className="item">
                        <Link to={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}`} title={t('medical_shop')}>
                            <div className="box-avatar">
                                <i className="fas fa-cart-plus icon"></i>
                            </div>
                            <div className="title">{t('medical_shop')}</div>
                        </Link>
                    </Col>
                    <Col xs={8} md={3} className="item">
                        <Link to={`/${CONSTANT.SLUG_DOCTOR}`} title={t('specialist_doctor')}>
                            <div className="box-avatar">
                                <i className="fas fa-user-md icon"></i>
                            </div>
                            <div className="title">{t('specialist_doctor')}</div>
                        </Link>
                    </Col>
                    <Col xs={8} md={3} className="item">
                        <Link to={`/${CONSTANT.SLUG_FACILITY}`} title={t('medical_facility')}>
                            <div className="box-avatar">
                                <i className="far fa-hospital icon"></i>
                            </div>
                            <div className="title">{t('medical_facility')}</div>
                        </Link>
                    </Col>
                    <Col xs={8} md={3} className="item">
                        <Link to={`/${CONSTANT.SLUG_SERVICE}`} title={t('medical_service')}>
                            <div className="box-avatar">
                                <i className="fa fa-briefcase-medical icon"></i>
                            </div>
                            <div className="title">{t('medical_service')}</div>
                        </Link>
                    </Col>
                    <Col xs={8} md={3} className="item">
                        <Link to={`/${CONSTANT.SLUG_HANDBOOK}`} title={t('doctor_talking')}>
                            <div className="box-avatar">
                                <i className="fas fa-book-reader icon"></i>
                            </div>
                            <div className="title">{t('doctor_talking')}</div>
                        </Link>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SectionGroupService;