import * as Services from "../services";
import CONSTANT from '../../config/constant';
import {
    // get list
    GET_LIST, GET_LIST_FAILURE, GET_LIST_SUCCESS,
    // get detail
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,
    // Get doctor category
    GET_DOCTOR_CATEGORY, GET_DOCTOR_CATEGORY_SUCCESS, GET_DOCTOR_CATEGORY_FAILURE
} from './type';

// get list
export const getList = (queryParam) => {
    let url = `${CONSTANT.API.DOCTOR.DOCTOR}`;
    return async dispatch => {
        dispatch({ type: GET_LIST });
        return await Services.get(url, queryParam)
            .then(res => {
                
                if (res && res.data && res.data.code === 200) {
                    dispatch({
                        type: GET_LIST_SUCCESS,
                        payload: res.data.data
                    })
                }
                else {
                    dispatch({ type: GET_LIST_FAILURE, payload: 'Lỗi lấy dữ liệu List Doctor từ API' })
                }
            })
    }
}

export const getDetail = (id) => {
    return async dispatch => {
        if (id) {
            let url = `${CONSTANT.API.DOCTOR.DOCTOR}/${id}`;
            dispatch({ type: GET_DETAIL });
            return await Services.get(url)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        dispatch({
                            type: GET_DETAIL_SUCCESS,
                            payload: res.data.data
                        })
                    }
                    else {
                        dispatch({ type: GET_DETAIL_FAILURE, payload: 'Lỗi lấy dữ liệu doctor từ API' })
                    }
                })
        }
        else {
            dispatch({ type: GET_DETAIL_FAILURE, payload: "Id không đúng" });
        }
    }
}
// process get list doctor category
export function getDoctorCategory(queryParam) {
    let url = `${CONSTANT.API.DOCTOR.CATEGORY}`;
    return async (dispatch) => {
        dispatch({
            type: GET_DOCTOR_CATEGORY
        });
        return await Services.get(url, queryParam)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    dispatch({
                        type: GET_DOCTOR_CATEGORY_SUCCESS,
                        payload: res.data.data.docs
                    })
                }
                else {
                    dispatch({ type: GET_DOCTOR_CATEGORY_FAILURE, payload: 'Không có dữ liệu trả về từ API' })
                }
            })
    }
}
