import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Steps, Form, Row, Col, Button, Radio, Space, message, Divider } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';


const { Step } = Steps;

const DoctorCheckout = () => {
    const [form] = Form.useForm();
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });
    const [method, setMethod] = useState('bank_transfer');
    const history = useHistory();
    const [processing, setProcessing] = useState(false);
    const [order, setOrder] = useState({});
    const [feeDoctor, setFeeDoctor] = useState(CONSTANT.FEE.DOCTOR);

    // lấy order_id từ query url
    const urlQueryParams = useLocation().search;
    const order_id = new URLSearchParams(urlQueryParams).get('order');

    if (!order_id) history.push(`/`);

    useEffect(() => {
        // load detail order
        const loadOrder = async () => {
            if (typeof order_id !== 'undefined') {
                return await Services.get(`${CONSTANT.API.DOCTOR.ORDER}/${order_id}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin đặt lịch hẹn!');
                    });
            }
        }
        let mounted = true;

        loadOrder().then(data => {
            if (mounted) {
                if (data) {
                    if (data?.items[0]?.service?.is_online === false)
                        setFeeDoctor(0);
                    setOrder(data);
                }
            }
        });

        return () => mounted = false;
    }, []);

    const onChangeMethod = e => {
        setMethod(e.target.value);
    };

    const onSubmit = async (values) => {
        setProcessing(true);
        // cập nhật thông tin đơn hàng
        const dataInfo = {
            payment_method: method
        }
        let saveInfo = await Services.post(`${CONSTANT.API.DOCTOR.CHECKOUT}/${order_id}/information`, dataInfo);
        if (saveInfo.status === 200) {

            if (method === 'bank_transfer') {
                //setProcessing(false);
                message.success('Đặt lịch dịch vụ thành công! Vui lòng chờ trong giây lát', 8).then(() => {
                    history.push(`/${CONSTANT.SLUG_DOCTOR}/checkout/bank-transfer?token=${order?._id}`);
                })
            }
            else {
                // Nếu thanh toán online thì tiếp tục gọi api thanh toán
                let dataPayment = {
                    platform: 'web',
                    payment_method: method
                };
                if (method === 'onepayDomestic') {
                    dataPayment.payment_method_title = "OnePay Nội địa";
                }
                else if (method === 'onepayInternational') {
                    dataPayment.payment_method_title = "OnePay Quốc tế";
                }
                else {
                    dataPayment.payment_method_title = "Chuyển khoản ngân hàng";
                }
                // call payment
                Services.post(`${CONSTANT.API.DOCTOR.CHECKOUT}/${order_id}/payment`, dataPayment)
                    .then(res => {

                        if (res && res.data && res.data.code === 200) {
                            message.success('Vui lòng chờ trong giây lát. Bạn sẽ được chuyển đến trang thanh toán trực tuyến!', 5).then(() => {
                                window.location.href = res.data.data.url;
                            })
                        }
                        else {
                            message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!', 8)
                            setProcessing(false);
                        }
                    })
            }

        }
        else
            setProcessing(false);
    }

    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={1}>
                        <Step title="Chọn dịch vụ" />
                        <Step title="Thanh toán" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Row gutter={[{ xs: 0, lg: 48 }, { xs: 0, lg: 48 }]}>
                    {/* Begin col info */}
                    <Col xs={24} lg={15} className="col-info" style={{ borderRight: '1px solid #e5e5e5' }} >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onSubmit}
                        >

                            <div className="pay-method">
                                <div className="pay-method-title">Phương thức thanh toán</div>
                                <div style={{ fontSize: 13, marginBottom: 10 }}>Chúng tôi sử dụng cổng thanh toán an toàn để bảo mật thông tin của bạn</div>

                                <Radio.Group onChange={onChangeMethod} value={method}>
                                    <Space direction="vertical">
                                        <div className="method">
                                            <Radio value='onepayDomestic' disabled>
                                                <div className="fw-medium">Thẻ ATM nội địa - (TẠM THỜI CHƯA ÁP DỤNG)</div>
                                                <div>Quý khách sẽ được chuyển đến OnePay để thanh toán</div>
                                                <img className="logo-onepay" src="https://tatinta.com/static/images/logo-onepay.png" alt="logo onepay" />
                                            </Radio>
                                        </div>
                                        <div className="method">
                                            <Radio value='onepayInternational' disabled>
                                                <div className="fw-medium">Thẻ tín dụng (VISA, Master Card) - (TẠM THỜI CHƯA ÁP DỤNG)</div>
                                                <div>Quý khách sẽ được chuyển đến OnePay để thanh toán</div>
                                                <img className="logo-onepay" src="https://tatinta.com/static/images/logo-onepay-visa.png" alt="logo onepay" />
                                            </Radio>
                                        </div>
                                        <div className="method">
                                            <Radio value='bank_transfer'>
                                                <div className="fw-medium">Chuyển khoản ngân hàng</div>
                                                <div>Quý khách có thể chuyển khoản tại ngân hàng, chuyển qua thẻ ATM, Internet Banking.</div>
                                                {method === 'bank_transfer' ?
                                                    <div className="bank">
                                                        <strong className="text-uppercase">Thông tin tài khoản</strong>
                                                        <Row gutter={[{ xs: 16, lg: 48 }, { xs: 16, lg: 48 }]} align="middle" style={{ marginTop: 20 }}>
                                                            <Col xs={6} lg={6}>
                                                                <img src={`${CONSTANT.STATIC_PATH}images/logo-acb.png`} alt="logo" className="logo-bank" />
                                                            </Col>
                                                            <Col xs={18} lg={18}>
                                                                <p><strong>{CONSTANT.BANK.BANK_NAME}</strong></p>
                                                                <p>Số tài khoản: <strong>{CONSTANT.BANK.NUMBER}</strong></p>
                                                                <p>Người thụ hưởng: <strong>{CONSTANT.BANK.NAM_CARD}</strong></p>
                                                                <p>Nội dung chuyển tiền: <strong>#Mã lịch hẹn</strong></p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    : null}
                                            </Radio>
                                        </div>
                                    </Space>
                                </Radio.Group>
                            </div>

                            <div className="group-btn">
                                <Button className="btn-global bg" icon={<ArrowLeftOutlined />} onClick={history.goBack}>Quay lại</Button>
                                <Button className="btn-global bg" loading={processing} htmlType="submit">Tiếp tục <ArrowRightOutlined /></Button>
                            </div>
                        </Form>

                    </Col>
                    {/* Begin col cart */}
                    <Col xs={24} lg={9} className="col-right">
                        <div className="fw-bold block-title">Dịch vụ đặt</div>
                        <div className="list-product">
                            {
                                order?.items?.length > 0 &&
                                <div className="item">
                                    <div className="box-cover">
                                        <img src={order.items[0]?.doctor?.image ? COMMON.getImage(order.items[0].doctor.image, 'doctor') : COMMON.getImage('')} alt={order.items[0].doctor.slug} />
                                    </div>
                                    <div className="info">
                                        <div className="title">

                                            {COMMON.createDoctorName(order.items[0]?.doctor?.academic_rank, order.items[0]?.doctor?.degree, order.items[0]?.doctor?.category?.short_name)} {order.items[0]?.doctor?.fullname}

                                        </div>
                                        <div style={{ color: '#777' }}>
                                            <span>Dịch vụ: </span>
                                            <span className="price fw-medium">{order.items[0]?.service?.name}</span>
                                        </div>
                                        <div style={{ color: '#777' }}>
                                            <span>Giá: </span>
                                            <span className="price fw-medium">{COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</span>
                                        </div>
                                        <div style={{ color: '#777', paddingLeft: 87 }}>
                                            <span>Ngày khám bệnh: </span>
                                            <span className="price fw-medium">{`${order?.date?.day}/${order?.date?.month}/${order?.date?.year}`}</span>
                                        </div>
                                        <div style={{ color: '#777', paddingLeft: 87 }}>
                                            <span>Giờ: </span>
                                            <span className="price fw-medium">{moment(`${order?.time?.time_h}:${order?.time?.time_m}`, "HH:mm").format("HH:mm")}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Divider />
                            <Row>
                                <Col xs={16} md={8}>Phí dịch vụ</Col>
                                <Col xs={8} md={16} className="fw-medium text-end">
                                    {COMMON.currencyFormat(feeDoctor)} {COMMON.getCurrency(order?.currency)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={16} md={14}>VAT 10% (Giá + Phí dịch vụ)</Col>
                                <Col xs={8} md={10} className="text-end fw-medium">{order?.items?.length > 0 && COMMON.currencyFormat((order?.items[0]?.service?.price + feeDoctor) * CONSTANT.FEE.VAT)} {COMMON.getCurrency(order?.currency)}</Col>
                            </Row>
                            <div className="order-total">
                                <span>Tổng cộng</span>
                                <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default DoctorCheckout;

