import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Steps, Typography, Spin, Result, Divider } from 'antd';

import { ServiceAction } from '../../store/root-action';
import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import moment from 'moment';

const { Step } = Steps;

const PAY_METHOD = {
    bank_transfer: 'Thanh toán chuyển khoản',
    onepayDomestic: 'Thanh toán qua cổng thanh toán OnePay nội địa',
    onepayInternational: 'Thanh toán qua cổng thanh toán OnePay quốc tế',
}

const ServiceBookingComplete = () => {
    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);
    // lấy query url
    const urlQueryParams = useLocation().search;

    useEffect(() => {
        if (urlQueryParams) {
            Services.get(`${CONSTANT.API.SERVICE.CHECKOUT}/payment/pay-verify${urlQueryParams}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        let data = res.data.data;
                        let order = data.data_order;
                        let result = { isSucceed: data.isSucceed, message: data.message }
                        setResult(result);
                        if (order) {
                            setOrder(order);
                            dispatch(ServiceAction.getDetail(order.items[0].service.uid));
                        }

                    }
                    setLoading(false);
                })
        }
        // remove localstorage
        localStorage.removeItem(CONSTANT.STORAGE_DATA_BOOKING_SERVICE);
    }, [dispatch, urlQueryParams]);

    return (
        <div className="page-checkout service-complete">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Step title="Chọn dịch vụ" />
                        <Step title="Thanh toán" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Spin spinning={loading}>
                    <div className="payment-success">
                        {
                            result && result.isSucceed &&
                            <Result
                                status="success"
                                title={"TUYỆT VỜI! BẠN ĐÃ THANH TOÁN CHO HÓA ĐƠN #VIVMEDIC-S" + order?.uid}
                                subTitle={<div>Chúng tôi đã gửi email xác nhận đến địa chỉ email <strong>{order && order.contact && order.contact.email}</strong> của bạn</div>}
                            />
                        }
                        {
                            result && !result.isSucceed &&
                            <>
                                {
                                    (() => {
                                        switch (result.message) {
                                            case 'Wrong checksum':
                                                return <Result
                                                    status="warning"
                                                    title="Thông tin xác thực thanh toán không hợp lệ. Chúng tôi sẽ hủy lịch hẹn này. Xin cảm ơn!"
                                                />
                                            case 'Order not exist':
                                                return <Result
                                                    status="404"
                                                    title="404"
                                                    subTitle="Lịch đặt dịch vụ không tồn tại"
                                                />
                                            default:
                                                return <Result
                                                    status="500"
                                                    title="500"
                                                    subTitle={<span>{result.message}. Chúng tôi sẽ hủy lịch hẹn này. Xin cảm ơn!</span>}
                                                />
                                        }
                                    })()
                                }
                            </>

                        }
                        {
                            order && order._id &&
                            <div className="box-info">
                                <Row>
                                    <Col xs={8} lg={8} className="fw-bold">Thông tin của bạn</Col>
                                    <Col xs={16} lg={16}>
                                        <div className="fw-bold">{order.contact && order.contact.fullname}</div>
                                        <Typography.Text>{order.contact && order.contact.email}</Typography.Text><br />
                                        {
                                            order.contact && order.contact.address && <><Typography.Text>{order.contact.address}</Typography.Text><br /></>
                                        }
                                        <Typography.Text>{order.contact && order.contact.phone && `+${order.contact.dial_code}${order.contact.phone}`}</Typography.Text>
                                    </Col>
                                </Row>

                                <div className="fw-bold block-title" style={{ marginTop: 8 }}>Thông tin lịch hẹn</div>
                                <div className="list-product" style={{ marginBottom: 0 }}>
                                    {
                                        order?.items?.length > 0 &&
                                        <Row className="item">
                                            <Col xs={8} lg={4}>
                                                <div className="box-cover">
                                                    <img src={order.items[0]?.service?.images?.length > 0 ? COMMON.getImage(order.items[0].service.images[0].filename, 'service') : COMMON.getImage('')} alt={order?.items[0]?.service?.slug} />
                                                </div>
                                            </Col>
                                            <Col xs={16} lg={20} className="info">
                                                <div className="title">
                                                    {order.items[0]?.service?.name}
                                                </div>

                                                <div style={{ color: '#777' }}>
                                                    <span>Giá: </span>
                                                    <span className="fw-medium">{COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Ngày khám bệnh: </span>
                                                    <span className="fw-medium">{moment(order?.date).format('DD/MM/YYYY')}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Giờ: </span>
                                                    <span className="fw-medium">{moment(order?.time).format('HH:mm A')}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Địa điểm khám:  </span>
                                                    <span className="fw-medium">{(order?.work_place === 'at_guest' ? 'Tại nhà khách hàng, ' : `${order?.place_name}, `) + order?.address_work}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col xs={16} md={8}>Thành tiền</Col>
                                        <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.subtotal)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                    <Divider />
                                    {
                                        <React.Fragment>
                                            <Row>
                                                <Col xs={16} md={8}>Phí dịch vụ ({CONSTANT.FEE.SERVICE_MEDIC * 100}%)</Col>
                                                <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(CONSTANT.FEE.SERVICE_MEDIC * order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</Col>
                                            </Row>
                                            {
                                                order?.transpot_fee > 0 &&
                                                <Row>
                                                    <Col xs={16} md={8}>Phí đi lại</Col>
                                                    <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.transpot_fee)} {COMMON.getCurrency(order?.currency)}</Col>
                                                </Row>
                                            }
                                            {
                                                order?.overtime_fee > 0 &&
                                                <Row>
                                                    <Col xs={16} md={8}>Phí ngoài giờ</Col>
                                                    <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.overtime_fee)} {COMMON.getCurrency(order?.currency)}</Col>
                                                </Row>
                                            }
                                            <Divider />
                                            {
                                                order?.discount && order.discount?.value > 0 &&
                                                <Row>
                                                    <Col xs={16} md={8}>Khuyến mãi giảm giá</Col>
                                                    <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order.discount.value_type === 'cash' ? order.discount.value : order.discount.value_type === 'percent' ? (order.subtotal + order.total_tax + order.transpot_fee + order.overtime_fee) * order.discount.value / 100 : 0)} {COMMON.getCurrency(order?.currency)}</Col>
                                                </Row>
                                            }
                                        </React.Fragment>
                                    }
                                    <div className="order-total">
                                        <span>Tổng cộng</span>
                                        <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                                    </div>
                                </div>

                                <div>
                                    Phương thức thanh toán: <span className="fw-medium">{PAY_METHOD[`${order?.payment_method}`]}</span>
                                </div>


                                <div className="text-end" style={{ marginTop: 30 }}>
                                    <Link to={`/${CONSTANT.SLUG_SERVICE}`} className="btn-global bg" style={{ padding: '10px 50px' }}>Xem dịch vụ y tế khác</Link>
                                </div>
                            </div>
                        }

                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default ServiceBookingComplete;