import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Spin, Avatar, Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-multi-lang';

import * as COMMON from '../../../helper/common';
import CONSTANT from '../../../config/constant';


const SectionService = () => {
    const t = useTranslation();
    const categories = useSelector(state => state.serviceReducer.categories);
    const loadingCategory = useSelector(state => state.serviceReducer.loadingCategory);
    const [selectCategory, setSelectCategory] = useState({});
    const [numShow, setNumShow] = useState(2);

    useEffect(() => {
        if (categories.length > 0) {
            setSelectCategory(categories[0]);
        }
    }, [categories]);

    // on change category
    const onChangeCategory = (cate) => {
        if (cate._id !== selectCategory._id) {
            if (cate.uid !== 2)
                setNumShow(4);
            else
                setNumShow(2);
            setSelectCategory(cate);
        }
    }

    return (
        <React.Fragment>
            <div className="professional-team">
                <div className="caption">
                    <div className="text-uppercase title">{t('medical_service')}</div>
                    <div className="sapo">{t('medical_service_sapo')}</div>
                </div>
            </div>

            <div className="container health-service">
                <div className="block-header">
                    <div className="text-uppercase title"></div>

                </div>
                {/* Display on mobile */}
                <div className="text-center dropdown-filter d-block d-md-none">
                    <Dropdown
                        overlay={(
                            <Menu>
                                {
                                    categories && categories.length > 0 &&
                                    categories.map(item => (
                                        <Menu.Item key={item._id} onClick={() => onChangeCategory(item)}>
                                            {item.name}
                                        </Menu.Item>
                                    ))
                                }

                            </Menu>
                        )}
                        className="btn-global"
                    >
                        <Button>
                            {selectCategory.name} <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
                {
                    // display on table and pc
                    categories && categories.length > 0 &&
                    <div className="text-center box-filter d-none d-md-block">
                        {
                            categories.map(item => (
                                <React.Fragment key={item._id}>
                                    {
                                        item.slug !== 'suc-khoe-tinh-than' &&
                                        <Button className={`btn-global btn-filter ${item._id === selectCategory._id && 'active'}`}
                                            onClick={() => onChangeCategory(item)}
                                        >{item.name}</Button>
                                    }

                                </React.Fragment>
                            ))
                        }

                    </div>
                }

                <Spin spinning={loadingCategory} >
                    <div className="list">
                        <Row gutter={[{ xs: 16, lg: 0 }, 24]} justify="center">
                            {
                                selectCategory?.uid === 2 &&
                                <React.Fragment>
                                    <Col xs={12} lg={6}>
                                        <Card
                                            hoverable
                                            cover={
                                                <Avatar className="cover" size={200}
                                                    src={`${CONSTANT.STATIC_PATH}images/kham-tong-quat-tai-nha.jpeg`} />
                                            }
                                            className="service"
                                        >
                                            <Card.Meta
                                                title={<h3 className="text-center title">Khám tổng quát tại nhà</h3>}
                                            />
                                            <Link to='/dich-vu-y-te/c-dich-vu-y-te-tai-nha/2' title={`${t('learn_more')} Khám tổng quát tại nhà`} className="btn-global btn-view bg">Xem</Link>
                                        </Card>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Card
                                            hoverable
                                            cover={
                                                <Avatar className="cover" size={200}
                                                    src={`${CONSTANT.STATIC_PATH}images/f0-tai-nha.jpeg`} />
                                            }
                                            className="service"
                                        >
                                            <Card.Meta
                                                title={<h3 className="text-center title">Tư vấn khám bệnh từ xa</h3>}
                                            />
                                            <Link to='/tu-van-tu-xa' title={`${t('learn_more')} Tư vấn khám bệnh từ xa`} className="btn-global btn-view bg">Xem</Link>
                                        </Card>
                                    </Col>
                                </React.Fragment>
                            }

                            {
                                selectCategory && selectCategory.childs && selectCategory.childs.length > 0 &&
                                selectCategory.childs.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {
                                            index < numShow &&
                                            <Col xs={12} lg={6} key={index}>
                                                <Card
                                                    hoverable
                                                    cover={
                                                        <Avatar className="cover" size={200}
                                                            src={item.avatar ? COMMON.getImage(item.avatar, 'service') : COMMON.getImage('')} />
                                                    }
                                                    className="service"
                                                >
                                                    <Card.Meta
                                                        title={<h3 className="text-center title">{item.name}</h3>}
                                                    />
                                                    <Link to={COMMON.createLinkServiceCategory(item.slug, item.uid)} title={`${t('learn_more')} ${item.name}`} className="btn-global btn-view bg">{t('learn_more')}</Link>
                                                </Card>
                                            </Col>
                                        }

                                    </React.Fragment>
                                ))
                            }
                        </Row>
                    </div>
                </Spin>
            </div>
        </React.Fragment>
    )
}

export default SectionService;