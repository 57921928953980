import produce from 'immer';
import {
    GET_AREAS, GET_AREAS_SUCCESS, GET_AREAS_FAILURE,
    GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE,
    GET_CITIES, GET_CITIES_SUCCESS, GET_CITIES_FAILURE,
    GET_WARDS, GET_WARDS_SUCCESS, GET_WARDS_FAILURE,
} from './type';

const initialState = {
    areas: [],
    countries: [],
    cities: [],
    wards: [],
    loading: false,
    error: false
}

function locationReducer(state = initialState, action) {

    const { payload } = action;
    return produce(state, draft => {
        switch (action.type) {
            // List
            case GET_AREAS:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_AREAS_SUCCESS:
                draft.areas = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_AREAS_FAILURE:
                draft.loading = false;
                draft.areas = [];
                draft.error = payload;
                break;
            case GET_COUNTRIES:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_COUNTRIES_SUCCESS:
                draft.countries = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_COUNTRIES_FAILURE:
                draft.countries = [];
                draft.loading = false;
                draft.error = payload;
                break;
            case GET_CITIES:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_CITIES_SUCCESS:
                draft.cities = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_CITIES_FAILURE:
                draft.cities = [];
                draft.loading = false;
                draft.error = payload;
                break;
            case GET_WARDS:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_WARDS_SUCCESS:
                draft.wards = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_WARDS_FAILURE:
                draft.wards = [];
                draft.loading = false;
                draft.error = payload;
                break;
            default:
                break;
        }
    })

}

export default locationReducer;