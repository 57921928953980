import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Card, Spin, Dropdown, Menu, Avatar } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-multi-lang';

import { ProductAction } from '../../../store/root-action';
import * as COMMON from '../../../helper/common';


const SectionShop = () => {
    const t = useTranslation();
    const categories = useSelector(state => state.productReducer.tree_category);
    const [selectCategory, setSelectCategory] = useState({});
    const listProducts = useSelector(state => state.productReducer.list);
    const loading = useSelector(state => state.productReducer.loading);

    const dispatch = useDispatch();

    // load product theo category id
    const loadProducts = useCallback(async (id) => {
        await dispatch(ProductAction.getList({
            perPage: 4, page: 1, category: id, status: 'publish'
        }));
    }, [dispatch])

    // Tạm ẩn thay the pharmacity
    /*useEffect(() => {

        
        if (categories.length > 0) {
            setSelectCategory(categories[0]);
            loadProducts(categories[0].uid);
        }
    }, [categories, loadProducts]);
    */

    // on change category
    const onChangeCategory = (cate) => {
        if (cate.uid !== selectCategory.uid) {
            loadProducts(cate.uid);
            setSelectCategory(cate);
        }
    }

    return (
        <>
            <div className="professional-team section-shop">
                <div className="caption">
                    <div className="text-uppercase title">{t('medical_shop')}</div>
                    <div className="sapo">{t('medical_shop_sapo')}</div>
                </div>
            </div>
            <div className="container">
                <div className="list list-pharma">
                    <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]} style={{ marginBottom: 30 }}>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/duoc-pham?utm_source=tatinta' title='Dược phẩm' target='_blank'>
                                <Card
                                    className="service service-pharma"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10379.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Dược phẩm</h3>}
                                    />
                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/cham-soc-suc-khoe?utm_source=tatinta' title='Chăm sóc sức khỏe' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Chăm sóc sức khỏe</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/cham-soc-ca-nhan?utm_source=tatinta' title='Chăm sóc cá nhân' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378-1.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Chăm sóc cá nhân</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/san-pham-tien-loi?utm_source=tatinta' title='Sản phẩm tiện lợi' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378-2.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Sản phẩm tiện lợi</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/thuc-pham-chuc-nang?utm_source=tatinta' title='Thực phẩm chức năng' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378-3.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Thực phẩm chức năng</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/me-va-be?utm_source=tatinta' title='Mẹ và bé' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378-4.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Mẹ và bé</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/cham-soc-sac-dep?utm_source=tatinta' title='Chăm sóc sắc đẹp' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378-5.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Chăm sóc sắc đẹp</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                        <Col xs={12} lg={6}>
                            <a rel="noreferrer" href='https://www.pharmacity.vn/thiet-bi-y-te-2?utm_source=tatinta' title='Thiết bị y tế' target='_blank'>
                                <Card
                                    className="service"
                                    cover={
                                        <Avatar className="cover" size={200} style={{ marginTop: 20 }}
                                            src='https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/category-icons/Group_10378-6.png' />
                                    }
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Thiết bị y tế</h3>}
                                    />

                                </Card>

                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default SectionShop;