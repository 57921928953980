import React from "react";
import { Route, Switch } from "react-router-dom";

// import layout
import Layout from "../layout/default";
import LayoutDark from "../layout/dark";

// import component from module
import {

    //home
    Home, F0AtHome,
    // Shopping
    Shop, ProductDetail, ProductCategory, Cart, CheckoutInformation, CheckoutPayment, PaymentBankTransfer, PaymentSuccess, ShopBookingConfirm, MyOrder,
    // about
    About,
    // Doctor
    Doctor, DoctorDetail, MedicalOnline, DoctorBookingConfirm, DoctorCheckout, DoctorPaymentBankTransfer,
    DoctorBookingComplete, DoctorPaymentOnSpot, DoctorPaymentFree, MyDoctor, AdviseF0,
    //Facility
    Facility, FacilityDetail, FacilityPaymentOnSpot, MyHospital,
    //Service
    ServiceMedical, ServiceMedicalCategory, ServiceDetail, ServiceBooking, ServiceBookingConfirm, ServicePaymentBankTransfer, ServiceBookingComplete,
    MyServiceMedical,
    // User
    Login, LoginSuccess, Register, ActiveAccount, RegisterAgency,
    // account
    Profile, Address, ChangePass, UserOrder,
    // Handbook
    HandBook, HandBookDetail,
    // Page
    Page,
    // Qa
    Qa,
    // Travel medical
    TravelMedical, TravelMedicalEmergency, TravelMedicalHealthBeauty, TravelMedicalHealthMental,
    // Page 404
    PageNotFound,
} from "../module";

import PageHeader from "../layout/default/header";

import CONSTANT from "../config/constant";

export const routes = [

    { component: PageHeader },

    { path: "/", component: Home, exact: true },
    { path: "/f0-tai-nha", component: F0AtHome },

    // Shopping
    { path: `/${CONSTANT.SLUG_MEDICAL_SHOPPING}`, component: Shop, exact: true },
    { path: `/${CONSTANT.SLUG_MEDICAL_SHOPPING}/c-:slug/:id`, component: ProductCategory },
    { path: `/${CONSTANT.SLUG_MEDICAL_SHOPPING}/${CONSTANT.SLUG_MEDICAL_PRODUCT}/:slug/:id`, component: ProductDetail, exact: true },
    { path: `/${CONSTANT.SLUG_MEDICAL_SHOPPING}/my-order`, component: MyOrder },
    { path: `/${CONSTANT.SLUG_MEDICAL_SHOPPING}/confirm/:status/:code`, component: ShopBookingConfirm },
    { path: `/cart`, component: Cart },
    { path: `/checkout/infomation`, component: CheckoutInformation },
    { path: `/checkout/payment`, component: CheckoutPayment, exact: true },
    { path: `/checkout/payment/bank-transfer`, component: PaymentBankTransfer },
    { path: `/checkout/payment/success`, component: PaymentSuccess },
    //Doctor
    { path: `/${CONSTANT.SLUG_DOCTOR}`, component: Doctor, exact: true },
    { path: `/${CONSTANT.SLUG_DOCTOR}/checkout`, exact: true, component: DoctorCheckout },
    { path: `/${CONSTANT.SLUG_DOCTOR}/checkout/bank-transfer`, component: DoctorPaymentBankTransfer },
    { path: `/${CONSTANT.SLUG_DOCTOR}/checkout/pay-on-spot`, component: DoctorPaymentOnSpot },
    { path: `/${CONSTANT.SLUG_DOCTOR}/checkout/complete`, component: DoctorBookingComplete },
    { path: `/${CONSTANT.SLUG_DOCTOR}/my-doctor`, component: MyDoctor },
    { path: `/${CONSTANT.SLUG_DOCTOR}/confirm/:status/:code`, component: DoctorBookingConfirm },
    { path: `/${CONSTANT.SLUG_DOCTOR}/:slug/:id`, component: DoctorDetail },
    { path: `/${CONSTANT.SLUG_MEDICAL_ONLINE}`, component: MedicalOnline, exact: true },
    { path: `/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout`, component: DoctorCheckout, exact: true },
    { path: `/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/bank-transfer`, component: DoctorPaymentBankTransfer },
    { path: `/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/pay-on-spot`, component: DoctorPaymentOnSpot },
    { path: `/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/pay-free`, component: DoctorPaymentFree },
    { path: `/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/complete`, component: DoctorBookingComplete },
    { path: `/bac-si-tu-van-f0`, component: AdviseF0 },


    //Facility
    { path: `/${CONSTANT.SLUG_FACILITY}`, component: Facility, exact: true },
    { path: `/${CONSTANT.SLUG_FACILITY}/:slug/:id`, component: FacilityDetail },
    { path: `/${CONSTANT.SLUG_FACILITY}/checkout/pay-on-spot`, component: FacilityPaymentOnSpot },
    { path: `/${CONSTANT.SLUG_FACILITY}/my-hospital`, component: MyHospital },

    // Service medical
    { path: `/${CONSTANT.SLUG_SERVICE}`, component: ServiceMedical, exact: true },
    { path: `/${CONSTANT.SLUG_SERVICE}/c-:slug/:id`, component: ServiceMedicalCategory },
    { path: `/${CONSTANT.SLUG_SERVICE}/s-:slug/:id`, component: ServiceDetail },
    { path: `/${CONSTANT.SLUG_SERVICE}/booking`, component: ServiceBooking },
    { path: `/${CONSTANT.SLUG_SERVICE}/booking/bank-transfer`, component: ServicePaymentBankTransfer },
    { path: `/${CONSTANT.SLUG_SERVICE}/booking/complete`, component: ServiceBookingComplete },
    { path: `/${CONSTANT.SLUG_SERVICE}/confirm/:status/:code`, component: ServiceBookingConfirm },
    { path: `/${CONSTANT.SLUG_SERVICE}/my-service`, component: MyServiceMedical },
    // Handbook
    { path: `/${CONSTANT.SLUG_HANDBOOK}`, component: HandBook, exact: true },
    { path: `/${CONSTANT.SLUG_HANDBOOK}/c-:slug/:id`, component: HandBook },
    { path: `/${CONSTANT.SLUG_HANDBOOK}/h-:slug/:id`, component: HandBookDetail },

    // Page
    { path: `/${CONSTANT.SLUG_PAGE_CONTENT}/:slug/:id`, component: Page },

    // Qa
    { path: `/${CONSTANT.SLUG_QA}`, component: Qa },

    // Travel Medical
    { path: `/${CONSTANT.SLUG_TRAVEL_MEDICAL}`, component: TravelMedical, exact: true },
    { path: `/${CONSTANT.SLUG_TRAVEL_MEDICAL}/cap-cuu`, component: TravelMedicalEmergency },
    { path: `/${CONSTANT.SLUG_TRAVEL_MEDICAL}/khoe-dep`, component: TravelMedicalHealthBeauty },
    { path: `/${CONSTANT.SLUG_TRAVEL_MEDICAL}/suc-khoe-tinh-than`, component: TravelMedicalHealthMental },

    // user 
    { path: `/${CONSTANT.SLUG_LOGIN}`, component: Login, exact: true },
    { path: `/${CONSTANT.SLUG_LOGIN}/thanh-cong`, component: LoginSuccess },
    { path: `/${CONSTANT.SLUG_REGISTER}`, component: Register },
    { path: `/${CONSTANT.SLUG_ACTIVE_ACCOUNT}`, component: ActiveAccount },
    { path: `/${CONSTANT.SLUG_REGISTER_AGENCY}`, component: RegisterAgency },
    // account
    //{path: '/thong-tin-tai-khoan', exact: true, component: Profile},
    //{path: '/dang-ky-cua-hang', component: RegisterSeller},

    { path: '/thong-tin-tai-khoan', component: Profile },
    { path: '/so-dia-chi', component: Address },
    { path: '/thay-doi-mat-khau', component: ChangePass },
    { path: '/don-hang-cua-ban', component: UserOrder },
    { path: "/dark", component: About, exact: true },

    // 404
    { path: "*", component: PageNotFound }
]

const AppRoutes = () => {
    return (
        <Switch>
            {/* Layout dark */}
            <Route path="/dark/:path?" exact>
                <LayoutDark>
                    <Switch>
                        <Route path='/dark' exact component={About} />
                    </Switch>
                </LayoutDark>
            </Route>

            {/* Layout default */}
            <Route>
                <Layout>
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/f0-tai-nha' exact component={F0AtHome} />

                        {/* Healthy Shopping */}
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}`} exact component={Shop} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}/c-:slug/:id`} exact component={ProductCategory} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}/${CONSTANT.SLUG_MEDICAL_PRODUCT}/:slug/:id`} exact component={ProductDetail} />
                        <Route path={`/cart`} component={Cart} />
                        <Route path={`/checkout/information`} component={CheckoutInformation} />
                        <Route path={`/checkout/payment`} component={CheckoutPayment} exact />
                        <Route path={`/checkout/payment/bank-transfer`} component={PaymentBankTransfer} />
                        <Route path={`/checkout/payment/success`} component={PaymentSuccess} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}/my-order`} component={MyOrder} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}/confirm/:status/:code`} component={ShopBookingConfirm} />
                        {/* Doctor */}
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}`} exact component={Doctor} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/checkout`} exact component={DoctorCheckout} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/checkout/bank-transfer`} component={DoctorPaymentBankTransfer} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/checkout/pay-on-spot`} component={DoctorPaymentOnSpot} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/checkout/complete`} component={DoctorBookingComplete} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/my-doctor`} component={MyDoctor} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/confirm/:status/:code`} component={DoctorBookingConfirm} />
                        <Route path={`/${CONSTANT.SLUG_DOCTOR}/:slug/:id`} component={DoctorDetail} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}`} exact component={MedicalOnline} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout`} exact component={DoctorCheckout} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/bank-transfer`} component={DoctorPaymentBankTransfer} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/pay-on-spot`} component={DoctorPaymentOnSpot} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/pay-free`} component={DoctorPaymentFree} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/complete`} component={DoctorBookingComplete} />
                        <Route path={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/:type`} component={MedicalOnline} />
                        <Route path={`/bac-si-tu-van-f0`} component={AdviseF0} />

                        {/* Facility */}
                        <Route path={`/${CONSTANT.SLUG_FACILITY}`} exact component={Facility} />
                        <Route path={`/${CONSTANT.SLUG_FACILITY}/checkout/pay-on-spot`} component={FacilityPaymentOnSpot} />
                        <Route path={`/${CONSTANT.SLUG_FACILITY}/my-hospital`} component={MyHospital} />
                        <Route path={`/${CONSTANT.SLUG_FACILITY}/:slug/:id`} component={FacilityDetail} />


                        {/* Service */}
                        <Route path={`/${CONSTANT.SLUG_SERVICE}`} exact component={ServiceMedical} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/c-:slug/:id`} component={ServiceMedicalCategory} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/s-:slug/:id`} exact component={ServiceDetail} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/booking`} exact component={ServiceBooking} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/booking/bank-transfer`} exact component={ServicePaymentBankTransfer} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/booking/complete`} exact component={ServiceBookingComplete} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/confirm/:status/:code`} component={ServiceBookingConfirm} />
                        <Route path={`/${CONSTANT.SLUG_SERVICE}/my-service`} component={MyServiceMedical} />
                        {/* Handbook */}
                        <Route path={`/${CONSTANT.SLUG_HANDBOOK}`} exact component={HandBook} />
                        <Route path={`/${CONSTANT.SLUG_HANDBOOK}/c-:slug/:id`} component={HandBook} />
                        <Route path={`/${CONSTANT.SLUG_HANDBOOK}/h-:slug/:id`} exact component={HandBookDetail} />

                        {/* Page */}
                        <Route path={`/${CONSTANT.SLUG_PAGE_CONTENT}/:slug/:id`} exact component={Page} />

                        {/* Qa */}
                        <Route path={`/${CONSTANT.SLUG_QA}`} exact component={Qa} />

                        {/* Travel medical */}
                        <Route path={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}`} exact component={TravelMedical} />
                        <Route path={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}/cap-cuu`} exact component={TravelMedicalEmergency} />
                        <Route path={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}/khoe-dep`} exact component={TravelMedicalHealthBeauty} />
                        <Route path={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}/suc-khoe-tinh-than`} exact component={TravelMedicalHealthMental} />

                        {/*  User */}
                        <Route path={`/${CONSTANT.SLUG_LOGIN}`} exact component={Login} />
                        <Route path={`/${CONSTANT.SLUG_LOGIN}/thanh-cong`} component={LoginSuccess} />
                        <Route path={`/${CONSTANT.SLUG_REGISTER}`} component={Register} />
                        <Route path={`/${CONSTANT.SLUG_ACTIVE_ACCOUNT}`} component={ActiveAccount} />
                        <Route path={`/${CONSTANT.SLUG_REGISTER_AGENCY}`} component={RegisterAgency} />

                        {/* <Route path='/dang-ky-cua-hang' component={RegisterSeller} /> */}
                        <Route path='/thong-tin-tai-khoan' component={Profile} />
                        <Route path='/so-dia-chi' component={Address} />
                        <Route path='/thay-doi-mat-khau' component={ChangePass} />
                        <Route path='/don-hang-cua-ban' component={UserOrder} />
                        {/* Page 404 */}
                        <Route path={`*`} component={PageNotFound} />

                    </Switch>
                </Layout>
            </Route>
        </Switch>

    )
}

export default AppRoutes;
