import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Steps, message } from 'antd';
import moment from 'moment';

import * as Services from "../../store/services";
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';

const FacilityPaymentOnSpot = (props) => {
    const [order, setOrder] = useState({});
    
    // lấy token từ query url
    const urlQueryParams = useLocation().search;
    const _token = new URLSearchParams(urlQueryParams).get('token');

    useEffect(() => {
        // load detail order
        const loadOrder = async () => {
            if (typeof _token !== 'undefined') {
                return await Services.get(`${CONSTANT.API.HOSPITAL.ORDER}/${_token}`)
                    .then(res => {
                        if (res?.data?.code === 200) {
                            return res?.data?.data;
                        }
                        else{
                            message.warning('Lịch hẹn không tồn tại!').then(()=> props.history.push(`/${CONSTANT.SLUG_FACILITY}`));
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin lịch hẹn!');
                    });
            }
        }
        let mounted = true;

        loadOrder().then(data => {
            if (mounted) {
                if (data) {
                    setOrder(data);
                }
            }
        });

        return () => mounted = false;
    }, [_token]);
    
    return (
        <div className="page-checkout">
            <MetaSeo/>
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Steps.Step title="Chọn gói khám" />
                        <Steps.Step title="Thanh toán" />
                        <Steps.Step title="Hoàn thành" />
                    </Steps>
                </div>
                <div className="bank-transfer">
                    <div className="fw-bold">Cảm ơn Quý khách đã sử dụng dịch vụ của Vivmedic</div>
                    <div>Lịch hẹn của Quý khách đã được tạo.</div>
                    <div>Danh mục khám chữa bệnh: <span className="fw-medium"> {order?.items?.length > 0 && order?.items[0].service?.name}</span></div>
                    <div>Ngày khám: <span className="fw-medium">{order?.date?.day}/{order?.date?.month}/{order?.date?.year}</span></div>
                    <div>Vào lúc: <span className="fw-medium">{moment(`${order?.time?.time_h}:${order?.time?.time_m}`, "HH:mm").format("HH:mm")}</span></div>
                    <div>Địa điểm khám: <span className="fw-medium">
                        {order?.items?.length > 0 && order?.items[0]?.hospital?.name + ' - ' + order?.items[0]?.hospital?.address}
                    </span></div>
                    <div>Phương thức thanh toán: <span className="fw-medium">Thanh toán tại chỗ</span></div>
                    <div>Tổng tiền: <span className="price">{order?.total ? COMMON.currencyFormat(order?.total) : '_'}{COMMON.getCurrency('VND')}</span></div>
                    
                    <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                        <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FacilityPaymentOnSpot;