import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import {
    Menu, Dropdown, Button
} from 'antd';
import { RightOutlined, DownOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// import common
import * as COMMON from '../../../helper/common';

const MenuLeft = (props) => {
    const t = useTranslation();
    const categories = useSelector(state => state && state.productReducer && state.productReducer.tree_category);
    // Lấy id category từ url
    const { id } = useParams();
    const [rootCategories, setRootCategories] = useState({});
    const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const [visibleDropDown, setVisibleDropDown] = useState(false);

    const onOpenChangeMenu = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    // load danh sách danh mục
    const rawCategories = async () => {
        if (categories.length > 0) {

            let currentCategoryUid = id;
            let _rootCategories = { ...rootCategories };
            let _currentCategory = {};
            let _siteMapCategories = [];
            let _rootSubmenuKeys = [...rootSubmenuKeys];

            categories.forEach(lv1 => {
                if (lv1.uid == currentCategoryUid) {
                    _rootCategories = lv1;
                    _currentCategory = lv1;
                    _siteMapCategories.push(lv1);

                    // get root submenu key
                    if (lv1.childs.length > 0) {
                        lv1.childs.forEach(item => {
                            _rootSubmenuKeys.push(item.uid);
                        })
                    }
                    return;
                }
                else if (lv1.childs.length > 0) {
                    lv1.childs.forEach(lv2 => {
                        if (lv2.uid == currentCategoryUid) {
                            _rootCategories = lv1;
                            _currentCategory = lv2;
                            _siteMapCategories.push(lv1, lv2);

                            // get root submenu key
                            if (lv2.childs.length > 0) {
                                lv2.childs.forEach(item => {
                                    _rootSubmenuKeys.push(item.uid);
                                })
                            }
                            return;
                        }
                        else if (lv2.childs.length > 0) {
                            lv2.childs.forEach(lv3 => {
                                if (lv3.uid == currentCategoryUid) {
                                    _rootCategories = lv1;
                                    _currentCategory = lv3;
                                    _siteMapCategories.push(lv1, lv2, lv3);
                                    // get root submenu key
                                    if (lv3.childs.length > 0) {
                                        lv3.childs.forEach(item => {
                                            _rootSubmenuKeys.push(item.uid);
                                        })
                                    }
                                    return;
                                }
                                else if (lv3.childs.length > 0) {
                                    lv3.childs.forEach(lv4 => {
                                        if (lv4.uid == currentCategoryUid) {
                                            _rootCategories = lv1;
                                            _currentCategory = lv4;
                                            _siteMapCategories.push(lv1, lv2, lv3, lv4);
                                            return;
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            setRootCategories(_rootCategories);
            setRootSubmenuKeys(_rootSubmenuKeys);
            props.onLoaded({
                siteMapCategories: _siteMapCategories,
                currentCategory: _currentCategory
            });
        }

    }
    useEffect(() => {
        rawCategories(id);
    }, [categories, id]);

    const closeDropdown = () => {
        setVisibleDropDown(false);
    }
    const toogleDropDown = () => {
        setVisibleDropDown(state => !state);
    }

    const MenuOverlay = ({ ismobile }) => {
        let propsLink = {};
        if (ismobile) {
            propsLink.onClick = closeDropdown;
        }
        return (
            <>
                {
                    rootCategories && Object.keys(rootCategories).length > 0 && rootCategories.childs && rootCategories.childs.length > 0 &&
                    <Menu className="category"
                        mode="inline" openKeys={openKeys}
                        onOpenChange={onOpenChangeMenu}
                    >
                        {
                            rootCategories.childs.map(item_lv2 => (
                                <React.Fragment key={item_lv2._id}>
                                    {
                                        item_lv2.childs && item_lv2.childs.length > 0 ? (
                                            <Menu.SubMenu key={item_lv2.uid}
                                                title={(<Link {...propsLink} to={COMMON.createLinkProductCategory(item_lv2.slug, item_lv2.uid)} title={item_lv2.name}>{item_lv2.name}</Link>)}
                                            >
                                                {
                                                    item_lv2.childs.map(item_lv3 => (
                                                        <Menu.Item key={item_lv3.uid} icon={<RightOutlined />}>
                                                            <Link {...propsLink} to={COMMON.createLinkProductCategory(item_lv3.slug, item_lv3.uid)} title={item_lv3.name}>{item_lv3.name}</Link>
                                                        </Menu.Item>
                                                    ))
                                                }
                                            </Menu.SubMenu>

                                        )
                                            :
                                            <Menu.Item key={item_lv2.uid}>
                                                <Link {...propsLink} to={COMMON.createLinkProductCategory(item_lv2.slug, item_lv2.uid)} title={item_lv2.name}>{item_lv2.name}</Link>
                                            </Menu.Item>
                                    }


                                </React.Fragment>
                            ))
                        }

                    </Menu>
                }
            </>
        )
    }



    return (
        <React.Fragment>
            {/* Chỉ hiển thị trên điện thoại */}
            <div className="menu-left-mobile d-block d-md-none">
                <Dropdown overlay={<MenuOverlay ismobile="true" />}
                    visible={visibleDropDown}
                    overlayClassName="menu-left-mobile-drop"
                >
                    <Button onClick={toogleDropDown}>
                        {t('category_product')} <DownOutlined />
                        {visibleDropDown && <CloseCircleOutlined style={{ marginTop: 4, color: '#777' }} />}
                    </Button>
                </Dropdown>
            </div>
            {/* Không hiển thị trên điện thoại */}
            <div className="d-none d-md-block">
                {
                    rootCategories && Object.keys(rootCategories).length > 0 && (
                        <div className="menu-left-service" >
                            <div className="category-header">{t('category_product')}</div>
                            <MenuOverlay />
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    )
}

export default MenuLeft;