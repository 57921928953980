import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';

const DoctorBookingConfirm = () => {
    const [order, setOrder] = useState();
    const [textConfirm, setTextConfirm] = useState();
    const { status, code } = useParams();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (status && code) {
            let curr_status = '';
            switch (status) {
                case 'confirmed':
                    setTextConfirm('Xác nhận chấp nhận lịch hẹn thành công');
                    curr_status = 'wait-confirm';
                    break;
                case 'patient-begin':
                    setTextConfirm('Quý khách đã xác nhận bắt đầu dịch vụ thành công');
                    curr_status = 'confirmed';
                    break;
                case 'doctor-begin':
                    setTextConfirm('Xác nhận bắt đầu dịch vụ thành công ! Bác sĩ vui lòng yêu Khách hàng/Bệnh nhân xác nhận hoàn thành sau khi kết thúc dịch vụ, cảm ơn Bác sĩ !');
                    curr_status = 'patient-begin';
                    break;
                case 'patient-completed':
                    setTextConfirm('Quý khách Xác nhận đã hoàn thành dịch vụ thành công !');
                    curr_status = 'doctor-begin';
                    break;
                case 'completed':
                    setTextConfirm('Xác nhận đã hoàn thành dịch vụ thành công! Cảm ơn Bác sĩ !');
                    curr_status = 'patient-completed';
                    break;
                case 'accepted':
                    setTextConfirm('Quý khách đã chấp nhận lịch hẹn mới từ bác sĩ thành công ! Quý khách vui lòng sắp xếp thời gian để đến đúng hẹn theo lịch mới. Cảm ơn Quý khách');
                    curr_status = 'wait-confirm';
                    break;
                case 'abandoned':
                    setTextConfirm('Quý khách đã hủy lịch hẹn mới từ bác sĩ thành công ! Trong trường hợp Quý khách từ chối nhưng đã thanh toán phí, thì phí sẽ được hoàn lại trong vòng 7 ngày kể từ ngày hủy dịch vụ. Cảm ơn Quý khách !');
                    curr_status = 'wait-confirm';
                    break;
                default: break;
            }
            Services.get(`${CONSTANT.API.DOCTOR.ORDER}?verify_code=${code}&status=${curr_status}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200 && res.data.data.docs.length > 0) {
                        await setOrder(res.data.data.docs[0]);
                        let upd = { actUpd: 'udpStatus', status: status };
                        Services.patch(`${CONSTANT.API.DOCTOR.ORDER}/${res.data.data.docs[0]._id}`, upd)
                            .then(async res1 => {
                                if (res1.data.code === 200) {
                                    message.success(textConfirm, 5);
                                }
                                setLoading(false);
                            })
                    }
                    setLoading(false);
                })
        }
    }, []);

    return (
        <div className="page-checkout service-complete">
            <div className="container">
                <Spin spinning={loading}>
                    <div className="fw-bold text-center">{textConfirm}</div>
                    <div className="bank-transfer">
                        <div>Tên dịch vụ: <span className="fw-medium"> {order?.items?.length > 0 && order?.items[0].service?.name}</span></div>
                        <div>Ngày thực hiện: <span className="fw-medium">{order?.date?.day}/{order?.date?.month}/{order?.date?.year}</span></div>
                        <div>Vào lúc: <span className="fw-medium">{order?.time?.time_h}:{order?.time?.time_m}</span></div>
                        <div>Địa điểm khám: <span className="fw-medium">
                            {order?.location && order?.location.name}
                        </span></div>
                        <div>Địa chỉ: <span className="fw-medium">
                            {order?.location && order?.location.address}
                        </span></div>
                        <div>Phương thức thanh toán: <span className="fw-medium">
                            {(order?.payment_method && order?.payment_method === 'pay_on_spot') ? 'Thanh toán tại địa điểm khám' : 'Thanh toán trực tuyến'}
                        </span>
                        </div>
                        <div>Tổng tiền: <span className="price">{COMMON.currencyFormat(order?.total)}{COMMON.getCurrency('VND')}</span></div>

                        <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                            <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default DoctorBookingConfirm;