import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
   Row, Col, Rate, Slider, Image, Button, List, Empty, Spin
} from 'antd';
import {
   StarFilled
} from '@ant-design/icons';

import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import './review.css';

// import item comment
import ItemComment from './item-comment';
import ModalFromReview from './form-review';
import * as Services from '../../store/services';
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';

const Review = ({ content_id, type }) => {

   const [isModalVisible, setIsModalVisible] = useState(false);
   const [reivews, setReviews] = useState([]);
   const [total, setTotal] = useState(0);
   const [reviewStatistic, setReviewStatistic] = useState(0);
   const [loading, setLoading] = useState(true);
   const [images, setImages] = useState([]);
   const [filterActive, setFilterActive] = useState(0);
   const [pagination, setPagination] = useState({
      pageSize: 10,
      hideOnSinglePage: true,
      total: 0
   });
   const refReview = useRef(null);

   const loadReview = useCallback((params = {}) => {
      if (!params?.page)
         params.page = 1;
      params.perPage = pagination.pageSize;
      params.content_id = content_id;
      params.type = type;
      setLoading(true);
      Services.get(`${CONSTANT.API.REVIEW}`, params)
         .then(res => {
            if (res?.data?.code === 200) {
               const { docs, total, statistic } = res?.data?.data;
               setTotal(total);
               setReviewStatistic(statistic);
               let _images = [];
               if (docs?.length > 0) {
                  for (let item of docs) {
                     _images.push(...item.images);
                  }
                  setReviews(docs);
               }
               else {
                  setReviews([]);
               }
               // pagination config
               setImages(_images);
               let _pagination = { ...pagination };
               _pagination.total = total;
               setPagination(_pagination);
            }
            setLoading(false);
         })
   }, [content_id, type])

   useEffect(() => {
      let mounted = true;
      if (mounted && content_id && type) {
         loadReview();
      }
      return () => {
         //mounted = false;
      }
   }, [content_id, type, loadReview])

   const showModalAddReivew = async () => {
      setIsModalVisible(true);
   };

   // xử lý sự kiện filter theo sao
   const onFilterStar = (value) => () => {
      if (value > 0)
         loadReview({ rating: value });
      else
         loadReview();
      setFilterActive(value);
      window.scrollTo({
         top: refReview.current.offsetTop,
         behavior: "smooth",
      });
   }

   // Xử lý sự kiện phân trang
   const onChangePage = (page) => {
      window.scrollTo({
         top: refReview.current.offsetTop,
         behavior: "smooth",
      });
      let params = { page };
      if (filterActive > 0)
         params.rating = filterActive;
      loadReview(params);
   }

   return (
      <div className="block-review" >
         <div className="block-review-title">Cảm nhận</div>
         {
            reviewStatistic?.average?.length > 0 &&
            <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]} ref={refReview}>
               <Col xs={24} lg={8}>
                  <Row align="middle" className="total">
                     <Col xs={6} lg={5}><div className="total-rate">{reviewStatistic?.average[0].avg.toFixed(1)}</div></Col>
                     <Col xs={16} lg={19}>
                        <Rate className="total-star" disabled allowHalf value={reviewStatistic?.average[0].avg.toFixed(1)} />
                        <div className="total-rate-text">{reviewStatistic?.average[0].count} cảm nhận</div>
                     </Col>
                  </Row>
                  {
                     reviewStatistic?.rate?.length > 0 && reviewStatistic?.rate.map(item => (
                        <Row align="middle" gutter={[0, 24]} className="rate-statictis" key={item._id}>
                           <Col lg={8}>
                              <Rate className="rate-star" disabled allowHalf value={item._id} />
                           </Col>
                           <Col lg={11}>
                              <Slider defaultValue={(item.count / total) * 100} disabled={true} />
                           </Col>
                           <Col lg={4}>
                              <div className="num">{item.count}</div>
                           </Col>
                        </Row>
                     ))
                  }


               </Col>
               <Col xs={24} lg={16}>
                  <div className="rate-filter">
                     <Button className={`btn-global ${filterActive === 0 ? 'bg' : ''}`} onClick={onFilterStar(0)}>Tất cả</Button>
                     {
                        CONSTANT.RATING_DESC?.length > 0 && CONSTANT.RATING_DESC.map((item, index) =>
                           <Button className={`btn-global ${filterActive === CONSTANT.RATING_DESC.length - index ? 'bg' : ''}`} key={CONSTANT.RATING_DESC.length - index} onClick={onFilterStar(CONSTANT.RATING_DESC.length - index)}>{CONSTANT.RATING_DESC.length - index} <StarFilled /></Button>
                        )
                     }

                  </div>
                  {
                     images?.length > 0 &&
                     <div className="rate-images">
                        <div className="title">Hình ảnh</div>
                        <LightgalleryProvider>
                           <Row gutter={[{ xs: 16, lg: 24 }, { xs: 16, lg: 24 }]}>
                              {
                                 images.map((item, index) => (
                                    <Col xs={6} lg={5} key={index} className={`item-img ${index === 3 ? 'mask' : ''}`}>
                                       <LightgalleryItem
                                          group={`groupTotal-${index}`}
                                          src={COMMON.getImage(item, 'review')}
                                       >
                                          <Image
                                             preview={false}
                                             src={COMMON.getImage(item, 'review')}
                                          />
                                          {index === 3 && <div className="caption">+4</div>}
                                       </LightgalleryItem>
                                    </Col>
                                 ))
                              }

                           </Row>
                        </LightgalleryProvider>
                     </div>
                  }

               </Col>
            </Row>
         }

         {/* Begin list comment */}
         <List
            pagination={{
               ...pagination,
               onChange: (page) => onChangePage(page)
            }}
            dataSource={reivews}
            renderItem={item => <ItemComment data={item} />}
            className="list-comment"
            locale={{ emptyText: <Empty description="Chưa có cảm nhận nào" /> }}
            loading={loading}
         />

         <div className="text-center">
            <Button className="btn-global bg btn-write-comment" onClick={showModalAddReivew} icon={<StarFilled />}>Viết cảm nhận</Button>
         </div>

         { /* Begin form review */}
         <ModalFromReview
            isModalVisible={isModalVisible}
            cancel={() => setIsModalVisible(false)}
            content_id={content_id}
            type={type}
         />
      </div>
   )
}

export default Review;