import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import {
    Statistic, Breadcrumb, Form, Input, Button, Tabs, Modal, message, Row, Col
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import * as COMMON from '../../helper/common';
import * as Services from '../../store/services';
import CONSTANT from '../../config/constant';
import firebase from '../../config/firebase';
import './user-style.css';
const Login = () => {
    const { Countdown } = Statistic;
    let history = useHistory();
    const [form] = Form.useForm();
    const [waiting, setWaiting] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });
    const [deadline, setDeadline] = useState(0);
    useEffect(() => {
        let user = localStorage.getItem(CONSTANT.STORAGE_USER);
        if (user) {
            history.push('/');
        }
    })
    const onFinish = () => {
        setWaiting(false);
    }
    const onLogin = (values) => {
        setWaiting(true);
        Services.post(`${CONSTANT.API.AUTH.LOGIN}`,
            {
                email: values.email,
                password: values.password,
                role: 'user'
            },
            {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }
        )
            .then(res => {
                if (res && res.data && res.data.code === 200 && res.data.data) {

                    localStorage.setItem(CONSTANT.STORAGE_USER, JSON.stringify(res.data.data.user));
                    cookie.save(CONSTANT.STORAGE_REFRESH_TOKEN, res.data.data.token.refreshToken, { path: '/' });
                    cookie.save(CONSTANT.STORAGE_TOKEN, `${res.data.data.token.tokenType} ${res.data.data.token.accessToken}`, { path: '/' });
                    message.success('Đăng nhập thành công', 3).then(() => {
                        if (cookie.load('loginBack')) {
                            let loginBack = cookie.load('loginBack');
                            cookie.remove('loginBack', { path: '/' });
                            window.location.href = loginBack;
                        }
                        else {
                            window.location.href = '/dang-nhap/thanh-cong';
                        }
                    })
                }
                else if (res.data.code === 403) {
                    setWaiting(false);
                    message.warning('Tài khoản chưa kích hoạt. Vui lòng truy cập email xem lại email và kích hoạt tài khoản!', 7)
                }
                else if (res.data.code === 400) {
                    setWaiting(false);
                    message.warning(res.data.message === 'wrong_pass' ? 'Mật khẩu không đúng. Vui lòng kiểm tra lại mật khẩu!' : 'Email không tồn tại. Vui lòng kiểm tra lại!', 7)
                }

                else {
                    setWaiting(false);
                    message.error('Đăng nhập thất bại. Vui lòng thử lại sau', 7)

                }
            });
    }
    const onFinishFailed = () => {

    }
    const closePopup = useCallback(() => {
        form.resetFields();
        setIsOpen(false);
    }, [form]);
    const configureCaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                this.onSubmit();
            },
            defaultCountry: "VN"
        });

    }
    const onSubmit = useCallback((values) => {
        window.confirmationResult.confirm(values.code).then((result) => {
            //Chỗ này opt đúng chèn api login và get token chỗ này 
            let phone = '+' + phones.phone;
            phone = phone.replace('+' + phones.dial_code, '');
            Services.post(`${CONSTANT.API.AUTH.LOGIN_WITH_PHONE}`,
                {
                    dial_code: phones.dial_code,
                    phone: phone,
                    role: 'user'
                },
                {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }
            )
                .then(res => {
                    if (res && res.data && res.data.code === 200 && res.data.data) {
                        localStorage.setItem(CONSTANT.STORAGE_USER, JSON.stringify(res.data.data.user));
                        cookie.save(CONSTANT.STORAGE_REFRESH_TOKEN, res.data.data.token.refreshToken, { path: '/' });
                        cookie.save(CONSTANT.STORAGE_TOKEN, `${res.data.data.token.tokenType} ${res.data.data.token.accessToken}`, { path: '/' });
                        message.success('Đăng nhập thành công', 2).then(() => {
                            if (cookie.load('loginBack')) {
                                let loginBack = cookie.load('loginBack');
                                cookie.remove('loginBack', { path: '/' });
                                window.location.href = loginBack;
                            }
                            else {
                                window.location.href = '/dang-nhap/thanh-cong';
                            }
                        })
                    }
                    else {
                        setWaiting(false);
                        message.error('Đăng nhập thất bại. Vui lòng thử lại sau', 7)

                    }
                });
        }).catch((error) => {
            //Mã kích hoạt không đúng làm gì tùy nha
            alert("Mã kích hoạt không đúng vui lòng kiểm tra lại")
        });
    }, [phones]);
    const onLoginPhone = () => {
        setWaiting(true);
        if (COMMON.validatePhone('+' + phones.phone)) {
            Services.post(`${CONSTANT.API.USER.CHECK_PHONE}`,
                {
                    dial_code: phones.dial_code,
                    phone: ('+' + phones.phone).replace('+' + phones.dial_code, '')
                },
                {
                    validateStatus: function (status) {
                        return status < 500;
                    }
                }
            )
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        configureCaptcha();
                        const appVerifier = window.recaptchaVerifier;
                        firebase.auth().signInWithPhoneNumber('+' + phones.phone, appVerifier).then(function (confirmationResult) {
                            window.confirmationResult = confirmationResult;
                            message.warning('Mã xác minh vừa gửi đến số điện thoại mà Quý khách đã đăng ký, vui lòng kiểm tra và nhập mã để đăng nhập. Cảm ơn Quý khách !', 10);
                            setIsOpen(true);
                            setDeadline(Date.now() + 60 * 1000);

                        }).catch(function (error) {
                            message.warning('Không thể xác nhận được số điện thoại, vui lòng kiểm tra lại !', 10);
                            console.log(error);
                            setWaiting(false);
                        });
                    }
                    else {
                        setWaiting(false);
                        message.warning('Số điện thoại chưa được đăng ký sử dụng, vui lòng đăng ký trước khi sử dụng. Cảm ơn quý khách', 10);
                    }

                });
        }
        else {
            setWaiting(false);
            message.error('Số điện thoại không đúng định dạng. Vui lòng kiểm tra lại, cảm ơn', 10);
        }
    }
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Đăng nhập</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                <div className="block-header">
                    <div className="text-uppercase title">Đăng nhập</div>
                    <div className="line"></div>
                </div>
                <div className="box-login">
                    <Tabs defaultActiveKey="phone_login" size="large" tabBarGutter={24}>
                        <Tabs.TabPane tab="Sử dụng số điện thoại" key="vi" forceRender={true}>
                            <PhoneInput
                                className="ant-form-item"
                                country='vn'
                                value={phones.phone}
                                onChange={(value, data) => {
                                    setPhones({
                                        phone: value,
                                        dial_code: data.dialCode,
                                        country_code: data.countryCode
                                    })
                                }}

                            />
                            <div className="ant-form-item text-center">
                                <Button type="primary" loading={waiting} className="btn-global bg btn-login" onClick={() => { onLoginPhone(); }}>
                                    Đăng nhập
                                </Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender={true} tab="Sử dụng Email" key="email_login">
                            <Form
                                name="basic"
                                onFinish={onLogin}
                                onFinishFailed={onFinishFailed}
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, type: "email", message: 'Email bỏ trống hoặc chưa đúng định dạng!' }]}
                                >
                                    <Input className="rounded-pill" size="large" />
                                </Form.Item>

                                <Form.Item
                                    label="Mật khẩu"
                                    name="password"
                                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                                >
                                    <Input.Password className="rounded-pill" size="large" />
                                </Form.Item>

                                <Form.Item className="text-center">
                                    <Button type="primary" loading={waiting} className="btn-global bg btn-login" htmlType="submit">
                                        Đăng nhập
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div className="text-center">
                                <Link className="login-form-forgot" to="/quen-mat-khau">
                                    Quên mật khẩu?
                                </Link>
                            </div>
                            <div className="text-center" style={{ marginTop: 10, marginBottom: 40 }} >
                                Bạn chưa có tài khoản?
                                <Link className="link-register" to="/dang-ky-tai-khoan">
                                    Đăng ký ngay!
                                </Link>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                <Modal
                    title='Nhập mã xác minh'
                    visible={isOpen}
                    onOk={form.submit}
                    onCancel={closePopup}
                    okText='Xác minh'
                    cancelText='Hủy'
                    width={370}
                    maskClosable={false}
                    footer={[
                        <Button key="submit" type="primary" onClick={form.submit}>
                            Xác minh
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                        <Col lg={24}>
                            <div>Vui lòng nhập mã xác minh đăng nhập gồm 6 chữ số đã được gửi đến: <span style={{ width: '100%', fontWeight: 'bold' }}>{'(+' + phones.dial_code + ')' + ('+' + phones.phone).replace('+' + phones.dial_code, '')} </span></div>
                        </Col>
                        <Col lg={24}>

                        </Col>
                        <Col lg={18}>
                            <div style={{ marginTop: '10px', marginBottom: '20px' }}>Mã số còn hiệu lực trong</div>
                        </Col>
                        <Col lg={6}>
                            <Countdown title="" suffix={'s'} value={deadline} onFinish={onFinish} format="ss" />
                        </Col>
                    </Row>
                    <Form
                        form={form}
                        onFinish={onSubmit}
                        layout="vertical"
                    >
                        <Form.Item
                            name="code"
                        >
                            <OtpInput
                                numInputs={6}
                                isInputNum={true}
                                separator={<span>-</span>}
                                inputStyle={{
                                    width: "2rem",
                                    height: "2rem",
                                    margin: "0 0.5rem",
                                    fontSize: "1rem",
                                    borderRadius: 4,
                                    border: "1px solid rgba(0,0,0,0.3)"
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <div id="sign-in-button"></div>
            </div>
        </React.Fragment>

    )
}

export default Login;