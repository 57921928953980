import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Button, Form, Input, Empty, message, Popover, List } from 'antd';
import { MedicineBoxOutlined, CalendarOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import moment from 'moment';
import ShowMoreText from "react-show-more-text";

import * as COMMON from '../../../helper/common';
import ModalBookService from './modal-book-service';

const ItemService = (props) => {
    const [form] = Form.useForm();

    const [calendars, setCalendars] = useState([]);
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedTime, setSelectedTime] = useState({});
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [showAddress, setShowAddress] = useState(false);
    const [mLocation, setMLocation] = useState({});

    const [isModalOnlineVisible, setIsModalOnlineVisible] = useState(false);

    useEffect(() => {
        if (props?.data?.calendars?.length > 0) {
            let calendars = [...props.data.calendars];
            let currentDate = moment(new Date()).format('YYYY-MM-DD');
            calendars = calendars.filter(c => moment(currentDate).isSameOrBefore(moment(`${c.year}-${c.month}-${c.day}`, 'YYYY-MM-DD')));
            calendars.sort((a, b) => (a.month > b.month) ? 1 : (a.month === b.month) ? ((a.day > b.day) ? 1 : -1) : -1);
            setCalendars(calendars);
            if (calendars.length > 0) {
                let calendar = { ...calendars[0] };
                let calendarTimes = [...calendar.times];
                calendarTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
                setSelectedTimes(calendarTimes);
                setSelectedDate({ ...calendar });
                form.setFieldsValue({ date: calendar._id });
            }
        }
    }, [props]);
    // Xử lý sự kiện thay đổi địa điểm khám
    const onChangeLocation = (value, option) => {
        if (option?.children === 'Khám tại nhà bệnh nhân')
            setShowAddress(true)
        else
            setShowAddress(false)
        for (let loc of props?.dataDoctor?.addresses) {
            if (loc._id === value) {
                setMLocation({ ...loc });
            }
        }
        // Nếu đã chọn ngày thì lọc lại thông tin thời gian
        if (selectedDate) {
            getTimes(selectedDate);
        }

    }
    // Xử lý sự kiện khi chọn ngày
    const onChangeDate = (value, option) => {
        if (value) {
            let calendar = JSON.parse(option.key);
            getTimes(calendar);
            setSelectedDate({ ...calendar });
        }
    }
    const getTimes = (calendar) => {
        if (calendar) {
            let arrTimes = [];
            let _localtion = form.getFieldValue('location');
            if (_localtion) {
                if (calendar?.times?.length > 0) {
                    for (const time of calendar.times) {
                        if (time?.address === _localtion)
                            arrTimes.push({ ...time });
                    }
                }
                else {
                    message.warning('Tạm thời chưa có lịch khám cho địa điểm khám này', 3);
                }

            }
            else {
                message.warning('Vui lòng chọn địa điểm khám để xem thông tin thời gian khám.', 3);
            }
            arrTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
            setSelectedTimes(arrTimes);
        }
    }

    const bookService = data => () => {

        if (!form.getFieldValue('location')) {
            return message.warning('Vui lòng chọn địa điểm khám');
        }
        else if (!selectedDate?._id) {
            return message.warning('Vui lòng chọn ngày khám');
        }
        else {
            setIsModalOnlineVisible(true);
            setSelectedTime({ ...data })
        }

    }
    return (
        <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]} className="item">
            <Col xs={24} lg={9} key={props.data._id} className="col-info">
                <div className="title">{props.data.name}</div>
                <div className="price"><strong>Giá từ:</strong> {COMMON.currencyFormat(props.data.price)} {COMMON.getCurrency('VND')}</div>
                {
                    props.data?.price_note &&
                    <div style={{ fontStyle: 'italic', color: "#777", fontSize: '13px', marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: `${props.data?.price_note.replace(/(?:\r\n|\r|\n)/g, '<br />')}` }}></div>
                }
                {
                    (props?.data?.is_government_insurance || props?.data?.is_direct_guarantee_insurance) &&
                    <div className="insurance">
                        <strong>Loại bảo hiểm áp dụng:</strong>
                        {
                            props?.data?.is_government_insurance &&
                            <div className="insurance-item">
                                <div className="insurance-title">Bảo hiểm y tế nhà nước</div>
                                <div className="insurance-sapo">{props?.data?.note_government_insurance && parse(props?.data?.note_government_insurance?.replace(/(?:\r\n|\r|\n)/g, '<br />')) || 'Chưa có thông tin'}</div>
                            </div>
                        }
                        {
                            props?.data?.is_direct_guarantee_insurance &&
                            <div className="insurance-item">
                                <div className="insurance-title">Bảo hiểm bảo lãnh trực tiếp</div>
                                <div className="insurance-sapo">{props?.data?.note_direct_guarantee_insurance && parse(props?.data?.note_direct_guarantee_insurance?.replace(/(?:\r\n|\r|\n)/g, '<br />')) || 'Chưa có thông tin'}</div>
                                <Popover title="Danh sách công ty bảo lãnh" content={(
                                    <div>
                                        {
                                            props?.data?.list_direct_guarantee_insurance?.length > 0 ?
                                                <div className="popover-company">
                                                    <List
                                                        size="small"
                                                        bordered
                                                        dataSource={props?.data?.list_direct_guarantee_insurance}
                                                        renderItem={(item, index) => <List.Item>{index + 1}. {item}</List.Item>}
                                                    />
                                                </div>
                                                :
                                                <div>Thông tin đang cập nhật</div>
                                        }
                                    </div>

                                )} trigger="click">
                                    <Button type="link" className="btn-view-list">Xem danh sách</Button>
                                </Popover>
                            </div>
                        }
                    </div>
                }
                <div className="d-none d-md-block">
                    <ShowMoreText
                        lines={3}
                        more="Xem thêm"
                        less="Thu gọn"
                    >
                        <div className="description">{props?.data?.summary && parse(props?.data?.summary?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}</div>
                    </ShowMoreText>
                </div>

            </Col>
            <Col xs={24} lg={15}>
                <div className="text-uppercase header-calendar">
                    <MedicineBoxOutlined /> Đặt lịch khám
                </div>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinishFailed={() => { }}
                    form={form}
                >

                    <Form.Item
                        label="Địa điểm khám" name="location" required tooltip="Vui lòng chọn trường này"
                        rules={[{ required: true, message: 'Vui lòng chọn địa điểm khám bệnh' }]}
                    >
                        <Select placeholder="Chọn điểm khám" className="select-global" size='large'
                            onChange={onChangeLocation}
                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
                        >
                            {
                                props?.dataDoctor?.addresses?.length > 0 &&
                                props.dataDoctor.addresses.map(item => (
                                    <Select.Option key={item._id} value={item._id}>{item?.name} <br /> {item?.address}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>


                    {
                        showAddress &&
                        <Col xs={24} lg={24}>
                            <Form.Item label="Địa chỉ của bạn" name="address" required tooltip="Thông tin này bắt buộc nhập"
                                rules={[{ required: true, message: 'Vui lòng nhập địa chỉ nhà của bạn' }]}
                            >
                                <Input className="rounded-pill" size="large" />
                            </Form.Item>
                        </Col>
                    }


                    <Form.Item label="Chọn ngày"
                        name="date"
                        rules={[{ required: true, message: 'Vui lòng chọn ngày khám bệnh' }]}
                    >
                        <Select
                            placeholder="Chọn ngày"
                            className="select-global select-date"
                            size='large'
                            onChange={onChangeDate}
                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
                        >
                            {
                                calendars.length > 0 &&
                                calendars.map(c => (
                                    <Select.Option key={JSON.stringify(c)} value={c._id}>{`${COMMON.getDayOfWeek(c.day_of_week.toString())} - ${c.day}/${c.month}/${c.year}`}</Select.Option>
                                ))
                            }

                        </Select>
                    </Form.Item>
                </Form>
                <Row className="group-btn-time" gutter={[{ xs: 16, md: 16 }, { xs: 16, md: 16 }]}>
                    {
                        selectedTimes.length > 0 && selectedTimes.map(time => (
                            <Col xs={12} md={6} key={time._id}>

                                <Button className="btn-time"
                                    type="primary"
                                    disabled={
                                        time.is_order || (
                                            moment(new Date(), "YYYY-MM-DD").isSameOrAfter(moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`, "YYYY-MM-DD")) &&
                                            moment(new Date(), "HH:mm").isSameOrAfter(moment(`${time.time_h}:${time.time_m}`, "HH:mm"))
                                        )
                                    }
                                    key={time._id} shape="round" icon={<CalendarOutlined />}
                                    onClick={bookService(time)}
                                >
                                    {moment(`${time.time_h}:${time.time_m}`, "HH:mm").format("HH:mm")} - {COMMON.calculateTime(`${time.time_h}:${time.time_m}`, time.duration)}
                                </Button>
                            </Col>
                        ))
                    }
                </Row>
                <div className="description d-block d-md-none">
                    <div className="fw-medium">Mô tả:</div>
                    {props?.data?.summary && parse(props?.data?.summary?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                </div>
            </Col>

            <ModalBookService
                visible={isModalOnlineVisible}
                cancel={() => setIsModalOnlineVisible(false)}
                data={props.data}
                dataDoctor={props.dataDoctor}
                date={selectedDate}
                time={selectedTime}
                location={mLocation}
            />
        </Row>
    )
}

export default ItemService;