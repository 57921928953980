import React from 'react';

import { Row, Col, Avatar, Rate, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ShowMoreText from "react-show-more-text";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';

const ItemComment = ({ data }) => {
   return (
      <div className="item-comment">
         <Row gutter={[{ xs: 8, lg: 32 }, { xs: 8, lg: 32 }]} >
            <Col xs={6} lg={6} className="text-center">
               {
                  data?.fullname ?
                     <Avatar style={{ backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}` }} className="text-uppercase fw-medium avatar-user" size={70}>{data.fullname[0]}</Avatar>
                     :
                     <Avatar icon={<UserOutlined />} size={70} className="avatar-user" />
               }

               <div className="cus-name">{data?.fullname ? data.fullname : ''}</div>
            </Col>
            <Col xs={18} lg={18}>
               <div>
                  <Rate className="star" disabled allowHalf value={data.rating} />
                  <span className="pleased">{CONSTANT.RATING_DESC[data.rating - 1]}</span>
               </div>
               <div className="util d-none">Loại: Đang cập nhật...</div>
               <ShowMoreText
                  /* Default options */
                  lines={3}
                  more="Xem thêm"
                  less="Thu gọn"
                  className="content"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
               >
                  <p dangerouslySetInnerHTML={{ __html: `${data.content}` }}></p>
               </ShowMoreText>
               <div className="images">
                  <Row>
                     <LightgalleryProvider>
                        <Row gutter={[{ xs: 8, lg: 24 }, { xs: 8, lg: 24 }]}>
                           {
                              data?.images?.length > 0 && data?.images.map((item, index) => (
                                 <Col xs={6} lg={4} key={index} className={`item-img ${index === 3 ? 'mask' : ''}`}>
                                    <LightgalleryItem
                                       group={`comment-${index}`}
                                       src={COMMON.getImage(item, 'review')}
                                    >
                                       <Image
                                          preview={false}
                                          src={COMMON.getImage(item, 'review')}
                                       />
                                       {index === 3 && <div className="caption">+4</div>}
                                    </LightgalleryItem>
                                 </Col>
                              ))
                           }

                        </Row>
                     </LightgalleryProvider>
                  </Row>
               </div>
            </Col>
         </Row>
      </div>
   )
}

export default ItemComment;