import * as Services from "../services";
import CONSTANT from "../../config/constant";
import {
  // get list
  GET_LIST,
  GET_LIST_FAILURE,
  GET_LIST_SUCCESS,
  // get detail
  GET_DETAIL,
  GET_DETAIL_SUCCESS,
  GET_DETAIL_FAILURE,
  // Get handbook category
  GET_HANDBOOK_CATEGORY,
  GET_HANDBOOK_CATEGORY_SUCCESS,
  GET_HANDBOOK_CATEGORY_FAILURE,
} from "./type";

// get list
export const getList = (queryParam) => {
  let url = `${CONSTANT.API.HANDBOOK.HANDBOOK}`;
  return async (dispatch) => {
    dispatch({ type: GET_LIST });
    return await Services.get(url, queryParam).then((res) => {
      if (res && res.data && res.data.code === 200) {
        dispatch({
          type: GET_LIST_SUCCESS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_LIST_FAILURE,
          payload: "Lỗi lấy dữ liệu List HANDBOOK từ API",
        });
      }
    });
  };
};

export const getDetail = (id) => {
  return async (dispatch) => {
    if (id) {
      let url = `${CONSTANT.API.HANDBOOK.HANDBOOK}/${id}`;
      dispatch({ type: GET_DETAIL });
      return await Services.get(url).then((res) => {
        if (res && res.data && res.data.code === 200) {
          dispatch({
            type: GET_DETAIL_SUCCESS,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: GET_DETAIL_FAILURE,
            payload: "Lỗi lấy dữ liệu HANDBOOK từ API",
          });
        }
      });
    } else {
      dispatch({ type: GET_DETAIL_FAILURE, payload: "Id không đúng" });
    }
  };
};
// process get list hankbook category
export function getHandbookCategory(queryParam) {
  let url = `${CONSTANT.API.HANDBOOK.CATEGORY}`;
  return async (dispatch) => {
    dispatch({
      type: GET_HANDBOOK_CATEGORY,
    });
    return await Services.get(url, queryParam).then((res) => {
      if (res && res.data && res.data.code === 200) {
        dispatch({
          type: GET_HANDBOOK_CATEGORY_SUCCESS,
          payload: res.data.data.docs,
        });
      } else {
        dispatch({
          type: GET_HANDBOOK_CATEGORY_FAILURE,
          payload: "Không có dữ liệu trả về từ API",
        });
      }
    });
  };
}
