import React from 'react';

import { Menu, List, Divider, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, FileDoneOutlined } from '@ant-design/icons';
import CONSTANT from '../../../../config/constant';
const { SubMenu } = Menu;

const AccountMenu = (props) => {
    const userInfor = props.data;
    return (
        <>
            <List
                itemLayout="horizontal"
                dataSource={[{ title: userInfor && userInfor.email }]}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={`${CONSTANT.STATIC_URL}${userInfor && userInfor.avatar}`} size={{ xs: 24, sm: 32, md: 48, lg: 48, xl: 48 }} />}
                            title={<span>{item.title}</span>}
                            description={<Link to="thong-tin-tai-khoan">Sửa hồ sơ</Link>}
                        />
                    </List.Item>
                )}
            />
            <Divider />

            <Menu
                defaultSelectedKeys={[userInfor.active]}
                defaultOpenKeys={['ac']}
                mode="inline">
                <SubMenu key="ac" icon={<UserOutlined />} title="Tải khoản của tôi">
                    <Menu.Item key="hs"><Link to="/thong-tin-tai-khoan">Hồ sơ</Link></Menu.Item>
                    <Menu.Item key="dc"><Link to="/so-dia-chi">Sổ địa chỉ</Link></Menu.Item>
                    <Menu.Item key="cp"><Link to="/thay-doi-mat-khau">Đổi mật khẩu</Link></Menu.Item>

                </SubMenu>
                <Menu.Item key="qldh" icon={<FileDoneOutlined />}>
                    <Link to="/don-hang-cua-ban">Quản lý đơn hàng</Link>
                </Menu.Item>
            </Menu>
        </>
    )
}
export default AccountMenu;