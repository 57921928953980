import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Steps, Typography, Result, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as Services from "../../store/services";
import { ProductAction } from '../../store/root-action';
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
const { Step } = Steps;

const PaymentSuccess = () => {
    const [order, setOrder] = useState();
    const orderItem = useSelector(state => state.productReducer.orderItem);
    const dispatch = useDispatch();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);
    // lấy query url
    const urlQueryParams = useLocation().search;

    useEffect(() => {
        if (urlQueryParams) {
            setLoading(true);
            Services.get(`${CONSTANT.API.PRODUCT.CHECKOUT}/payment/pay-verify${urlQueryParams}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        let data = res.data.data;
                        let order = data.data_order;
                        let result = { isSucceed: data.isSucceed, message: data.message }
                        setResult(result);
                        if (order) {
                            setOrder(order);
                        }
                    }
                    setLoading(false);
                });
            // reset bộ đếm giỏ hàng
            dispatch(ProductAction.emptyCart());
        }
    }, [dispatch, urlQueryParams]);

    localStorage.removeItem(CONSTANT.ORDER_ID);

    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={3}>
                        <Step title="Chọn hàng" />
                        <Step title="Nhập thông tin" />
                        <Step title="Thanh toán" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Spin spinning={loading}>
                    <div className="payment-success">
                        {
                            result && result.isSucceed &&
                            <Result
                                status="success"
                                title={`TUYỆT VỜI! BẠN ĐÃ THANH TOÁN CHO ĐƠN HÀNG MÃ #VIVMEDIC-P${order?.uid}`}
                                subTitle={<div>Chúng tôi đã gửi email xác nhận đến địa chỉ email <strong>{order?.shipping?.email}</strong> của bạn</div>}
                            />
                        }
                        {
                            result && !result.isSucceed &&
                            <>
                                {
                                    (() => {
                                        switch (result.message) {
                                            case 'Wrong checksum':
                                                return <Result
                                                    status="warning"
                                                    title="Thông tin xác thực thanh toán không hợp lệ. Chúng tôi sẽ hủy đơn hàng này. Xin cảm ơn!"
                                                />
                                            case 'Order not exist':
                                                return <Result
                                                    status="404"
                                                    title="404"
                                                    subTitle="Đơn hàng không tồn tại"
                                                />
                                            default:
                                                return <Result
                                                    status="500"
                                                    title="500"
                                                    subTitle={<span>{result.message}. Chúng tôi sẽ hủy đơn hàng này. Xin cảm ơn!</span>}
                                                />
                                        }
                                    })()
                                }
                            </>

                        }
                        {
                            order && order?._id &&
                            <div className="box-info">
                                <Row>
                                    <Col lg={8} className="fw-bold">Địa chỉ gửi hàng</Col>
                                    <Col lg={16}>
                                        <div className="fw-bold">{order ? order.shipping.first_name + ' ' + order.shipping.last_name : ''}</div>
                                        <Typography.Text>{order ? order.shipping.email : ''}</Typography.Text><br />
                                        <Typography.Text>{order ? order.shipping.address_1 + ',' + order.shipping.address_2 + ',' + order.shipping.city + ',' + order.shipping.country : ''}</Typography.Text><br />
                                        <Typography.Text>{order ? '+' + order.shipping.phone : ''}</Typography.Text>
                                    </Col>
                                </Row>
                                <div className="fw-bold" style={{ marginBottom: 20 }}>Thông tin đơn hàng</div>
                                <div className="list-product">
                                    {
                                        orderItem && orderItem.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="box-cover">
                                                    <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                                </div>
                                                <div className="info">
                                                    <div className="title">
                                                        {item.product.name}<br></br>
                                                        <p className="product-attribute">
                                                            {
                                                                item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                                    let str = attr.options[0].name;
                                                                    if (indexAttr < item.variant.attributes.length - 1)
                                                                        str += " / ";
                                                                    return str;
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="quantity-price">
                                                        <span>x{item.quantity}</span>
                                                        <span className="price">{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    }
                                    <div className="other-price">
                                        <span>Tổng giá sản phẩm</span>
                                        <span className="price">{COMMON.currencyFormat(order ? order.subtotal : 0)} ₫</span>
                                    </div>
                                    <div className="other-price">
                                        <span>Phí vận chuyển</span>
                                        <span >Quý khách tự thanh toán</span>
                                    </div>
                                    <div className="other-price">
                                        <span>Giảm giá</span>
                                        <span className="price">{COMMON.currencyFormat(order ? order.discount_total : 0)} ₫</span>
                                    </div>
                                    <div className="order-total">
                                        <span>Tổng cộng</span>
                                        <span className="price-total">{COMMON.currencyFormat(order ? order.total : 0)} ₫</span>
                                    </div>
                                </div>

                                <Row>
                                    <Col lg={8} className="fw-bold">Vận chuyển</Col>
                                    <Col lg={16}>
                                        <Typography.Text>Quý khách tự thanh toán cho bên vận chuyển</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={8} className="fw-bold">Phương thức thanh toán</Col>
                                    <Col lg={16}>
                                        {
                                            order && order.payment_method === 'onepayDomestic' && <Typography.Text>Thẻ ATM nội địa</Typography.Text>
                                        }
                                        {
                                            order && order.payment_method === 'onepayInternational' && <Typography.Text>Thẻ tín dụng (VISA, Master Card)</Typography.Text>
                                        }
                                    </Col>
                                </Row>
                                <div className="text-end" style={{ marginTop: 30 }}>
                                    <Link to="/" className="btn-global bg" style={{ padding: '10px 50px' }}>Tiếp tục mua sắm</Link>
                                </div>
                            </div>
                        }

                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default PaymentSuccess;