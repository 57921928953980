import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
const ShopBookingConfirm = () => {
    const [order, setOrder] = useState();
    const [textConfirm, setTextConfirm] = useState();
    const { status, code } = useParams();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let curr_status = '';
        switch (status) {
            case 'abandoned':
                setTextConfirm('Quý khách đã hủy đơn hàng thành công');
                curr_status = 'wait-paid';
                break;
            case 'completed':
                setTextConfirm('Quý khách đã xác nhận hoàn thành đơn hàng thành công. Cảm ơn quý khách !');
                curr_status = 'customer-received';
                break;
            default: break;
        }
        if (status && code) {
            Services.get(`${CONSTANT.API.PRODUCT.ORDER}?verify_code=${code}&status=${curr_status}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200 && res.data.data.docs.length > 0) {
                        await setOrder(res.data.data.docs[0]);
                        let upd = { actUpd: 'udpStatus', status: status };
                        Services.patch(`${CONSTANT.API.PRODUCT.ORDER}/${res.data.data.docs[0]._id}`, upd)
                            .then(async res1 => {
                                if (res1.data.code === 200) {
                                    switch (status) {
                                        case 'abandoned':                                            
                                            message.success('Quý khách đã hủy đơn hàng thành công', 5);
                                            break;
                                        case 'completed':
                                            message.success('Quý khách đã xác nhận hoàn thành đơn hàng thành công. Cảm ơn quý khách', 5);                                            
                                            break;
                                        default: break;
                                    }
                                    
                                }
                                setLoading(false);
                            })
                    }
                    setLoading(false);
                })
        }
    }, []);

    return (

        <div className="page-checkout service-complete">
            {
                order ? <div className="container">
                    <Spin spinning={loading}>
                        <div className="bank-transfer">
                            <div className="fw-bold text-center">{textConfirm}</div>
                            <div className="fw-bold">Thông tin đơn hàng</div>
                            <div className="list-product">
                                {
                                    order && order.shop.map((shop) => (
                                        shop.items.map((item) => (
                                            <div className="item" key={item._id}>
                                                <div className="box-cover">
                                                    <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                                </div>
                                                <div className="info">
                                                    <div className="title">
                                                        {item.product.name}<br></br>
                                                        <p className="product-attribute">
                                                            {
                                                                item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                                    let str = attr.options[0].name;
                                                                    if (indexAttr < item.variant.attributes.length - 1)
                                                                        str += " / ";
                                                                    return str;
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="quantity-price">
                                                        <span>x{item.quantity}</span>
                                                        <span className="price">{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )))
                                }
                                <div className="other-price">
                                    <span>Tổng giá sản phẩm</span>
                                    <span className="price">{COMMON.currencyFormat(order?.subtotal)} ₫</span>
                                </div>
                                <div className="other-price">
                                    <span>Phí vận chuyển</span>
                                    <span >Quý khách tự thanh toán</span>
                                </div>
                                <div className="other-price">
                                    <span>Giảm giá</span>
                                    <span className="price">{COMMON.currencyFormat(order?.discount_total)} ₫</span>
                                </div>
                                <div className="order-total">
                                    <span>Tổng cộng</span>
                                    <span className="price-total">{COMMON.currencyFormat(order?.subtotal - (order?.discount_total))} ₫</span>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div> :
                    <div className="container" style={{ height: 200 }}>
                        <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                            <div className="bank-transfer">
                                <div className="fw-bold text-center">Liên kết xác nhận đã được thực hiện hoặc không đúng.<br></br> Vui lòng kiểm tra lại, cảm ơn Quý khách !</div><br></br>
                                <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default ShopBookingConfirm;