import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Breadcrumb, Result, Spin
} from 'antd';

import CONSTANT from '../../config/constant';
import * as Services from '../../store/services';

import './user-style.css';
const ActiveAccount = () => {
    let history = useHistory();

    // lấy query url
    const urlQueryParams = useLocation().search;
    const token = new URLSearchParams(urlQueryParams).get('token');
    const p = new URLSearchParams(urlQueryParams).get('p');

    const [activeSuccess, setActiveSuccess] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let user = localStorage.getItem(CONSTANT.STORAGE_USER);
        if (user) {
            history.push('/');
        }
    });

    useEffect(() => {
        if (token && p) {
            Services.post(`${CONSTANT.API.USER.ACTIVE}`, { token, p })
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        setActiveSuccess(true);
                    }
                    else {
                        setActiveSuccess(false);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setActiveSuccess(false);
                    setLoading(false);
                })
        }

    })
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Kích hoạt tài khoản</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container login-success active-success" >
                <Spin spinning={loading}>
                    {
                        !loading &&
                        <Result
                            status={activeSuccess ? 'success' : 'error'}
                            title={activeSuccess ? 'Kích hoạt tài khoản thành công' : 'Kích hoạt tài khoản không thành công'}
                            subTitle={activeSuccess ? `Tài khoản của bạn đã được kích hoạt. Bây giờ bạn có thể đăng nhập vào tài khoản của mình rồi!` : 'Liên kết kích hoạt hết hạn hoặc không hợp lệ'}
                            extra={[
                                activeSuccess && p !== 'agency' ? <Link className="btn-global bg" to={`/${CONSTANT.SLUG_LOGIN}`}>Đăng nhập</Link> : <a className="btn-global bg" href={CONSTANT.BASE_AGENCY}>Đăng nhập</a>,
                            ]}
                        />
                    }
                </Spin>
            </div>
        </React.Fragment>

    )
}

export default ActiveAccount;