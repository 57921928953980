import React from 'react';
import { Empty, Divider, Collapse, Avatar } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';

import ItemDoctor from './item-doctor';

const DoctorSpecialty = (props) => {

    // Render doctor
    const renderDoctor = (idSpecialty) => {
        if (idSpecialty) {
            let _doctors = props.doctors.filter(item => item.specialty === idSpecialty);
            if (_doctors.length > 0) {
                return (
                    <React.Fragment>
                        {
                            _doctors.map(item => (
                                <React.Fragment key={item._id}>
                                    <ItemDoctor data={item} hospital={props.hospital} />
                                    <Divider dashed />
                                </React.Fragment>
                            ))
                        }
                    </React.Fragment>
                )
            }
            else {
                return <Empty description="Chưa có Bác sĩ" />
            }
        }
    }
    return (
        <div className="doctor-specialty">
            {
                props?.specialties?.length > 0 &&
                <Collapse className="specialty-collapse" accordion expandIconPosition="right" defaultActiveKey={['1']}>
                    {
                        props?.specialties.map((item, index) => (
                            <Collapse.Panel header={<div><Avatar icon={<DeploymentUnitOutlined />} /> {item?.name_other}</div>} key={index + 1}>
                                <div className="summary">
                                    {item?.summary}
                                </div>

                                {renderDoctor(item._id)}
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
            }
        </div>
    )
}

export default DoctorSpecialty;