// Get list
export const GET_LIST = "GET_LIST_DOCTOR";
export const GET_LIST_SUCCESS = "GET_LIST_DOCTOR_SUCCESS";
export const GET_LIST_FAILURE = "GET_LIST_DOCTOR_FAILURE";

// Get detail
export const GET_DETAIL = "GET_DETAIL_DOCTOR";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_DOCTOR_SUCCESS";
export const GET_DETAIL_FAILURE = "GET_DETAIL_DOCTOR_FAILURE";

//get doctor category
export const GET_DOCTOR_CATEGORY = 'GET_DOCTOR_CATEGORY'
export const GET_DOCTOR_CATEGORY_SUCCESS = 'GET_DOCTOR_CATEGORY_SUCCESS'
export const GET_DOCTOR_CATEGORY_FAILURE= 'GET_DOCTOR_CATEGORY_FAILURE'
