import React from 'react';
import {
    Row, Col, Rate, Slider, Image, Button, List
} from 'antd';
import {
    StarFilled
} from '@ant-design/icons';

import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";

// import item comment
import ItemComment from './item-comment';

const Review = () => {
    return (
        <div className="block-review">
            <div className="block-review-title">Đánh giá</div>
            <Row gutter={[{xs:0, lg:32},{xs:0, lg:32}]}>
                <Col xs={24} lg={8}>
                    <Row align="middle" className="total">
                        <Col xs={6} lg={5}><div className="total-rate">3.5</div></Col>
                        <Col xs={16} lg={19}>
                            <Rate className="total-star" disabled allowHalf defaultValue={3.5} />
                            <div className="total-rate-text">7 đánh giá</div>
                        </Col>
                    </Row>
                    {
                        [4, 3].map(item => (
                            <Row align="middle" gutter={[0, 24]} className="rate-statictis" key={item}>
                                <Col lg={8}>
                                    <Rate className="rate-star" disabled allowHalf defaultValue={item} />
                                </Col>
                                <Col lg={11}>
                                    <Slider defaultValue={item*10} disabled={true} />
                                </Col>
                                <Col lg={4}>
                                    <div className="num">{item}</div>
                                </Col>
                            </Row>
                        ))
                    }


                </Col>
                <Col xs={24} lg={16}>
                    <div className="rate-filter">
                        <Button className="btn-global bg">Tất cả</Button>
                        <Button className="btn-global">5 <StarFilled /></Button>
                        <Button className="btn-global">4 <StarFilled /></Button>
                        <Button className="btn-global">3 <StarFilled /></Button>
                        <Button className="btn-global">2 <StarFilled /></Button>
                        <Button className="btn-global">1 <StarFilled /></Button>
                    </div>
                    <div className="rate-images">
                        <div className="title">Hình ảnh</div>
                        <LightgalleryProvider>
                            <Row gutter={[{xs:16, lg:24},{xs:16, lg:24}]}>
                                {
                                    [1, 2, 3, 4].map(item => (
                                        <Col xs={6} lg={6} key={item} className="item-img">
                                            <LightgalleryItem
                                                group="groupTotal"
                                                src="https://www.omronhealthcare-ap.com/Content/uploads/products/HEM-7322.jpg"
                                            >
                                                <Image
                                                    preview={false}
                                                    src="https://www.omronhealthcare-ap.com/Content/uploads/products/HEM-7322.jpg"
                                                />
                                                {item === 4 && <div className="caption">+4</div>}
                                            </LightgalleryItem>
                                        </Col>
                                    ))
                                }

                            </Row>
                        </LightgalleryProvider>
                    </div>
                </Col>
            </Row>
            {/* Begin list comment */}
            <List
                pagination={{
                    pageSize: 3,
                    hideOnSinglePage:true
                }}
                dataSource={[1, 2]}
                renderItem={item => <ItemComment data={item} />}
                className="list-comment"
            />
        </div>
    )
}

export default Review;