import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { Breadcrumb, Spin, Empty, message, Collapse, List, Row, Col, Card, Avatar } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./travel-medical.css";
import { axiosInstance } from "../../config/api";
import { useTranslation } from 'react-multi-lang';

import MetaSeo from "../../component/meta-seo";
import CONSTANT from "../../config/constant";
import * as COMMON from "../../helper/common";

function TravelMedicalHealthBeauty() {
    const history = useHistory();
    const t = useTranslation();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageSize: 20,
        total: 0,
        hideOnSinglePage: true,
        showLessItems: true,
    });


    useEffect(() => {
        let mounted = true;
        if (mounted) {

        }
        return () => (mounted = false);
    }, []);



    return (
        <React.Fragment>
            <MetaSeo
                title="Khỏe & Đẹp"
                keyword={`khỏe và đẹp, khỏe & đẹp, khoe va dep, khoe & dep, ${CONSTANT.PAGE_KEYWORD_EXT}`}
                description="DayCare là các dịch vụ chăm sóc sức khỏe & đẹp có liên quan đến chuyên môn y khoa được thực hiện hoàn tất trong ngày"
            />

            <div className="page-travel-medical">
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">Trang chủ</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}`}>{t('travel_medical')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Khỏe & Đẹp</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <div className="container">
                    <div className="block-header">
                        <div className="text-uppercase title">Khỏe & Đẹp</div>
                        <div className="sapo">DayCare là các dịch vụ chăm sóc sức khỏe & đẹp có liên quan đến chuyên môn y khoa được thực hiện hoàn tất trong ngày</div>
                        <div className="line"></div>
                    </div>
                    <div className="list">
                        <Row gutter={[{ xs: 16, lg: 0 }, 24]} justify="center">
                            <Col xs={12} md={6} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/khoe-dep.jpg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Chăm sóc da</h3>}
                                    />
                                    <Link to={`/${CONSTANT.SLUG_FACILITY}?spec=cham-soc-da`} title={`${t('learn_more')} Chăm sóc da`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/nha-khoa.jpg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Nha khoa</h3>}
                                    />
                                    <Link to={`/${CONSTANT.SLUG_FACILITY}?spec=nha-khoa`} title={`${t('learn_more')} Nha khoa`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/kham-mat.jpg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Khám mắt</h3>}
                                    />
                                    <Link to={`/${CONSTANT.SLUG_FACILITY}?spec=mat`} title={`${t('learn_more')} Khám mắt`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/spa-vat-ly-tri-lieu.jpg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Spa, Vật lý trị liệu</h3>}
                                    />
                                    <Link to={`/${CONSTANT.SLUG_FACILITY}?spec=spa-vat-ly-tri-lieu`} title={`${t('learn_more')} Spa, Vật lý trị liệu`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TravelMedicalHealthBeauty;
