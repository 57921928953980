import React from 'react';

import { Row, Col, Avatar, Rate, Image } from 'antd';

import ShowMoreText from "react-show-more-text";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";

const ItemComment = () => {
    return (
        <div className="item-comment">
            <Row gutter={[{xs:8, lg:32}, {xs:8, lg:32}]}>
                <Col xs={6} lg={6} className="text-center">
                    <Avatar size={70} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
                    <div className="cus-name">Trần Long</div>
                </Col>
                <Col xs={18} lg={18}>
                    <div>
                        <Rate className="star" disabled allowHalf defaultValue={5} />
                        <span className="pleased">Cực kỳ hài lòng</span>
                    </div>
                    <div className="util">Loại: Chăm sóc sau sinh cho Mẹ và Bé</div>
                    <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Xem thêm"
                        less="Thu gọn"
                        className="content"
                        anchorClass="my-anchor-css-class"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                    >
                        <p>
                        Tận tình chu đáo hỏi thăm và nói chuyện rất nhẹ nhàng dễ mến, rất hài lòng và mong sao mọi nguời sẽ biết đến dịch vụ này nhiều hơn nữa
                        </p>
                    </ShowMoreText>
                    <div className="images">
                        <Row>
                            <LightgalleryProvider>
                                <Row gutter={[{xs:8, lg:24}, {xs:8, lg:24}]}>
                                    {
                                        [1, 2, 3, 4].map(item => (
                                            <Col xs={6} lg={4} key={item} className="item-img">
                                                <LightgalleryItem 
                                                    group={`comment-${item}`}
                                                    src="https://www.omronhealthcare-ap.com/Content/uploads/products/HEM-7322.jpg"
                                                >
                                                    <Image
                                                        preview={false}
                                                        src="https://www.omronhealthcare-ap.com/Content/uploads/products/HEM-7322.jpg"
                                                    />
                                                    {item === 4 && <div className="caption">+4</div>}
                                                </LightgalleryItem>
                                            </Col>
                                        ))
                                    }

                                </Row>
                            </LightgalleryProvider>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ItemComment;