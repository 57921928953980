import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, Row, Col, Steps, Divider } from 'antd';
import moment from 'moment';
import * as Services from "../../store/services";
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';

const ServicePaymentBankTransfer = () => {
    const [order, setOrder] = useState();
    // lấy token từ query url
    const urlQueryParams = useLocation().search;
    const _token = new URLSearchParams(urlQueryParams).get('token');

    useEffect(() => {
        const loadSpots = async () => {
            await Services.get(`${CONSTANT.API.SERVICE.ORDER}/${_token}`)
                .then(res => {
                    debugger;
                    if (res.data.code === 200) {
                        setOrder(res.data.data);
                    }
                })
        };
        loadSpots();
    }, []);

    // remove storage
    localStorage.removeItem(CONSTANT.STORAGE_DATA_BOOKING_SERVICE);

    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Steps.Step title="Chọn dịch vụ" />
                        <Steps.Step title="Thanh toán" />
                        <Steps.Step title="Hoàn thành" />
                    </Steps>
                </div>
                <div className="bank-transfer">
                    <div className="fw-bold">Cảm ơn quý khách đã sử dụng dịch vụ của Vivmedic</div>
                    <div>Lịch đặt dịch vụ của Quý khách đã được tạo.</div>
                    {
                        order?.total > 0 ?
                            <div>Quý khách vui lòng thanh toán mã lịch hẹn <strong>VIVMEDIC-S{order?.uid}</strong> để hoàn tất việc đặt dịch vụ này</div>
                            :
                            <div>Mã lịch hẹn: <strong>VIVMEDIC-S{order?.uid}</strong></div>
                    }


                    <div className="fw-bold block-title" style={{ marginTop: 8 }}>Thông tin lịch hẹn</div>
                    <div className="list-product" style={{ marginBottom: 0 }}>
                        {
                            order?.items?.length > 0 &&
                            <Row className="item">
                                <Col xs={8} lg={4}>
                                    <div className="box-cover">
                                        <img src={order.items[0]?.service?.images?.length > 0 ? COMMON.getImage(order.items[0].service.images[0].filename, 'service') : COMMON.getImage('')} alt={order?.items[0]?.service?.slug} />
                                    </div>
                                </Col>
                                <Col xs={16} lg={20} className="info">
                                    <div className="title">
                                        {order.items[0]?.service?.name}
                                    </div>

                                    <div style={{ color: '#777' }}>
                                        <span>Giá: </span>
                                        <span className="fw-medium">{COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Ngày khám bệnh: </span>
                                        <span className="fw-medium">{moment(order?.date).format('DD/MM/YYYY')}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Giờ: </span>
                                        <span className="fw-medium">{moment(order?.time).format('HH:mm A')}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Địa điểm khám: </span>
                                        <span className="fw-medium">{(order?.work_place === 'at_guest' ? 'Tại nhà khách hàng, ' : `${order?.place_name}, `) + order?.address_work}</span>
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Divider />
                        <Row>
                            <Col xs={16} md={8}>Thành tiền</Col>
                            <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.subtotal)} {COMMON.getCurrency(order?.currency)}</Col>
                        </Row>
                        <Divider />
                        {
                            <React.Fragment>
                                <Row>
                                    <Col xs={16} md={8}>Phí dịch vụ ({CONSTANT.FEE.SERVICE_MEDIC * 100}%)</Col>
                                    <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(CONSTANT.FEE.SERVICE_MEDIC * order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</Col>
                                </Row>
                                {
                                    order?.transpot_fee > 0 &&
                                    <Row>
                                        <Col xs={16} md={8}>Phí đi lại</Col>
                                        <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.transpot_fee)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                }
                                {
                                    order?.overtime_fee > 0 &&
                                    <Row>
                                        <Col xs={16} md={8}>Phí ngoài giờ</Col>
                                        <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.overtime_fee)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                }
                                <Divider />
                                {
                                    order?.discount && order.discount?.value > 0 &&
                                    <Row>
                                        <Col xs={16} md={8}>Khuyến mãi giảm giá</Col>
                                        <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order.discount.value_type === 'cash' ? order.discount.value : order.discount.value_type === 'percent' ? (order.subtotal + order.total_tax + order.transpot_fee + order.overtime_fee) * order.discount.value / 100 : 0)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                }
                            </React.Fragment>
                        }

                        <div className="order-total">
                            <span>Tổng cộng</span>
                            <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                        </div>
                    </div>
                    {
                        order?.total > 0 &&
                        <React.Fragment>
                            <Card className="bank-info" size="small" title={CONSTANT.BANK.BANK_NAME}>
                                <Row gutter={48} align="middle" style={{ marginTop: 20 }}>
                                    <Col lg={6}>
                                        <img style={{ width: 110 }} src={`${CONSTANT.STATIC_PATH}images/logo-acb.png`} alt="logo" className="logo-bank" />
                                    </Col>
                                    <Col lg={18}>
                                        <p>Số tài khoản: <strong>{CONSTANT.BANK.NUMBER}</strong></p>
                                        <p>Người thụ hưởng: <strong>{CONSTANT.BANK.NAM_CARD}</strong></p>
                                        <p>Nội dung chuyển tiền: <strong>VIVMEDIC-S{order?.uid}</strong></p>
                                    </Col>
                                </Row>
                            </Card>
                            <p>
                                Xác nhận đặt hàng sẽ được gửi đến email của Quý khách sau khi Vivmedic nhận được thanh toán. Nếu Quý khách không nhận được email xác nhận sau 30 phút, vui lòng liên hệ Vivmedic để được hỗ trợ.
                            </p>
                            <p>Sau khi Vivmedic nhận được thanh toán, Vivmedic sẽ tiến hành kích hoạt dịch vụ của bạn.</p>
                        </React.Fragment>
                    }


                    <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                        <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ServicePaymentBankTransfer;