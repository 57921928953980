/* Defined language for EN Tieng Viet  */
export const en = {
   search: 'Search',
   cart: 'Cart',
   login: 'Login',
   logout: 'Logout',
   account: 'Profile',
   your_order: 'Your order',
   home: 'Home',
   specialist_doctor: 'Specialist',
   medical_facility: 'Medical & Healthcare Facilities',
   medical_service: 'Medical & Healthcare Services',
   medical_online: 'Online Medical Consultant',
   medical_online_free: 'Telemedicine (Free of Charge)',
   medical_online_paid: 'Telemedicine (Surcharge applied)',
   doctor_talking: 'Health Guide',
   medical_shop: 'Medical Supplies & Pharmacy',
   specialist_doctor_sapo: 'List of Specialists, General Practitioners, Surgeons, Nurses & Caregivers',
   specialist_doctor_view_more: 'View more specialist',
   view_more: 'View more',
   medical_shop_sapo: 'Medical Equipments, Medicine & Prescription, and Dietary Supplements with quality assurance.',
   medical_facility_sapo: 'List of Medical Facilities, Private & Public Hospitals, Clinics in Ho Chi Minh City. Find a trusting place that provides outpatient healthcare services with best-in-class professionals and modern equipment for your safety during Covid-19.',
   detail: 'Detail',
   medical_service_sapo: 'Book various healthcare packages at hospitals, clinics or housebound nursing services, such as wound care, home testing, and elderly aide.',
   learn_more: 'Learn more',
   medical_online_sapo: 'Book an appointment to consult with a specialist via Video Chat.',
   company_name: 'VIVMEDIC Technology Private Joint Stock Company',
   company_add: 'No. 24, My Thai 2A Street, Tan Phu Ward, District 7, Ho Chi Minh City, Vietnam',
   service: 'Services',
   customer_service: 'Customer Service',
   support: 'Support',
   partner_registration: 'Partner Registration',
   partner_login: 'Partner Login',
   user_terms_condition: 'User Terms & Conditions',
   telemedicine_terms_condition: 'Telemedicine Terms & Conditions',
   privacy_policy: 'Privacy Policy',
   transportation_policy: 'Transportation Policy',
   service_guideline: 'Service Guideline',
   adjustment_claim_complaint_resolution: 'Adjustment of Claims & Complaint Resolution',
   operating_rule_regulation: 'Operating Rules & Regulations',
   frequently_asked_question: 'Frequently Asked Questions',
   contact_hotline: 'Contact Hotline',
   social_media: 'Social Media',
   web_is_dev: 'Website is in the development and testing phase.',
   all: 'All',
   search_medicine: 'Search medicine',
   category_product: 'Product categories',
   no_data: 'No data',
   travel_medical: "Medical tourism"
}