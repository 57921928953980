import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, Col, Row, Button, Input, Popconfirm, Spin, Empty } from 'antd';
import { PlusOutlined, MinusOutlined, DeleteOutlined, ShoppingOutlined } from '@ant-design/icons';

import './shop-style.css';

// import common
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import { ProductAction } from '../../store/root-action';
import * as Services from "../../store/services";
const Cart = (props) => {
    const dispatch = useDispatch();
    const orderItem = useSelector(state => state.productReducer.orderItem);
    const cartTotal = useSelector(state => state.productReducer.subTotal);
    useEffect(() => {
        const loadSpots = async () => {
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            if (order_id) {
                await dispatch(ProductAction.getOrderItem(order_id));
            }
        };
        loadSpots();
    }, [dispatch]);
    // process change quantity
    const changeQuantity = async (index, type = 'increase') => {
        let orders = JSON.parse(JSON.stringify(orderItem));
        if (type === 'increase') {
            orders[index].quantity = orders[index].quantity + 1;
        }
        else if (orders[index].quantity > 1) {
            orders[index].quantity = orders[index].quantity - 1;
        }
        // update quantity into DB
        let save = await Services.patch(`${CONSTANT.API.PRODUCT.ORDER_ITEM}/${orders[index]._id}`, { quantity: orders[index].quantity });
        if (save.status === 200) {
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            await dispatch(ProductAction.getOrderItem(order_id));
        }
    }
    // remove product in cart
    const removeProduct = async (index) => {
        let deleteOrder = await Services.remove(`${CONSTANT.API.PRODUCT.ORDER_ITEM}/${orderItem[index]._id}`);
        if (deleteOrder.status === 200) {
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            await dispatch(ProductAction.getOrder(order_id));
            await dispatch(ProductAction.getOrderItem(order_id));
        }
    }
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Giỏ hàng</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div className="container">
                <div className="block-header">
                    <div className="text-uppercase title">Giỏ hàng của bạn</div>
                    <div className="line"></div>
                </div>

                {
                    orderItem && orderItem.length === 0 &&
                    <Empty
                        description={
                            <div style={{marginBottom: 30}}>
                                Bạn chưa có gì trong giỏ hàng
                            </div>
                        }
                    />
                }

                {
                    orderItem && orderItem.length > 0 &&
                    <div className="cart">
                        <Row className="cart-header d-none d-md-flex">
                            <Col lg={12}>Sản phẩm</Col>
                            <Col lg={3}>Đơn giá</Col>
                            <Col lg={4}>Số lượng</Col>
                            <Col lg={4}>Tổng cộng</Col>
                        </Row>
                        {
                            orderItem && orderItem.map((item, index) => (
                                <div className="cart-item border" key={index}>
                                    <Row align="middle">
                                        <Col xs={24} lg={12}>
                                            <div className="product">
                                                <div className="box-cover">
                                                    <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                                </div>
                                                <div className="info">
                                                    <div className="title">{item.product.name}</div>
                                                    <p className="product-attribute">
                                                        {
                                                            item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                                let str = attr.options[0].name;
                                                                if (indexAttr < item.variant.attributes.length - 1)
                                                                    str += " / ";
                                                                return str;
                                                            })
                                                        }
                                                    </p>
                                                    <div className="d-md-none">
                                                        Giá: {COMMON.currencyFormat(item.price)} {COMMON.getCurrency(item.product.currency)}
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        </Col>
                                        <Col xs={12} lg={3} className="d-none d-md-block">
                                            {COMMON.currencyFormat(item.price)} {COMMON.getCurrency(item.product.currency)}
                                        </Col>
                                        <Col xs={{span: 12, offset: 11}} lg={{span:4, offset: 0}}>
                                            <Input value={item.quantity}
                                                addonAfter={<PlusOutlined onClick={() => { changeQuantity(index, 'increase') }} />}
                                                addonBefore={<MinusOutlined onClick={() => { changeQuantity(index, 'decrease') }} />}
                                            />
                                        </Col>
                                        <Col xs={{span: 10, offset: 11}} lg={{span: 4, offset:0}}><strong>{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</strong></Col>
                                        <Col lg={1}>
                                            <Popconfirm title="Bạn muốn xóa sản phẩm này?" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => { removeProduct(index) }}>
                                                <Button className="btn-delete" icon={<DeleteOutlined />} size={25} />
                                            </Popconfirm>

                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }
                        <Row align="middle" className="row-total">
                            <Col lg={16} className="text-end fw-bold">
                                Tổng cộng:
                            </Col>
                            <Col lg={8} className="text-end">
                                <span className="price-total">{COMMON.currencyFormat(cartTotal)} ₫</span>
                            </Col>
                        </Row>

                        <div className="group-btn">
                            <Button className="btn-global bg btn-continue" onClick={() => props.history.push('/')} icon={<ShoppingOutlined />}>Tiếp tục mua sắm</Button>
                            <Button className="btn-payment" type="primary" danger onClick={() => props.history.push('/checkout/information')}>Thanh toán</Button>
                        </div>

                    </div>
                }
            </div>
        </React.Fragment>
    )
}
export default Cart;