import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { Breadcrumb, Spin, Empty, message, Collapse, List, Row, Col, Card, Avatar } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./travel-medical.css";
import { axiosInstance } from "../../config/api";
import { useTranslation } from 'react-multi-lang';

import MetaSeo from "../../component/meta-seo";
import CONSTANT from "../../config/constant";
import * as COMMON from "../../helper/common";

function TravelMedicalHealthMental() {
    const history = useHistory();
    const t = useTranslation();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageSize: 20,
        total: 0,
        hideOnSinglePage: true,
        showLessItems: true,
    });


    useEffect(() => {
        let mounted = true;
        if (mounted) {

        }
        return () => (mounted = false);
    }, []);



    return (
        <React.Fragment>
            <MetaSeo
                title="Sức khỏe tinh thần"
                keyword={`sức khỏe tinh thần, suc khoe tinh than, ${CONSTANT.PAGE_KEYWORD_EXT}`}
                description="Chăm sóc sức khỏe tinh thần kết hợp giữa du lịch và các hoạt động tâm lý, tinh thần nhằm mục đích cải thiện sức khỏe tinh thần và trải nghiệm du lịch sâu sắc của Khách hàng khi tham gia"
            />

            <div className="page-travel-medical">
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">Trang chủ</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/${CONSTANT.SLUG_TRAVEL_MEDICAL}`}>{t('travel_medical')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Sức khỏe tinh thần</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <div className="container">
                    <div className="block-header">
                        <div className="text-uppercase title">Sức khỏe tinh thần</div>
                        <div className="sapo">Chăm sóc sức khỏe tinh thần kết hợp giữa du lịch và các hoạt động tâm lý, tinh thần nhằm mục đích cải thiện sức khỏe tinh thần và trải nghiệm du lịch sâu sắc của Khách hàng khi tham gia</div>
                        <div className="line"></div>
                    </div>
                    <div className="mental-content">
                        <Row gutter={[{ xs: 16, lg: 24 }, 24]} justify="center">
                            <Col xs={24} md={14}>
                                <div className="title">Kỳ nghỉ tuyệt vời</div>
                                <div className="sapo">Chúng tôi mang đến môi trường thư giãn, yên bình và hỗ trợ 24/7 để giúp khách hàng giảm căng thẳng, lo âu, và cải thiện tinh thần.</div>
                            </Col>
                            <Col xs={24} md={10}>
                                <img src={`${CONSTANT.STATIC_PATH}images/mental/ky-nghi-tuyet-voi.jpg`} alt="Kỳ nghĩ tuyệt vời" />
                            </Col>
                        </Row>
                        <Row gutter={[{ xs: 16, lg: 24 }, 24]} justify="center">
                            <Col xs={24} md={10}>
                                <img src={`${CONSTANT.STATIC_PATH}images/mental/thien.jpg`} alt="Thiền & Yoga" />
                            </Col>
                            <Col xs={24} md={14}>
                                <div className="title">Thiền & Yoga</div>
                                <div className="sapo">Giảm căng thẳng, tăng sự tập trung và cân bằng tâm hồn</div>
                            </Col>

                        </Row>
                        <Row gutter={[{ xs: 16, lg: 24 }, 24]} justify="center">
                            <Col xs={24} md={6}>
                                <img src={`${CONSTANT.STATIC_PATH}images/mental/tinh-lang-1.jpg`} alt="Tĩnh lặng cùng thiên nhiên" />
                            </Col>
                            <Col xs={24} md={12}>
                                <div className="title mt text-center">Tĩnh lặng cùng thiên nhiên</div>
                                <div className="sapo text-center">Tận hưởng không gian thiên nhiên yên bình và thư giãn thông qua các hoạt động như đi bộ, trekking, hoặc thiền trong tự nhiên.</div>
                            </Col>
                            <Col xs={24} md={6}>
                                <img src={`${CONSTANT.STATIC_PATH}images/mental/tinh-lang-2.jpg`} alt="Tĩnh lặng cùng thiên nhiên" />
                            </Col>
                        </Row>
                        <Row gutter={[{ xs: 16, lg: 24 }, 24]} justify="center">
                            <Col xs={24} md={10}>
                                <img src={`${CONSTANT.STATIC_PATH}images/mental/thien-tra.jpg`} alt="Thiền trà" />
                            </Col>
                            <Col xs={24} md={14}>
                                <div className="title">Thiền Trà</div>
                                <div className="sapo">Là sự kết hợp giữa thiền và trải nghiệm với trà, tạo ra một trải nghiệm thư giãn và tinh thần không gian thư giãn và yên bình, khách hàng cảm nhận sự yên bình và sự kết nối với bản thân và với thiên nhiên. Không gian mở, thoải mái và thân thiện, với các góc ngồi yên bình để thực hành thiền và thưởng thức trà.</div>
                                <div className="sapo">Thiền và trà đều là các phương pháp được chứng minh giúp giảm căng thẳng và lo âu, tạo ra trạng thái tinh thần yên bình và thư giãn.</div>
                                <div className="sapo">Thiền giúp cải thiện khả năng tập trung và sự chú ý, trong khi thưởng thức trà có thể tạo ra một trạng thái tinh thần tỉnh táo và tự do.</div>
                            </Col>
                        </Row>
                        <Row gutter={[{ xs: 16, lg: 24 }, 24]} justify="center">
                            <Col xs={24} md={14}>
                                <div className="title">Các buổi tham vấn trị liệu tinh thần</div>
                                <div className="sapo">Cung cấp các buổi tư vấn cá nhân hoặc nhóm, hỗ trợ tâm lý từ các chuyên gia nhằm giúp người tham gia xử lý và giải quyết các vấn đề tâm lý, tinh thần.</div>
                            </Col>
                            <Col xs={24} md={10}>
                                <img src={`${CONSTANT.STATIC_PATH}images/mental/tham-van.jpg`} alt="Các buổi tham vấn trị liệu tinh thần" />
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Link to={`/${CONSTANT.SLUG_SERVICE}/c-suc-khoe-tinh-than/26`} title={`${t('learn_more')}`} className="btn-global btn-view bg">Xem thêm</Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TravelMedicalHealthMental;
