import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, Row, Col, Steps } from 'antd';
import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import { ProductAction } from '../../store/root-action';
const PaymentBankTransfer = () => {
    const order_id = localStorage.getItem(CONSTANT.ORDER_ID);
    const history = useHistory();
    if (!order_id) history.push(`/${CONSTANT.SLUG_MEDICAL_SHOPPING}`);

    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    useEffect(() => {
        const loadSpots = async () => {
            await Services.get(`${CONSTANT.API.PRODUCT.ORDER}/${order_id}`)
                .then(res => {
                    if (res.data.code === 200) {
                        setOrder(res.data.data);
                        localStorage.removeItem(CONSTANT.ORDER_ID);
                    }
                });
            // reset bộ đếm giỏ hàng
            await dispatch(ProductAction.emptyCart());
        };
        loadSpots();
    }, []);
    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={3}>
                        <Steps.Step title="Chọn hàng" />
                        <Steps.Step title="Nhập thông tin" />
                        <Steps.Step title="Thanh toán" />
                        <Steps.Step title="Hoàn thành" />
                    </Steps>
                </div>
                <div className="bank-transfer">
                    <div className="fw-bold">Cảm ơn quý khách đã mua hàng của Vivmedic !</div>
                    <div>Đơn hàng của Quý khách đã được tạo.</div>
                    <div>Quý khách vui lòng thanh toán mã đơn hàng <strong>{order ? '#VIVMEDIC-P' + order.uid : ''}</strong> để hoàn tất việc mua hàng</div>

                    <div className="fw-bold block-title" style={{ marginTop: 20 }}>Thông tin đơn hàng</div>
                    <div className="list-product">
                        {
                            order && order.shop.map((shop) => (
                                shop.items.map((item) => (
                                    <div className="item" key={item._id}>
                                        <div className="box-cover">
                                            <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                        </div>
                                        <div className="info">
                                            <div className="title">
                                                {item.product.name}<br></br>
                                                <p className="product-attribute">
                                                    {
                                                        item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                            let str = attr.options[0].name;
                                                            if (indexAttr < item.variant.attributes.length - 1)
                                                                str += " / ";
                                                            return str;
                                                        })
                                                    }
                                                </p>
                                            </div>
                                            <div className="quantity-price">
                                                <span>x{item.quantity}</span>
                                                <span className="price" style={{ fontSize: 16 }}>{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                )))
                        }
                        <div className="other-price">
                            <span>Tổng giá sản phẩm</span>
                            <span className="price">{COMMON.currencyFormat(order?.subtotal)} ₫</span>
                        </div>
                        <div className="other-price">
                            <span>Phí vận chuyển</span>
                            <span >Quý khách tự thanh toán</span>
                        </div>
                        <div className="other-price">
                            <span>Giảm giá</span>
                            <span className="price">{COMMON.currencyFormat(order?.discount_total)} ₫</span>
                        </div>
                        <div className="order-total">
                            <span>Tổng cộng</span>
                            <span className="price-total">{COMMON.currencyFormat(order?.subtotal - (order?.discount_total))} ₫</span>
                        </div>
                    </div>
                    <div className="fw-bold block-title" style={{ marginTop: 20 }}>Thông tin tài khoản nhận thanh toán</div>
                    <Card className="bank-info" size="small" title={CONSTANT.BANK.BANK_NAME} style={{ marginBottom: '16px' }}>
                        <Row gutter={48} align="middle" style={{ marginTop: 20 }}>
                            <Col lg={6}>
                                <img style={{ width: 110, marginBottom: 20 }} src="/static/images/logo-acb.png" alt="logo" />
                            </Col>
                            <Col lg={18}>
                                <p>Số tài khoản: <strong>{CONSTANT.BANK.NUMBER}</strong></p>
                                <p>Người thụ hưởng: <strong>{CONSTANT.BANK.NAM_CARD}</strong></p>
                                <p>Nội dung chuyển tiền: <strong>{order ? '#VIVMEDIC-P' + order.uid : '#Mã đơn hàng'}</strong></p>
                            </Col>
                        </Row>
                    </Card>
                    <p>
                        Xác nhận đặt hàng sẽ được gửi đến email của Quý khách sau khi Vivmedic nhận được thanh toán. Nếu Quý khách không nhận được email xác nhận sau 30 phút, vui lòng liên hệ Vivmedic để được hỗ trợ.
                    </p>
                    <p>Sau khi Vivmedic nhận được thanh toán, Đối tác Vivmedic sẽ tiến hành xác nhận đơn đóng gói và gửi sản phẩm đến Quý khách.</p>
                    <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                        <Link to="/" className="btn-global bg btn-buy">Tiếp tục mua sắm</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PaymentBankTransfer;