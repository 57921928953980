import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Steps, Form, Row, Col, Radio, List, Button, Space, message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import * as Services from "../../store/services";
import { ProductAction } from '../../store/root-action';
// import common
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';

const { Step } = Steps;

const CheckoutPayment = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [method, setMethod] = useState("bank_transfer");
    const dispatch = useDispatch();
    const orderItem = useSelector(state => state.productReducer.orderItem);
    const [order, setOrder] = useState();
    const subTotal = useSelector(state => state.productReducer.subTotal);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const loadSpots = async () => {
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            if (order_id) {
                await dispatch(ProductAction.getOrderItem(order_id));
                loadOrder();
            }
        };
        loadSpots();
    }, [dispatch]);
    const loadOrder = async () => {
        await Services.get(`${CONSTANT.API.PRODUCT.ORDER}/${localStorage.getItem(CONSTANT.ORDER_ID)}`)
            .then(res => {
                let orderData = { ...order };
                if (res.data.code === 200) {
                    orderData = res.data.data;
                    setOrder(orderData);
                }
            })
    }
    const onChangeMethod = e => {
        setMethod(e.target.value);
    };
    //process pay now
    const processPayNow = async () => {
        if (method === '') {
            message.warning('Vui lòng chọn phương thức thanh toán.', 4);
            return;
        }
        let id = localStorage.getItem(CONSTANT.ORDER_ID);
        if (id) {
            setProcessing(true);
            //Nếu thanh toán chuyển khoản ngân hàng hàm update quá :) ham update
            if (method === 'bank_transfer') {
                // cập nhật đơn hàng
                let upd = await Services.patch(`${CONSTANT.API.PRODUCT.ORDER}/${id}`, { actUpd: method })

                if (upd.status === 200) {
                    message.success('Lưu thông tin thành công! Vui lòng chờ trong giây lát!', 4);
                    setTimeout(() => {
                        history.push('/checkout/payment/bank-transfer');
                    }, 2500);
                }
                else {
                    message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!', 5);
                    setProcessing(false);
                }
            }
            else {
                // Nếu thanh toán online
                let dataPayment = {
                    "_id": id,
                    platform: 'web',
                    payment_method: method,
                    type: "payment",
                    card: '',
                    token: ""
                };
                if (method === 'onepayDomestic') {
                    dataPayment.payment_method_title = "OnePay Nội địa";
                }
                else if (method === 'onepayInternational') {
                    dataPayment.payment_method_title = "OnePay Quốc tế";
                }
                else {
                    dataPayment.payment_method_title = "Chuyển khoản ngân hàng";
                }
                let upd = await Services.post(`${CONSTANT.API.PRODUCT.CHECKOUT}/${id}/payment`, dataPayment)
                if (upd.status === 200) {
                    message.success('Vui lòng chờ trong giây lát! Bạn đang được chuyển đến trang thanh toán!', 4);
                    setTimeout(() => {
                        window.location.href = upd.data.data.url;
                    }, 2100);
                }
                else {
                    message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau!', 5);
                    setProcessing(false);
                }
            }
        }

    }
    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Step title="Chọn hàng" />
                        <Step title="Nhập thông tin" />
                        <Step title="Thanh toán" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Row gutter={[{ xs: 0, lg: 48 }, { xs: 0, lg: 48 }]}>
                    {/* Begin col info */}
                    <Col xs={24} lg={15} className="col-info" >
                        <Form
                            form={form}
                            layout="vertical"

                        >
                            <div className="sumary-info">
                                {
                                    order && (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={[
                                                { label: 'Khách hàng', title: order.shipping.first_name + " " + order.shipping.last_name, description: order.shipping.email },
                                                { label: 'Địa chỉ', description: `${order.shipping.address_1}, ${order.shipping.city}, ${order.shipping.state} ${order.shipping.postcode}, ${order.shipping.country}` },
                                                { label: 'Vận chuyển', description: 'Quý khách tự thanh toán cho bên vận chuyển' }
                                            ]}
                                            renderItem={item => (
                                                <List.Item
                                                    actions={[<Link to="/checkout/information" key="list-loadmore-edit">Sửa</Link>]}
                                                ><List.Item.Meta
                                                        avatar={<div style={{ width: 100 }}>{item.label}</div>}
                                                        title={item.title}
                                                        description={item.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    )
                                }


                            </div>

                            <div className="pay-method">
                                <div className="pay-method-title">Phương thức thanh toán</div>

                                <Radio.Group onChange={onChangeMethod} value={method}>
                                    <Space direction="vertical">
                                        <div className="method">
                                            <Radio value="onepay_Domestic" disabled>
                                                <div className="fw-medium">Thẻ ATM nội địa - (TẠM THỜI CHƯA ÁP DỤNG)</div>
                                                <div>Quý khách sẽ được chuyển đến OnePay để thanh toán</div>
                                                <img className="logo-onepay" src="https://tatinta.com/static/images/logo-onepay.png" alt="logo onepay" />
                                            </Radio>
                                        </div>
                                        <div className="method">
                                            <Radio value="onepay_International" disabled>
                                                <div className="fw-medium">Thẻ tín dụng (VISA, Master Card) - (TẠM THỜI CHƯA ÁP DỤNG)</div>
                                                <div>Quý khách sẽ được chuyển đến OnePay để thanh toán</div>
                                                <img className="logo-onepay" src="https://tatinta.com/static/images/logo-onepay-visa.png" alt="logo onepay" />
                                            </Radio>
                                        </div>
                                        <div className="method">
                                            <Radio value='bank_transfer'>
                                                <div className="fw-medium">Chuyển khoản ngân hàng</div>
                                                <div>Quý khách có thể chuyển khoản tại ngân hàng, chuyển qua thẻ ATM, Internet Banking.</div>
                                                {method === 'bank_transfer' ?
                                                    <div className="bank">
                                                        <strong className="text-uppercase">Thông tin tài khoản</strong>
                                                        <Row gutter={48} align="middle" style={{ marginTop: 20 }}>
                                                            <Col lg={6}>
                                                                <img src="https://muasam.tatinta.com/static/images/logo-acb.png" alt="logo" />
                                                            </Col>
                                                            <Col lg={18}>
                                                                <p><strong>{CONSTANT.BANK.BANK_NAME}</strong></p>
                                                                <p>Số tài khoản: <strong>{CONSTANT.BANK.NUMBER}</strong></p>
                                                                <p>Người thụ hưởng: <strong>{CONSTANT.BANK.NAM_CARD}</strong></p>
                                                                <p>Nội dung chuyển tiền: <strong>#Mã đơn hàng</strong></p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    : null}
                                            </Radio>
                                        </div>
                                    </Space>
                                </Radio.Group>
                            </div>

                            <div className="group-btn">
                                <Button className="btn-global bg" icon={<ArrowLeftOutlined />} onClick={history.goBack}>Quay lại</Button>
                                <Button className="btn-global bg" loading={processing} onClick={() => processPayNow()}>Tiếp tục <ArrowRightOutlined /></Button>
                            </div>
                        </Form>

                    </Col>
                    {/* Begin col cart */}
                    <Col xs={24} lg={9}>
                        <div className="fw-bold">Đơn hàng</div>
                        <div className="list-product">
                            {
                                orderItem && orderItem.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="box-cover">
                                            <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                        </div>
                                        <div className="info">
                                            <div className="title">
                                                {item.product.name}<br></br>
                                                <p className="product-attribute">
                                                    {
                                                        item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                            let str = attr.options[0].name;
                                                            if (indexAttr < item.variant.attributes.length - 1)
                                                                str += " / ";
                                                            return str;
                                                        })
                                                    }
                                                </p>
                                            </div>
                                            <div className="quantity-price">
                                                <span>x{item.quantity}</span>
                                                <span className="price">{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</span>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                            <div className="other-price">
                                <span>Tổng giá sản phẩm</span>
                                <span className="price">{COMMON.currencyFormat(subTotal)} ₫</span>
                            </div>
                            <div className="other-price">
                                <span>Phí vận chuyển</span>
                                <span >Quý khách tự thanh toán</span>
                            </div>
                            <div className="other-price">
                                <span>Giảm giá</span>
                                <span className="price">{COMMON.currencyFormat(order ? order.discount_total : 0)} ₫</span>
                            </div>
                            <div className="order-total">
                                <span>Tổng cộng</span>
                                <span className="price-total">{COMMON.currencyFormat(subTotal - (order ? order.discount_total : 0))} ₫</span>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default CheckoutPayment;

