import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row, Col, Breadcrumb, Card, message, List, Button, Badge
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AccountMenu from './components/menu';
import ModalAddAddress from './components/modal-add-address';
import CONSTANT from '../../../config/constant';
import * as Services from "../../../store/services";
import cookie from 'react-cookies';
import './account.css';
const Address = () => {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataEdit, setDataEdit] = useState({});
    const [user, setUser] = useState({});
    const showModalAdd = async () => {
        await setDataEdit(null);
        setIsModalVisible(true);
    };

    useEffect(() => {
        loadListAddress();
    }, []);

    // load list địa chỉ
    const loadListAddress = () => {
        let currentUser = JSON.parse(localStorage.getItem(CONSTANT.STORAGE_USER));
        if (currentUser) {
            setUser(currentUser);
        }
        else {
            window.location.href = '/';
        }
    }

    // chỉnh sửa
    const onEdit = (data) => async () => {
        if (data) {
            setDataEdit(data);
            setIsModalVisible(true);
        }
    }
    // chỉnh sửa
    const onRemove = (data) => async () => {
        let remove = {
            act_address: 'remove',
            address_id: data
        }
        if (data) {
            await Services.patch(`${CONSTANT.API.USER.UPDATE}/${user._id}`, remove, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookie.load(CONSTANT.STORAGE_TOKEN)
                }
            })
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Xóa địa chỉ thành công!');
                        localStorage.setItem(CONSTANT.STORAGE_USER, JSON.stringify(res.data.data));
                        setUser(res.data.data);
                    }
                    else {
                        message.error('Xóa địa chỉ thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
    }
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Sổ địa chỉ</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                <div className="page-profile">
                    <Row gutter={32}>
                        <Col lg={7} className="col-left">
                            <AccountMenu data={user} />
                        </Col>
                        <Col lg={17}>
                            <Card
                                title={
                                    <>
                                        <div>Sổ địa chỉ của tôi</div>
                                        <div style={{ fontSize: 14, fontWeight: 400 }}>Quản lý thông tin địa chỉ để thuận tiện cho việc mua hàng</div>
                                    </>
                                }
                                extra={
                                    <Button type="primary" icon={<PlusOutlined />} onClick={showModalAdd}>Thêm mới</Button>
                                }
                            >
                                <List
                                    loading={loading}
                                    itemLayout="horizontal"
                                    dataSource={user.address}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            actions={[
                                                <Button type="link" icon={<EditOutlined />} onClick={onEdit(item)}>Chỉnh sửa</Button>,
                                                <Button type="link" icon={<DeleteOutlined />} onClick={onRemove(item._id)}>Xóa</Button>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={
                                                    <>
                                                        <span style={{ textTransform: 'uppercase', paddingRight: 20 }}>{item.fullname}</span>
                                                        {item.is_default && <Badge status="success" text="Địa chỉ mặc định" />}
                                                    </>
                                                }
                                                description={
                                                    <>
                                                        <div>
                                                            Địa chỉ: <span style={{ color: '#333' }}>{item.detail}{item.ward && ',' + item.ward}{item.district && ',' + item.district}{item.city && ',' + item.city}</span>
                                                        </div>
                                                        <div>
                                                            Điện thoại: <span style={{ color: '#333' }}>{item.phone}</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                                {/* Modal add address */}
                                <ModalAddAddress
                                    isModalVisible={isModalVisible}
                                    cancel={() => setIsModalVisible(false)}
                                    reload={loadListAddress}
                                    data={dataEdit}
                                    userId={user._id}
                                />
                            </Card>

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}
export default Address;
