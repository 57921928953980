import * as Services from "../services";
import CONSTANT from "../../config/constant";
import {
    // get list
    GET_LIST, GET_LIST_FAILURE, GET_LIST_SUCCESS,
    // get detail
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,

    // get tree category
    GET_TREE_CATEGORY, GET_TREE_CATEGORY_SUCCESS, GET_TREE_CATEGORY_FAILURE,

    // order
    GET_ORDER, GET_ORDER_SUCCESS, GET_ORDER_FAILURE,
    // order
    GET_ORDER_ITEM, GET_ORDER_ITEM_SUCCESS, GET_ORDER_ITEM_FAILURE,
    //get list hot
    GET_LIST_HOT, GET_LIST_HOT_FAILURE, GET_LIST_HOT_SUCCESS
} from './type';

// get list
export const getList = (queryParam = {}) => {
    let url = `${CONSTANT.API.PRODUCT.PRODUCT}`;
    return async dispatch => {
        dispatch({ type: GET_LIST });
        return await Services.get(url, queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_LIST_SUCCESS,
                            payload: res.data.data
                        })
                    else
                        dispatch({
                            type: GET_LIST_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_LIST_FAILURE, payload: 'Lỗi lấy dữ liệu từ API' })
                }
            })
    }
}

// get list
export const getListHot = (queryParam = {}) => {
    let url = `${CONSTANT.API.PRODUCT.PRODUCT}`;
    return async dispatch => {
        dispatch({ type: GET_LIST_HOT });
        return await Services.get(url, queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_LIST_HOT_SUCCESS,
                            payload: res.data.data
                        })
                    else
                        dispatch({
                            type: GET_LIST_HOT_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_LIST_HOT_FAILURE, payload: 'Lỗi lấy dữ liệu từ API' })
                }
            })
    }
}

export const getDetail = (id) => {
    return async dispatch => {
        if (id) {
            dispatch({ type: GET_DETAIL });
            let url = `${CONSTANT.API.PRODUCT.PRODUCT}/${id}`;
            return await Services.get(url)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.data)
                            dispatch({
                                type: GET_DETAIL_SUCCESS,
                                payload: res.data.data
                            })
                        else
                            dispatch({
                                type: GET_DETAIL_FAILURE,
                                payload: 'Dữ liệu không tồn tại'
                            })
                    }
                    else {
                        dispatch({ type: GET_DETAIL_FAILURE, payload: 'Lỗi lấy dữ liệu từ API' })
                    }
                })
        }
        else {
            dispatch({ type: GET_DETAIL_FAILURE, payload: "Id không đúng" });
        }
    }
}
// process order
export function getOrder(id) {
    return async (dispatch) => {
        dispatch({
            type: GET_ORDER
        });
        let url = `${CONSTANT.API.PRODUCT.ORDER}/${id}`;
        return await Services.get(url)
            .then(res => {
                debugger;
                if (res.status === 200) {
                    if (res.data.data)
                        dispatch({
                            type: GET_ORDER_SUCCESS,
                            payload: res.data.data
                        })
                    else
                        dispatch({
                            type: GET_ORDER_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_ORDER_FAILURE, payload: 'Lỗi lấy dữ liệu cây danh mục từ API' })
                }
            })
            .catch(error => dispatch({ type: GET_ORDER_ITEM_FAILURE, payload: 'Lỗi lấy dữ liêu ' + error + '' }))
    }
}

// process get order
export function getOrderItem(id) {
    return async (dispatch) => {
        dispatch({
            type: GET_ORDER_ITEM
        });
        let url = `${CONSTANT.API.PRODUCT.ORDER_ITEM}?order_id=${id}`;
        return await Services.get(url)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data)
                        dispatch({
                            type: GET_ORDER_ITEM_SUCCESS,
                            payload: res.data.data
                        })
                    else
                        dispatch({
                            type: GET_ORDER_ITEM_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_ORDER_ITEM_FAILURE, payload: 'Lỗi lấy dữ liệu cây danh mục từ API' })
                }
            })
            .catch(error => dispatch({ type: GET_ORDER_ITEM_FAILURE, payload: 'Lỗi lấy dữ liêu ' + error + '' }))
    }
}
export function emptyCart() {
    return async (dispatch) => {
        dispatch({
            type: GET_ORDER_ITEM_SUCCESS,
            payload: []
        })
    }
}
// get tree category
export const getTreeCategory = (queryParam) => {
    return async dispatch => {
        dispatch({ type: GET_TREE_CATEGORY });
        let url = `${CONSTANT.API.PRODUCT.CATEGORY_TREE}`;
        return await Services.get(url, queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.length > 0)
                        dispatch({
                            type: GET_TREE_CATEGORY_SUCCESS,
                            payload: res.data.data
                        })
                    else
                        dispatch({
                            type: GET_TREE_CATEGORY_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_TREE_CATEGORY_FAILURE, payload: 'Lỗi lấy dữ liệu cây danh mục từ API' })
                }
            })
    }
}