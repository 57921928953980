import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Menu, Dropdown, Button
} from 'antd';
import { RightOutlined, DownOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// import common
import * as COMMON from '../../../helper/common';

const MenuLeft = (props) => {
    const categories = useSelector(state => state && state.serviceReducer && state.serviceReducer.categories);
    // Lấy id category từ url
    const { id } = useParams();
    const [rootCategories, setRootCategories] = useState({});
    const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);

    const [visibleDropDown, setVisibleDropDown] = useState(false);

    // load danh sách danh mục
    const rawCategories = async () => {
        if (categories.length > 0) {
            let currentCategoryUid = parseInt(id);
            let _rootCategories = { ...rootCategories };
            let _currentCategory = {};
            let _siteMapCategories = [];
            let _rootSubmenuKeys = [];

            categories.forEach(lv1 => {
                _rootSubmenuKeys.push(lv1.uid);
                if (lv1.uid === currentCategoryUid) {
                    _rootCategories = lv1;
                    _currentCategory = lv1;
                    _siteMapCategories.push(lv1);
                    return;
                }
                else if (lv1.childs.length > 0) {
                    lv1.childs.forEach(lv2 => {
                        if (lv2.uid === currentCategoryUid) {
                            _rootCategories = lv1;
                            _currentCategory = lv2;
                            _siteMapCategories.push(lv1, lv2);
                            return;
                        }
                        else if (lv2.childs.length > 0) {
                            lv2.childs.forEach(lv3 => {
                                if (lv3.uid == currentCategoryUid) {
                                    _rootCategories = lv1;
                                    _currentCategory = lv3;
                                    _siteMapCategories.push(lv1, lv2, lv3);
                                    return;
                                }
                                else if (lv3.childs.length > 0) {
                                    lv3.childs.forEach(lv4 => {
                                        if (lv4.uid == currentCategoryUid) {
                                            _rootCategories = lv1;
                                            _currentCategory = lv4;
                                            _siteMapCategories.push(lv1, lv2, lv3, lv4);
                                            return;
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            setRootCategories(_rootCategories);
            setRootSubmenuKeys(_rootSubmenuKeys);

            props.onLoaded({
                siteMapCategories: _siteMapCategories,
                currentCategory: _currentCategory
            });
        }

    }
    useEffect(() => {
        rawCategories(id);
    }, [categories, id]);

    const closeDropdown = () => {
        setVisibleDropDown(false);
    }
    const toogleDropDown = () => {
        setVisibleDropDown(state => !state);
    }

    const MenuOverlay = ({ ismobile }) => {
        let propsLink = {};
        if (ismobile) {
            propsLink.onClick = closeDropdown;
        }
        return (
            <Menu className="category" mode="inline"
                defaultOpenKeys={rootSubmenuKeys}
            >
                {
                    categories.map(lv1 => (
                        <React.Fragment key={lv1._id}>
                            {
                                lv1.childs && lv1.childs.length > 0 ? (
                                    <Menu.SubMenu key={lv1.uid}
                                        title={(<Link {...propsLink} to={COMMON.createLinkServiceCategory(lv1.slug, lv1.uid)} title={lv1.name}>{lv1.name}</Link>)}
                                    >
                                        {
                                            lv1.childs.map(lv2 => (
                                                <React.Fragment key={lv2._id}>
                                                    {
                                                        lv2?.childs?.length > 0 ?
                                                            <Menu.SubMenu key={lv2.uid} icon={<RightOutlined />}
                                                                title={(<Link {...propsLink} to={COMMON.createLinkServiceCategory(lv2.slug, lv2.uid)} title={lv2.name}>{lv2.name}</Link>)}
                                                            >
                                                                {
                                                                    lv2.childs.map(lv3 => (
                                                                        <Menu.Item key={lv3._id} icon={<RightOutlined />} className='lv3' >
                                                                            <Link {...propsLink} to={COMMON.createLinkServiceCategory(lv3.slug, lv3.uid)} title={lv3.name}>{lv3.name}</Link>
                                                                        </Menu.Item>
                                                                    ))
                                                                }

                                                            </Menu.SubMenu>
                                                            :
                                                            <Menu.Item key={lv2.uid} icon={<RightOutlined />}>
                                                                <Link {...propsLink} to={COMMON.createLinkServiceCategory(lv2.slug, lv2.uid)} title={lv2.name}>{lv2.name}</Link>
                                                            </Menu.Item>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </Menu.SubMenu>

                                )
                                    :
                                    <Menu.Item key={lv1.uid}>
                                        <Link {...propsLink} to={COMMON.createLinkServiceCategory(lv1.slug, lv1.uid)} title={lv1.name} className="fw-medium">{lv1.name}</Link>
                                    </Menu.Item>
                            }
                        </React.Fragment>
                    ))
                }

            </Menu>
        )
    }

    return (
        <React.Fragment>
            {/* Chỉ hiển thị trên điện thoại */}
            <div className="menu-left-mobile d-block d-md-none">
                <Dropdown overlay={<MenuOverlay ismobile="true" />}
                    visible={visibleDropDown}
                    overlayClassName="menu-left-mobile-drop"
                >
                    <Button onClick={toogleDropDown}>
                        {Object.keys(props.currentCategory).length > 0 ? props.currentCategory.name : 'Dịch vụ y tế'} <DownOutlined />
                        {visibleDropDown && <CloseCircleOutlined style={{ marginTop: 4, color: '#777' }} />}
                    </Button>
                </Dropdown>
            </div>
            {/* Không hiển thị trên điện thoại */}
            <div className="d-none d-md-block">
                {
                    categories && categories.length > 0 && (
                        <div className="menu-left-service">
                            <div className="category-header text-uppercase">Danh mục dịch vụ</div>
                            <MenuOverlay ismobile="false" />
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    )
}

export default MenuLeft;