import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
const config = {
    apiKey: "AIzaSyCyYj4-9T4U5fptw66Fbr6WrrEOYzERbz0",
    authDomain: "fir-auth-phonenumber.firebaseapp.com",
    projectId: "fir-auth-phonenumber",
    storageBucket: "fir-auth-phonenumber.appspot.com",
    messagingSenderId: "212723198719",
    appId: "1:212723198719:web:d13a61ead070c61aecfaca",
    measurementId: "G-YHEHL7ZT6V"
}
firebase.initializeApp(config);  
export default firebase