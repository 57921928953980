import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Breadcrumb, Row, Col, Rate, Button, Avatar, Descriptions, Collapse, message, DatePicker, TimePicker, Select, Spin, Alert, Empty
} from 'antd';
import {
    EyeOutlined
} from '@ant-design/icons';

import moment from 'moment';
import parse from 'html-react-parser';

// import lybrary image gallery
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import { ServiceAction } from '../../store/root-action';
import * as Services from '../../store/services';
import MetaSeo from '../../component/meta-seo';

// import block review
import Review from './components/review';

const ServiceDetail = (props) => {
    const { id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    let images = [];
    const detail = useSelector(state => state.serviceReducer.detail);
    const error = useSelector(state => state.serviceReducer.error);
    const loading = useSelector(state => state.serviceReducer.loading);
    const [date, setDate] = useState();
    const [time, setTime] = useState();
    const [workPlace, setWorkPlace] = useState();
    const [processBooking, setProcessBooking] = useState(false);
    const [hospital, setHospital] = useState({});

    const [voucher, setVoucher] = useState([]);

    const [disabledHours] = useState([0, 1, 2, 3, 4, 5, 6, 21, 22, 23]);

    const [areaApply, setAreaApply] = useState();


    // load detail
    const loadDetail = useCallback(async () => {
        await dispatch(ServiceAction.getDetail(id));
    }, [dispatch, id]);

    // load hospital
    const loadHospital = useCallback(async () => {
        if (detail?._id) {
            await Services.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${detail?.created_by}&short=true`)
                .then(res => {
                    if (res?.data?.code === 200) {
                        if (res?.data?.data?.docs?.length > 0) {
                            setHospital(res?.data?.data?.docs[0])
                        }
                    }
                })
                .catch(err => null)
        }
    }, [detail]);

    // load voucher
    const getVoucher = useCallback(async () => {
        let voucher = await Services.post(`${CONSTANT.API.SERVICE.VOUCHER}/check-service-voucher`, {
            service_apply: detail?._id
        });
        if (voucher)
            setVoucher(voucher.data.data);
    }, [detail]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            loadDetail();
        }
        return () => mounted = false;
    }, [loadDetail]);

    useEffect(() => {
        if (detail?._id) {
            if (detail.work_place !== 'at_both') setWorkPlace(detail.work_place);
            loadHospital();
            getVoucher();
        }
    }, [loadHospital, detail, getVoucher])

    useEffect(() => {
        if (error !== false) {
            message.warning('Dịch vụ y tế không tồn tại!').then(() => history.push(`/${CONSTANT.SLUG_SERVICE}`));
        }
    }, [error, history]);

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }
    const getServiceContent = (content, key = 'Overview') => {
        if (content && content.length > 0) {
            for (const item of content) {
                if (item.key === key)
                    return item.value;
            }
        }
    }

    // Xử lý sự kiện khi chọn khu vực
    const onChangeArea = (value, option) => {
        if (option) {
            setAreaApply({ fee: option.value, name: option.children, _id: option.key });
        }
    }

    // process booking
    const booking = async () => {
        if (typeof date === 'undefined') {
            message.warning('Vui lòng chọn ngày');
            return;
        }
        else if (typeof time === 'undefined') {
            message.warning('Vui lòng chọn giờ');
            return;
        }
        else if (detail.work_place === 'at_both' && !workPlace) {
            message.warning('Vui lòng chọn địa điểm');
            return;
        }
        else if (detail?.area_apply?.length > 0 && !areaApply) {
            message.warning('Vui lòng chọn khu vực');
            return;
        }
        else {
            setProcessBooking(true);
            const dataBookingService = {
                date, time, agency: detail.created_by, created_by: '', service: detail._id, place_name: hospital?.name,
                work_place: workPlace, address_work: detail?.address_service, area_apply: areaApply
            }
            localStorage.setItem(CONSTANT.STORAGE_DATA_BOOKING_SERVICE, JSON.stringify(dataBookingService));

            setTimeout(() => {
                setProcessBooking(false);
                props.history.push(`/${CONSTANT.SLUG_SERVICE}/booking`);
            }, 1000);

        }
    }

    return (
        <div className="product-detail service-detail">
            <MetaSeo
                title={detail?.name}
                description={detail?.overview && COMMON.splitString(detail.overview.replace(/<[^>]+>/g, ''), 50)}
                keyword={`${detail?.name}, ${CONSTANT.PAGE_KEYWORD}`}
                type="article"
                image={detail?.images?.length > 0 && COMMON.getImage(detail.images[0]?.filename, 'service')}
                extra={
                    <script async src="https://sp.zalo.me/plugins/sdk.js"></script>
                }
            />

            <Spin spinning={loading}>
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                            {
                                detail && detail.categories && detail.categories.length > 0 && (
                                    <Breadcrumb.Item>
                                        <Link to={COMMON.createLinkServiceCategory(detail.categories[0].slug, detail.categories[0]._id)} title={detail.name}>{detail.categories[0].name}</Link>
                                    </Breadcrumb.Item>
                                )
                            }
                            <Breadcrumb.Item><>{detail?.name}</></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                {
                    detail?._id &&
                    <div className="container">
                        {
                            detail?.images?.length > 0 && detail.images.forEach(element => {
                                let original = COMMON.getImage(element?.filename, 'service');
                                /*let thumbnail = original;
                                if (original) {
                                    let ext = original.substring(original.lastIndexOf("."))
                                    thumbnail = original.replace(ext, '-small') + ext;
                                }*/
                                images.push({
                                    original: original,
                                    thumbnail: original
                                })
                            })
                        }
                        <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]}>
                            {
                                images?.length > 0 &&
                                <Col xs={24} lg={11} className="col-gallery">
                                    <ImageGallery items={images} showPlayButton={false} />
                                </Col>
                            }
                            <Col xs={24} lg={13} className="col-info">
                                <h1 className="product-title">{detail.name}</h1>
                                <div className="control-rate">
                                    <Row>
                                        <Col xs={24} lg={12}>
                                            <Rate allowHalf disabled defaultValue={4.5} />
                                            <span className="rate-text">0 đánh giá</span>
                                        </Col>
                                        <Col xs={24} lg={12} className="group-social">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: `<div class="zalo-share-button" data-href="${CONSTANT.BASE_URL}${COMMON.createLinkServiceDetail(detail.slug, detail.uid)}" data-oaid="${CONSTANT.ZALO_ID}" data-layout="1" data-color="blue" data-customize=false></div>` }}
                                            />
                                            <iframe
                                                src={`https://www.facebook.com/plugins/share_button.php?href=${CONSTANT.BASE_URL}${COMMON.createLinkServiceDetail(detail.slug, detail.uid)}&layout=button&size=small&appId=2703342366634713&width=76&height=20`}
                                                width="76" height="20"
                                                style={{ border: 'none', overflow: 'hidden', marginTop: '2px' }}
                                                scrolling="no"
                                                frameBorder="0"
                                                allowFullScreen={true}
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="product-detail-price">
                                    {
                                        detail?.price > 0 ? `${COMMON.currencyFormat(detail.price)} ${COMMON.getCurrency(detail.currency)}` : 'Liên hệ'
                                    }

                                </div>
                                <div className="select-time">
                                    <Row gutter={24} align='middle' className='mb-20'>
                                        <Col xs={24} sm={8} lg={6} className="fw-bold">Thời gian</Col>
                                        <Col xs={12} sm={8} lg={9}>
                                            <DatePicker
                                                size="large" className="input-date"
                                                placeholder="Chọn ngày"
                                                disabledDate={disabledDate}
                                                format="DD-MM-YYYY"
                                                onChange={(v) => setDate(v)}
                                            />
                                        </Col>

                                        <Col xs={12} sm={8} lg={9}>
                                            <div>
                                                <TimePicker
                                                    size="large"
                                                    minuteStep={15}
                                                    format="HH:mm"
                                                    placeholder="Chọn giờ"
                                                    showNow={false}
                                                    className="input-date"
                                                    onChange={(v) => setTime(v)}
                                                    disabledHours={() => disabledHours}
                                                    hideDisabledOptions={true}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        detail?.area_apply?.length > 0 &&
                                        <Row gutter={24} align='middle' className='mb-20'>
                                            <Col xs={24} sm={8} lg={6} className="fw-bold">Khu vực</Col>
                                            <Col xs={24} lg={18}>
                                                <Select
                                                    placeholder="Chọn khu vực"
                                                    className="select-global w-100"
                                                    size='large'
                                                    onChange={onChangeArea}
                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
                                                >
                                                    {
                                                        detail?.area_apply?.length > 0 &&
                                                        detail.area_apply.map(area => (
                                                            <Select.Option key={area._id} value={area.fee}>{area.name}</Select.Option>
                                                        ))
                                                    }

                                                </Select>
                                            </Col>
                                        </Row>
                                    }


                                    <Row gutter={24} className="mb-20">
                                        <Col xs={24} sm={8} lg={6} className="fw-bold">Địa điểm</Col>
                                        {
                                            detail.work_place && detail.work_place === 'at_guest' ? <Col xs={18} sm={16} lg={18}>
                                                Tại nhà của Quý khách
                                            </Col> : detail.work_place === 'at_agency' ? <Col xs={18} sm={16} lg={18}>
                                                <span className="fw-medium">Tại cơ sở dịch vụ</span> <br />
                                                <span dangerouslySetInnerHTML={{ __html: detail.address_service.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></span>
                                            </Col> : detail.work_place === 'at_both' ? <Col xs={18} sm={16} lg={18}>
                                                <Select placeholder="Chọn địa điểm" onChange={value => setWorkPlace(value)}
                                                    className="select-global" size='large' style={{ width: '100%', fontSize: 18 }}
                                                    optionFilterProp="children">
                                                    <Select.Option value='at_guest'>Tại nhà Quý khách</Select.Option>
                                                    <Select.Option value='at_agency'>Tại cơ sở dịch vụ</Select.Option>
                                                </Select>
                                                {
                                                    workPlace === 'at_agency' &&
                                                    <div className="location">
                                                        <span className="fw-medium">Tại cơ sở dịch vụ</span> <br />
                                                        <span dangerouslySetInnerHTML={{ __html: detail.address_service.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></span>
                                                    </div>
                                                }
                                            </Col> : null
                                        }

                                    </Row>
                                    {
                                        hospital?._id &&
                                        <Row>
                                            <Col xs={24} sm={8} lg={6} className="fw-bold">Đơn vị thực hiện</Col>
                                            <Col xs={18} sm={16} lg={18}>{hospital.name}</Col>
                                        </Row>
                                    }

                                </div>
                                {
                                    voucher._id && <Alert
                                        message={voucher.name}
                                        description={
                                            <div>
                                                <p>{voucher.description}</p>
                                                <p>
                                                    Thời gian ưu đãi: Từ ngày {moment(voucher.from_date).format('DD/MM/YYYY')} đến {moment(voucher.to_date).format('DD/MM/YYYY')}.
                                                </p>
                                                <div style={{ color: 'red' }}>
                                                    {
                                                        voucher.quantity_used < voucher.quantity && voucher.type === 'count_max' ?
                                                            <div>
                                                                Đã có <strong>{voucher.quantity_used}</strong> đặt. Vẫn còn <strong>{voucher.quantity - voucher.quantity_used}</strong> lượt ưu đãi nữa! Đặt hẹn ngay thôi!
                                                            </div>
                                                            : voucher.type === 'count_max' ?
                                                                <div>Ưu đãi đã hết hiệu lực do đã có <strong>{voucher.quantity_used}</strong> đặt.</div> : <></>
                                                    }

                                                </div>

                                            </div>
                                        }
                                        type="error"

                                        style={{ margin: '10px  0px 30px 0px' }}
                                    />

                                }


                                <div>
                                    <Button className="btn-global bg btn-buy-now" loading={processBooking} onClick={booking}>Đặt hẹn ngay</Button>
                                </div>
                            </Col>
                        </Row>

                        {/* Begin shop info */}
                        {
                            hospital?._id &&
                            <div className="shop-info">

                                <Row align="middle" gutter={24}>
                                    <Col xs={9} lg={3}>
                                        <Avatar size={110} src={hospital?.images?.length > 0 ? COMMON.getImage(hospital.images[0].filename, 'hospital') : COMMON.getImage('')} />
                                    </Col>
                                    <Col xs={15} lg={8}>
                                        <h4 className="shop-name">{hospital?.name}</h4>
                                        <div className="location mb-10">
                                            <ion-icon name="location-sharp"></ion-icon>
                                            {hospital?.address}
                                        </div>
                                        <Link to={COMMON.createLinkFacilityDetail(hospital.slug, hospital.uid)} className="btn-global btn-link"><EyeOutlined /> Xem chi tiết</Link>
                                    </Col>
                                    <Col lg={13}>
                                        <Descriptions column={2} className="info-rate" >
                                            <Descriptions.Item label="Đánh giá">0</Descriptions.Item>
                                            <Descriptions.Item label="Tham gia">{COMMON.timeAgo(hospital?.created_at)}</Descriptions.Item>
                                            <Descriptions.Item label="Dịch vụ">{hospital?.services?.length}</Descriptions.Item>
                                            <Descriptions.Item label="Người theo dõi">0</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </div>
                        }


                        {/* Begin product sapo */}
                        <div className="product-sapo" style={{ marginTop: '30px' }}>
                            <div className="title">Tổng quan</div>
                            <div className="content">
                                {detail?.overview && parse(detail.overview)}
                            </div>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            {
                                detail?.what_included &&
                                <div className="product-content">
                                    <Collapse ghost
                                        expandIconPosition="right"
                                    >
                                        <Collapse.Panel header="Dịch vụ bao gồm">
                                            {parse(detail.what_included)}
                                        </Collapse.Panel>
                                    </Collapse>
                                </div>
                            }

                            {
                                detail?.additional_info &&
                                <div className="product-content">
                                    <Collapse ghost
                                        expandIconPosition="right"
                                    >
                                        <Collapse.Panel header="Thông tin khác">
                                            {parse(detail.additional_info)}
                                        </Collapse.Panel>
                                    </Collapse>
                                </div>
                            }

                            {
                                detail?.cancellation_policy &&
                                <div className="product-content">
                                    <Collapse ghost
                                        expandIconPosition="right"
                                    >
                                        <Collapse.Panel header="Chính sách hủy">
                                            {parse(detail.cancellation_policy)}
                                        </Collapse.Panel>
                                    </Collapse>
                                </div>
                            }
                        </div>

                        {/* Begin review*/}
                        <div className="d-none">
                            <Review />
                        </div>
                    </div>
                }

            </Spin>
        </div>

    )
}

ServiceDetail.serverFetch = async ({ store, params }) => {
    await store.dispatch(ServiceAction.getDetail(params.id));
}

export default ServiceDetail;