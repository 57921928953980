import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Breadcrumb, Row, Col, Rate, Button, Input, Avatar, Descriptions, Collapse, message, Skeleton
} from 'antd';
import {
    CheckCircleFilled, MinusOutlined, PlusOutlined, ShoppingCartOutlined, ShopOutlined,
} from '@ant-design/icons';

// import lybrary image gallery
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import MetaSeo from '../../component/meta-seo';
import { ProductAction } from '../../store/root-action';
import { useSelector } from 'react-redux';
import * as Services from "../../store/services";
// import block review
import Review from './components/review';
import parse from 'html-react-parser';
const ProductDetail = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let images = [];
    const [arrAttributes, setArrAttributes] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [currVariantId, setCurrVariantId] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [currPrice, setCurrPrice] = useState(0);
    const [agency, setAgency] = useState(null);
    const productDetail = useSelector(state => state.productReducer.detail);
    const orderStores = useSelector(state => state.productReducer.order);
    const [loading, setLoading] = useState(false);
    const addToCart = async (buyNow) => {
        setLoading(true);
        let currentUser = JSON.parse(localStorage.getItem(CONSTANT.STORAGE_USER));
        if (id) {
            if (arrAttributes.length === 0 || currVariantId) {
                let dataOrder = {
                    "product_id": productDetail._id,
                    "variant_id": currVariantId,
                    "quantity": quantity,
                    "type": "single",
                    "currency": productDetail.currency
                }
                if (currentUser) {
                    dataOrder.created_by = currentUser._id;
                }
                let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
                if (order_id) {
                    // check exist variant in cart   
                    if (orderStores && orderStores.shop?.length > 0) {
                        dataOrder.order_id = order_id.toString();
                        if (currVariantId) {
                            for (let shop of orderStores.shop) {
                                for (let itemOrder of shop.items) {
                                    if (itemOrder.variant && itemOrder.variant._id === currVariantId) {
                                        message.warning("Sản phẩm đã được thêm vào giỏ hàng của bạn !", 3);
                                        if (buyNow && buyNow === true) {
                                            props.history.push('/cart')
                                        }
                                        setLoading(false);
                                        return 0;
                                    }
                                }
                            }

                        }
                        else {
                            for (let shop of orderStores.shop) {
                                for (let itemOrder of shop.items) {
                                    if (itemOrder.product._id === productDetail._id) {
                                        message.warning("Sản phẩm đã được thêm vào giỏ hàng của bạn !", 3);
                                        if (buyNow && buyNow === true) {
                                            props.history.push('/cart')
                                        }
                                        setLoading(false);
                                        return 0;
                                    }
                                }
                            }
                        }

                    }
                    else {
                        let save = await Services.post(CONSTANT.API.PRODUCT.ORDER_ITEM, dataOrder);
                        if (save.status === 200) {
                            message.success('Đã thêm vào giỏ hàng của bạn thành công !', 4);
                            localStorage.setItem(CONSTANT.ORDER_ID, save.data.data._id);
                            if (buyNow && buyNow === true) {
                                props.history.push('/cart')
                            }
                            else {
                                await dispatch(ProductAction.getOrder(order_id));
                                await dispatch(ProductAction.getOrderItem(order_id));                                
                            }
                            setLoading(false);
                            return 0;
                        }
                        else {
                            message.error('Đặt sản phẩm không thành công ! Quý khách vui lòng quay lại sau, cảm ơn !', 4);
                            setLoading(false);
                            return 0;
                        }
                    }
                }
                let save = await Services.post(CONSTANT.API.PRODUCT.ORDER_ITEM, dataOrder);
                if (save.status === 200) {
                    message.success('Đã thêm vào giỏ hàng của bạn thành công !', 4);
                    localStorage.setItem(CONSTANT.ORDER_ID, save.data.data._id);
                    if (buyNow && buyNow === true) {
                        props.history.push('/cart')
                    }
                    else {
                        await dispatch(ProductAction.getOrder(order_id));
                        await dispatch(ProductAction.getOrderItem(order_id));
                    }
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    message.error('Đặt sản phẩm không thành công ! Quý khách vui lòng quay lại sau, cảm ơn !', 4);
                }
            }
            else {
                setLoading(false);
                message.warning("Vui lòng chọn đầy đủ thuộc tính sản phẩm bên trên!", 3);
            }
        }

    }

    // process change attribute
    const changeAttribute = async (style, option) => {
        if (style && option) {
            let selectAttributes = [...selectedAttributes];
            let findIndex = selectAttributes.findIndex(o => o.style === style);
            if (findIndex === -1)
                selectAttributes.push({ style, option });
            else {
                selectAttributes.splice(findIndex, 1);
                selectAttributes.push({ style, option });
            }
            setSelectedAttributes(selectAttributes);
            if (selectAttributes.length === arrAttributes.length) {
                // find variant
                let selectedVariant;
                for (const variant of productDetail.variants) {
                    if (variant.attributes && variant.attributes.length > 0) {
                        let arrayCheck = [];
                        for (const vAtri of variant.attributes) {
                            for (const sAttri of selectAttributes) {
                                if (sAttri.style === vAtri.name && sAttri.option === vAtri.options[0].name)
                                    arrayCheck.push(vAtri);
                            }
                        }
                        if (arrayCheck.length === selectAttributes.length) {
                            selectedVariant = variant;
                            break;
                        }
                    }
                }
                if (selectedVariant) {
                    setCurrPrice(selectedVariant.price);
                    setCurrVariantId(selectedVariant._id);
                }
                else {
                    message.error("Không có sản phẩm nào tương ứng với thuộc tính bạn chọn. Vui lòng chọn thuộc tính khác!", 4);
                    setCurrPrice(0);
                    setCurrVariantId('');
                }

            }
            else {
                message.warning("Vui lòng chọn đầy đủ thuộc tính sản phẩm bên trên!", 4)
            }
        }

    }
    const loadArrAttributes = async (product) => {
        let arr = [];
        if (product && product.variants && product.variants.length > 0) {
            product.variants[0].attributes.length > 0 && product.variants[0].attributes.forEach(item => {
                let options = [];
                product.variants.forEach((itemVariant) => {
                    if (itemVariant.attributes.length > 0) {
                        itemVariant.attributes.forEach((itemAttribute) => {
                            if (itemAttribute.name === item.name) {
                                let findIndex = options.findIndex(o => o.name === itemAttribute.options[0].name);
                                if (findIndex === -1)
                                    options.push(itemAttribute.options[0]);
                            }

                        })
                    }
                });
                arr.push({ style: item.name, options });
                setArrAttributes(arr);
            });
        }
    }
    useEffect(() => {
        const loadSpots = async () => {
            await dispatch(ProductAction.getDetail(id));
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            if (order_id) {
                await dispatch(ProductAction.getOrder(order_id));
            }
        };
        loadSpots();
    }, [dispatch]);
    useEffect(() => {
        if (Object.keys(productDetail).length > 0) setCurrPrice(productDetail.price);
        loadArrAttributes(productDetail);
        const loadAgency = async () => {
            return await Services.get(`${CONSTANT.API.AGENCY.AGENCY}`, { created_by: productDetail?.created_by }).then(res => {
                if (res && res.data.code === 200 && res.data.data.docs.length > 0) {
                    return res.data.data.docs[0];
                }
                return null;
            }).catch(err => null);
        }
        let mounted = true;
        if (productDetail?._id) {
            if (mounted) {
                loadAgency().then(data => setAgency(data));
            }
        }
        return () => mounted = false;

    }, [productDetail]);
    return (
        <div className="product-detail">
            <MetaSeo
                title={productDetail?.name}
                description={productDetail?.content && COMMON.splitString(productDetail?.content.replace(/<[^>]+>/g, ''), 50)}
                keyword={`${productDetail?.name}, ${CONSTANT.PAGE_KEYWORD}`}
                type="article"
                image={productDetail?.images?.length > 0 && COMMON.getImage(productDetail.images[0].filename, 'product')}
            />
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        {
                            productDetail.categories && productDetail.categories.length > 0 && (
                                <Breadcrumb.Item>
                                    <Link to={COMMON.createLinkProductCategory(productDetail.categories[0].slug, productDetail.categories[0].uid)} title={productDetail.name}>{productDetail.categories[0].name}</Link>
                                </Breadcrumb.Item>
                            )
                        }
                        <Breadcrumb.Item><>{productDetail.name}</></Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                {
                    productDetail && productDetail.images && productDetail.images.map(element => {
                        let original = COMMON.getImage(element.filename, 'product');
                        let thumbnail = original;
                        if (original) {
                            let ext = original.substring(original.lastIndexOf("."))
                            thumbnail = original.replace(ext, '-small') + ext;
                        }
                        images.push({
                            original: original,
                            thumbnail: original
                        })
                    })
                }
                {
                    productDetail && (
                        <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]}>
                            <Col xs={24} lg={11} className="col-gallery">
                                {
                                    images?.length > 0 ?
                                        <ImageGallery items={images} showPlayButton={false} />
                                        :
                                        <Skeleton.Button className="skeleton-image" active />
                                }

                            </Col>
                            <Col xs={24} lg={13} className="col-info">
                                <h1 className="product-title">{productDetail.name}</h1>
                                <div className="control-rate">
                                    <Rate allowHalf disabled defaultValue={4.5} />
                                    <span className="rate-text">7 đánh giá</span>
                                </div>
                                <div className="product-detail-price">{COMMON.currencyFormat(currPrice)} {COMMON.getCurrency(productDetail.currency || 'VND')}</div>
                                <div className="variant">
                                    {
                                        arrAttributes.length > 0 && arrAttributes.map((attribute, index) => (
                                            <Row align="middle" key={index}>
                                                <Col lg={4} className="fw-bolder">{attribute.style}</Col>
                                                <Col lg={20}>
                                                    {
                                                        attribute.options && attribute.options.length > 0 && attribute.options.map((option, iOption) => (
                                                            <Button
                                                                className={selectedAttributes.findIndex(o => o.option === option.name) !== -1 ? `btn-global btn-variant active` : 'btn-global btn-variant'}
                                                                onClick={() => changeAttribute(attribute.style, option.value, attribute._id)}
                                                                key={iOption}
                                                            >
                                                                {option.name} {selectedAttributes.findIndex(o => o.option === option.name) !== -1 && <CheckCircleFilled />}
                                                            </Button>
                                                        ))
                                                    }
                                                </Col>
                                            </Row>

                                        ))
                                    }
                                    <Row align="middle">
                                        <Col xs={6} lg={4} className="fw-bolder">Số lượng</Col>
                                        <Col xs={10} lg={6}>
                                            <Input value={quantity}
                                                addonAfter={<PlusOutlined onClick={() => quantity < 10000 ? setQuantity(quantity + 1) : null} />}
                                                addonBefore={<MinusOutlined onClick={() => quantity > 1 ? setQuantity(quantity - 1) : null} />}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Button loading={loading} icon={<ShoppingCartOutlined />} onClick={() => addToCart()} className="btn-global btn-add-cart">Thêm vào giỏ</Button>
                                    <Button className="btn-global bg btn-buy-now" onClick={() => addToCart(true)}>Mua ngay</Button>
                                </div>
                            </Col>
                        </Row>

                    )
                }

                {/* Begin shop info */}
                {
                    agency?._id &&
                    <div className="shop-info">
                        <Row align="middle">
                            <Col xs={9} lg={3}>
                                <Avatar size={110} src={COMMON.getImage(agency?.logo, 'agency')} />
                            </Col>
                            <Col xs={15} lg={6}>
                                <h4 className="shop-name">{agency?.name}</h4>
                                <div className="location">
                                    <ion-icon name="location-sharp"></ion-icon>
                                    {agency?.address}
                                </div>
                                <Button className="btn-global btn-view-shop" icon={<ShopOutlined />}>Xem shop</Button>
                            </Col>
                            <Col lg={15}>
                                <Descriptions column={2} className="info-rate" >
                                    <Descriptions.Item label="Đánh giá">{agency?.rating_count}</Descriptions.Item>
                                    <Descriptions.Item label="Tham gia">{COMMON.timeAgo(agency?.created_at)}</Descriptions.Item>
                                    <Descriptions.Item label="Sản phẩm">100</Descriptions.Item>
                                    <Descriptions.Item label="Người theo dõi">5k</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </div>
                }


                {/* Begiin product param */}
                <div className="product-param">
                    <div className="title">Thông số sản phẩm</div>
                    <div className="content">
                        {productDetail.description ? parse(productDetail.description) : ''}
                    </div>
                </div>
                {/* Product content */}
                <div className="product-content">
                    <Collapse ghost
                        onChange={(e) => console.log(e)}
                        expandIconPosition="right"
                    >
                        <Collapse.Panel header="Thông tin chi tiết">
                            {productDetail.content ? parse(productDetail.content) : ''}
                        </Collapse.Panel>
                    </Collapse>
                </div>

                {/* Begin review*/}
                <div className="d-none">
                    <Review />
                </div>
            </div>
        </div>

    )
}


export default ProductDetail;