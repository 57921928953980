import React from 'react';

import PageHeader from './header';
import PageFooter from './footer';

const Layout = ({ children }) => {
	return (
		<div>
			<PageHeader />
			{children}
			<PageFooter />
		</div>
	)
}

export default Layout;