import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, Col, Row, Card, Button, Pagination, Input, Select, Form, Spin, Empty, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-multi-lang';

import './shop-style.css';

// import common
import * as COMMON from '../../helper/common';
import MetaSeo from '../../component/meta-seo';

import { ProductAction } from '../../store/root-action';

const Shop = () => {
	const t = useTranslation();
	const categories = useSelector(state => state.productReducer.tree_category);
	const [selectCategory, setSelectCategory] = useState({});
	const listProducts = useSelector(state => state.productReducer.list);
	const loading = useSelector(state => state.productReducer.loading);
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 12;
	const listTotal = useSelector(state => state.productReducer.listTotal);
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [optionMedicine2s, setOptionMedicine2s] = useState([]);


	// load product theo category id
	const loadProducts = async (id, page = currentPage, name = '') => {
		let params = {
			perPage: pageSize, page, category: id, status: 'publish'
		}
		if (name !== '') params.name = name;
		await dispatch(ProductAction.getList(params));
	}

	useEffect(() => {
		if (categories.length > 0) {
			setSelectCategory(categories[0]);
			loadProducts(categories[0].uid);
		}

	}, [categories]);

	// on change category
	const onChangeCategory = (cate) => {
		if (cate.uid !== selectCategory.uid) {
			setCurrentPage(1);
			loadProducts(cate.uid, 1);
			setSelectCategory(cate);
			form.resetFields();
		}
	}

	// xử lý sự kiện chọn danh mục từ select option
	const onChangeOptionCategory = (value) => {
		if (value === 'all')
			value = selectCategory.uid;
		loadProducts(value, 1);
	}

	// Change pagination
	const onChangePagination = (page) => {
		setCurrentPage(page);
		let id;
		// Nếu khác thuốc men
		if (selectCategory.slug !== 'thuoc-men') {
			let optionCategoryValue = form.getFieldValue('optionCategory');
			if (optionCategoryValue !== 'all')
				id = optionCategoryValue;
			else
				id = selectCategory.uid;
		}
		else {
			id = selectCategory.uid;
		}
		loadProducts(id, page);
	}

	// xu ly su kien chon option 1 của thuốc
	const optionMedicine1Change = (value) => {
		let _optionMedicine2s = [];
		let keyword = form.getFieldValue('keyword') || '';
		// Nếu category khác all và undefined thì gán lại mảng category cho option 2 và load data
		if (value !== 'all' && value !== undefined) {
			selectCategory.childs.forEach(item => {
				if (item.uid === value)
					_optionMedicine2s.push(...item.childs);
			});
			loadProducts(value, 1, keyword);
		}
		// Ngược lại reset form và load dữ liệu theo category root
		else {
			form.setFieldsValue({ optionMedicine1: 'all', optionMedicine2: 'all' });
			loadProducts(selectCategory.uid, 1, keyword);
		}
		setOptionMedicine2s(_optionMedicine2s);
	}
	// xu ly su kien chon option 2 của thuốc
	const optionMedicine2Change = (value) => {
		let keyword = form.getFieldValue('keyword') || '';
		if (value !== 'all' && value !== undefined) {
			loadProducts(value, 1, keyword);
		}
		else {
			loadProducts(form.getFieldValue('optionMedicine1'), 1, keyword);
			form.setFieldsValue({ optionMedicine2: 'all' });
		}

	}

	// Xử lý sự kiện tìm thuốc
	const onSearch = (value) => {
		const valueOptionMedicine1 = form.getFieldValue('optionMedicine1');
		const valueOptionMedicine2 = form.getFieldValue('optionMedicine2');
		let idCate;
		if (valueOptionMedicine2 !== 'all' && valueOptionMedicine2 !== undefined)
			idCate = valueOptionMedicine2;
		else if (valueOptionMedicine1 !== 'all' && valueOptionMedicine1 !== undefined)
			idCate = valueOptionMedicine1;
		else
			idCate = selectCategory.uid;

		loadProducts(idCate, 1, value);

	}

	return (
		<React.Fragment>
			<MetaSeo
				title="Mua sắm y tế"
				description="Cung cấp các thiết bị y tế, sản phẩm thuốc và thực phẩm chức năng đảm bảo chất lượng"
			/>
			<div className="breadcrumb">
				<div className="container">
					<Breadcrumb>
						<Breadcrumb.Item><Link to="/">{t('home')}</Link></Breadcrumb.Item>
						<Breadcrumb.Item>{t('medical_shop')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
			</div>
			{/* Begin medical shopping */}
			<div className="container category-product">
				<div className="block-header">
					<div className="text-uppercase title">{t("medical_shop")}</div>
					<div className="sapo">{t('medical_shop_sapo')}</div>
					<div className="line"></div>
				</div>

				{/* Display on mobile */}
				<div className="text-center dropdown-filter d-block d-md-none">
					<Dropdown
						overlay={(
							<Menu>
								{
									categories && categories.length > 0 &&
									categories.map(item => (
										<Menu.Item key={item._id} onClick={() => onChangeCategory(item)}>
											{item.name}
										</Menu.Item>
									))
								}

							</Menu>
						)}
						className="btn-global"
					>
						<Button>
							{selectCategory.name} <DownOutlined />
						</Button>
					</Dropdown>
				</div>

				{
					categories && categories.length > 0 &&
					<div className="text-center box-filter d-none d-md-block">
						{
							categories.map(item => (
								<Button
									className={`btn-global btn-filter ${item.uid === selectCategory.uid && 'active'}`}
									key={item._id}
									onClick={() => onChangeCategory(item)}
								>
									{item.name}
								</Button>
							))
						}
					</div>
				}

				<div className="box-search">
					<Form
						initialValues={{
							optionCategory: 'all',
							optionMedicine1: 'all',
							optionMedicine2: 'all'
						}}
						form={form}
					>
						{
							selectCategory && selectCategory.slug !== 'thuoc-men' ?
								<Row justify="center" gutter={32}>
									<Col xs={18} lg={7}>
										<Form.Item name="optionCategory" >
											<Select
												size="large" className="select-global"
												onChange={onChangeOptionCategory}
											>
												<Select.Option value="all">{t('all')}</Select.Option>
												{
													selectCategory && selectCategory.childs && selectCategory.childs.length > 0 &&
													selectCategory.childs.map(lv2 => (
														<Select.Option value={lv2.uid} key={lv2._id}>{lv2.name}</Select.Option>
													))
												}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								:
								<>
									<Row justify="center" gutter={[{ xs: 16, lg: 32 }]}>
										<Col xs={18} lg={12}>
											<Form.Item name="keyword" >
												<Input.Search
													className="input-keyword" placeholder={t('search_medicine')}
													onSearch={onSearch}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="center" gutter={[{ xs: 16, lg: 32 }, { xs: 16, lg: 32 }]}>
										<Col xs={9} lg={6}>
											<Form.Item name="optionMedicine1" >
												<Select
													size="large"
													className="select-global" allowClear
													onChange={optionMedicine1Change}
													dropdownClassName="cus-select-drop"
												>
													<Select.Option value="all">{t('all')}</Select.Option>
													{
														selectCategory && selectCategory.childs.length > 0 &&
														selectCategory.childs.map(lv2 => (
															<Select.Option value={lv2.uid} key={lv2._id}>{lv2.name}</Select.Option>
														))
													}

												</Select>
											</Form.Item>
										</Col>
										<Col xs={9} lg={6}>
											<Form.Item name="optionMedicine2" >
												<Select
													size="large"
													className="select-global" allowClear
													onChange={optionMedicine2Change}
													dropdownClassName="cus-select-drop"
												>
													<Select.Option value="all">{t('all')}</Select.Option>
													{
														optionMedicine2s && optionMedicine2s.length > 0 &&
														optionMedicine2s.map(lv3 => (
															<Select.Option value={lv3.uid} key={lv3._id}>{lv3.name}</Select.Option>
														))
													}
												</Select>
											</Form.Item>
										</Col>
									</Row>
								</>
						}


					</Form>
				</div>

				<Spin spinning={loading}>
					<div className="list-product">
						<Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]}>
							{
								listProducts && listProducts.length > 0 &&
								listProducts.map(item => (
									<Col xs={12} lg={6} key={item._id}>
										<Link to={COMMON.createLinkProductDetail(item.slug, item.uid)} title={item.name}>
											<Card
												hoverable
												cover={<img alt={item.name} src={item.images && item.images.length > 0 ? COMMON.getImage(item.images[0].filename) : COMMON.getImage()} />}
												className="product"
											>
												<Card.Meta
													title={<h2 className="text-center title">{item.name}</h2>}
													description={<h3 className="text-center sapo" dangerouslySetInnerHTML={{ __html: `${item.description.replace(/<[^>]+>/g, ' ')}` }}></h3>}
												/>
												<div className="text-center price">{COMMON.currencyFormat(item.price)} {COMMON.getCurrency(item.currency)}</div>
											</Card>
										</Link>
									</Col>
								))
							}

						</Row>
					</div>
				</Spin>
				{
					!loading && listProducts.length === 0 &&
					<Empty description={<span style={{ color: '#999' }}>Chưa có dữ liệu rồi</span>} />
				}
				<div className="text-end pagination">
					<Pagination
						pageSize={pageSize} current={currentPage} total={listTotal}
						hideOnSinglePage={true}
						showLessItems={true}
						onChange={onChangePagination}
					/>
				</div>

			</div>
		</React.Fragment>
	)
}
export default Shop;