// Get list
export const GET_LIST = "GET_LIST_SERVICE";
export const GET_LIST_SUCCESS = "GET_LIST_SERVICE_SUCCESS";
export const GET_LIST_FAILURE = "GET_LIST_SERVICE_FAILURE";

// Get detail
export const GET_DETAIL = "GET_DETAIL_SERVICE";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_SERVICE_SUCCESS";
export const GET_DETAIL_FAILURE = "GET_DETAIL_SERVICE_FAILURE";
//fetch order
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE= 'GET_ORDER_FAILURE'
//get service category
export const GET_SERVICE_CATEGORY = 'GET_SERVICE_CATEGORY'
export const GET_SERVICE_CATEGORY_SUCCESS = 'GET_SERVICE_CATEGORY_SUCCESS'
export const GET_SERVICE_CATEGORY_FAILURE= 'GET_SERVICE_CATEGORY_FAILURE'
