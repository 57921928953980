import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Modal, Form, Row, Col, Input, message, Radio, Space, InputNumber, Checkbox } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';

import * as Services from '../../../store/services';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../helper/common';

const ModalBookDoctorOnline = (props) => {
    // Lấy thông tin khách hàng đã từng book lịch hẹn từ storage
    let storage_contact = localStorage.getItem(CONSTANT.STORAGE_INFO_CUSTOMER_BOOK_DOCTOR);
    if (storage_contact) storage_contact = JSON.parse(storage_contact);

    const [form] = Form.useForm();
    const history = useHistory();
    const currentUser = COMMON.getCurrentUser();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });

    // Nếu khách hàng đã từng book lịch thì gán lại thông tin trước đó
    useEffect(() => {
        if (storage_contact && Object.keys(storage_contact).length > 0) {
            form.setFieldsValue({
                fullname_orderer: storage_contact?.fullname_orderer,
                phone: `${storage_contact?.dial_code}${storage_contact?.phone}`,
                email: storage_contact?.email,
                fullname_patients: storage_contact?.fullname_patients,
                gender: storage_contact?.gender,
                dob: storage_contact?.dob,
                note: storage_contact?.note
            })
        }
    }, []);

    // Hủy
    const handleCancel = useCallback(() => {
        //form.resetFields();
        props.cancel();
    }, [form, props]);

    // Xử lý lưu
    const onSubmit = useCallback(async (values) => {
        setConfirmLoading(true);
        let service = { ...props.data };
        delete service.calendars;
        let dataOrder = {
            order_id: "",
            doctor_id: props.dataDoctor._id,
            doctor_email: props.dataDoctor?.email,
            service,
            quantity: 1,
            agency: props.dataDoctor.created_by,
            created_by: currentUser ? currentUser._id : '',
            contact: {
                fullname_orderer: values.fullname_orderer,
                fullname_patients: values.fullname_patients,
                email: values.email,
                dob: values.dob,
                gender: values.gender,
                phone: values?.phone?.replace(phones.dial_code, ''),
                dial_code: phones.dial_code,
                country_code: phones.country_code
            },
            location: values.location,
            date: props.date,
            date_medic: new Date(`${props.date.year}-${props.date.month}-${props.date.day}`),
            time: props.time,
            note: values.note,
            is_online: true,
        }

        let save = await Services.post(CONSTANT.API.DOCTOR.ORDER_ITEM, dataOrder);
        if (save.status === 200) {
            // Lưu thông tin khách vào storage  để dùng lại cho lần sau
            dataOrder.contact.note = dataOrder.note;
            localStorage.setItem(CONSTANT.STORAGE_INFO_CUSTOMER_BOOK_DOCTOR, JSON.stringify(dataOrder.contact));

            message.info('Đã tạo lịch hẹn. Vui lòng chờ trong giây lát!', 3).then(async () => {

                // Nếu khám miễn phí
                if (props.data?.is_free === true) {
                    const dataInfo = {
                        payment_method: "free"
                    }
                    let saveInfo = await Services.post(`${CONSTANT.API.DOCTOR.CHECKOUT}/${save.data.data._id}/information`, dataInfo);
                    if (saveInfo.status === 200) {
                        setConfirmLoading(false);
                        message.success('Đặt lịch hẹn thành công! Vui lòng chờ trong giây lát', 3).then(() => {
                            history.push(`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout/pay-free?token=${save.data.data._id}`);
                        });
                    }
                    else {
                        setConfirmLoading(false);
                        message.error('Đặt lịch hẹn không thành công! Quý khách vui lòng quay lại sau, cảm ơn!', 5);
                    }
                }
                else {
                    //setConfirmLoading(false);
                    handleCancel();
                    history.push(`/${CONSTANT.SLUG_MEDICAL_ONLINE}/checkout?order=${save.data.data._id}`);
                }

            })
        }
        else {
            setConfirmLoading(false);
            message.error('Đặt lịch hẹn không thành công! Quý khách vui lòng quay lại sau, xin cảm ơn!', 5);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const onFinishFailed = () => {
        message.warning('Bạn chưa nhập đủ thông tin. Vui lòng kiểm tra lại');
    }

    return (
        <Modal title={`Đặt lịch hẹn ${props.data && props.data.name} từ xa`}
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Đặt lịch"
            confirmLoading={confirmLoading}
            className="modal-book-service"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            destroyOnClose={true}
            keyboard={false}
            getContainer={false}
        >

            <Form
                name="basic"
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={() => { }}
                form={form}
                initialValues={{
                    payment: props.data?.is_free === false ? "pay_online" : "free"
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={[24]}>
                    <Col span={24}>
                        <Form.Item label="Họ tên người đăng ký" name="fullname_orderer" required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập họ tên người đăng ký' }]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Số điện thoại người đặt" name="phone" required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                        >
                            <PhoneInput
                                country='vn'
                                value={phones.phone}
                                onChange={(value, data) => {
                                    setPhones({
                                        phone: value,
                                        dial_code: data.dialCode,
                                        country_code: data.countryCode
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Email của người đặt"
                            name="email"
                            rules={[
                                { required: true, message: 'Vui lòng nhập email' },
                                { type: "email", message: 'Email chưa đúng định dạng! (Ví dụ: vivmedic@gmail.com)' }
                            ]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Họ tên người khám" name="fullname_patients" required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập họ tên người khám bệnh' }]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Địa chỉ người khám" name="address" required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập địa chỉ người khám bệnh' }]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>

                    <Col xs={12} lg={12}>
                        <Form.Item label="Năm sinh" name="dob"
                            rules={[{
                                type: 'number', max: moment().year(), message: "Năm sinh không đúng !",
                            }, {
                                type: 'number', min: moment().year() - 100, message: "Năm sinh không đúng !"
                            }]}
                        >
                            <InputNumber className="rounded-pill" style={{ width: '100%' }} size="large" maxLength={4} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} lg={12}>
                        <Form.Item name="gender" label="&nbsp;">
                            <Radio.Group className="gender">
                                <Radio value='male'>Nam</Radio>
                                <Radio value='female'>Nữ</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={24}>
                        <Form.Item name="note" label="Mô tả sơ lược triệu chứng">
                            <Input.TextArea className="rounded-pill" size="middle" style={{ fontSize: 14 }} />
                        </Form.Item>
                    </Col>

                    <Col span={24}><div className="fw-medium" style={{ marginBottom: 15, fontSize: 16, color: '#555' }}>Thông tin lịch hẹn</div></Col>

                    <Col span={24}>
                        <Row>
                            <Col xs={8} md={7} className="fw-medium">Địa điểm khám: </Col>
                            <Col xs={16} md={17}><div style={{ color: '#333', fontWeight: '400' }}><VideoCameraOutlined /> Tư vấn từ xa qua video</div></Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={7} className="fw-medium">Ngày: </Col>
                            <Col xs={16} md={17}>{`${props?.date.day}/${props?.date.month}/${props?.date?.year}`}</Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={7} className="fw-medium">Thời gian: </Col>
                            <Col xs={16} md={17}>{moment(`${props?.time.time_h}:${props?.time.time_m}`, "HH:mm").format("HH:mm")} - {COMMON.calculateTime(`${props?.time.time_h}:${props?.time.time_m}`, props?.time?.duration)}</Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={7} className="fw-medium">Giá: </Col>
                            <Col xs={16} md={17}>
                                {
                                    props.data?.is_free === false ?
                                        <>
                                            <span className="fw-bold">{COMMON.calculatePriceDoctor(props.data.price, 'VND')}</span><br />
                                            <span style={{ fontStyle: 'italic', color: '#777', fontSize: '13px' }}>Bao gồm: ({COMMON.currencyFormat(props.data.price)} {COMMON.getCurrency('VND')} giá khám + 60.000 ₫ phí dịch vụ) + 10% VAT</span>
                                        </>
                                        :
                                        <span>Miễn phí</span>
                                }
                            </Col>
                        </Row>

                    </Col>

                    <Col span={24}><div className="fw-medium" style={{ fontSize: 16, color: '#555', marginBottom: -15, marginTop: 16 }}>Thanh toán</div></Col>
                    <Col xs={24} lg={24}>
                        <Form.Item name="payment" label="&nbsp;">
                            <Radio.Group>
                                <Space direction="vertical">
                                    {
                                        props.data?.is_free === false ?
                                            <Radio value='pay_online'>Thanh toán trực tuyến</Radio>
                                            :
                                            <Radio value='free'>Miễn phí</Radio>
                                    }

                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={24}>
                        <Form.Item name="agreement"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chọn chấp nhận điều kiện khám bệnh từ xa')),
                                },
                            ]}
                        >
                            <Checkbox>Tôi đã đọc hiểu và đồng ý với <a href={`${CONSTANT.BASE_URL}/${CONSTANT.SLUG_PAGE_CONTENT}/dieu-kien-kham-benh-tu-xa/7`} target="_blank">Điều kiện khám bệnh từ xa</a> của Vivmedic.com</Checkbox>
                        </Form.Item>
                    </Col>


                </Row>
            </Form>

        </Modal >
    )
}
export default ModalBookDoctorOnline;