import React, { useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { RightOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


import { ServiceAction } from '../../../store/root-action';

import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../helper/common';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 2
    }
}


const SectionHot = () => {
    const refHot = useRef(null);
    const loading = useSelector(state => state.serviceReducer.loading);
    const listServices = useSelector(state => state.serviceReducer.list);
    const dispatch = useDispatch();

    // load danh sách dịch vụ y tế tại nhà
    const loadServices = useCallback(async () => {
        await dispatch(ServiceAction.getList({
            perPage: 20, page: 1, status: 'publish'
        }));
    }, [dispatch]);


    useEffect(() => {
        if (listServices?.length === 0) {
            loadServices();
        }
    }, [listServices, loadServices]);

    return (
        <div className="section-hot">

            <div className="container">
                <div className="block-header">
                    <div className="text-uppercase title">
                        <img src={`${CONSTANT.STATIC_PATH}images/hot.gif`} alt="hot" className="icon-hot" />
                        Nổi bật
                    </div>
                    <div className="line"></div>
                </div>
                <Spin spinning={loading} tip="Đang tải dữ liệu...">
                    <div className="wrap-carousel">
                        <LeftCircleOutlined className='btn-nav nav-left' onClick={() => refHot.current.prev()} />
                        <Carousel ref={refHot} responsive={responsive} arrows={false} infinite={true} autoPlay={true} showDots={true} ssr={true}
                            dotListClass="custom-dot"
                            removeArrowOnDeviceType={["mobile"]}
                        >

                            {
                                listServices?.docs?.length > 0 && listServices.docs.map((item => (
                                    <Link to={COMMON.createLinkServiceDetail(item.slug, item.uid)} title={item.name} key={item._id}>
                                        <div className="hot-item">
                                            <div className="box-cover">
                                                <img alt="cover" className='cover' src={item.images && item.images.length > 0 ? COMMON.getImage(item.images[0].filename, 'service') : COMMON.getImage()} />
                                                {/*<div className="caption">Dịch vụ y tế tại nhà</div>*/}
                                            </div>
                                            <h3 className='title'>{item.name}</h3>
                                            <div className="sapo" dangerouslySetInnerHTML={{ __html: item?.overview.replace(/(<([^>]+)>)/ig, '').replace(/&.*;/g, '') }}></div>
                                            <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                                        </div>
                                    </Link>
                                )))

                            }

                            {/*<Link to="bac-si-chuyen-khoa/ts-bs-pham-phuong-thao/38" title="Tư vấn từ xa">
                            <div className="hot-item">
                                <div className="box-cover" style={{ backgroundImage: `url('https://v2.api.vivmedic.com/v1/static/doctor/file-1656663568937.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center -30px' }}>
                                    <div className="caption">Tư vấn từ xa</div>
                                </div>
                                <h3 className='title'>Tư vấn từ xa - Tiến sĩ bác sĩ Phạm Thu Thảo</h3>
                                <div className="sapo">
                                    - Tham vấn và Trị liệu Tâm lý cho bệnh nhân<br />
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                        </Link>

                        <Link to="dich-vu-y-te/s-goi-kham-toan-dien-vip/267" title="Khám tại nhà">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src='https://v2.api.vivmedic.com/v1/static/service/file-1665487119945.jpg' />
                                    <div className="caption">Khám tại nhà</div>
                                </div>
                                <h3 className='title'>Gói khám VIP tại nhà</h3>
                                <div className="sapo">
                                    Khám tầm soát các chức năng như gan, thận, các bệnh lý mãn tính như tiểu đường, tăng huyết áp rối loạn lipide máu.
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                        </Link>

                        <Link to="dich-vu-y-te/s-goi-kham-suc-khoe-tong-quat-platinum/253" title="Platinum">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src='https://v2.api.vivmedic.com/v1/static/service/file-1646887329930.png' />
                                    <div className="caption">Platinum</div>
                                </div>
                                <h3 className='title'>Gói khám sức khỏe tổng quát Platinum</h3>
                                <div className="sapo">
                                    Khám, kiểm tra và tư vấn các vấn đề về: Nội tổng quát, mắt, tai mũi họng, răng, phụ khoa, tuyến vú
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                        </Link>

                        <Link to="dich-vu-y-te/s-phuc-hoi-chuc-nang-sau-dot-quy/228" title="Phục hồi chức năng">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src='https://v2.api.vivmedic.com/v1/static/service/file-1641526639499.jpeg' />
                                    <div className="caption">Phục hồi chức năng</div>
                                </div>
                                <h3 className='title'>Phục hồi chức năng sau đột qụy</h3>
                                <div className="sapo">
                                    Tai biến mạch máu não (đột quỵ) là căn bệnh nguy hiểm để lại rất nhiều di chứng nặng nề
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                        </Link>
                        */}


                            {/*<Link to="dich-vu-y-te/c-xet-nghiem-tai-nha/7" title="Test COVID">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src={`${CONSTANT.STATIC_PATH}images/test-covid-tai-nha.jpeg`} />
                                    <div className="caption">Test Covid</div>
                                </div>
                                <h3 className='title'>Test Covid tại nhà</h3>
                                <div className="sapo">
                                    - Tầm soát, sàng lọc COVID-19.<br />
                                    - Phương pháp đơn giản, dễ thực hiện.<br />
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                        </Link>
                        <Link to="/f0-tai-nha" title="F0 tại nhà">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src={`${CONSTANT.STATIC_PATH}images/f0-tai-nha.jpeg`} />
                                    <div className="caption">F0 tại nhà</div>
                                </div>
                                <h3 className='title'>F0 tại nhà <img src={`${CONSTANT.STATIC_PATH}images/hot.gif`} alt="hot" className="icon-hot" style={{ marginLeft: '0px', width: '28px', marginTop: "-5px" }} /></h3>
                                <div className="sapo">
                                    - Đội ngũ bác sĩ giỏi, giàu kinh nghiệm<br />
                                    - Tư vấn F0 điều trị tại nhà<br />
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                        </Link>
                        <Link to="/dich-vu-y-te/s-kham-dieu-tri-f0-tai-nha/243" title="Khám - Điều trị F0 tại nhà">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src={`${CONSTANT.STATIC_PATH}images/kham-dieu-tri-f0-tai-nha.jpeg`} />
                                    <div className="caption">F0 tại nhà</div>
                                </div>
                                <h3 className='title'>Khám - Điều trị F0 tại nhà</h3>
                                <div className="sapo">
                                    - Bác sĩ thăm khám tại nhà<br />
                                    - Thở oxy nếu cần<br />
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                    </Link>*/}
                            {/*
                            listProducts?.length > 1 &&
                            <Link to={COMMON.createLinkProductDetail(listProducts[1].slug, listProducts[1].uid)} title={listProducts[1].name}>
                                <div className="hot-item">
                                    <div className="box-cover">
                                        <img alt="cover" className='cover pro' src={listProducts[1].images && listProducts[1].images.length > 0 ? COMMON.getImage(listProducts[1].images[1].filename) : COMMON.getImage()} />
                                        <div className="caption">{listProducts[1]?.categories[0]?.name}</div>
                                    </div>
                                    <h3 className='title'>{listProducts[1].name}</h3>
                                    <div className="sapo" dangerouslySetInnerHTML={{ __html: `${listProducts[1].description}` }}></div>
                                    <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                                </div>
                            </Link>
                        */}
                            {/*<Link to="/dich-vu-y-te/s-tam-soat-hau-covid/242" title="Tầm soát hậu COVID">
                            <div className="hot-item">
                                <div className="box-cover">
                                    <img alt="cover" className='cover' src={`${CONSTANT.STATIC_PATH}images/tam-soat-hau-covid.jpg`} />
                                    <div className="caption">COVID</div>
                                </div>
                                <h3 className='title'>Tầm soát hậu COVID</h3>
                                <div className="sapo">
                                    - Tầm soát và xác định COVID-19<br />
                                    - Phương pháp test nhanh và PCR<br />
                                </div>
                                <span className='view-detail'>Xem chi tiết <RightOutlined style={{ fontSize: '10px' }} /></span>
                            </div>
                    </Link>*/}
                        </Carousel>
                        <RightCircleOutlined className='btn-nav nav-right' onClick={() => refHot.current.next()} />
                    </div>
                </Spin>


            </div >
        </div >
    )
}

export default SectionHot;