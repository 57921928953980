import * as Services from "../services";
import CONSTANT from "../../config/constant";
import {
    GET_AREAS, GET_AREAS_SUCCESS, GET_AREAS_FAILURE,
    GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE,
    GET_CITIES, GET_CITIES_SUCCESS, GET_CITIES_FAILURE,
    GET_WARDS, GET_WARDS_SUCCESS, GET_WARDS_FAILURE,
} from './type';

// get Areas
export const getAreas = (queryParam) => {
    let url = `${CONSTANT.API.LOCATION.AREA}`;    
    return async dispatch => {
        dispatch({ type: GET_AREAS });
        return await Services.get(url,queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_AREAS_SUCCESS,
                            payload: res.data.data.docs
                        })
                    else
                        dispatch({
                            type: GET_AREAS_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_AREAS_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                }
            })
    }
}
// get countries
export const getCountries = (queryParam) => {
    let url = `${CONSTANT.API.LOCATION.COUNTRY}`;   
    return async dispatch => {
        dispatch({ type: GET_COUNTRIES });
        return await Services.get(url,queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_COUNTRIES_SUCCESS,
                            payload: res.data.data.docs
                        })
                    else
                        dispatch({
                            type: GET_COUNTRIES_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_COUNTRIES_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                }
            })
    }
}
// get cities
export const getCities = (queryParam) => {
    let url = `${CONSTANT.API.LOCATION.CITY}`;   
    return async dispatch => {
        dispatch({ type: GET_CITIES });
        return await Services.get(url,queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_CITIES_SUCCESS,
                            payload: res.data.data.docs
                        })
                    else
                        dispatch({
                            type: GET_CITIES_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_CITIES_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                }
            })
    }
}
// get ward
export const getWards = (queryParam) => {
    let url = `${CONSTANT.API.LOCATION.WARD}`;   
    return async dispatch => {
        dispatch({ type: GET_WARDS });
        return await Services.get(url,queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_WARDS_SUCCESS,
                            payload: res.data.data.docs
                        })
                    else
                        dispatch({
                            type: GET_WARDS_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_WARDS_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                }
            })
    }
}