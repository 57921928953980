import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, message, Row, Col, Spin, Divider } from 'antd';
import moment from 'moment';

import * as Services from "../../store/services";
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';

const PAY_METHOD = {
    bank_transfer: 'Thanh toán chuyển khoản',
    onepayDomestic: 'Thanh toán qua cổng thanh toán OnePay nội địa',
    onepayInternational: 'Thanh toán qua cổng thanh toán OnePay quốc tế',
}

const MyServiceMedical = () => {
    const [order, setOrder] = useState({});

    const [loading, setLoading] = useState(true);

    // lấy token từ query url
    const urlQueryParams = useLocation().search;
    const _token = new URLSearchParams(urlQueryParams).get('token');

    useEffect(() => {
        // load detail order
        const loadOrder = async () => {
            if (typeof _token !== 'undefined') {
                return await Services.get(`${CONSTANT.API.SERVICE.ORDER}/${_token}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin lịch hẹn!');
                    });

            }
        }
        let mounted = true;

        if (mounted) {
            setLoading(true);
            loadOrder().then(data => {
                if (data) {
                    setOrder(data);
                }
                setLoading(false);
            });
        }


        return () => mounted = false;
    }, [_token]);

    return (
        <div className="page-checkout" style={{ paddingTop: 0 }}>
            <MetaSeo title="Lịch hẹn Bác sĩ của tôi" />
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Lịch hẹn dịch vụ y tế</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                {loading && <div className="text-center" style={{ paddingTop: 30 }}> <Spin /></div>}
                {
                    order?._id &&
                    <div className="bank-transfer">
                        <div className="fw-bold">Cảm ơn Quý khách đã sử dụng dịch vụ của Vivmedic</div>
                        <div className="fw-bold block-title" style={{ marginTop: 8 }}>Thông tin lịch hẹn</div>
                        <div className="list-product" style={{ marginBottom: 0 }}>
                            {
                                order?.items?.length > 0 &&
                                <Row className="item">
                                    <Col xs={8} lg={4}>
                                        <div className="box-cover">
                                            <img src={order.items[0]?.service?.images?.length > 0 ? COMMON.getImage(order.items[0].service.images[0].filename, 'service') : COMMON.getImage('')} alt={order.items[0].service.slug} />
                                        </div>
                                    </Col>
                                    <Col xs={16} lg={20} className="info">
                                        <div className="title">
                                            {order.items[0]?.service?.name}
                                        </div>

                                        <div style={{ color: '#777' }}>
                                            <span>Giá: </span>
                                            <span className="fw-medium">{COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</span>
                                        </div>
                                        <div style={{ color: '#777' }}>
                                            <span>Ngày khám bệnh: </span>
                                            <span className="fw-medium">{moment(order?.date).format('DD/MM/YYYY')}</span>
                                        </div>
                                        <div style={{ color: '#777' }}>
                                            <span>Giờ: </span>
                                            <span className="fw-medium">{moment(order?.time).format('HH:mm A')}</span>

                                        </div>
                                        <div style={{ color: '#777' }}>
                                            <span>Địa điểm: </span>
                                            <span className="fw-medium">
                                                {order?.address_work}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs={16} md={8}>Thành tiền</Col>
                                <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.subtotal)} {COMMON.getCurrency(order?.currency)}</Col>
                            </Row>
                            <Divider />
                            {
                                <React.Fragment>
                                    <Row>
                                        <Col xs={16} md={8}>Phí dịch vụ ({CONSTANT.FEE.SERVICE_MEDIC * 100}%)</Col>
                                        <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(CONSTANT.FEE.SERVICE_MEDIC * order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                    {
                                        order?.transpot_fee > 0 &&
                                        <Row>
                                            <Col xs={16} md={8}>Phí đi lại</Col>
                                            <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.transpot_fee)} {COMMON.getCurrency(order?.currency)}</Col>
                                        </Row>
                                    }
                                    {
                                        order?.overtime_fee > 0 &&
                                        <Row>
                                            <Col xs={16} md={8}>Phí ngoài giờ</Col>
                                            <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order?.overtime_fee)} {COMMON.getCurrency(order?.currency)}</Col>
                                        </Row>
                                    }
                                    <Divider />
                                    {
                                        order?.discount && order.discount?.value > 0 &&
                                        <Row>
                                            <Col xs={16} md={8}>Khuyến mãi giảm giá</Col>
                                            <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(order.discount.value_type === 'cash' ? order.discount.value : order.discount.value_type === 'percent' ? (order.subtotal + order.total_tax + order.transpot_fee + order.overtime_fee) * order.discount.value / 100 : 0)} {COMMON.getCurrency(order?.currency)}</Col>
                                        </Row>
                                    }
                                </React.Fragment>
                            }
                            <div className="order-total">
                                <span>Tổng cộng</span>
                                <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                            </div>
                        </div>
                        {
                            order.total > 0 &&
                            <div>
                                Phương thức thanh toán: <span className="fw-medium">{PAY_METHOD[`${order?.payment_method}`]}</span>
                            </div>
                        }


                        <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                            <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                        </div>
                    </div>
                }


            </div>
        </div>

    )
}

export default MyServiceMedical;