import React, { useState } from 'react';
import { Row, Col, Form, message, Input, Button, Switch, Modal } from 'antd';

import PhoneInput from 'react-phone-input-2';

import * as Services from '../../store/services';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';


const RegisterAgency = () => {
    const [waiting, setWaiting] = useState(false);
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });
    const onFinish = (values) => {
        setWaiting(true);
        let dataRegister = {
            email: values.email,
            password: values.password,
            firstname: values.firstname,
            //lastname: values.lastname,
            dob: values.dob,
            sex: values.gender,
            dial_code: phones.dial_code,
            phone: values.phone ? values.phone.replace(phones.dial_code, '') : '',
            role: "agency",
            is_doctor: values.is_doctor,
            is_hospital: values.is_hospital,
            is_seller: values.is_seller,
            is_service: values.is_service
        };

        Services.post(`${CONSTANT.API.AUTH.REGISTER}`, dataRegister)
            .then(res => {
                if (res && res.data.code === 200) {
                    Modal.success({
                        title: "Đăng ký tài khoản thành công",
                        content: `Vivmedic đã gửi email kích hoạt tài khoản vào email của bạn. Vui lòng kiểm tra email và kích hoạt tài khoản của bạn! Xin cảm ơn!`,
                        okText: "Đóng",
                        onOk: () => window.location.href = `${CONSTANT.BASE_AGENCY}`
                    });
                    setWaiting(false);
                }
                else if (res.data.code === 409) {
                    message.warning('Email này đã được đăng ký! Vui lòng nhập Email khác!', 5);
                }
                setWaiting(false);
            })
            .catch(error => {
                message.error('Đăng ký không thành công! Vui lòng thử lại sau!', 5);
                setWaiting(false);
            })
    }
    const onFinishFailed = () => {
        message.warning('Vui lòng kiểm tra lại dữ liệu nhập', 5);
    }
    return (
        <div className="container register-agency">
            <MetaSeo title="Đăng ký tài khoản Vivmedic" />
            <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]}>
                <Col xs={24} lg={12}>
                    <div className="title">Chào mừng đến với Vivmedic</div>
                    <div className="slogan">
                        Vivmedic rất hân hạnh được hợp tác cùng các bác sĩ và cơ sở y tế.
                        Xin vui lòng gửi thông tin để tham gia nền tảng y tế Vivmedic.

                        <p>Trân trọng cảm ơn!</p>
                    </div>
                    <div className=" text-center banner">
                        <img src="images/healthcare.svg" alt="icon healthcare" />
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className="register-now text-center text-uppercase">Thông tin</div>
                    <div className="box-register register-box ">
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                        >
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        label="Họ tên đầy đủ"
                                        name="firstname"
                                        rules={[{ required: true, message: 'Vui lòng nhập Họ tên đầy đủ!' }]}
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="fw-medium" style={{ color: '#444', marginBottom: 10 }}>Lĩnh vực hợp tác</div>
                            <Row gutter={8} justify="center" align="center">
                                <Col xs={12} lg={12}>
                                    <Form.Item
                                        name="is_seller"
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Bán hàng" unCheckedChildren="Bán hàng" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <Form.Item
                                        name="is_doctor"
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Bác sĩ" unCheckedChildren="Bác sĩ" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <Form.Item
                                        name="is_hospital"
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Cơ sở y tế" unCheckedChildren="Cơ sở y tế" />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <Form.Item
                                        name="is_service"
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Dịch vụ y tế" unCheckedChildren="Dịch vụ y tế" />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Form.Item
                                label="Email"
                                name="email"

                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email chưa đúng định dạng',
                                    },
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập E-mail!',
                                    }
                                ]}
                            >
                                <Input className="rounded-pill" size="large" />
                            </Form.Item>

                            <Form.Item label="Số điện thoại" name="phone">
                                <PhoneInput
                                    country='vn'
                                    value={phones.phone}
                                    onChange={(value, data) => {
                                        setPhones({
                                            phone: value,
                                            dial_code: data.dialCode,
                                            country_code: data.countryCode
                                        })
                                    }}

                                />
                            </Form.Item>

                            <Form.Item
                                label="Mật khẩu"
                                name="password"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mật khẩu!' },
                                    {
                                        pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                                        message: "Mật khẩu phải có ít nhất 6 ký tự bao gồm ký tự Hoa, thường, số và ký tự đặc biệt."
                                    }
                                ]}
                            >
                                <Input.Password className="rounded-pill" size="large" />
                            </Form.Item>

                            <Form.Item
                                label="Nhập lại mật khẩu"
                                name="repassword"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập lại mật khẩu ',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Mật khẩu nhập lại chưa khớp!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password className="rounded-pill" size="large" />
                            </Form.Item>
                            <div className="text-center" style={{ marginBottom: 20, color: '#777' }}>
                                Khi nhấn "Đăng ký", bạn xác nhận đồng ý với Điều khoản và điều kiện và Chính sách bảo mật
                            </div>

                            <Form.Item className="text-center">
                                <Button type="primary" loading={waiting} className="btn-global bg btn-register" htmlType="submit">
                                    Đăng ký
                                </Button>
                            </Form.Item>

                            <div className="text-center" style={{ marginTop: 10, marginBottom: 40 }} >
                                Bạn đã có tài khoản?
                                <a className="link-register" style={{ paddingLeft: 7 }} href="https://agency.vivmedic.com">
                                    Đăng nhập!
                                </a>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default RegisterAgency;