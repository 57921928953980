import React from 'react';

const LayoutDark = ({ children }) => {
	return (
		<div>
			Dark
            {children}
		</div>
	)
}

export default LayoutDark;