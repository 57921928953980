import React, { useState, useCallback } from 'react';
import { Form, Modal, Input, Button, Row, Col, Rate, Upload, message } from 'antd';

import { CloseOutlined, StarOutlined, UploadOutlined, SendOutlined, StopOutlined, } from '@ant-design/icons';

import CONSTANT from '../../config/constant';
import * as Services from '../../store/services';

const ModalFromReview = (props) => {
   const [form] = Form.useForm();
   const [confirmLoading, setConfirmLoading] = useState(false);
   const [rating, setRating] = useState(0);
   const [imageList, setImageList] = useState([]);
   const [errorImg, setErrorImg] = useState(false);

   // Hủy
   const handleCancel = useCallback(() => {
      form.resetFields();
      setRating(0);
      setImageList([]);
      props.cancel();
   }, [form, props]);

   // Xu ly su kien upload image
   const onChangeImages = ({ fileList, file }) => {
      if (errorImg) {
         fileList = fileList.filter(o => o.uid !== file.uid)
      }
      setImageList(fileList);
   }
   // Kiem tra image truoc khi upload
   const beforeUpload = (file) => {
      let _errorImg = false;
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
         message.error('Quý khách chỉ được tải ảnh JPG/PNG!');
         _errorImg = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
         message.error('Hình ảnh phải nhỏ hơn 2MB!');
         _errorImg = true;
      }
      setErrorImg(_errorImg);
      return isJpgOrPng && isLt2M;
   }

   // Xu ly luu image
   const saveImage = useCallback(async (images) => {
      let arrImage = [];
      if (images.length > 0) {
         for (const item of images) {
            if (item.status === 'done') {
               if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                  let _filename = await Services.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                     filename: item.response.data.filename,
                     type: "review"
                  })
                     .then(async res => {
                        if (res && res.data && res.data.code === 200) {
                           let filename = res.data.data.url;
                           if (typeof filename !== 'undefined') {
                              return filename;
                           }
                        }
                     });
                  arrImage.push(_filename);
               }
            }
         }
      }
      return arrImage;
   }, []);


   // Xử lý lưu review
   const onSubmit = useCallback(async (values) => {
      if (rating < 1) {
         return message.warning('Quý khách cảm thấy như thế nào? Quý khách vui lòng chọn Sao nhé!');
      }

      // Xu ly luu review
      let images = await saveImage(imageList);
      setConfirmLoading(true);
      await Services.post(`${CONSTANT.API.REVIEW}`, {
         type: props.type, content_id: props.content_id, rating, content: values.content, images,
         email: values.email, fullname: values.fullname
      }).then(res => {
         if (res?.data?.code === 200) {
            message.success('cảm nhận của Quý khách đã được gửi. cảm nhận của Quý khách sẽ được hiển thị sau khi được duyệt. Xin cảm ơn Quý khách!', 5);
         }
         if (res?.data?.code === 403) {
            switch (props.type) {
               case 'doctor':
                  message.error('Hình như Quý khách chưa từng được thăm khám bởi Bác sĩ! Quý khách chỉ cảm nhận được khi đã được Bác sĩ thăm khám! Xin cảm ơn Quý khách!', 7);
                  break;
               default:
                  break;
            }

         }
      })

      setTimeout(() => {
         setConfirmLoading(false);
         // đóng modal
         handleCancel();
      }, 7000);
   }, [props, rating, imageList, saveImage, handleCancel]);

   // on change rating
   const onChangeRating = (value) => {
      setRating(value);
   }

   return (
      <Modal title="Viết cảm nhận"
         visible={props.isModalVisible}
         onOk={form.submit}
         onCancel={handleCancel}
         confirmLoading={confirmLoading}
         cancelText={<span><StopOutlined /> Hủy</span>}
         okText={<span><SendOutlined /> Gửi cảm nhận ngay</span>}
         centered
         maskClosable={false}
         wrapClassName="wrap-write-review"
         destroyOnClose={true}
         closeIcon={<Button icon={<CloseOutlined />} shape="round">Đóng</Button>}
         cancelButtonProps={{ shape: 'round' }}
         okButtonProps={{ shape: 'round' }}
      >
         <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => { }}
            form={form}
         >
            <Row align="middle">
               <Col xs={24} md={10}>
                  <div>Quý khách cảm thấy như thế nào?</div>
                  <div>(Chọn sao nhé :))</div>
               </Col>
               <Col xs={24} md={14}>
                  <Rate tooltips={CONSTANT.RATING_DESC} character={<StarOutlined />} onChange={onChangeRating} value={rating} />
                  {rating > 0 ? <span className="ant-rate-text">{CONSTANT.RATING_DESC[rating - 1]}</span> : ''}
               </Col>
            </Row>

            <Form.Item
               name="content"
               rules={[
                  { required: true, message: 'Vui lòng nhập nội dung cảm nhận' },
                  { min: 1, message: "Nội dung cảm nhận quá ít. Vui lòng nhập thêm nội dung cảm nhận" }
               ]}
            >
               <Input.TextArea
                  className="review-content" showCount={{ formatter: ({ count }) => `${count} ký tự (tối thiểu 20)` }}
                  placeholder="Mời Quý khách chia sẽ thêm một số cảm nhận..."
                  autoSize={{ minRows: 3 }}
               />
            </Form.Item>

            <Upload
               action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
               listType="picture"
               className="upload-list-inline"
               maxCount={6}
               onChange={onChangeImages}
               beforeUpload={beforeUpload}
               fileList={imageList}
            >
               <Button className="btn-upload" shape="round" icon={<UploadOutlined />}>Gửi hình ảnh chụp thực tế</Button>
               <span className="upload-note">(Tối đa 6 hình)</span>
            </Upload>

            <Row gutter={24} style={{ marginTop: '24px' }}>
               <Col xs={24} md={12}>
                  <Form.Item
                     name="fullname"
                     rules={[
                        { required: true, message: 'Vui lòng nhập Họ và tên' },
                     ]}
                  >
                     <Input className="rounded-pill form-control" placeholder="Nhập Họ và tên (bắt buộc)" />
                  </Form.Item>
               </Col>
               <Col xs={24} md={12}>
                  <Form.Item
                     name="email"
                     rules={[
                        { required: true, message: 'Vui lòng nhập Email' },
                        { type: 'email', message: 'Email chưa đúng định dạng! (Ví dụ: info@vivmedic.com)' },
                     ]}
                  >
                     <Input className="rounded-pill form-control" placeholder="Nhập Email (bắt buộc)" />
                  </Form.Item>
               </Col>
            </Row>

            <div className="text-center review-note">
               Để cảm nhận được duyệt, quý khách vui lòng tham khảo <a href={`${CONSTANT.BASE_URL}/${CONSTANT.SLUG_PAGE_CONTENT}/quy-dinh-dang-binh-luan/8`} target="_blank" title="Quy định duyệt cảm nhận">Quy định duyệt cảm nhận</a>
            </div>
         </Form>
      </Modal>
   )
}

export default ModalFromReview;
