import React from 'react';

const About = () => {

    return (
        <div>
            <div>ABOUT PAGE</div>
           
        </div>
    )
}

//About.serverFetch = fetchData;

export default About;