import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Avatar } from 'antd';
import { ForkOutlined, EnvironmentOutlined } from '@ant-design/icons';


// import common
import * as COMMON from '../../../helper/common';

const ItemFacility = (props) => {
	return (
		<div className="facility">
			<Row gutter={[{ xs: 0, lg: 24 }, { xs: 0, lg: 24 }]}>
				<Col xs={24} lg={10}>
					<div className="box-cover">
						{
							props.data && props.data.images && props.data.images.length > 0 ?
								<img src={COMMON.getImage(props.data.images[0].filename, 'hospital')} alt="" />
								:
								<img src={COMMON.getImage('', 'hospital')} alt="" />
						}
					</div>
				</Col>
				<Col xs={24} lg={14} className="col-info">
					<h1 className="title">{props.data.name}</h1>
					<div className="specialty">
						{
							props.data && props.data.specialties && props.data.specialties.length > 0 && props.data.specialties.map((item, index) => (
								<Avatar key={index} size={22} src={item?.specialty?.avatar && COMMON.getImage(item.specialty.avatar, 'hospital')} title={item?.specialty?.name} />
							))
						}
					</div>
					<div className="location">
						<EnvironmentOutlined />
						{props.data.address}
					</div>
					{
						props?.data?.scope_of_specialty &&
						<div className="location">
							<ForkOutlined />
							{props?.data?.scope_of_specialty}
						</div>

					}
					<div className="text-end control-btn">
						<Link to={COMMON.createLinkFacilityDetail(props.data.slug, props.data.uid)} title={"Xem chi tiết " + props.data.name} className="btn-global bg btn-view-detail">Chi tiết</Link>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default ItemFacility;