// Get list
export const GET_LIST = "GET_LIST_PRODUCT";
export const GET_LIST_SUCCESS = "GET_LIST_PRODUCT_SUCCESS";
export const GET_LIST_FAILURE = "GET_LIST_PRODUCT_FAILURE";

// Get list
export const GET_LIST_HOT = "GET_LIST_PRODUCT_HOT";
export const GET_LIST_HOT_SUCCESS = "GET_LIST_PRODUCT_HOT_SUCCESS";
export const GET_LIST_HOT_FAILURE = "GET_LIST_PRODUCT_HOT_FAILURE";

// Get detail
export const GET_DETAIL = "GET_DETAIL_PRODUCT";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_PRODUCT_SUCCESS";
export const GET_DETAIL_FAILURE = "GET_DETAIL_PRODUCT_FAILURE";
//add order
export const ADD_ORDER = 'ADD_ORDER'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE'
//fetch order
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'
//fetch detail order
export const GET_ORDER_ITEM = 'GET_ORDER_ITEM'
export const GET_ORDER_ITEM_SUCCESS = 'GET_ORDER_ITEM_SUCCESS'
export const GET_ORDER_ITEM_FAILURE = 'GET_ORDER_ITEM_FAILURE'
// Get tree category
export const GET_TREE_CATEGORY = "GET_TREE_CATEGORY";
export const GET_TREE_CATEGORY_SUCCESS = "GET_TREE_CATEGORY_SUCCESS";
export const GET_TREE_CATEGORY_FAILURE = "GET_TREE_CATEGORY_FAILURE";

