import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Button, BackTop, Modal, Row, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-multi-lang';
import './home-style.css';

// import section group service
import SectionGroupService from './components/section-group-service';

// import section medical online
import SectionMedicalOnline from './components/section-medical-online';

// import section medical shoping
import SectionShop from './components/section-shop';
// import dection doctor
import SectionDoctor from './components/section-doctor';
// impport dịch vụ y tế
import SectionService from './components/section-service';
// import section hot
import SectionHot from './components/section-hot';

import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';
import { DoctorAction, ProductAction } from '../../store/root-action';

const Home = () => {
    const t = useTranslation();
    const refBanner = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <React.Fragment>

            <MetaSeo />

            {/* Banner home */}

            <div className="banner-home">
                <Carousel autoplay ref={refBanner} dots={false}>
                    <div className='item'>
                        <img src={`${CONSTANT.STATIC_PATH}images/banner/banner-5.jpeg`} alt="Dịch vụ y tế tại nhà" style={{ marginTop: '-20px' }} />
                    </div>
                    <div className='item'>
                        <img src={`${CONSTANT.STATIC_PATH}images/banner/banner-6.jpeg`} alt="Dịch vụ y tế tại nhà" style={{ marginTop: '-20px' }} />
                    </div>

                    <div className='item'>
                        <img src={`${CONSTANT.STATIC_PATH}images/banner/banner-7.jpeg`} alt="Dịch vụ y tế tại nhà" style={{ marginTop: '-20px' }} />
                    </div>

                    <div className='item'>
                        <img src={`${CONSTANT.STATIC_PATH}images/banner/banner-8.jpeg`} alt="Dịch vụ y tế tại nhà" style={{ marginTop: '-20px' }} />
                    </div>
                    <div className='item'>
                        <img src={`${CONSTANT.STATIC_PATH}images/banner/banner-9.jpeg`} alt="Dịch vụ y tế tại nhà" style={{ marginTop: '-20px' }} />
                    </div>
                </Carousel>
                <div className="control-arrow">
                    <Button className="btn-prev" shape="circle" icon={<LeftOutlined />} onClick={() => refBanner.current.prev()} />
                    <Button className="btn-next" shape="circle" icon={<RightOutlined />} onClick={() => refBanner.current.next()} />
                </div>
                <div className='home-slogan'>
                    <span className="bg">
                        <h2 className='animate-charcter'>
                            SỨC KHỎE LÀ MỘT QUÁ TRÌNH
                        </h2>
                    </span>
                </div>
                <div className='caption'>
                    <div className="container">
                        <button onClick={showModal} className='ant-btn ant-btn-default book-now'>Đặt hẹn khám ngay</button>
                        <div className='hotline'>Hotline: <a href="tel:+84818889668">(+84) 818 889 668</a></div>
                    </div>
                </div>
                <Modal className='modal-select-service' title="Chọn dịch vụ" visible={isModalOpen} onCancel={handleCancel} width={800} footer={null}>
                    <Row gutter={24}>
                        <Col xs={12} md={6} lg={6} className='item'>
                            <Link to="/tu-van-tu-xa" title="Tư vấn từ xa">
                                <div className="box-avatar"><i className="fas fa-video icon"></i></div>
                                <div className="title">Tư vấn từ xa</div>
                            </Link>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='item'>
                            <Link to="/co-so-y-te?type=benh-vien" title="Khám tại Bệnh viện">
                                <div className="box-avatar"><i className="far fa-hospital icon"></i></div>
                                <div className="title">Khám tại Bệnh viện</div>
                            </Link>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='item'>
                            <Link to="/co-so-y-te?type=phong-kham" title="Khám tại Phòng khám">
                                <div className="box-avatar"><i className="fas fa-clinic-medical icon"></i></div>
                                <div className="title">Khám tại Phòng khám</div>
                            </Link>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='item'>
                            <Link to="/dich-vu-y-te/c-dich-vu-y-te-tai-nha/2" title="Khám tại nhà">
                                <div className="box-avatar"><i className="fas fa-home icon"></i></div>
                                <div className="title">Khám tại nhà</div>
                            </Link>
                        </Col>
                    </Row>
                </Modal>

            </div>


            {/* group service */}
            <SectionGroupService />

            { /* Section hot */}
            <SectionHot />

            {/* Begin đội ngũ chuyên môn */}
            <SectionDoctor />

            {/* Begin dịch vụ y tế */}
            <SectionService />

            {/* Begin section medical online */}
            <SectionMedicalOnline />

            {/* Begin medical shopping */}
            <SectionShop />



            {/* Begin cơ sở y tế */}
            <div className="professional-team health-facility">
                <div className="caption">
                    <div className="text-uppercase title">{t('medical_facility')}</div>
                    <div className="sapo">{t('medical_facility_sapo')}</div>
                    <div className="line"></div>
                    <Link to={`/${CONSTANT.SLUG_FACILITY}`} title={`${t('detail')} ${t('medical_facility')}`} className="btn-global bg btn-detail">{t('detail')}</Link>
                </div>
            </div>


            <BackTop />
        </React.Fragment >
    )
}

Home.serverFetch = async ({ store, params }) => {
    await store.dispatch(DoctorAction.getList({
        perPage: 4, page: 1, status: 'publish', is_online: true, is_service: 0
    }));
    await store.dispatch(ProductAction.getList({
        perPage: 4, page: 1, category: 67, status: 'publish'
    }));
};

export default Home;