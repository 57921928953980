// Get list
export const GET_LIST = "GET_LIST_HANDBOOK";
export const GET_LIST_SUCCESS = "GET_LIST_HANDBOOK_SUCCESS";
export const GET_LIST_FAILURE = "GET_LIST_HANDBOOK_FAILURE";

// Get detail
export const GET_DETAIL = "GET_DETAIL_HANDBOOK";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_HANDBOOK_SUCCESS";
export const GET_DETAIL_FAILURE = "GET_DETAIL_HANDBOOK_FAILURE";

//get handbook category
export const GET_HANDBOOK_CATEGORY = 'GET_HANDBOOK_CATEGORY'
export const GET_HANDBOOK_CATEGORY_SUCCESS = 'GET_HANDBOOK_CATEGORY_SUCCESS'
export const GET_HANDBOOK_CATEGORY_FAILURE= 'GET_HANDBOOK_CATEGORY_FAILURE'
