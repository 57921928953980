import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Empty } from 'antd';
import ShowMoreText from 'react-show-more-text';

import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';
import AdviceF0Doctor from './components/advice-f0-doctor';
import * as Service from '../../store/services';

const AdviseF0 = () => {

    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadDoctors = useCallback(async () => {
        await Service.get(`${CONSTANT.API.DOCTOR.DOCTOR}?perPage=30&page=1&is_active=true&is_online=true&is_f0=true`)
            .then(res => {
                if (res?.data?.code === 200) {
                    setDoctors(res.data.data.docs);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch(err => setLoading(false))
    }, []);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            loadDoctors();
        }
        return () => mounted = false;
    }, [loadDoctors]);

    return (
        <div className="f0-at-home">
            <MetaSeo
                title="Bác sĩ tư vấn F0 tại nhà"
                description="Hiện nay dịch bệnh Covid-19 diễn biến phức tạp gây khó khăn cho người bệnh trong quá trình thăm khám sức khỏe. Với mong muốn chung tay góp sức chăm sóc sức khỏe cộng đồng, Vivmedic cung cấp dịch vụ Tư vấn sức khoẻ từ xa  dành cho người bệnh F0, F1.Đội ngũ bác sĩ giỏi, giàu kinh nghiệm, tư vấn F0 điều trị tại nhà, đối tượng áp dụng: F0, F1"
                keyword={`bác sĩ tư vấn f0 tại nhà, tư vấn f0, covid 19, covid, tư vấn f0, tư vấn covid, điều trị covid, điều trị covid 19, ${CONSTANT.PAGE_KEYWORD_EXT}`}
            />
            <div className="container">
                <div className="block-header">
                    <div className="text-uppercase title">
                        Bác sĩ tư vấn cho F0 tại nhà
                    </div>
                    <ShowMoreText
                        lines={2}
                        more="Xem thêm"
                        less="Thu gọn"
                        className='box-show-more-text'
                    >
                        <div className="text-start">
                            Dịch bệnh Covid-19 diễn biến phức tạp gây khó khăn cho người bệnh trong quá
                            trình thăm khám sức khỏe. Với mong muốn chung tay góp sức chăm sóc sức khỏe cộng đồng,
                            Vivmedic cung cấp dịch vụ Tư vấn sức khoẻ từ xa  dành cho người bệnh F0, F1.
                            <p>
                                <strong>Nếu bạn là:</strong>
                            </p>
                            <ul>
                                <li>F0 đang tự điều trị tại nhà cần tư vấn, hỗ trợ chăm sóc</li>
                                <li> Người tiếp xúc gần với F0</li>
                            </ul>

                            <p>
                                <strong>Đừng ngần ngại, hãy đăng ký ngay lịch tư vấn từ xa của Vivmedic.</strong>
                            </p>
                            <ul>
                                <li>Tư vấn nhận diện triệu chứng F0, cách phòng tránh nhiễm bệnh, cách chăm sóc sức khỏe khi bị bệnh</li>
                                <li>Được thăm khám với đội ngũ bác sĩ chuyên khoa giỏi</li>
                                <li>Quy trình, thao tác đơn giản, nhanh gọn</li>
                            </ul>

                            <p>
                                <strong>Các bước tư vấn cho F0</strong><br />
                                Bước 1: Đặt lịch tư vấn với bác sĩ phù hợp và thanh toán chi phí<br />
                                Bước 2: Vivmedic sẽ xác nhận thanh toán và gửi thông tin chi tiết cuộc hẹn đến Quý khách.<br />
                                Bước 3: Gặp bác sĩ từ xa để được tư vấn
                            </p>
                        </div>
                    </ShowMoreText>
                    <div className="line"></div>
                </div>
                <Spin spinning={loading}>
                    <div className='doctor-detail' style={{ marginTop: '0px' }}>
                        <div className='tabs-detail' style={{ marginTop: '0px' }}>
                            <div className='service'>
                                <div className='doctor-specialty'>
                                    {doctors?.length > 0 && doctors.map(item =>
                                        <React.Fragment key={item._id}>
                                            <AdviceF0Doctor data={item} />
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
                {
                    !loading && doctors?.length === 0 &&
                    <Empty description="Chưa có dữ liệu" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                <br />
            </div>

        </div >
    )
}

export default AdviseF0;