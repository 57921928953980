import * as Services from "../services";
import CONSTANT from '../../config/constant';
import {
    // get list
    GET_LIST, GET_LIST_FAILURE, GET_LIST_SUCCESS,
    // get detail
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,
    // specialties
    GET_SPECIALTIES, GET_SPECIALTIES_SUCCESS, GET_SPECIALTIES_FAILURE,
    // types
    GET_TYPES, GET_TYPES_SUCCESS, GET_TYPES_FAILURE,
} from './type';

// get list
export const getList = (queryParam) => {
    let url = `${CONSTANT.API.HOSPITAL.HOSPITAL}`;
    queryParam.short = true;
    return async dispatch => {
        dispatch({ type: GET_LIST });
        return await Services.get(url, queryParam)
            .then(res => {
                if (res?.data?.code === 200) {
                    dispatch({
                        type: GET_LIST_SUCCESS,
                        payload: res.data.data
                    })
                }
                else {
                    dispatch({ type: GET_LIST_FAILURE, payload: 'Lỗi lấy dữ liệu List Hospital từ API' })
                }
            })
    }
}

export const getDetail = (id) => {
    return async dispatch => {
        if (id) {
            let url = `${CONSTANT.API.HOSPITAL.HOSPITAL}/${id}`;
            dispatch({ type: GET_DETAIL });
            return await Services.get(url)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.data)
                            dispatch({
                                type: GET_DETAIL_SUCCESS,
                                payload: res.data.data
                            })
                        else
                            dispatch({
                                type: GET_DETAIL_FAILURE,
                                payload: 'Dữ liệu không tồn tại'
                            })
                    }
                    else {
                        dispatch({ type: GET_DETAIL_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                    }
                })
        }
        else {
            dispatch({ type: GET_DETAIL_FAILURE, payload: "Id không đúng" });
        }
    }
}
// process get list specialties (Chuyên ngành)
export function getSpecialties(queryParam) {
    let url = `${CONSTANT.API.HOSPITAL.SPECIALTIES}`;
    return async (dispatch) => {
        dispatch({
            type: GET_SPECIALTIES
        });
        return await Services.get(url, queryParam)
            .then(res => {

                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_SPECIALTIES_SUCCESS,
                            payload: res.data.data.docs
                        })
                    else
                        dispatch({
                            type: GET_SPECIALTIES_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_SPECIALTIES_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                }
            })
    }
}
// process get list types (Loại cơ sở y tế)
export function getTypes(queryParam) {
    let url = `${CONSTANT.API.HOSPITAL.TYPES}`;
    return async (dispatch) => {
        dispatch({
            type: GET_TYPES
        })
        return await Services.get(url, queryParam)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data.docs.length > 0)
                        dispatch({
                            type: GET_TYPES_SUCCESS,
                            payload: res.data.data.docs
                        })
                    else
                        dispatch({
                            type: GET_TYPES_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_TYPES_FAILURE, payload: 'Lỗi lấy dữ liệu Hospital từ API' })
                }
            })
    }
}