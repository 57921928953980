import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Breadcrumb, Spin, Empty, message } from "antd";
import "./page.css";
import { axiosInstance } from "../../config/api";

import MetaSeo from "../../component/meta-seo";
import CONSTANT from "../../config/constant";
import * as COMMON from "../../helper/common";

function Page() {
  const { id } = useParams();
  const history = useHistory();

  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Load thông tin trang
    const loadDetail = async () => {
      if (typeof id !== "undefined") {
        return await axiosInstance
          .get(`${CONSTANT.API.EXTRA.PAGE}/${id}`)
          .then((res) => {
            if (res && res.data && res.data.code === 200) {
              return res.data.data;
            } else {
              message
                .warning("Lỗi lấy thông tin trang!")
                .then(() => history.push("/"));
            }
          })
          .catch((error) => {
            message
              .warning("Lỗi lấy thông tin trang!")
              .then(() => history.push("/"));
          });
      } else {
        message.warning("Nội dung không tồn tại!");
        history.push("/");
      }
    };
    let mounted = true;
    setLoading(true);
    if (mounted) {
      loadDetail().then((data) => {
        if (data) {
          setDetail(data);
          setLoading(false);
        }
      });
    }
    return () => (mounted = false);
  }, [id]);

  return (
    <React.Fragment>
      {detail?._id && (
        <MetaSeo
          title={detail?.name}
          keyword={`${detail?.name}, ${CONSTANT.PAGE_KEYWORD_EXT}`}
          description={
            detail?.description &&
            COMMON.splitString(detail?.description.replace(/<[^>]+>/g, ""), 50)
          }
        />
      )}

      <div className="page-content">
        <div className="breadcrumb">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Trang chủ</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detail?._id && detail?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        {loading && (
          <div className="text-center" style={{ padding: "30px 0px" }}>
            <Spin spinning={loading} tip="Đang tải dữ liệu..."></Spin>
          </div>
        )}
        {!loading && (
          <div className="container">
            <div className="block-header">
              <div className="text-uppercase title">{detail?.name}</div>
              <div className="line"></div>
            </div>
            <div className="content">
              {detail?.content && parse(detail.content)}
              {!loading && !detail?.content && (
                <Empty description="Nội dung đang cập nhật" />
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Page;
