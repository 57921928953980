import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import {
    Breadcrumb, Col, Row, Pagination, Spin, Empty
} from 'antd';
import { } from '@ant-design/icons';


// import common
import * as COMMON from '../../helper/common';

import './service-style.css';
import { useDispatch, useSelector } from 'react-redux';

import { ServiceAction } from '../../store/root-action';

import MenuLeft from './components/menu-left';
import ItemService from './components/item-service';
import MetaSeo from '../../component/meta-seo';

const ServiceMedicalCategory = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [currentCategory, setCurrentCategory] = useState({});
    const [siteMapCategories, setSiteMapCategories] = useState([]);
    const loading = useSelector(state => state.serviceReducer.loading);
    const listServices = useSelector(state => state.serviceReducer.list);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;

    //  onloaded menu
    const onLoadedMenu = (data) => {
        setSiteMapCategories(data.siteMapCategories);
        setCurrentCategory(data.currentCategory);
    }

    // Load services
    const loadServices = (page) => {
        let params = {
            perPage: pageSize, page, status: 'publish'
        }
        if (id)
            params.category = id;
        dispatch(ServiceAction.getList(params));
    }
    useEffect(() => {
        setCurrentPage(1);
        loadServices(1);
    }, [id])

    // Change pagination
    const onChangePagination = (page) => {
        setCurrentPage(page);
        loadServices(page);
    }
    return (
        <React.Fragment>
            <MetaSeo
                title={currentCategory?.name}
                description={currentCategory?.description || currentCategory?.name}
                keyword={currentCategory?.name}
                image={currentCategory?.avatar && COMMON.getImage(currentCategory.avatar, 'service')}
            />

            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">{t('home')}</Link></Breadcrumb.Item>
                        {
                            siteMapCategories && siteMapCategories.length > 0 && siteMapCategories.map(item => (
                                <Breadcrumb.Item key={item.uid}>
                                    <Link title={item.name} to={COMMON.createLinkProductCategory(item.slug, item.uid)}>
                                        {item.name}
                                    </Link>
                                </Breadcrumb.Item>
                            ))
                        }
                    </Breadcrumb>
                </div>
            </div>
            {/* Begin medical shopping */}
            <div className="container">
                <div className="product-category">
                    <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]}>
                        <Col xs={24} lg={6} className="col-left">
                            <MenuLeft onLoaded={onLoadedMenu} currentCategory={currentCategory} />
                        </Col>
                        <Col xs={24} lg={18}>
                            <Spin spinning={loading} tip="Đang tải dữ liệu...">
                                {
                                    !loading && listServices && listServices.docs && listServices.docs.length > 0 &&
                                    <React.Fragment>
                                        <div className="list-product">
                                            <Row gutter={[{ xs: 16, lg: 32 }, { xs: 16, lg: 32 }]}>
                                                {
                                                    listServices.docs.map(item => (
                                                        <ItemService data={item} key={item.uid} />
                                                    ))
                                                }

                                            </Row>
                                        </div>
                                        <div className="text-end pagination">
                                            <Pagination
                                                pageSize={pageSize} current={currentPage} total={listServices.total}
                                                hideOnSinglePage={true}
                                                showLessItems={true}
                                                onChange={onChangePagination}
                                            />
                                        </div>
                                    </React.Fragment>

                                }
                                {
                                    !loading && listServices.docs && listServices.docs.length === 0 &&
                                    <Empty description={<span style={{ color: '#999' }}>Không có dữ liệu</span>} />
                                }

                            </Spin>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
ServiceMedicalCategory.serverFetch = async ({ store, params, query }) => {
    let param_query = {
        perPage: 12, page: 1, status: 'publish', category: params.id
    }
    await store.dispatch(ServiceAction.getList(param_query));
}
export default ServiceMedicalCategory;