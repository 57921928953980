import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Steps, Form, Row, Col, Input, Typography, Select, Button, message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import * as Services from "../../store/services";
import { ProductAction } from '../../store/root-action';
// import common
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
const { Step } = Steps;

const CheckoutInformation = (props) => {
    const [form] = Form.useForm();
    const [countries, setCountries] = useState();
    const [phone, setPhone] = useState();
    const history = useHistory();
    const dispatch = useDispatch();
    const orderStores = useSelector(state => state.productReducer.orderItem);
    const subTotal = useSelector(state => state.productReducer.subTotal);
    const [discountInfo, setDiscountInfo] = useState({
        discount: "",
        codeIsDiscount: "",
        discountTotal: 0
    });
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const loadSpots = async () => {
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            if (order_id) {
                await dispatch(ProductAction.getOrder(order_id));
                await dispatch(ProductAction.getOrderItem(order_id));
            }
            loadCountry();
        };
        loadSpots();
    }, [dispatch]);
    const loadCountry = async () => {
        await Services.get(CONSTANT.API.PRODUCT.COUNTRY)
            .then(res => {
                if (res.data.code === 200) {
                    let docs = res.data.data.docs;
                    if (docs.length > 0) {
                        setCountries(docs);
                        loadInfoEdit();
                    }
                }
            })
    }
    const loadInfoEdit = async () => {
        await Services.get(`${CONSTANT.API.PRODUCT.ORDER}/${localStorage.getItem(CONSTANT.ORDER_ID)}`)
            .then(res => {
                if (res.data.code === 200) {
                    form.setFieldsValue({
                        first_name: res.data.data.shipping.first_name,
                        last_name: res.data.data.shipping.last_name,
                        email: res.data.data.shipping.email,
                        phone: res.data.data.shipping.phone,
                        city: res.data.data.shipping.city,
                        country: res.data.data.shipping.country_code + '-' + res.data.data.shipping.country,
                        postcode: res.data.data.shipping.postcode,
                        address_1: res.data.data.shipping.address_1,
                        address_2: res.data.data.shipping.address_2,
                    })
                }
            })
    }
    const applyDiscount = async () => {
        if (discountInfo.codeIsDiscount === "") {
            let check = await Services.post(`${CONSTANT.API.PRODUCT.CHECKOUT}/${localStorage.getItem(CONSTANT.ORDER_ID)}/discount`, { discount_code: discountInfo.discount });
            if (check.status === 200) {
                setDiscountInfo({
                    codeIsDiscount: check.data.data.discount_code,
                    cartPrice: check.data.data.total,
                    discountTotal: check.data.data.discount_total
                });
                dispatch(ProductAction.getOrderItem(localStorage.getItem(CONSTANT.ORDER_ID)));
            }
            else {
                message.warning('Mã giảm giá đã được sử dụng hoặc đã hết hạn !', 4);
            }
        }
        else {
            message.warning('Mã giảm giá đã áp dụng !', 4);
        }

    }
    const onFinish = async (values) => {
        let country = values.country.split('-');
        let dataShipping = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "fullname": values.first_name + ' ' + values.last_name,
            "company": "Other",
            "address_1": values.address_1,
            "address_2": values.address_2,
            "city": values.city,
            "postcode": values.postcode,
            "country": country.length === 2 ? country[1] : "",
            "country_code": country.length === 2 ? country[0] : ""
        }
        let dataBilling = {};
        dataBilling = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "fullname": values.first_name + ' ' + values.last_name,
            "company": "Other",
            "address_1": values.address_1,
            "address_2": values.address_2,
            "city": values.city,
            "postcode": values.postcode,
            "country": country.length === 2 ? country[1] : "",
            "country_code": country.length === 2 ? country[0] : ""
        }
        dataShipping.email = values.email;
        dataShipping.phone = values.phone;
        dataShipping.type = 'information';
        setProcessing(true);
        let udp = await Services.post(`${CONSTANT.API.PRODUCT.CHECKOUT}/${localStorage.getItem(CONSTANT.ORDER_ID)}/information`, { "billing": dataBilling, "shipping": dataShipping })
        if (udp.status === 200) {
            dispatch(ProductAction.getOrderItem(localStorage.getItem(CONSTANT.ORDER_ID)));
            props.history.push('/checkout/payment');
        }
        else {
            setProcessing(false);
        }
    }
    return (
        <div className="page-checkout">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={1}>
                        <Step title="Chọn hàng" />
                        <Step title="Nhập thông tin" />
                        <Step title="Thanh toán" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Row gutter={[{ xs: 0, lg: 48 }, { xs: 0, lg: 48 }]}>
                    {/* Begin col info */}
                    <Col xs={24} lg={15} className="col-info" >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <div className="fw-bold form-header">Thông tin khách hàng</div>
                            <Row gutter={24}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập email' },
                                            { type: 'email', message: 'Email không đúng định dạng' },
                                            { type: 'string', max: 100, message: "Email không hợp lệ, tối đa 100 ký tự !" }
                                        ]}
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item label="Số điện thoại" name="phone" required tooltip="Chúng tôi có thể liên hệ với bạn về đơn đặt hàng của bạn">
                                        <PhoneInput
                                            country={'vn'}
                                            value={phone}
                                            onChange={phone => setPhone(phone)}
                                            className="rounded-pill"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className="fw-bold form-header">Địa chỉ giao hàng</div>
                            <Row gutter={24}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Họ"
                                        name="first_name"
                                        rules={[{ required: true, message: 'Vui lòng nhập Họ' }]}
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Tên"
                                        name="last_name"
                                        rules={[{ required: true, message: 'Vui lòng nhập Tên' }]}
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label={<><span style={{ marginRight: 10 }}>Địa chỉ: </span> <Typography.Text type="secondary"> (Số nhà, tên đường, thôn, xã/phường...)</Typography.Text></>}
                                name="address_1"
                                rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                            >
                                <Input className="rounded-pill" size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Quận/Huyện"
                                name="address_2"
                                rules={[{ required: true, message: 'Vui lòng nhập Quận/Huyện' }]}
                            >
                                <Input className="rounded-pill" size="large" />
                            </Form.Item>
                            <Form.Item
                                label="Tỉnh/Thành phố"
                                name="city"
                                rules={[{ required: true, message: 'Vui lòng nhập Tỉnh/Thành phố' }]}
                            >
                                <Input className="rounded-pill" size="large" />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col xs={14} lg={16}>
                                    <Form.Item
                                        label="Quốc gia"
                                        name="country"
                                        rules={[{ required: true, message: 'Vui lòng chọn Quốc Gia' }]}
                                    >
                                        <Select placeholder="Chọn quốc gia"
                                            className="select-global" size='large'
                                            optionFilterProp="children"
                                            showSearch>
                                            {
                                                countries && countries.map(item => (
                                                    <Select.Option key={item.code} value={item.code + '-' + item.name} >{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={8} lg={8}>
                                    <Form.Item
                                        label="Mã bưu cục"
                                        name="postcode"
                                    >
                                        <Input className="rounded-pill" size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className="group-btn">
                                <Button className="btn-global bg" icon={<ArrowLeftOutlined />} onClick={history.goBack}>Quay lại</Button>
                                <Button className="btn-global bg" htmlType="submit" loading={processing}>Tiếp tục <ArrowRightOutlined /></Button>
                            </div>
                        </Form>

                    </Col>
                    {/* Begin col cart */}
                    <Col xs={24} lg={9}>
                        <div className="fw-bold">Chi tiết đơn hàng</div>
                        <div className="list-product">
                            {
                                orderStores && orderStores.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="box-cover">
                                            <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                        </div>
                                        <div className="info">
                                            <div className="title">
                                                {item.product.name}<br></br>
                                                <p className="product-attribute">
                                                    {
                                                        item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                            let str = attr.options[0].name;
                                                            if (indexAttr < item.variant.attributes.length - 1)
                                                                str += " / ";
                                                            return str;
                                                        })
                                                    }
                                                </p>
                                            </div>
                                            <div className="quantity-price">
                                                <span>x{item.quantity}</span>
                                                <span className="price">{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</span>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                            <Input.Search
                                placeholder="Mã giảm giá"
                                onChange={async (e) => {
                                    let discountCheck = { ...discountInfo };
                                    discountCheck.discount = e.target.value;
                                    await setDiscountInfo(discountCheck);
                                }}
                                onSearch={() => applyDiscount()}
                                enterButton="Áp dụng"
                                size="large"
                                className="discount"
                            />

                            <div className="other-price">
                                <span>Tổng giá sản phẩm</span>
                                <span className="price">{COMMON.currencyFormat(subTotal)} ₫</span>
                            </div>
                            <div className="other-price">
                                <span>Phí vận chuyển</span>
                                <span >Quý khách tự thanh toán</span>
                            </div>
                            <div className="other-price">
                                <span>Giảm giá</span>
                                <span className="price">{COMMON.currencyFormat(discountInfo.discountTotal)} đ</span>
                            </div>
                            <div className="order-total">
                                <span>Tổng cộng</span>
                                <span className="price-total">{COMMON.currencyFormat(subTotal - discountInfo.discountTotal)} ₫</span>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default CheckoutInformation;

