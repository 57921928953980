import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookies';
import { getLanguage, useTranslation } from 'react-multi-lang';
import {
    Row, Col, Input, Avatar, Dropdown, Menu, Button
} from 'antd';
import {
    ShoppingCartOutlined, UserOutlined, SettingOutlined, OrderedListOutlined, LogoutOutlined,
    AlignRightOutlined, CloseOutlined, DownOutlined
} from '@ant-design/icons';


import './style-default.css';

// import action
import { ProductAction, ServiceAction, HospitalAction } from '../../store/root-action';
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';

import MenuMain from './menu';

const PageHeader = () => {
    const t = useTranslation();
    const [currentUser, setCurrentUser] = useState();
    const [openSideMenu, setOpenSideMenu] = useState();

    const dispatch = useDispatch();
    // category product
    const categoryProduct = useSelector(state => state && state.productReducer && state.productReducer.tree_category);
    // category doctor
    //const doctorCategories = useSelector(state => state.doctorReducer.categories);
    // category service
    const serviceCategories = useSelector(state => state.serviceReducer.categories);
    // category service
    const typeHospitals = useSelector(state => state.hospitalReducer.types);
    // specialty
    const specialties = useSelector(state => state.hospitalReducer.specialties);
    // order item
    const orderItem = useSelector(state => state.productReducer.orderItem);

    useEffect(() => {
        // get current user from storage
        let user = localStorage.getItem(CONSTANT.STORAGE_USER);
        if (user) {
            user = JSON.parse(user);
            setCurrentUser(user);
        }
    }, []);

    // load order item
    const loadOrderItem = async () => {
        if (orderItem && orderItem.length === 0) {
            let order_id = localStorage.getItem(CONSTANT.ORDER_ID);
            if (order_id) await dispatch(ProductAction.getOrderItem(order_id));
        }
    }

    useEffect(() => {
        // Load cây danh mục sản phẩm
        const loadTreeCaetegory = async () => {
            await dispatch(ProductAction.getTreeCategory());
        }
        // Load danh mục dịch vụ
        const loadServiceCategories = async () => {
            await dispatch(ServiceAction.getServiceCategory({ perPage: -1, is_active: true, is_parent: true, is_deleted: false, sort: `{"order": 1}` }));
        }
        // Load loại cơ sở y tế
        const loadTypeHospital = async () => {
            await dispatch(HospitalAction.getTypes({ perPage: -1, is_active: true }));
        }
        // Load chuyên khoa
        const loadSpecialties = async () => {
            await dispatch(HospitalAction.getSpecialties({ perPage: -1, is_active: true, sort: `{"order": 1}` }))
        }
        loadTreeCaetegory();
        //loadDoctorCategories();
        loadServiceCategories();
        loadTypeHospital();
        loadSpecialties();

        // load order item
        loadOrderItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const logOut = () => {
        cookie.remove(CONSTANT.STORAGE_TOKEN, { path: '/' });
        cookie.remove(CONSTANT.STORAGE_REFRESH_TOKEN, { path: '/' });
        localStorage.removeItem(CONSTANT.STORAGE_USER);
        window.location.reload();
    }

    // Menu mục tài khoản
    const menuAccount = (
        <React.Fragment>
            {
                currentUser && currentUser._id &&
                <Menu>
                    <Menu.Item icon={<SettingOutlined />} key="1">
                        <Link to="/thong-tin-tai-khoan">{t('account')}</Link>
                    </Menu.Item>
                    <Menu.Item icon={<OrderedListOutlined />} key="2">
                        <Link to="/don-hang-cua-ban">{t('your_order')}</Link>
                    </Menu.Item>
                    <Menu.Item onClick={logOut} icon={<LogoutOutlined />} key="3">{t('logout')}</Menu.Item>
                </Menu>
            }
        </React.Fragment>
    );
    const changeLanguage = (e) => {
        if (e?.key) {
            //setLanguage(e.key);
            cookie.save('VLANG', e.key, { path: '/' });
            window.location.reload();
        }
    }

    return (
        <div className="wrap-header">

            <div className="container">
                <div className="header">
                    {/* Begin topbar only display on mobile */}
                    <Row justify="space-between" className="d-flex d-sm-none topbar">
                        <Col className="hotline">
                            Hotline: <a href="tel:+84818889668">(+84) 818 889 668</a>
                        </Col>
                        {/*<Col className='block-end'>
                            <Dropdown
                                overlay={
                                    <Menu onClick={changeLanguage}>
                                        <Menu.Item key="vi"><img className='icon-flag-menu' src={`${CONSTANT.STATIC_PATH}images/vi.png`} /> Tiếng Việt</Menu.Item>
                                        <Menu.Item key="en"><img className='icon-flag-menu' src={`${CONSTANT.STATIC_PATH}images/en.png`} /> English</Menu.Item>
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button className='language mobile'>
                                    {
                                        getLanguage() === 'vi' ?
                                            <React.Fragment>
                                                <img className='icon-flag' src={`${CONSTANT.STATIC_PATH}images/vi.png`} /> Vi <DownOutlined />
                                            </React.Fragment>
                                            :
                                            <>
                                                <img className='icon-flag' src={`${CONSTANT.STATIC_PATH}images/en.png`} /> En <DownOutlined />
                                            </>
                                    }
                                </Button>
                            </Dropdown>
                                </Col>*/}
                    </Row>

                    <Row gutter={8} align="middle" justify="space-between">
                        <Col lg={4}>
                            <Link to="/" title="Vivmedic.com">
                                <img src={`${CONSTANT.STATIC_PATH}images/logo-vivmedic.png`} alt="Vivmedic Logo" className="logo" />
                            </Link>
                        </Col>

                        {/* Begin icon open menu on mobile, only display on mobile */}
                        <Col lg={0}>
                            <AlignRightOutlined className="icon-toggle-menu" onClick={() => setOpenSideMenu(state => !state)} />
                        </Col>
                        {/* End icon open menu on mobile, only display on mobile */}

                        <Col xs={0} lg={5} className="search d-none d-sm-block">
                            <Input.Search placeholder={t('search')} />
                        </Col>
                        <Col xs={0} lg={5} className="hotline">
                            Hotline: <a href="tel:+84818889668">(+84) 818 889 668</a>
                        </Col>
                        <Col xs={0} lg={3} className="block-end pl-0 pr-0">
                            <Link to="/cart" className="btn-cart">
                                <ShoppingCartOutlined className="icon-cart" />
                                <span className="text-cart">{t('cart')}</span>
                                <span className="badge">{orderItem.length}</span>
                            </Link>
                        </Col>
                        {/*<Col xs={0} lg={2} className='block-end'>
                            <Dropdown
                                overlay={
                                    <Menu onClick={changeLanguage}>
                                        <Menu.Item key="vi"><img className='icon-flag-menu' src={`${CONSTANT.STATIC_PATH}images/vi.png`} /> Tiếng Việt</Menu.Item>
                                        <Menu.Item key="en"><img className='icon-flag-menu' src={`${CONSTANT.STATIC_PATH}images/en.png`} /> English</Menu.Item>
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button className='language'>
                                    {
                                        getLanguage() === 'vi' ?
                                            <React.Fragment>
                                                <img className='icon-flag' src={`${CONSTANT.STATIC_PATH}images/vi.png`} /> Vi <DownOutlined />
                                            </React.Fragment>
                                            :
                                            <>
                                                <img className='icon-flag' src={`${CONSTANT.STATIC_PATH}images/en.png`} /> En <DownOutlined />
                                            </>
                                    }
                                </Button>
                            </Dropdown>
                                </Col>*/}
                        <Col xs={0} lg={3} className="block-end">
                            {/*  ===== Begin User ==== */}
                            <Dropdown overlay={menuAccount} placement="bottomRight" arrow>
                                <div className="user rounded-pill">

                                    {
                                        currentUser && currentUser._id ? (
                                            <>
                                                {
                                                    currentUser.avatar ?
                                                        <Avatar size={30} src={<img alt="avatar-user" src={COMMON.getImage(currentUser.avatar, 'user')} />} />
                                                        :
                                                        <Avatar style={{ backgroundColor: 'var(--c-primary)' }}>{currentUser.email.charAt(0).toUpperCase()}</Avatar>
                                                }
                                                <span className="name text-truncate">
                                                    {
                                                        currentUser.firstname || currentUser.lastname ? `${currentUser.firstname} ${currentUser.lastname}` : currentUser.email
                                                    }
                                                </span>
                                            </>
                                        )
                                            : (
                                                <>
                                                    <Avatar icon={<UserOutlined />} size={30} />
                                                    <Link to={`/${CONSTANT.SLUG_LOGIN}`} style={{ marginLeft: 8, color: 'var(--c-primary)' }}>{t('login')}</Link>
                                                </>
                                            )
                                    }
                                </div>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>

                {/* Begin menu for Table, PC not display in Mobile */}
                <Row className="d-none d-lg-block">
                    <Col lg={24} className="block-end">
                        <div className="menu">
                            <MenuMain
                                mode="horizontal"
                                categoryProduct={categoryProduct}
                                typeHospitals={typeHospitals}
                                serviceCategories={serviceCategories}
                                specialties={specialties}
                            />
                        </div>
                    </Col>
                </Row>
                {/* End menu for Table, PC not display in Mobile */}

                {/* Begin menu for mobile */}
                <div className={`sidebar-mobile d-block d-md-none ${openSideMenu ? 'open' : ''}`}>
                    <div>
                        <CloseOutlined className="btn-close-menu" onClick={() => setOpenSideMenu(state => !state)} />
                    </div>
                    <div className="search">
                        <Input.Search placeholder="Tìm kiếm" />
                    </div>
                    <MenuMain
                        mode="inline"
                        categoryProduct={categoryProduct}
                        typeHospitals={typeHospitals}
                        serviceCategories={serviceCategories}
                        specialties={specialties}
                        onClick={() => { return setOpenSideMenu(state => !state) }}
                    />
                </div>
            </div>
        </div>
    )
}
PageHeader.serverFetch = async ({ store }) => {
    // Load cây danh mục sản phẩm
    await store.dispatch(ProductAction.getTreeCategory());
    // Load danh mục dịch vụ
    await store.dispatch(ServiceAction.getServiceCategory({ perPage: -1, is_active: true, is_parent: true, is_deleted: false, sort: `{"order": 1}` }));
    // Load loại cơ sở y tế
    await store.dispatch(HospitalAction.getTypes({ perPage: -1, is_active: true }));
    // Load chuyên khoa
    await store.dispatch(HospitalAction.getSpecialties({ perPage: -1, is_active: true, sort: `{"order": 1}` }))
}
export default PageHeader;