import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Select, Button, Form, Empty, message } from 'antd';
import { MedicineBoxOutlined, CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as COMMON from '../../../helper/common';
import ModalBookDoctor from './modal-book-doctor';
import ModalBookDoctorOnline from './modal-book-doctor-online';

const ItemDoctor = (props) => {
    const [form] = Form.useForm();

    const [calendars, setCalendars] = useState([]);
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedTime, setSelectedTime] = useState({});
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [selectedService, setSelectedService] = useState({});

    const [isModalOnlineVisible, setIsModalOnlineVisible] = useState(false);

    useEffect(() => {

        if (props?.data?.doctor?.services?.length > 0) {
            let services = props.data.doctor.services;
            setSelectedService(services[0]);

            form.setFieldsValue({
                service: services[0]._id
            });
            // sort calendar
            sortCalendar(services[0]?.calendars);
        }

    }, [props, form]);

    // sắp xếp lại calendar
    const sortCalendar = (_calendars) => {
        if (_calendars.length > 0) {
            // sort calendar
            let currentDate = moment(new Date()).format('YYYY-MM-DD');
            let newCalendars = _calendars.filter(c => moment(currentDate).isSameOrBefore(moment(`${c.year}-${c.month}-${c.day}`, 'YYYY-MM-DD')));
            newCalendars.sort((a, b) => (a.month > b.month) ? 1 : (a.month === b.month) ? ((a.day > b.day) ? 1 : -1) : -1);
            setCalendars(newCalendars);
            if (newCalendars.length > 0) {
                let calendar = { ...newCalendars[0] };
                let calendarTimes = [...calendar?.times];
                calendarTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
                setSelectedTimes(calendarTimes);
                setSelectedDate({ ...calendar });
                form.setFieldsValue({ date: calendar._id });
            }
            else {
                setSelectedTimes([]);
                form.setFieldsValue({ date: null });
            }

        }
    }

    // Xử lý sự kiện khi chọn ngày
    const onChangeDate = (value, option) => {
        if (value) {
            let calendar = JSON.parse(option.key);
            getTimes(calendar);
            setSelectedDate({ ...calendar });
        }
    }
    const getTimes = (calendar) => {
        if (calendar) {
            let arrTimes = [];
            let _localtion = props?.hospital?._id;
            if (_localtion) {
                if (calendar?.times?.length > 0) {
                    for (const time of calendar.times) {
                        if (time?.address === _localtion)
                            arrTimes.push({ ...time });
                    }
                }
                else {
                    message.warning('Tạm thời chưa có lịch khám', 3);
                }
            }
            else {
                message.warning('Vui lòng chọn dịch vụ khám và điều trị để xem thông tin thời gian khám.', 3);
            }
            arrTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
            setSelectedTimes(arrTimes);
        }
    }

    const bookService = data => () => {
        if (!selectedDate?._id) {
            return message.warning('Vui lòng chọn ngày khám');
        }
        else {
            setIsModalOnlineVisible(true);
            setSelectedTime({ ...data })
        }

    }
    // Xử lý sự kiện chọn chuyên khoa
    const onSelectSpecialty = (value) => {
        if (value && props?.data?.doctor?.services?.length > 0) {
            let services = props.data.doctor.services.filter(item => item._id === value);
            if (services.length > 0) {
                let service = services[0];
                sortCalendar(service.calendars);
                setSelectedService(service);
            }
        }
    }
    return (

        <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 24 }]} className="item">
            <Col xs={8} sm={6} lg={3}>
                <div className="box-cover">
                    <img
                        src={COMMON.getImage(props.data.doctor.image, 'doctor')}
                        alt={COMMON.createDoctorName(props.data.doctor.academic_rank, props.data.doctor.degree, props.data.doctor.fullname)}
                    />
                </div>
                <Link to={COMMON.createLinkDoctorDetail(`${props?.data?.doctor?.academic_rank || ''} ${props?.data?.doctor?.degree || ''} ${props?.data?.doctor?.category?.short_name || ''} ${props.data.doctor.slug}`, props.data.doctor.uid)} title="Xem thông tin chi tiết" className="btn-view-detail" size="small">Xem thêm</Link>
            </Col>

            <Col xs={16} sm={18} lg={10} className="col-info">
                <div className="title">{props.data.doctor.fullname}</div>
                <h3 className="degree">{COMMON.createDoctorName(props.data.doctor.academic_rank, props.data.doctor.degree, props.data.doctor.category?.short_name)}</h3>
                {
                    props.data?.regency && <div className="regency">Chức vụ: {props.data?.regency}</div>
                }
                <div className="description" dangerouslySetInnerHTML={{ __html: `${props.data.doctor.working_process && COMMON.splitString(props.data.doctor.working_process?.replace(/(?:\r\n|\r|\n)/g, '<br />'), 50)}` }}></div>

            </Col>

            <Col xs={24} lg={11}>
                <div className="text-uppercase header-calendar">
                    <MedicineBoxOutlined /> Đặt lịch khám
                </div>

                {
                    props.data.doctor.services?.length > 0 ? (
                        <React.Fragment>
                            <Form
                                name="basic"
                                layout="vertical"
                                onFinishFailed={() => { }}
                                form={form}
                            >
                                <Form.Item
                                    label="Dịch vụ khám và điều trị" name="service" required tooltip="Vui lòng chọn trường này"
                                    rules={[{ required: true, message: 'Vui lòng chọn địa điểm khám bệnh' }]}
                                >
                                    <Select placeholder="Chọn dịch vụ khám và điều trị" className="select-global" size='large'
                                        onChange={onSelectSpecialty}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
                                    >
                                        {
                                            props.data.doctor.services.length > 0 &&
                                            props.data.doctor.services.map(item => (
                                                <Select.Option key={item._id} value={item._id}>{item?.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                {
                                    selectedService.is_online &&
                                    <div style={{ color: '#318fb5', fontWeight: '700', marginBottom: '10px' }}><VideoCameraOutlined /> Tư vấn từ xa qua video</div>
                                }

                                {
                                    selectedService?.price > 0 &&
                                    <div className="price">
                                        <strong style={{ color: '#333' }}>Giá:</strong> {COMMON.currencyFormat(selectedService.price)} {COMMON.getCurrency('VND')}
                                    </div>
                                }

                                {
                                    selectedService?.price_note &&
                                    <div style={{ fontStyle: 'italic', color: "#777", fontSize: '13px', marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: `${selectedService?.price_note.replace(/(?:\r\n|\r|\n)/g, '<br />')}` }}></div>
                                }


                                <Form.Item label="Chọn ngày"
                                    name="date"
                                    rules={[{ required: true, message: 'Vui lòng chọn ngày khám bệnh' }]}
                                >
                                    <Select
                                        placeholder="Chọn ngày"
                                        className="select-global select-date"
                                        size='large'
                                        onChange={onChangeDate}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
                                    >
                                        {
                                            calendars?.length > 0 &&
                                            calendars.map(c => (
                                                <Select.Option key={JSON.stringify(c)} value={c._id}>{`${COMMON.getDayOfWeek(c.day_of_week.toString())} - ${c.day}/${c.month}/${c.year}`}</Select.Option>
                                            ))
                                        }

                                    </Select>
                                </Form.Item>
                            </Form>
                            <Row className="group-btn-time" gutter={[{ xs: 16, md: 16 }, { xs: 16, md: 16 }]}>
                                {
                                    selectedTimes.length > 0 && selectedTimes.map(time => (
                                        <Col xs={12} md={8} key={time._id}>

                                            <Button className="btn-time"
                                                type="primary"
                                                disabled={
                                                    time.is_order || (
                                                        moment(new Date(), "YYYY-MM-DD").isSameOrAfter(moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`, "YYYY-MM-DD")) &&
                                                        moment(new Date(), "HH:mm").isSameOrAfter(moment(`${time.time_h}:${time.time_m}`, "HH:mm"))
                                                    )
                                                }
                                                key={time._id} shape="round" icon={selectedService.is_online ? <VideoCameraOutlined /> : <CalendarOutlined />}
                                                onClick={bookService(time)}
                                            >
                                                {moment(`${time.time_h}:${time.time_m}`, "HH:mm").format("HH:mm")} - {COMMON.calculateTime(`${time.time_h}:${time.time_m}`, time.duration)}
                                            </Button>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </React.Fragment>
                    )
                        :
                        <div>Bác sĩ chưa có lịch khám</div>
                }

            </Col>

            {
                !selectedService?.is_online ?
                    <ModalBookDoctor
                        visible={isModalOnlineVisible}
                        cancel={() => setIsModalOnlineVisible(false)}
                        data={selectedService}
                        dataDoctor={props.data.doctor}
                        date={selectedDate}
                        time={selectedTime}
                        location={{
                            address: props.hospital.address,
                            name: props.hospital.name
                        }}
                    />
                    :
                    <ModalBookDoctorOnline
                        visible={isModalOnlineVisible}
                        cancel={() => setIsModalOnlineVisible(false)}
                        data={selectedService}
                        dataDoctor={props.data.doctor}
                        date={selectedDate}
                        time={selectedTime}
                    />
            }


        </Row>

    )
}

export default ItemDoctor;