import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Row, Col, Image, Tabs, Spin, Empty, message, Button } from 'antd';
import { EnvironmentFilled, MedicineBoxOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';

// import common
import * as COMMON from '../../helper/common';
import { DoctorAction } from '../../store/root-action';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';
import ItemServiceOnline from './components/item-service-online';
import ItemService from './components/item-service';
import Review from '../../component/review';


const DoctorDetail = () => {
	const dispatch = useDispatch();
	let history = useHistory();
	const { id } = useParams();
	const [isOnline, setIsOnline] = useState(false);
	const [isOffline, setIsOffline] = useState(false);
	const [activeTabKey, setActiveTabKey] = useState('medic')
	const refTab = useRef(null);

	// get doctor
	const detail = useSelector(state => state.doctorReducer.detail);
	const error = useSelector(state => state.doctorReducer.error);
	const loading = useSelector(state => state.doctorReducer.loading);

	useEffect(() => {
		// load detail
		const loadDetail = async () => {
			await dispatch(DoctorAction.getDetail(id));
		}
		loadDetail();
	}, [dispatch, id]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		// check xem bác sĩ có dịch vụ khám online hay không
		if (detail?._id) {
			if (detail?.services?.length > 0) {
				let flagIsOnline = false;
				let flagIsOfline = false;
				for await (const service of detail.services) {
					if (service.is_online) {
						flagIsOnline = true;
					}
					else {
						flagIsOfline = true;
					}
				}
				setIsOnline(flagIsOnline);
				setIsOffline(flagIsOfline);
			}
		}
	}, [detail]);

	useEffect(() => {
		if (error !== false) {
			message.warning('Bác sĩ chuyên khoa không tồn tại!').then(() => history.push(`/${CONSTANT.SLUG_DOCTOR}`));
		}
	}, [error, history]);

	const onChangeTab = (key) => {
		setActiveTabKey(key);
		window.scrollTo({
			top: refTab.current.offsetTop,
			behavior: "smooth",
		})
	}


	return (
		<React.Fragment>
			{
				detail?._id &&
				<MetaSeo
					title={`${COMMON.createDoctorName(detail.academic_rank, detail.degree, detail?.category?.short_name)} ${detail.fullname}`}
					description={detail?.description && COMMON.splitString(detail?.description.replace(/<[^>]+>/g, ''), 50)}
					keyword={`${COMMON.createDoctorName(detail.academic_rank, detail.degree, detail?.category?.short_name)} ${detail.fullname}, Đội ngũ chuyên môn, bác sĩ, y tá, điều dưỡng, y sĩ, hộ sinh, kỹ thuật viên, ${CONSTANT.PAGE_KEYWORD_EXT}`}
					image={detail?.image && COMMON.getImage(detail.image, 'doctor')}
					type="article"
				/>
			}

			<Spin spinning={loading}>
				{/* Begin breadcrumb */}
				<div className="breadcrumb">
					<div className="container">
						<Breadcrumb>
							<Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
							<Breadcrumb.Item><Link to={`/${CONSTANT.SLUG_DOCTOR}`}>Đội ngũ chuyên môn</Link></Breadcrumb.Item>
							<Breadcrumb.Item>{detail?._id && COMMON.createDoctorName(`${detail?.academic_rank || ''}`, `${detail?.degree || ''}`, `${detail?.category?.short_name} ${detail?.fullname}`)}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
				{/* Begin doctor detail */}
				<div className="container">
					{
						detail?._id &&
						<div className="doctor-detail">
							<Row gutter={[{ xs: 0, lg: 48 }, { xs: 0, lg: 48 }]}>
								<Col xs={24} lg={7}>
									<div className="box-avatar">
										<Image
											className="avatar"
											src={COMMON.getImage(detail.image, 'doctor')}
											alt={detail.slug}
											preview={false}
										/>
									</div>
								</Col>
								<Col xs={24} lg={17}>
									<div className="doctor-name">{COMMON.createDoctorName(detail.academic_rank, detail.degree, detail?.category?.short_name)} {detail.fullname}</div>
									<Row justify="center" align="middle" className="info">
										<Col xs={8} md={5} className="fw-medium title"><EnvironmentFilled /></Col>
										<Col xs={16} md={19}>{detail?.city?.name}</Col>

										<Col xs={8} md={5} className="fw-medium title">Chuyên khoa:</Col>
										<Col xs={16} md={19}>
											{detail?.specialties?.length > 0 ? detail.specialties.map(elem => elem.name).join(', ') : 'Chưa xác định'}
										</Col>
										{
											detail?.practicing_certificate &&
											<>
												<Col xs={8} md={5} className="fw-medium title">Số CCHN:</Col>
												<Col xs={16} md={19}>{detail?.practicing_certificate}</Col>
											</>
										}
										<Col xs={8} md={5} className="fw-medium title">Hiện công tác tại:</Col>
										<Col xs={16} md={19}>{detail?.home_office}</Col>
									</Row>
									<div className="group-btn-book">
										{
											isOffline && <Button className="btn-global bg btn-book-now" onClick={() => onChangeTab('medic')} icon={<MedicineBoxOutlined />} style={{ marginRight: '10px' }}>Đặt lịch khám và điều trị</Button>
										}
										{
											isOnline && <Button className="btn-global bg btn-book-now right" onClick={() => onChangeTab('online')} icon={<VideoCameraAddOutlined />}>Đặt lịch Tư vấn từ xa</Button>
										}
									</div>

									{/*
                                    <div className="specialty">
                                        <Avatar size={50} />
                                        Post bởi <Link to='/' style={{marginLeft:10}}> maitram.pham</Link>
                                    </div>
                                */}
								</Col>
							</Row>
							<div ref={refTab}>
								<Tabs type="card" size="large" className="tabs-detail"
									activeKey={activeTabKey}
									onChange={onChangeTab}
									destroyInactiveTabPane={true}
								>
									<Tabs.TabPane tab="Giới thiệu" key="about">
										<div className="about">

											{
												detail.training_process &&
												<>
													<div className="fw-bold head">Quá trình đào tạo</div>
													{parse(detail.training_process)}
												</>
											}
											{
												detail.working_process &&
												<>
													<div className="fw-bold head">Quá trình công tác</div>
													{parse(detail.working_process)}
												</>
											}
											{
												detail?.services?.length > 0 &&
												<>
													<div className="fw-bold head">Khám và điều trị</div>
													<div style={{ marginTop: '20px', marginBottom: '20px' }}>
														{
															isOffline && <Button className="btn-global bg btn-book-now" onClick={() => onChangeTab('medic')} icon={<MedicineBoxOutlined />}>Đặt lịch khám và điều trị</Button>
														}
														{
															isOnline && <Button className="btn-global bg btn-book-now right" onClick={() => onChangeTab('online')} icon={<VideoCameraAddOutlined />}>Đặt lịch Tư vấn từ xa</Button>
														}

													</div>
													{
														detail.services.map(item => (
															<React.Fragment key={item._id}>
																<div className="fw-bold head-sub">{item.name}</div>
																{item?.summary && parse(item?.summary?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
															</React.Fragment>
														))
													}
												</>
											}

										</div>
									</Tabs.TabPane>
									{
										isOffline &&
										<Tabs.TabPane tab="Khám và Điều trị" key="medic">
											<div className="service">
												{
													detail?.services?.length > 0 ? detail.services.map(item => (
														<React.Fragment key={item._id}>
															{
																item.is_online === false &&
																<ItemService
																	dataDoctor={{
																		_id: detail._id, created_by: detail.created_by, uid: detail.uid,
																		name: `${COMMON.createDoctorName(detail.academic_rank, detail.degree, detail?.category?.short_name)} ${detail.fullname}`,
																		addresses: detail?.addresses
																	}}
																	data={item}
																/>
															}
														</React.Fragment>

													))
														:
														<Empty description="Chưa có thông tin" />
												}


											</div>
										</Tabs.TabPane>
									}

									{
										isOnline &&
										<Tabs.TabPane tab="Tư vấn từ xa" key="online">
											<div className="service">

												{
													detail?.services?.length > 0 ? detail.services.map(item =>
														<ItemServiceOnline
															data={item} key={item._id}
															dataDoctor={{
																_id: detail._id, created_by: detail.created_by, email: detail?.email, uid: detail.uid,
																name: `${COMMON.createDoctorName(detail.academic_rank, detail.degree, detail?.category?.short_name)} ${detail.fullname}`
															}}
														/>
													)
														:
														<Empty description="Chưa có thông tin" />
												}

											</div>
										</Tabs.TabPane>
									}

								</Tabs>
								{/* begin modal */}
							</div>
						</div>
					}

					{ /* Begin review */}
					{
						detail?._id && <Review content_id={detail?._id} type="doctor" />
					}
				</div>
			</Spin>

		</React.Fragment>
	)
}

DoctorDetail.serverFetch = async ({ store, params }) => {
	await store.dispatch(DoctorAction.getDetail(params.id));
}

export default DoctorDetail;