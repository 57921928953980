import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row, Col, Breadcrumb, Avatar, Modal, Card, Form, Input,
    Select, Button, DatePicker, Upload, message, ConfigProvider
} from 'antd';
import ImgCrop from 'antd-img-crop';
import cookie from 'react-cookies';
import { SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CONSTANT from '../../../config/constant';
import * as Services from "../../../store/services";
// import component
import AccountMenu from './components/menu';
import './account.css';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 6 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 16 },
        sm: { span: 10 },
    },
};

const Profile = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState();
    const [dob, setDob] = useState();
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });
    const [avatarUrl, setAvatarUrl] = useState();
    const [email, setEmail] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);
    const beforeUploadAvatar = async file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ được tải ảnh JPG/PNG!');
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            message.error('Hình ảnh phải nhỏ hơn 1MB!');
        }
        return isJpgOrPng && isLt1M;
    }
    const onChange = date => {
        setDob(date);
    }
    const changeUploadAvatar = async info => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            const { response } = info.file;
            if (response && response.code === 200) {
                await Services.post(`${CONSTANT.API.USER.SAVE_AVATAR}`, {
                    filename: response.data.filename,
                    type: "user"
                }).then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        let dataSave = {
                            avatar: res.data.data.url
                        }
                        await Services.patch(`${CONSTANT.API.USER.UPDATE}/${userId}`, dataSave,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': cookie.load(CONSTANT.STORAGE_TOKEN)
                                }
                            })
                            .then(res1 => {
                                if (res1 && res1.data && res1.data.code === 200) {
                                    setAvatarUrl(res.data.data.url);
                                    localStorage.setItem(CONSTANT.STORAGE_USER, JSON.stringify(res1.data.data));
                                    message.success('Cập nhật ảnh đại diện thành công!');
                                }
                                else {
                                    message.error('Cập nhật ảnh đại diện thất bại! Vui lòng thử lại sau.');
                                }
                            });
                    }
                });
            }
        }
    }
    // load User    
    const loadUser = async () => {
        setLoading(true);
        let currentUser = JSON.parse(localStorage.getItem(CONSTANT.STORAGE_USER));
        if (currentUser) {
            setUserId(currentUser._id);
            setAvatarUrl(currentUser.avatar);
            setEmail(currentUser.email);
            setPhones({
                phone: (currentUser.dial_code && currentUser.dial_code + currentUser.phone) || '',
                dial_code: (currentUser.dial_code && Number(currentUser.dial_code)) || '',
            });
            setDob(moment(currentUser.dob));
            form.setFieldsValue({
                firstname: currentUser.firstname,
                lastname: currentUser.lastname,
                sex: currentUser.sex,
            });
            setLoading(false);
        }
        else {
            window.location.href = '/';
        }
    }
    // effect
    useEffect(() => {
        loadUser();
    }, []);
    // submit
    const onFinish = async (values) => {
        values.dob = dob;
        values.phone = phones.phone.replace(phones.dial_code, '');
        values.dial_code = phones.dial_code;
        values.country_code = phones.country_code;
        await Services.patch(`${CONSTANT.API.USER.UPDATE}/${userId}`, values, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookie.load(CONSTANT.STORAGE_TOKEN)
            }
        })
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Sửa thông tin thành công!');
                    localStorage.setItem(CONSTANT.STORAGE_USER, JSON.stringify(res.data.data));
                }
                else {
                    message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                }
            });
    };
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Thông tin tài khoản</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                <div className="page-profile">
                    <Row gutter={32}>
                        <Col xs={24} lg={7} className="col-left">
                            <AccountMenu data={{ avatar: avatarUrl, email: email, active: 'hs' }} />
                        </Col>
                        <Col xs={24} lg={17}>
                            <Card title={
                                <>
                                    <div>Hồ sơ của tôi</div>
                                    <div style={{ fontSize: 14, fontWeight: 400 }}>Quản lý thông tin hồ sơ để bảo mật tài khoản</div>
                                </>
                            }>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {/* begin form info */}
                                    <Col xs={24} md={16}>
                                        <Form
                                            {...formItemLayout} form={form} onFinish={onFinish}                >
                                            <Form.Item name="firstname" label="Họ"
                                                rules={[{ required: true, message: "Họ không được để trống" }, {
                                                    type: 'string', max: 50, message: "Họ không quá 100 ký tự"
                                                },]}>
                                                <Input placeholder="Nhập họ" className='form-global' />
                                            </Form.Item>
                                            <Form.Item name="lastname" label="Tên" rules={[{ required: true, message: "Tên không được để trống" }, {
                                                type: 'string', max: 30, message: "Tên không quá 30 ký tự"
                                            },]}>
                                                <Input placeholder="Nhập tên" className='form-global' />
                                            </Form.Item>
                                            <Form.Item
                                                name="sex"
                                                label="Giới tính"
                                                rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
                                            >
                                                <Select placeholder="Chọn giới tính" className='form-global'>
                                                    <Select.Option value="male">Nam</Select.Option>
                                                    <Select.Option value="female">Nữ</Select.Option>
                                                    <Select.Option value="other">Khác</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Ngày sinh">
                                                <ConfigProvider>
                                                    <DatePicker
                                                        className="ant-picker-input"
                                                        format="DD/MM/YYYY"
                                                        defaultValue={moment()}
                                                        onChange={onChange}
                                                        value={dob} />
                                                </ConfigProvider>
                                            </Form.Item>
                                            <Form.Item label="Số điện thoại">
                                                <PhoneInput
                                                    country='vn'
                                                    value={phones.phone}
                                                    onChange={(value, data) => {
                                                        setPhones({
                                                            phone: value,
                                                            dial_code: data.dialCode,
                                                            country_code: data.countryCode
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                                <Button type="primary" icon={<SaveOutlined />}
                                                    htmlType="submit"
                                                >
                                                    Lưu thông tin
                                                </Button>
                                            </Form.Item>

                                        </Form>
                                    </Col>
                                    {/* Begin col avatar */}
                                    <Col xs={24} md={8} className="text-center" style={{ borderLeft: '1px solid #e5e5e5' }}>
                                        <div style={{ marginBottom: 20 }}>
                                            <Avatar
                                                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                                src={`${CONSTANT.STATIC_URL}${avatarUrl}`}
                                            />
                                        </div>
                                        <ImgCrop
                                            rotate
                                            modalTitle="Sửa ảnh"
                                            modalOk="Đồng ý"
                                            aspect={800 / 600}
                                            quality={0.8}
                                            modalWidth={800}
                                        >
                                            <Upload
                                                name="file"
                                                action={`${CONSTANT.API.USER.UPLOAD_IMAGE}`}
                                                showUploadList={false}
                                                beforeUpload={beforeUploadAvatar}
                                                onChange={changeUploadAvatar}
                                                headers={{
                                                    'X-TK': CONSTANT.API.KEY
                                                }}
                                            >
                                                <Button>
                                                    {loading ? <LoadingOutlined /> : 'Chọn ảnh'}
                                                </Button>
                                            </Upload>
                                        </ImgCrop>
                                        <Modal
                                            visible={previewVisible}
                                            footer={null}
                                            onCancel={() => setPreviewVisible(false)}>
                                        </Modal>

                                        <div style={{ color: '#999', fontSize: 13, marginTop: 10 }}>
                                            Dung lượng file tối đa 1MB <br />
                                            Định dạng:.JPEG, .PNG
                                        </div>
                                    </Col>
                                </Row>
                            </Card>

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}
export default Profile;
