import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { getLanguage, useTranslation } from 'react-multi-lang';
import {
    DownOutlined
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';

const MenuMain = ({ categoryProduct, typeHospitals, serviceCategories, mode, onClick, specialties }) => {
    const t = useTranslation();
    let propsLink = {}
    if (mode === 'inline') {
        propsLink.onClick = onClick;
    }

    // Tạo menu danh mục mua sắm y tế
    const menuProduct = (
        <Menu.SubMenu
            popupClassName="menu-poup" key="vtyt"
            title={<Link {...propsLink} to={`#`} title={t('medical_shop')} className="text-uppercase lv1">{t('medical_shop')} <DownOutlined className="icon-drop" /></Link>}
            className="lv1"
        >
            <Menu.Item className="lv2" key="vtyt1">
                <a rel="noreferrer" href='https://www.pharmacity.vn/duoc-pham?utm_source=tatinta' title='Dược phẩm' target='_blank'>Dược phẩm</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt2">
                <a rel="noreferrer" href='https://www.pharmacity.vn/cham-soc-suc-khoe?utm_source=tatinta' title='Chăm sóc sức khỏe' target='_blank'>Chăm sóc sức khỏe</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt3">
                <a rel="noreferrer" href='https://www.pharmacity.vn/cham-soc-ca-nhan?utm_source=tatinta' title='Chăm sóc cá nhân' target='_blank'>Chăm sóc cá nhân</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt4">
                <a rel="noreferrer" href='https://www.pharmacity.vn/san-pham-tien-loi?utm_source=tatinta' title='Sản phẩm tiện lợi' target='_blank'>Sản phẩm tiện lợi</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt5">
                <a rel="noreferrer" href='https://www.pharmacity.vn/thuc-pham-chuc-nang?utm_source=tatinta' title='Thực phẩm chức năng' target='_blank'>Thực phẩm chức năng</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt6">
                <a rel="noreferrer" href='https://www.pharmacity.vn/me-va-be?utm_source=tatinta' title='Mẹ và bé' target='_blank'>Mẹ và bé</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt7">
                <a rel="noreferrer" href='https://www.pharmacity.vn/cham-soc-sac-dep?utm_source=tatinta' title='Chăm sóc sắc đẹp' target='_blank'>Chăm sóc sắc đẹp</a>
            </Menu.Item>
            <Menu.Item className="lv2" key="vtyt8">
                <a rel="noreferrer" href='https://www.pharmacity.vn/thiet-bi-y-te-2?utm_source=tatinta' title='Thiết bị y tế' target='_blank'>Thiết bị y tế</a>
            </Menu.Item>
        </Menu.SubMenu>
    )
    // const menuProduct = (
    //     <Menu.SubMenu
    //         popupClassName="menu-poup" key="vtyt"
    //         title={<Link {...propsLink} to={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}`} title={t('medical_shop')} className="text-uppercase lv1">{t('medical_shop')} <DownOutlined className="icon-drop" /></Link>}
    //         className="lv1"
    //     >
    //         {
    //             categoryProduct && categoryProduct.map(item_lv1 => (
    //                 <Menu.SubMenu
    //                     popupClassName="menu-poup" key={item_lv1._id}
    //                     title={(<Link {...propsLink} title={item_lv1.name} to={COMMON.createLinkProductCategory(item_lv1.slug, item_lv1.uid)}>{item_lv1.name}</Link>)}
    //                     className="lv2"
    //                 >
    //                     {
    //                         item_lv1.childs && item_lv1.childs.length > 0 ? item_lv1.childs.map(item_lv2 => (
    //                             <React.Fragment key={item_lv2._id}>
    //                                 {
    //                                     item_lv2.childs && item_lv2.childs.length > 0 ? (
    //                                         <Menu.SubMenu
    //                                             popupClassName="menu-poup" key={item_lv2.uid}
    //                                             title={(<Link {...propsLink} title={item_lv2.name} to={COMMON.createLinkProductCategory(item_lv2.slug, item_lv2.uid)}>{item_lv2.name}</Link>)}
    //                                             className="lv3"
    //                                         >
    //                                             {
    //                                                 item_lv2.childs.map(item_lv3 => (
    //                                                     <Menu.Item key={item_lv3.uid} className="lv4">
    //                                                         <Link {...propsLink} to={COMMON.createLinkProductCategory(item_lv3.slug, item_lv3.uid)} title={item_lv3.name}>{item_lv3.name}</Link>
    //                                                     </Menu.Item>
    //                                                 ))
    //                                             }
    //                                         </Menu.SubMenu>
    //                                     )
    //                                         :
    //                                         <Menu.Item key={item_lv2.uid} className="lv3">
    //                                             <Link {...propsLink} to={COMMON.createLinkProductCategory(item_lv2.slug, item_lv2.uid)} title={item_lv2.name}>{item_lv2.name}</Link>
    //                                         </Menu.Item>
    //                                 }

    //                             </React.Fragment>
    //                         ))
    //                             :
    //                             <Menu.Item key={item_lv1.uid}>
    //                                 <Link {...propsLink} to="/" title={item_lv1.name}>{item_lv1.name}</Link>
    //                             </Menu.Item>
    //                     }
    //                 </Menu.SubMenu>
    //             ))
    //         }

    //     </Menu.SubMenu>
    // )

    // Tạo menu danh mục dịch vụ y tế
    const menuServiceMedical = (
        <Menu.SubMenu
            popupClassName="menu-poup" key="serviceMedical"
            title={<Link {...propsLink} to={`/${CONSTANT.SLUG_SERVICE}`} title="Dịch vụ y tế" className="text-uppercase lv1">Dịch vụ y tế <DownOutlined className="icon-drop" /></Link>}
            className="lv1"
        >
            {
                serviceCategories && serviceCategories.map(item_lv1 => (
                    <React.Fragment key={item_lv1._id}>
                        {
                            item_lv1.childs && item_lv1.childs.length > 0 ?
                                <Menu.SubMenu
                                    popupClassName="menu-poup" key={item_lv1._id}
                                    title={(<Link {...propsLink} title={item_lv1.name} to={COMMON.createLinkServiceCategory(item_lv1.slug, item_lv1.uid)}>{item_lv1.name}</Link>)}
                                    className="lv2"
                                >
                                    {
                                        item_lv1.childs.map(item_lv2 => (
                                            <React.Fragment key={item_lv2._id}>
                                                {
                                                    item_lv2.childs && item_lv2.childs.length > 0 ? (
                                                        <Menu.SubMenu
                                                            popupClassName="menu-poup" key={item_lv2.uid}
                                                            title={(<Link {...propsLink} title={item_lv2.name} to={COMMON.createLinkServiceCategory(item_lv2.slug, item_lv2.uid)}>{item_lv2.name}</Link>)}
                                                            className="lv3"
                                                        >
                                                            {
                                                                item_lv2.childs.map(item_lv3 => (
                                                                    <Menu.Item key={item_lv3.uid} className="lv4">
                                                                        <Link {...propsLink} to={COMMON.createLinkServiceCategory(item_lv3.slug, item_lv3.uid)} title={item_lv3.name}>{item_lv3.name}</Link>
                                                                    </Menu.Item>
                                                                ))
                                                            }
                                                        </Menu.SubMenu>
                                                    )
                                                        :
                                                        <Menu.Item key={item_lv2.uid} className="lv3">
                                                            <Link {...propsLink} to={COMMON.createLinkServiceCategory(item_lv2.slug, item_lv2.uid)} title={item_lv2.name}>{item_lv2.name}</Link>
                                                        </Menu.Item>
                                                }

                                            </React.Fragment>
                                        ))
                                    }
                                </Menu.SubMenu> :
                                <Menu.Item key={item_lv1._id} className="lv2">
                                    <Link {...propsLink} to={COMMON.createLinkServiceCategory(item_lv1.slug, item_lv1.uid)} title={item_lv1.name}>
                                        {item_lv1.name}
                                    </Link>
                                </Menu.Item>
                        }

                    </React.Fragment>
                ))
            }

        </Menu.SubMenu>
    )
    return (
        <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode={mode}
            //className={`menu ${getLanguage() !== 'vi' ? 'lang' : ''}`}
            className='menu'
            forceSubMenuRender={true}
        >
            <Menu.Item key="home" className="lv1">
                <Link to="/"  {...propsLink} title="Trang chủ" className="text-uppercase lv1">{t('home')}</Link>
            </Menu.Item>

            {menuProduct}

            {/* menu bác sĩ chuyên khoa */}
            <Menu.SubMenu
                popupClassName="menu-poup" key="dncm"
                title={
                    <Link
                        {...propsLink} to={`/${CONSTANT.SLUG_DOCTOR}`} title={t('specialist_doctor')}
                        className="text-uppercase lv1">
                        {t('specialist_doctor')} <DownOutlined className="icon-drop" />
                    </Link>
                }
                className="lv1"
            >
                {
                    specialties?.length > 0 &&
                    specialties.map(item => (
                        <Menu.Item key={item._id} className="lv2">
                            <Link {...propsLink} to={COMMON.createLinkDoctor(`spec=${item.slug}`)} title={item.name}>
                                {item.name}
                            </Link>
                        </Menu.Item>
                    ))
                }
            </Menu.SubMenu>

            {/* Begin cơ sở y tế */}
            <Menu.SubMenu
                popupClassName="menu-poup" key="csyt"
                title={
                    <Link {...propsLink} to={`/${CONSTANT.SLUG_FACILITY}`} title={t('medical_facility')}
                        className="text-uppercase lv1">{t('medical_facility')} <DownOutlined className="icon-drop" /></Link>}
                className="lv1"
            >
                {
                    typeHospitals && typeHospitals.map(item => (
                        <Menu.Item key={item._id} className="lv2">
                            <Link {...propsLink} to={`/${CONSTANT.SLUG_FACILITY}?type=${item.slug}`} title={item.name}>
                                {item.name}
                            </Link>
                        </Menu.Item>
                    ))
                }
            </Menu.SubMenu>

            {/* Menu dịch vụ y tế */}
            {menuServiceMedical}

            {/* Begin tư vấn từ xa */}
            <Menu.SubMenu popupClassName="menu-poup" key="kbtx" className="lv1"
                title={<Link {...propsLink} to={`/${CONSTANT.SLUG_MEDICAL_ONLINE}`}
                    title={t('medical_online')} className="text-uppercase lv1">{t('medical_online')} <DownOutlined className="icon-drop" /></Link>}>
                <Menu.Item className="lv2" key="kbtx_free">
                    <Link {...propsLink} to={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/tu-van-mien-phi`} title={t('medical_online_free')}>{t('medical_online_free')}</Link>
                </Menu.Item>
                <Menu.Item className="lv2" key="kbtx_fee">
                    <Link {...propsLink} to={`/${CONSTANT.SLUG_MEDICAL_ONLINE}/tu-van-co-thu-phi`} title={t('medical_online_paid')}>{t('medical_online_paid')}</Link>
                </Menu.Item>
            </Menu.SubMenu>


            {/* Begin thầy thuốc trò chuyện */}
            <Menu.Item className="lv1" key="cnkb">
                <Link {...propsLink} className="text-uppercase lv1" to={`/${CONSTANT.SLUG_HANDBOOK}`} title={t('doctor_talking')}>{t('doctor_talking')}</Link>
            </Menu.Item>

            {
                isMobile &&
                <Menu.Item className="lv1 d-md-none" key="login">
                    <Link  {...propsLink} className="text-uppercase lv1" to={`/${CONSTANT.SLUG_LOGIN}`} title={t('login')}>{t('login')}</Link>
                </Menu.Item>
            }

        </Menu>
    )
}

export default MenuMain;