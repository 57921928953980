import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Spin, Avatar } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-multi-lang';

import * as COMMON from '../../../helper/common';
import CONSTANT from '../../../config/constant';
import * as Service from '../../../store/services';


const SectionDoctor = () => {
    const t = useTranslation();
    const [doctorSpec, setDoctorSpec] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadDoctor = async () => {
            setLoading(true);
            await Service.get(`${CONSTANT.API.DOCTOR.DOCTOR}?perPage=30&page=1&status=publish&is_online=false&is_service=0`)
                .then(res => {
                    if (res?.data?.code === 200) {
                        let doctors = res.data.data.docs;
                        doctors.sort(() => Math.random() - 0.5);
                        setDoctorSpec(doctors);
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                    }
                })
                .catch(err => setLoading(false))
        }
        let mounted = true;
        if (mounted) {
            loadDoctor();
        }

        return () => mounted = false;
    }, [])

    return (
        <React.Fragment>

            <div className="container section-doctor-spec">
                <div className="block-header">
                    <div className="text-uppercase title">{t('specialist_doctor')}</div>
                    <div className="sapo">{t('specialist_doctor_sapo')}</div>
                    <div className="line"></div>
                </div>

                <Spin spinning={loading}>
                    <div className="list-doctor">
                        <Row gutter={[{ xs: 16, lg: 24 }, { xs: 16, lg: 24 }]}>
                            {
                                doctorSpec.length > 0 && doctorSpec.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {
                                            index < 4 &&
                                            <Col xs={12} lg={6} key={item._id}>
                                                <Link to={COMMON.createLinkDoctorDetail(`${item?.academic_rank || ''} ${item?.degree || ''} ${item?.category?.short_name || ''} ${item.slug}`, item.uid)}>
                                                    <Card
                                                        hoverable
                                                        cover={<div className="box-cover"><img alt={`${COMMON.createDoctorName(item.academic_rank, item.degree, item.fullname)}`} src={COMMON.getImage(item.image, 'doctor')} /></div>}
                                                        className="doctor"
                                                    >
                                                        <Card.Meta
                                                            title={
                                                                <>
                                                                    <h2 className="title">{item.fullname}</h2>
                                                                    <h3 className="degree">{COMMON.createDoctorName(item.academic_rank, item.degree, item?.category?.short_name)}</h3>
                                                                </>
                                                            }
                                                        />
                                                        <div className="location">
                                                            <ion-icon name="location-sharp"></ion-icon>
                                                            {item.city && item.city.name}
                                                        </div>
                                                        <div className="specialty">
                                                            <Avatar src={item?.specialties?.length > 0 && COMMON.getImage(item.specialties[0].avatar, 'specialty')} />
                                                            <div className="name">
                                                                {item?.specialties?.length > 0 ? item.specialties.map(elem => elem.name).join(', ') : "Đang cập nhật..."}
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Link>
                                            </Col>
                                        }
                                    </React.Fragment>
                                ))
                            }

                        </Row>
                    </div>

                    <div className="text-end view-more">
                        <Link to={`/${CONSTANT.SLUG_DOCTOR}`} title={t('specialist_doctor_view_more')}>{t('view_more')} <RightOutlined /></Link>
                    </div>
                </Spin>
            </div>
        </React.Fragment>
    )
}

export default SectionDoctor;