import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { Breadcrumb, Spin, Empty, message, Collapse, List, Row, Col, Card, Avatar } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./travel-medical.css";
import { axiosInstance } from "../../config/api";
import { useTranslation } from 'react-multi-lang';

import MetaSeo from "../../component/meta-seo";
import CONSTANT from "../../config/constant";
import * as COMMON from "../../helper/common";

function TravelMedical() {
    const history = useHistory();
    const t = useTranslation();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageSize: 20,
        total: 0,
        hideOnSinglePage: true,
        showLessItems: true,
    });


    useEffect(() => {
        let mounted = true;
        if (mounted) {

        }
        return () => (mounted = false);
    }, []);



    return (
        <React.Fragment>
            <MetaSeo
                title="Du lịch y tế"
                keyword={`du lịch y tế, du lich y te, medical tourism, ${CONSTANT.PAGE_KEYWORD_EXT}`}
                description="Cung cấp dịch vụ y tế cá nhân hoá đồng hành chăm sóc sức khoẻ thể chất và tinh thần cho khách hàng mang lại những trải nghiệm du lịch tuyệt vời và trọn vẹn"
            />

            <div className="page-travel-medical">
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">Trang chủ</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{t('travel_medical')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <div className="container">
                    <div className="block-header">
                        <div className="text-uppercase title">{t('travel_medical')}</div>
                        <div className="line"></div>
                    </div>
                    <div className="list">
                        <Row gutter={[{ xs: 16, lg: 0 }, 24]} justify="center">
                            <Col xs={8} md={8} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/cap-cuu.jpeg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Cấp cứu</h3>}
                                    />
                                    <Link to={`${CONSTANT.SLUG_TRAVEL_MEDICAL}/cap-cuu`} title={`${t('learn_more')} Cấp cứu`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                            <Col xs={8} md={8} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/khoe-dep.jpg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Khỏe & Đẹp</h3>}
                                    />
                                    <Link to={`${CONSTANT.SLUG_TRAVEL_MEDICAL}/khoe-dep`} title={`${t('learn_more')} Khỏe & Đẹp`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                            <Col xs={8} md={8} className='item travel-medical'>
                                <Card
                                    hoverable
                                    cover={
                                        <Avatar className="cover" size={200}
                                            src={`${CONSTANT.STATIC_PATH}images/suc-khoe-tinh-than.jpeg`} />
                                    }
                                    className="service"
                                >
                                    <Card.Meta
                                        title={<h3 className="text-center title">Sức khỏe tinh thần</h3>}
                                    />
                                    <Link to={`${CONSTANT.SLUG_TRAVEL_MEDICAL}/suc-khoe-tinh-than`} title={`${t('learn_more')} Sức khỏe tinh thần`} className="btn-global btn-view bg">Xem</Link>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TravelMedical;
