import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, Row, Col, Steps, message, Divider } from 'antd';
import moment from 'moment';
import parse from 'html-react-parser';

import * as Services from "../../store/services";
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';

const DoctorPaymentBankTransfer = () => {
    const [order, setOrder] = useState({});
    const [feeDoctor, setFeeDoctor] = useState(CONSTANT.FEE.DOCTOR);

    // lấy token từ query url
    const urlQueryParams = useLocation().search;
    const _token = new URLSearchParams(urlQueryParams).get('token');

    useEffect(() => {
        // load detail order
        const loadOrder = async () => {
            if (typeof _token !== 'undefined') {
                return await Services.get(`${CONSTANT.API.DOCTOR.ORDER}/${_token}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin đặt lịch hẹn!');
                    });
            }
        }
        let mounted = true;

        loadOrder().then(data => {
            if (mounted) {
                if (data) {
                    if (data?.items[0]?.service?.is_online === false)
                        setFeeDoctor(0);
                    setOrder(data);
                }
            }
        });

        return () => mounted = false;
    }, [_token]);

    return (
        <div className="page-checkout">
            <MetaSeo />
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Steps.Step title="Chọn dịch vụ" />
                        <Steps.Step title="Thanh toán" />
                        <Steps.Step title="Hoàn thành" />
                    </Steps>
                </div>
                <div className="bank-transfer">
                    <div className="fw-bold">Cảm ơn quý khách đã sử dụng dịch vụ của Vivmedic</div>
                    <div>Lịch hẹn của Quý khách đã được tạo.</div>
                    <div>Quý khách vui lòng thanh toán mã lịch hẹn <strong>VIVMEDIC-D{order?.uid}</strong> để hoàn tất việc đặt lịch hẹn này</div>

                    <div className="fw-bold block-title" style={{ marginTop: 8 }}>Thông tin lịch hẹn</div>
                    <div className="list-product" style={{ marginBottom: 0 }}>
                        {
                            order?.items?.length > 0 &&
                            <Row className="item">
                                <Col xs={8} lg={4}>
                                    <div className="box-cover">
                                        <img src={order.items[0]?.doctor?.image ? COMMON.getImage(order.items[0].doctor.image, 'doctor') : COMMON.getImage('')} alt={order.items[0].doctor.slug} />
                                    </div>
                                </Col>
                                <Col xs={16} lg={20} className="info">
                                    <div className="title">
                                        {COMMON.createDoctorName(order.items[0]?.doctor?.academic_rank, order.items[0]?.doctor?.degree, order.items[0]?.doctor?.category?.short_name)} {order.items[0]?.doctor?.fullname}
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Dịch vụ: </span>
                                        <span className="fw-medium">{order.items[0]?.service?.name}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Giá: </span>
                                        <span className="fw-medium">
                                            {COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}
                                        </span>
                                    </div>

                                    <div style={{ color: '#777' }}>
                                        <span>Ngày khám bệnh: </span>
                                        <span className="fw-medium">{`${order?.date?.day}/${order?.date?.month}/${order?.date?.year}`}</span>
                                    </div>
                                    <div style={{ color: '#777' }}>
                                        <span>Giờ: </span>
                                        <span className="fw-medium">{moment(`${order?.time?.time_h}:${order?.time?.time_m}`, "HH:mm A").format("HH:mm A")}</span>
                                    </div>
                                    {
                                        order?.items[0]?.service?.is_online === false ?
                                            <>
                                                <div style={{ color: '#777' }}>
                                                    <span>Địa điểm khám: </span>
                                                    <span className="fw-medium">
                                                        {order?.location?.name} - {order?.location?.address}
                                                    </span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Hình thức khám: </span>
                                                    <span className="fw-medium">
                                                        Khám trực tiếp với Bác sĩ
                                                    </span>
                                                </div>
                                            </>
                                            :
                                            <div style={{ color: '#777' }}>
                                                <span>Hình thức khám: </span>
                                                <span className="fw-medium">Tư vấn từ xa qua video</span>
                                            </div>
                                    }

                                    {
                                        order?.items[0]?.service?.price_note &&
                                        <>
                                            <div style={{ color: '#777' }}>
                                                <span className="fw-medium">Lưu ý giá dịch vụ:</span>
                                            </div>
                                            <div style={{ fontStyle: 'italic', color: "#777", fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: `${order?.items[0]?.service?.price_note.replace(/(?:\r\n|\r|\n)/g, '<br />')}` }}></div>
                                        </>
                                    }

                                </Col>
                            </Row>
                        }
                        <Divider />
                        <Row>
                            <Col xs={16} md={8}>Phí dịch vụ</Col>
                            <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(feeDoctor)} {COMMON.getCurrency(order?.currency)}</Col>
                        </Row>
                        <Row>
                            <Col xs={16} md={14}>VAT 10% (Giá + Phí dịch vụ)</Col>
                            <Col xs={8} md={10} className="text-end fw-medium">{order?.items?.length > 0 && COMMON.currencyFormat((order?.items[0]?.service?.price + feeDoctor) * CONSTANT.FEE.VAT)} {COMMON.getCurrency(order?.currency)}</Col>
                        </Row>
                        <div className="order-total">
                            <span>Tổng cộng</span>
                            <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                        </div>
                    </div>
                    {
                        /*order?.items?.length > 0 && order.items[0]?.service?.is_online === true && 
                        <div style={{fontStyle:'italic', color: '#555'}}>
                            (Lưu ý: Đây là dịch vụ tư vấn từ xa qua video call. Sau khi Quý khách thực hiện thanh toán, và đến thời gian lịch hẹn, 
                            Quý khách vui lòng truy cập liên kết <a target="_blank" rel="noreferrer" href={`${order.link_meet}`}>Vivmedic Meet</a> để được Bác sĩ 
                            tư vấn từ xa. Xin cảm ơn!)
                        </div>*/
                    }
                    <div className="fw-bold">Thông tin tài khoản nhận thanh toán</div>
                    <Card className="bank-info" size="small" title={CONSTANT.BANK.BANK_NAME} style={{ marginBottom: 20 }}>
                        <Row gutter={48} align="middle" style={{ marginTop: 20 }}>
                            <Col lg={6}>
                                <img style={{ width: 110 }} src={`${CONSTANT.STATIC_PATH}images/logo-acb.png`} alt="logo" className="logo-bank" />
                            </Col>
                            <Col lg={18}>
                                <p>Số tài khoản: <strong>{CONSTANT.BANK.NUMBER}</strong></p>
                                <p>Người thụ hưởng: <strong>{CONSTANT.BANK.NAM_CARD}</strong></p>
                                <p>Nội dung chuyển tiền: <strong>VIVMEDIC-D{order?.uid}</strong></p>
                            </Col>
                        </Row>
                    </Card>
                    <p>
                        Xác nhận đặt lịch hẹn sẽ được gửi đến email của Quý khách sau khi Vivmedic nhận được thanh toán. Nếu Quý khách không nhận được email xác nhận sau 30 phút, vui lòng liên hệ Vivmedic để được hỗ trợ.
                    </p>
                    <p>Sau khi Vivmedic nhận được thanh toán, Vivmedic sẽ tiến hành kích hoạt dịch vụ của bạn.</p>
                    <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                        <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DoctorPaymentBankTransfer;