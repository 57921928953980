import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, List, Avatar } from "antd";
import { useTranslation } from 'react-multi-lang';

import CONSTANT from "../../config/constant";

const PageFooter = () => {
	const t = useTranslation();
	return (
		<footer>
			<div className="container">
				<div className="wrap-logo">
					<Link to="/" title={CONSTANT.PAGE_TITLE}>
						<img
							src={`${CONSTANT.STATIC_PATH}images/vivmedic-white.png`}
							className="logo"
							alt="logo vivmedic"
						/>
					</Link>
				</div>
				<Row gutter={16}>
					<Col xs={24} md={24} lg={7} className="col-info">
						<div className="company-name text-uppercase">{t('company_name')}</div>
						<Row>
							<Col span={3}>
								<strong>A:</strong>
							</Col>
							<Col span={20}>{t('company_add')}</Col>
						</Row>
						<Row>
							<Col span={3}>
								<strong>M:</strong>
							</Col>
							<Col span={20}>
								<a href="tel:+84818889668">(+84) 818 889 668</a>
							</Col>
						</Row>
						<Row>
							<Col span={3}>
								<strong>E:</strong>
							</Col>
							<Col span={20}>info@vivmedic.com</Col>
						</Row>
						{ /*
						<Row>
							<Col span={24}>
								<div
									style={{
										fontSize: 12,
										lineHeight: "15px",
										color: "#aaa",
										marginTop: "10px",
									}}
								>
									{t('web_is_dev')}
								</div>
							</Col>
						</Row>
						*/}
					</Col>

					<Col xs={24} md={7} lg={5}>
						<List
							header={<div className="text-uppercase">{t('service')}</div>}
							dataSource={[1]}
							renderItem={(item) => (
								<>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_MEDICAL_SHOPPING}`}
											tile={t('medical_shop')}
										>
											{t('medical_shop')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_DOCTOR}`}
											tile={t('specialist_doctor')}
										>
											{t('specialist_doctor')}
										</Link>
									</List.Item>
									<List.Item>
										<Link to={`/${CONSTANT.SLUG_FACILITY}`} tile={t('medical_facility')}>
											{t('medical_facility')}
										</Link>
									</List.Item>
									<List.Item>
										<Link to={`/${CONSTANT.SLUG_SERVICE}`} tile={t('medical_service')}>
											{t('medical_service')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_MEDICAL_ONLINE}`}
											tile={t('medical_online')}
										>
											{t('medical_online')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_HANDBOOK}`}
											tile={t('doctor_talking')}
										>
											{t('doctor_talking')}
										</Link>
									</List.Item>
									<List.Item>
										<Link to="/dang-ky-doi-tac" title={t('partner_registration')}>
											{t('partner_registration')}
										</Link>
									</List.Item>
									<List.Item>
										<a href={CONSTANT.BASE_AGENCY} title={t('partner_login')}>
											{t('partner_login')}
										</a>
									</List.Item>
								</>
							)}
							className="list-menu"
							locale={{ emptyText: " " }}
						/>
					</Col>
					<Col xs={24} md={9} lg={6}>
						<List
							header={<div className="text-uppercase">{t('customer_service')}</div>}
							dataSource={[1]}
							renderItem={(item) => (
								<>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/dieu-khoan-su-dung/1`}
											tile={t('user_terms_condition')}
										>
											{t('user_terms_condition')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/dieu-kien-kham-benh-tu-xa/7`}
											tile={t('telemedicine_terms_condition')}
										>
											{t('telemedicine_terms_condition')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/chinh-sach-bao-mat-thong-tin/2`}
											tile={t('privacy_policy')}
										>
											{t('privacy_policy')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/chinh-sach-van-chuyen/3`}
											tile={t('transportation_policy')}
										>
											{t('transportation_policy')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/huong-dan-su-dung-dic-vu/4`}
											tile={t('service_guideline')}
										>
											{t('service_guideline')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/quy-trinh-ho-tro-giai-quyet-khieu-nai/5`}
											tile={t('adjustment_claim_complaint_resolution')}
										>
											{t('adjustment_claim_complaint_resolution')}
										</Link>
									</List.Item>
									<List.Item>
										<Link
											to={`/${CONSTANT.SLUG_PAGE_CONTENT}/quy-che-hoat-dong/6`}
											tile={t('operating_rule_regulation')}
										>
											{t('operating_rule_regulation')}
										</Link>
									</List.Item>
									<List.Item>
										<Link to={`/${CONSTANT.SLUG_QA}`} tile={t('frequently_asked_question')}>
											{t('frequently_asked_question')}
										</Link>
									</List.Item>
								</>
							)}
							className="list-menu"
							locale={{ emptyText: " " }}
						/>
					</Col>

					<Col xs={24} md={8} lg={6}>
						<List
							header={<div className="text-uppercase">{t('support')}</div>}
							dataSource={[1]}
							renderItem={(item) => (
								<div>
									<Row className="hotline" align="middle">
										<Col xs={5} md={6} lg={5}>
											<img
												className="icon-phone"
												src={`${CONSTANT.STATIC_PATH}images/icon-telemarketer.svg`}
												alt="icon phone"
											/>
										</Col>
										<Col xs={19} md={18} lg={19}>
											<div>
												{t('contact_hotline')}: <strong>7:00 - 18:00</strong>
											</div>
											<div className="phone">
												<a href="tel:+84818889668">(+84) 818 889 668</a>
											</div>
										</Col>
									</Row>
									<div className="text-uppercase connect">{t('social_media')}</div>
									<div className="connect-icon">
										<a
											href="/"
											rel="nofflow noreferrer"
											target="_blank"
											title="Facebook"
										>
											<Avatar icon={<i className="fab fa-facebook-f"></i>} />
										</a>
										<a
											href="/"
											rel="nofflow noreferrer"
											target="_blank"
											title="Youtube"
										>
											<Avatar icon={<i className="fab fa-youtube"></i>} />
										</a>
										<a
											href="mailto:info@vivmedic.com"
											rel="nofflow noreferrer"
											target="_blank"
											title="Mail"
										>
											<Avatar icon={<i className="far fa-envelope"></i>} />
										</a>
									</div>
								</div>
							)}
							className="list-menu support"
							locale={{ emptyText: " " }}
						/>
					</Col>
				</Row>
			</div>
		</footer>
	);
};
export default PageFooter;
