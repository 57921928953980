import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card } from 'antd';
import * as COMMON from '../../../helper/common';


const ItemService = ({ data }) => {
    return (
        <Col xs={12} lg={8} key={data}>
            <Link to={COMMON.createLinkServiceDetail(data.slug, data.uid)} title={data.name}>
                <Card
                    hoverable
                    cover={<img alt={data.name} src={data.images && data.images.length > 0 ? COMMON.getImage(data.images[0].filename, 'service') : COMMON.getImage()} />}
                    className="product item-service"
                >
                    <Card.Meta
                        title={<h2 className="text-center title">{data.name}</h2>}
                        description={
                            <h3 className="text-center sapo" dangerouslySetInnerHTML={{ __html: data?.overview.replace(/(<([^>]+)>)/ig, '').replace(/&.*;/g, '') }}></h3>
                        }
                    />
                    <div className="text-center price">
                        {
                            data.price > 0 ? `${COMMON.currencyFormat(data.price)} ${COMMON.getCurrency(data.currency)}` : <span>&nbsp;</span>
                        }
                    </div>
                </Card>
            </Link>
        </Col>
    )
}
export default ItemService;