import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';


import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';

const F0AtHome = () => {

    return (
        <div className="f0-at-home">
            <MetaSeo
                title="F0 tại nhà"
                description="Đội ngũ bác sĩ giỏi, giàu kinh nghiệm, tư vấn F0 điều trị tại nhà, đối tượng áp dụng: F0, F1"
                keyword={`tư vấn f0, covid 19, covid, tư vấn f0, tư vấn covid, điều trị covid, điều trị covid 19, ${CONSTANT.PAGE_KEYWORD_EXT}`}
            />
            <div className="container">
                <div className="block-header">
                    <div className="text-uppercase title">
                        <img src={`${CONSTANT.STATIC_PATH}images/hot.gif`} alt="hot" className="icon-hot" />
                        F0 tại nhà
                    </div>
                    <div className="sapo">Đội ngũ bác sĩ giỏi, giàu kinh nghiệm, tư vấn F0 điều trị tại nhà, khám điều trị F0 tại nhà</div>
                    <div className="line"></div>
                </div>
                <Row gutter={[{ xs: 0, lg: 48 }]} className="list">
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 1 }} className="item">
                        <div className="box-cover">
                            <img className='cover' src={`${CONSTANT.STATIC_PATH}images/f0-tai-nha.jpeg`} />
                        </div>
                        <div className="title">Tư vấn cho F0 tại nhà miễn phí <img src={`${CONSTANT.STATIC_PATH}images/hot.gif`} alt="hot" className="icon-hot" style={{ marginLeft: '0px', width: '28px', marginTop: "-5px" }} /></div>

                        <div className="sapo">
                            Dịch bệnh Covid-19 diễn biến phức tạp gây khó khăn cho người bệnh trong quá
                            trình thăm khám sức khỏe. Với mong muốn chung tay góp sức chăm sóc sức khỏe cộng đồng,
                            Vivmedic cung cấp dịch vụ Tư vấn sức khoẻ từ xa  dành cho người bệnh F0, F1
                        </div>

                        <div className='text-center'>
                            <Link to="/bac-si-tu-van-f0" className='btn-global bg btn-book'>Đặt lịch tư vấn</Link>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 11 }} className="item">
                        <div className="box-cover">
                            <img className='cover' src={`${CONSTANT.STATIC_PATH}images/kham-f0-tai-nha.jpeg`} />
                        </div>
                        <div className="title">Khám - Điều trị F0 tại nhà</div>

                        <div className="sapo">
                            {/* <div style={{ color: '#d30808' }}>- <strong>10</strong> khách hàng đăng ký đầu tiên sẽ được miễn phí dịch vụ và được tặng mã giảm giá 500.000 VND phí dịch vụ cho lượt khám tiếp theo (có thể tặng mã giảm giá này cho gia đình, người thân).</div> */}
                            Nhằm mục đích mong muốn nâng cao sức khỏe cho bản thân và gia đình người bệnh nhiễm COVID-19. Đội ngũ Y Bác sĩ có nhiều kinh nghiệm trong công tác khám điều trị bệnh COVID-19 tại nhà,
                            từ đó đưa ra phác đồ điều trị phù hợp với từng cơ địa thể tạng của người...
                        </div>

                        <div className='text-center'>
                            <Link to="/dich-vu-y-te/s-kham-dieu-tri-f0-tai-nha/241" className='btn-global bg btn-book'>Đăng ký ngay</Link>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default F0AtHome;