import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Col, Row, Card, Pagination, Select, Form, Avatar, Spin, Empty } from 'antd';

// import common
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';
import { LocationAction, DoctorAction } from '../../store/root-action';

import './doctor-style.css';


const Doctor = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    // get cities
    const cities = useSelector(state => state.locationReducer.cities);
    // get doctor category
    const doctorCategories = useSelector(state => state.doctorReducer.categories);
    // get specialties
    const specialties = useSelector(state => state.hospitalReducer.specialties);
    // get list doctor
    const listDoctor = useSelector(state => state.doctorReducer.list);
    const loadingDoctor = useSelector(state => state.doctorReducer.loading);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;

    // lấy category từ query url
    const urlQueryParams = useLocation().search;
    const urlSpec = new URLSearchParams(urlQueryParams).get('spec');

    // load list doctor
    const loadDoctor = async (page = currentPage) => {
        let params = {
            perPage: pageSize, page, status: 'publish', is_service: 0
        }
        // param filter
        const filterCity = form.getFieldValue('city');
        const filterCate = form.getFieldValue('cate');
        const filterSpecialty = form.getFieldValue('specialty');
        if (filterCity !== 'all' && filterCity !== undefined) {
            params.city = filterCity;
        }
        if (filterCate !== 'all' && filterCate !== undefined) {
            params.category = filterCate;
        }
        if (filterSpecialty !== 'all' && filterSpecialty !== undefined) {
            params.specialties = filterSpecialty;
        }

        await dispatch(DoctorAction.getList(params));
    }

    useEffect(() => {
        //get list cities (tạm thời chỉ load city tại Việt Nam)
        const loadCities = async () => {
            await dispatch(LocationAction.getCities({ perPage: -1, is_active: true, country: '5e9ed13e45d6942f9e75f8c4' }));
        }
        if (cities.length === 0)
            loadCities();

        // load danh mục bác sĩ
        const loadDoctorCategory = async () => {
            await dispatch(DoctorAction.getDoctorCategory({ perPage: -1, is_active: true }));
        }
        if (doctorCategories.length === 0)
            loadDoctorCategory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (specialties.length > 0) {
            if (urlSpec) {
                for (let spec of specialties) {
                    if (spec.slug === urlSpec) {
                        form.setFieldsValue({ specialty: spec._id })
                        break;
                    }
                }
            }
            else {
                form.resetFields();
            }
            loadDoctor();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, [specialties, urlSpec]);

    // Xử lý sự kiện khi thay đổi các filter
    const onFilter = (value) => {
        loadDoctor();
    }
    // Change pagination
    const onChangePagination = (page) => {
        setCurrentPage(page);
        loadDoctor(page);
    }
    return (
        <React.Fragment>
            <MetaSeo
                title="Bác sĩ chuyên khoa"
                description="Bác sĩ chuyên khoa gồm các y bác sĩ chuyên môn giỏi, đầy kinh nghiệm trong việc khám, chữa bệnh"
                keyword={`Bác sĩ chuyên khoa, bác sĩ, y tá, điều dưỡng, y sĩ, hộ sinh, kỹ thuật viên, ${CONSTANT.PAGE_KEYWORD_EXT}`}
            />
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Bác sĩ chuyên khoa</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            {/* Begin medical shopping */}
            <div className="container page-doctor-category">
                <div className="block-header">
                    <div className="text-uppercase title">Bác sĩ chuyên khoa</div>
                    <div className="sapo">Các y bác sĩ chuyên môn giỏi, đầy kinh nghiệm trong việc khám, chữa bệnh</div>
                    <div className="line"></div>
                </div>

                <div className="box-search">
                    <Form
                        initialValues={{
                            //city: 'all',
                            //cate: 'all',
                            //specialty: 'all'
                        }}
                        form={form}
                    >
                        <Row justify="center" gutter={[{ xs: 8, lg: 32 }, { xs: 8, lg: 32 }]}>
                            <Col xs={24} lg={7}>
                                <Form.Item name="city" >
                                    <Select
                                        size="large"
                                        className="select-global"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        placeholder="Chọn thành phố"
                                        onChange={onFilter}
                                        allowClear
                                    >
                                        {
                                            cities && cities.map(item => (
                                                <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>
                                            ))
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={7}>
                                <Form.Item name="cate" >
                                    <Select size="large" allowClear className="select-global" placeholder="Tất cả Bác sĩ" onChange={onFilter}>
                                        {
                                            doctorCategories && doctorCategories.map(item => (
                                                <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={7}>
                                <Form.Item name="specialty" >
                                    <Select size="large" allowClear placeholder="Chuyên khoa" className="select-global" onChange={onFilter}>
                                        {
                                            specialties && specialties.map(item => (
                                                <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Spin spinning={loadingDoctor}>
                    <div className="list-doctor">
                        <Row gutter={[{ xs: 16, lg: 24 }, { xs: 16, lg: 24 }]}>
                            {
                                listDoctor && listDoctor.docs && listDoctor.docs.map(item => (
                                    <Col xs={12} lg={6} key={item._id}>
                                        <Link to={COMMON.createLinkDoctorDetail(`${item?.academic_rank || ''} ${item?.degree || ''} ${item?.category?.short_name || ''} ${item.slug}`, item.uid)} title={`Xem chi tiết ${COMMON.createDoctorName(item.academic_rank, item.degree, item?.category?.short_name)} ${item.fullname}`}>
                                            <Card
                                                hoverable
                                                cover={<div className="box-cover"><img alt={`${COMMON.createDoctorName(item.academic_rank, item.degree, item.fullname)}`} src={COMMON.getImage(item.image, 'doctor')} /></div>}
                                                className="doctor"
                                            >
                                                <Card.Meta
                                                    title={
                                                        <>
                                                            <h2 className="title">{item.fullname}</h2>
                                                            <h3 className="degree">{COMMON.createDoctorName(item.academic_rank, item.degree, item?.category?.short_name)}</h3>
                                                        </>
                                                    }
                                                />
                                                <div className="location">
                                                    <ion-icon name="location-sharp"></ion-icon>
                                                    {item.city && item.city.name}
                                                </div>
                                                <div className="specialty">
                                                    <Avatar src={item?.specialties?.length > 0 && COMMON.getImage(item.specialties[0].avatar, 'specialty')} />
                                                    <div className="name">
                                                        {item?.specialties?.length > 0 ? item.specialties.map(elem => elem.name).join(', ') : "Đang cập nhật..."}
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                ))
                            }

                        </Row>
                    </div>
                </Spin>
                {
                    !loadingDoctor && listDoctor.docs && listDoctor.docs.length === 0 &&
                    <Empty description={<span style={{ color: '#999' }}>Chưa có dữ liệu rồi</span>} />
                }
                <div className="text-end pagination">
                    <Pagination
                        pageSize={pageSize} current={currentPage} total={listDoctor.total}
                        hideOnSinglePage={true}
                        showLessItems={true}
                        onChange={onChangePagination}
                    />
                </div>

            </div>
        </React.Fragment>
    )
}
Doctor.serverFetch = async ({ store, params }) => {
    await store.dispatch(DoctorAction.getList({
        perPage: 12, page: 1, status: 'publish', is_service: 0
    }));
};
export default Doctor;