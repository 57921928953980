import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, Col, Row, Form, Select, Button, List, Spin, Empty } from 'antd';
import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons';
import { HospitalAction, LocationAction } from '../../store/root-action';
import { useSelector, useDispatch } from 'react-redux';
import './facility-style.css';

import ItemFacility from './components/item-facility';
import MetaSeo from '../../component/meta-seo';
import CONSTANT from '../../config/constant';

const Facility = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.hospitalReducer.loading);
    const types = useSelector(state => state.hospitalReducer.types);
    const specialties = useSelector(state => state.hospitalReducer.specialties);
    const areas = useSelector(state => state.locationReducer.areas);
    const [openSearch, setOpenSearch] = useState(false);
    let countries = useSelector(state => state.locationReducer.countries);
    let cities = useSelector(state => state.locationReducer.cities);
    let wards = useSelector(state => state.locationReducer.wards);
    let hospitals = useSelector(state => state.hospitalReducer.list);
    // lấy type từ query url
    const urlQueryParams = useLocation().search;
    const urlType = new URLSearchParams(urlQueryParams).get('type');
    const urlSpec = new URLSearchParams(urlQueryParams).get('spec');

    const loadSpots = useCallback(async () => {
        //await dispatch(HospitalAction.getTypes());
        //await dispatch(HospitalAction.getSpecialties());
        await dispatch(LocationAction.getAreas());
    }, [dispatch]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            loadSpots();
        }
        return () => mounted = false;
    }, [loadSpots]);

    const getHospitals = useCallback(async (page, pageSize) => {
        let params = {
            page, perPage: pageSize, is_active: true, is_service: 0
        }
        if (form.getFieldValue('type') && form.getFieldValue('type') !== 'all')
            params.type = form.getFieldValue('type');
        if (form.getFieldValue('specialty') && form.getFieldValue('specialty') !== 'all')
            params.specialty = form.getFieldValue('specialty');
        if (form.getFieldValue('area'))
            params.area = form.getFieldValue('area');
        if (form.getFieldValue('country'))
            params.country = form.getFieldValue('country');
        if (form.getFieldValue('city'))
            params.city = form.getFieldValue('city');
        if (form.getFieldValue('ward'))
            params.ward = form.getFieldValue('ward');
        await dispatch(HospitalAction.getList(params));
    }, [form, dispatch]);


    // gán type lấy từ URL
    useEffect(() => {
        if (types.length > 0) {
            if (urlType) {
                for (let type of types) {
                    if (type.slug === urlType) {
                        form.setFieldsValue({ type: type._id })
                        break;
                    }
                }
            }
            else {
                form.setFieldsValue({ type: null });
            }

            // gan specical lay tu url
            if (urlSpec) {
                for (let spec of specialties) {
                    if (spec.slug === urlSpec) {
                        form.setFieldsValue({ specialty: spec._id });
                        break;
                    }
                }
            }
            else {
                form.setFieldsValue({ specialty: null });
            }
            getHospitals(1, 10);
        }

    }, [types, specialties, urlType, urlSpec, form, getHospitals]);

    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'area':
                countries = [];
                cities = [];
                wards = [];
                form.setFieldsValue({
                    country: null,
                    city: null,
                    ward: null
                })
                // load country
                await dispatch(LocationAction.getCountries({ area: value }));
                break;
            case 'country':
                cities = [];
                wards = [];
                form.setFieldsValue({
                    city: null,
                    ward: null
                })
                // load city
                await dispatch(LocationAction.getCities({ country: value }));
                break;
            case 'city':
                wards = [];
                form.setFieldsValue({
                    ward: null
                })
                // load city
                await dispatch(LocationAction.getWards({ city: value }));
                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <MetaSeo
                title="Cơ sở y tế"
                keyword={`cơ sở y tế, bệnh viện, xét nghiệm, phòng khám, ${CONSTANT.PAGE_KEYWORD_EXT}`}
                description="Cơ sở y tế đa dạng như bệnh viện, phòng khám, phòng xét nghiệm, chuẩn đoán hình ảnh, các cơ sở y tế trang thiết bị hiện đại"
            />
            <div className="page-facility">
                <div className="breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Cơ sở y tế</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                {/* Begin medical facility */}
                <div className="container">
                    <div className="block-header">
                        <div className="text-uppercase title">Cơ sở y tế</div>
                        <div className="sapo">Các y bác sĩ chuyên môn giỏi, đầy kinh nghiệm trong việc khám, chữa bệnh</div>
                        <div className="line"></div>
                    </div>
                    <div className="box-search">

                        <Form
                            form={form}
                        >
                            <Row justify="center" gutter={[{ xs: 16, lg: 32 }, { xs: 16, lg: 32 }]}>
                                <Col xs={12} lg={7}>
                                    <Form.Item name="type" >
                                        <Select size="large" allowClear placeholder="Loại cơ sở y tế" onChange={() => getHospitals(1, 10)} className="select-global" dropdownClassName="cus-select-drop">
                                            {
                                                types && types.length > 0 &&
                                                types.map(item => <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={7}>
                                    <Form.Item name="specialty" >
                                        <Select size="large" onChange={() => getHospitals(1, 10)} className="select-global" placeholder="Chuyên khoa" allowClear dropdownClassName="cus-select-drop">
                                            {
                                                specialties.length > 0 &&
                                                specialties.map(item => <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]}>
                        <Col xs={24} lg={6} className="col-search">
                            <div className="box-search">
                                <div className="title">
                                    Tìm
                                    {
                                        !openSearch
                                            ?
                                            <DownSquareOutlined className="open-search d-block d-md-none" onClick={() => setOpenSearch(state => !state)} />
                                            :
                                            <UpSquareOutlined className="open-search d-block d-md-none" onClick={() => setOpenSearch(state => !state)} />
                                    }

                                </div>
                                <Form
                                    name="search"
                                    layout="vertical"
                                    form={form}
                                    className={`form-search ${openSearch ? 'open' : ''}`}
                                >
                                    <Form.Item name="area">
                                        <Select onChange={onChangeSelect('area')} placeholder="Khu vực" className="select-global select-location" size='large'>
                                            {
                                                areas && areas.length > 0 &&
                                                areas.map(item => <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="country">
                                        <Select onChange={onChangeSelect('country')} placeholder="Quốc gia" className="select-global select-location" size='large'>
                                            {
                                                countries && countries.length > 0 &&
                                                countries.map(item => <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="city">
                                        <Select onChange={onChangeSelect('city')} placeholder="Tỉnh/Thành" className="select-global select-location" size='large'>
                                            {
                                                cities && cities.length > 0 &&
                                                cities.map(item => <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="ward">
                                        <Select placeholder="Quận/Huyện" className="select-global select-location" size='large'>
                                            {
                                                wards && wards.length > 0 &&
                                                wards.map(item => <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="text-center">
                                        <Button className="btn-global bg btn-search" onClick={() => getHospitals(1, 3)}>
                                            Tìm
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={24} lg={18}>
                            <div className="text-center">
                                <Spin spinning={loading} tip="Đang tải dữ liệu..."></Spin>
                            </div>
                            {
                                !loading &&
                                <List
                                    pagination={{
                                        onChange: async (page, pageSize) => {
                                            getHospitals(page, pageSize);
                                        },
                                        pageSize: 10,
                                        total: hospitals.total,
                                        hideOnSinglePage: true
                                    }}
                                    dataSource={hospitals.docs}
                                    renderItem={item => <ItemFacility data={item} key={item._id} />}
                                    className="list-facility"
                                    locale={{ emptyText: hospitals?.docs?.length === 0 ? <Empty description={<span style={{ color: '#999' }}>Chưa có dữ liệu rồi</span>} /> : '' }}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
Facility.serverFetch = async ({ store, params }) => {
    await store.dispatch(HospitalAction.getList({
        perPage: 4, page: 1, is_active: true, is_service: 0
    }));
};
export default Facility;