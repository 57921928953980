const CONSTANT = {};

CONSTANT.BASE_AGENCY = "https://partner.vivmedic.com";
CONSTANT.BASE_MEET = "https://meet.vivmedic.com";

if (process.env.NODE_ENV === "development") {
	//CONSTANT.BASE_API = `http://localhost:8200/v1/`;
	//CONSTANT.STATIC_URL = `http://localhost:8200/v1/static/`;
	//CONSTANT.BASE_API = `http://localhost:7200/v1/`;
	//CONSTANT.STATIC_URL = `http://localhost:7200/v1/static/`;
	//CONSTANT.BASE_API = `https://api.vivmedic.com/v1/`;

	//CONSTANT.STATIC_URL = `https://api.vivmedic.com/v1/static/`;
	CONSTANT.BASE_URL = "http://localhost:7100";
	CONSTANT.STATIC_PATH = '/static/';
} else {
	CONSTANT.BASE_API = `https://api.vivmedic.com/v1/`;
	CONSTANT.STATIC_URL = `https://api.vivmedic.com/v1/static/`;
	CONSTANT.BASE_URL = "https://vivmedic.com";
	CONSTANT.STATIC_PATH = '';
}

CONSTANT.API = {
	KEY: "hbacrxhfgxhplcwrmfg2cu0946496x",
	AUTH: {
		LOGIN: `${CONSTANT.BASE_API}auth/login`,
		LOGIN_WITH_PHONE: `${CONSTANT.BASE_API}auth/login-phone`,
		REGISTER: `${CONSTANT.BASE_API}auth/register`,
	},
	USER: {
		CHECK: `${CONSTANT.BASE_API}users/check`,
		CHECK_PHONE: `${CONSTANT.BASE_API}users/check-phone`,
		ACTIVE: `${CONSTANT.BASE_API}users/active`,
		CHANGE_PASS: `${CONSTANT.BASE_API}auth/change-password`,
		UPDATE: `${CONSTANT.BASE_API}users`,
		UPLOAD_AVATAR: `${CONSTANT.BASE_API}extra/upload/avatar`,
		UPLOAD_IMAGE: `${CONSTANT.BASE_API}extra/upload/image`,
		SAVE_AVATAR: `${CONSTANT.BASE_API}extra/upload/save-file`,
	},
	AGENCY: {
		AGENCY: `${CONSTANT.BASE_API}agency/agency`,
		IMAGE: `${CONSTANT.BASE_API}agency/image`,
	},
	PRODUCT: {
		PRODUCT: `${CONSTANT.BASE_API}shop/product/product`,
		CATEGORY_TREE: `${CONSTANT.BASE_API}shop/product/category/tree`,
		ORDER: `${CONSTANT.BASE_API}shop/product/order`,
		ORDER_ITEM: `${CONSTANT.BASE_API}shop/product/order-item`,
		CHECKOUT: `${CONSTANT.BASE_API}shop/product/order-checkout`,
		COUNTRY: `${CONSTANT.BASE_API}shop/address/country`,
	},
	HOSPITAL: {
		HOSPITAL: `${CONSTANT.BASE_API}hospital/hospital`,
		SPECIALTIES: `${CONSTANT.BASE_API}hospital/specialty`,
		TYPES: `${CONSTANT.BASE_API}hospital/type`,
		ORDER: `${CONSTANT.BASE_API}hospital/order`,
		ORDER_ITEM: `${CONSTANT.BASE_API}hospital/order-item`,
		CHECKOUT: `${CONSTANT.BASE_API}hospital/checkout`,
	},
	DOCTOR: {
		DOCTOR: `${CONSTANT.BASE_API}doctor/doctor`,
		CATEGORY: `${CONSTANT.BASE_API}doctor/category`,
		ORDER: `${CONSTANT.BASE_API}doctor/order`,
		ORDER_ITEM: `${CONSTANT.BASE_API}doctor/order-item`,
		CHECKOUT: `${CONSTANT.BASE_API}doctor/checkout`,
	},
	TOUR: {
		TOUR: `${CONSTANT.BASE_API}tour/tour`,
		CATEGORY: `${CONSTANT.BASE_API}tour/category`,
		IMAGE: `${CONSTANT.BASE_API}tour/image`,
		AGE: `${CONSTANT.BASE_API}tour/age`,
		ORDER: `${CONSTANT.BASE_API}tour/order`,
	},
	SERVICE: {
		SERVICE: `${CONSTANT.BASE_API}service-medial/service`,
		CATEGORY: `${CONSTANT.BASE_API}service-medial/category`,
		IMAGE: `${CONSTANT.BASE_API}service-medial/image`,
		AGE: `${CONSTANT.BASE_API}service-medial/age`,
		ORDER: `${CONSTANT.BASE_API}service-medial/order`,
		ORDER_ITEM: `${CONSTANT.BASE_API}service-medial/order-item`,
		CHECKOUT: `${CONSTANT.BASE_API}service-medial/checkout`,
		VOUCHER: `${CONSTANT.BASE_API}service-medial/voucher`,
	},
	HANDBOOK: {
		HANDBOOK: `${CONSTANT.BASE_API}post/post`,
		CATEGORY: `${CONSTANT.BASE_API}post/category`,
	},
	AGENCY_SERVICE: {
		AGENCY: `${CONSTANT.BASE_API}agency-service/agency`,
		IMAGE: `${CONSTANT.BASE_API}agency-service/image`,
		SLUG_AGENCY_CHECK: `${CONSTANT.BASE_API}agency-service/agency/check`,
	},
	HOTEL: {
		HOTEL: `${CONSTANT.BASE_API}hotel/hotel`,
		FACILITY: `${CONSTANT.BASE_API}hotel/facility`,
		ROOM: `${CONSTANT.BASE_API}hotel/room`,
		CALENDAR: `${CONSTANT.BASE_API}hotel/room/calendar`,
		TYPE: `${CONSTANT.BASE_API}hotel/type`,
		IMAGE: `${CONSTANT.BASE_API}hotel/image`,
		ORDER: `${CONSTANT.BASE_API}hotel/order`,
	},
	TRANSACTION: {
		TRANSACTION: `${CONSTANT.BASE_API}transaction/transaction`,
	},
	MEDIA: {
		MEDIA: `${CONSTANT.BASE_API}audio/media`,
		UPLOAD_IMAGE: `${CONSTANT.BASE_API}audio/media/upload/image`,
		SAVE_FILE: `${CONSTANT.BASE_API}audio/media/upload/save-file`,
	},
	EXTRA: {
		UPLOAD_AVATAR: `${CONSTANT.BASE_API}extra/upload/avatar`,
		UPLOAD_IMAGE: `${CONSTANT.BASE_API}extra/upload/image`,
		SAVE_FILE: `${CONSTANT.BASE_API}extra/upload/save-file`,
		PAGE: `${CONSTANT.BASE_API}extra/page`,
	},
	LOCATION: {
		AREA: `${CONSTANT.BASE_API}destination/area`,
		COUNTRY: `${CONSTANT.BASE_API}destination/country`,
		CITY: `${CONSTANT.BASE_API}destination/city`,
		WARD: `${CONSTANT.BASE_API}destination/ward`,
	},
	QA: `${CONSTANT.BASE_API}qa/qa`,

	AUDIO_OBJECT: `${CONSTANT.BASE_API}audio/audio-object`,
	CONTENT: `${CONSTANT.BASE_API}audio/content`,
	REVIEW: `${CONSTANT.BASE_API}review`
};

CONSTANT.PER_PAGE = 12;
CONSTANT.STORAGE_KEY_USER = `auth-user-${process.env.NODE_ENV}`;
CONSTANT.STORAGE_DATA_BOOKING_SERVICE = `data-booking-service`;
CONSTANT.STORAGE_USER = "TM_U";
CONSTANT.STORAGE_REFRESH_TOKEN = "TM_RTK";
CONSTANT.STORAGE_TOKEN = "TM_TK";
CONSTANT.STORAGE_INFO_CUSTOMER_BOOK_DOCTOR = "I_C_B_D";


// Khai báo phí dịch vụ và VAT
CONSTANT.FEE = {
	VAT: 0.1,
	DOCTOR: 60000,
	//SERVICE_MEDIC: 0.05,
	SERVICE_MEDIC: 0,
};

CONSTANT.DATE_FORMAT = "DD/MM/YY - H:m";
CONSTANT.STATUS = {
	trash: "Nháp",
	processing: "Đang xử lý",
	completed: "Hoàn thành",
	cancelled: "Hủy",
	paid: "Đã thanh toán",
	unpaid: "Không thanh toán",
	wait_paid: "Chờ thanh toán",
	pending: "Chưa giải quyết",
};
CONSTANT.STATUS_COLOR = {
	trash: "default",
	processing: "orange",
	completed: "green",
	cancelled: "magenta",
	paid: "geekblue",
	unpaid: "volcano",
	wait_paid: "gold",
	pending: "purple",
};

/* Slug */
CONSTANT.SLUG_MEDICAL_SHOPPING = "mua-sam-y-te";
CONSTANT.SLUG_MEDICAL_PRODUCT = "san-pham";
CONSTANT.SLUG_DOCTOR = "bac-si-chuyen-khoa";
CONSTANT.ORDER_ID = "order_id";
CONSTANT.SLUG_FACILITY = "co-so-y-te";
CONSTANT.SLUG_SERVICE = "dich-vu-y-te";
CONSTANT.SLUG_LOGIN = "dang-nhap";
CONSTANT.SLUG_REGISTER = "dang-ky-tai-khoan";
CONSTANT.SLUG_ACTIVE_ACCOUNT = "kich-hoat-tai-khoan";
CONSTANT.SLUG_REGISTER_AGENCY = "dang-ky-doi-tac";
CONSTANT.SLUG_MEDICAL_ONLINE = "tu-van-tu-xa";
CONSTANT.SLUG_HANDBOOK = "thay-thuoc-tro-chuyen";
CONSTANT.SLUG_PAGE_CONTENT = "thong-tin";
CONSTANT.SLUG_QA = "cau-hoi-thuong-gap";
CONSTANT.SLUG_TRAVEL_MEDICAL = "du-lich-y-te";

/*  SEO */
CONSTANT.PAGE_TITLE =
	"Vivmedic - Chăm sóc sức khỏe toàn diện, mọi lúc, mọi nơi";
CONSTANT.PAGE_KEYWORD =
	"vivmedic, Khám bệnh online, y tế, nền tảng y tế, chăm sóc sức khỏe, tư vấn sức khỏe từ xa, khám bệnh từ xa, bác sĩ, y tá, điều dưỡng, dịch vụ khám bệnh";
CONSTANT.PAGE_KEYWORD_EXT =
	"vivmedic, nền tảng y tế, khám bệnh online, khám bệnh từ xa";
CONSTANT.PAGE_DESCRIPTION =
	"Vivmedic - Khám bệnh online là Nền tảng Y tế Chăm sóc sức khỏe toàn diện kết nối người dùng đến với dịch vụ y tế - chăm sóc sức khỏe chất lượng, hiệu quả và tin cậy";
CONSTANT.SITE_NAME = "vivmedic.com";

// Rating desc
CONSTANT.RATING_DESC = ['Rất không hài lòng', 'Không hài lòng', 'Bình thường', 'Hài lòng', 'Cực kỳ hài lòng'];
CONSTANT.FACEBOOK_ID = '2703342366634713';
CONSTANT.ZALO_ID = '4401829230643149078';

// Khai bao thong tin tai khoan ngan hang
CONSTANT.BANK = {
	BANK_NAME: "Ngân hàng ACB - Chi nhánh Nam Sài Gòn",
	NUMBER: "2886 68 68 68 68",
	NAM_CARD: "CÔNG TY CỔ PHẦN CÔNG NGHỆ VIVMEDIC",
};

CONSTANT.HERE = {
	APP_ID: "erWElBPTdcSVJhWkLQJJ",
	APP_CODE: "iYhTbrpb25W671WE_MK7Cw"
}
export default CONSTANT;
