import * as Services from "../services";
import CONSTANT from '../../config/constant';
import {
    // get list
    GET_LIST, GET_LIST_FAILURE, GET_LIST_SUCCESS,
    // get detail
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,
    //order
    GET_ORDER, GET_ORDER_SUCCESS, GET_ORDER_FAILURE, 
    // Get service category
    GET_SERVICE_CATEGORY, GET_SERVICE_CATEGORY_SUCCESS, GET_SERVICE_CATEGORY_FAILURE
} from './type';

// get list
export const getList = (queryParam) => {
    let url = `${CONSTANT.API.SERVICE.SERVICE}`;
    return async dispatch => {
        dispatch({ type: GET_LIST });
        return await Services.get(url, queryParam)
            .then(res => {
                
                if (res && res.data && res.data.code === 200) {
                    dispatch({
                        type: GET_LIST_SUCCESS,
                        payload: res.data.data
                    })
                }
                else {
                    dispatch({ type: GET_LIST_FAILURE, payload: 'Lỗi lấy dữ liệu List service từ API' })
                }
            })
    }
}
export function getOrder(id) {
    return async (dispatch) => {
        dispatch({
            type: GET_ORDER
        });
        let url = `${CONSTANT.API.SERVICE.ORDER}/${id}`;
        return await Services.get(url)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data)
                        dispatch({
                            type: GET_ORDER_SUCCESS,
                            payload: res.data.data
                        })
                    else
                        dispatch({
                            type: GET_ORDER_FAILURE,
                            payload: 'Dữ liệu không tồn tại'
                        })
                }
                else {
                    dispatch({ type: GET_ORDER_FAILURE, payload: 'Lỗi lấy dữ liệu cây danh mục từ API' })
                }
            })
            .catch(error => dispatch({ type: GET_ORDER_FAILURE, payload: 'Lỗi lấy dữ liêu ' + error + '' }))
    }
}
export const getDetail = (id) => {
    return async dispatch => {
        if (id) {
            let url = `${CONSTANT.API.SERVICE.SERVICE}/${id}`;
            dispatch({ type: GET_DETAIL });            
            return await Services.get(url)
                .then(res => {                    
                    if (res && res.data && res.data.code === 200) {
                        dispatch({
                            type: GET_DETAIL_SUCCESS,
                            payload: res.data.data
                        })
                    }
                    else {
                        dispatch({ type: GET_DETAIL_FAILURE, payload: 'Lỗi lấy dữ liệu service từ API' })
                    }
                })
        }
        else {
            dispatch({ type: GET_DETAIL_FAILURE, payload: "Id không đúng" });
        }
    }
}
// process get list service category
export function getServiceCategory(queryParam) {
    let url = `${CONSTANT.API.SERVICE.CATEGORY}`;
    return async (dispatch) => {
        dispatch({
            type: GET_SERVICE_CATEGORY
        });
        return await Services.get(url, queryParam)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    dispatch({
                        type: GET_SERVICE_CATEGORY_SUCCESS,
                        payload: res.data.data.docs
                    })
                }
                else {
                    dispatch({ type: GET_SERVICE_CATEGORY_FAILURE, payload: 'Không có dữ liệu trả về từ API' })
                }
            })
    }
}
