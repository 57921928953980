import React, { useCallback } from 'react';
import { Modal, Result } from 'antd';

import { HEREMap, Marker } from 'here-maps-react-v2';

import CONSTANT from '../../config/constant';

const ModalShowMap = (props) => {
    const handleCancel = useCallback(() => {
        props.cancel();
    }, []);
    // Can render any map element, make sure to pass map and platform as props to the children to avoid unwarranted behavior    
    return (
        <Modal title={props.data.title}
            visible={props.visible}
            footer={false}
            closable={true}
            onCancel={handleCancel}
            destroyOnClose={true}
            centered
            width={'90%'}
        >{
                props?.data?.position?.lat && props?.data?.position?.lng ? (
                    <div className="body-maps">
                        <HEREMap
                            appId={CONSTANT.HERE.APP_ID}
                            appCode={CONSTANT.HERE.APP_CODE}
                            center={{ lat: props.data?.position?.lat, lng: props.data?.position?.lng }}
                            zoom={16}
                            animateCenter={true}
                            animateZoom={true}
                            hidpi={true}
                            interactive={true}
                            secure={true}
                        >
                            <Marker
                                lat={props.data?.position?.lat}
                                lng={props.data?.position?.lng}
                                bitmap={`images/icon-map.png`}
                            />
                        </HEREMap>
                    </div>) : <Result title="Thông tin vị trí đang cập nhật. Vui lòng quay lại sau, cảm ơn Quý Khách !" />
            }
        </Modal >
    )
}
export default ModalShowMap;