/* Defined language for VI Tieng Viet  */
export const vi = {
   search: 'Tìm kiếm',
   cart: 'Giỏ hàng',
   login: 'Đăng nhập',
   logout: 'Đăng xuất',
   account: 'Tài khoản',
   your_order: 'Đơn hàng của bạn',
   home: 'Trang chủ',
   specialist_doctor: 'Bác sĩ chuyên khoa',
   medical_facility: 'Cơ sở y tế',
   medical_service: 'Dịch vụ y tế',
   medical_online: 'Tư vấn từ xa',
   medical_online_free: 'Tư vấn từ xa miễn phí',
   medical_online_paid: 'Tư vấn từ xa có thu phí',
   doctor_talking: 'Thầy thuốc trò chuyện',
   medical_shop: 'Mua sắm y tế',
   specialist_doctor_sapo: 'Các y bác sĩ chuyên môn giỏi, đầy kinh nghiệm trong việc khám, chữa bệnh',
   specialist_doctor_view_more: 'Xem thêm Bác sĩ chuyên khoa',
   view_more: 'Xem thêm',
   medical_shop_sapo: 'Cung cấp các sản phẩm thuốc và thực phẩm chức năng đảm bảo chất lượng',
   medical_facility_sapo: 'Danh sách các bệnh viện, phòng khám uy tín trên toàn thành phố. Tìm nơi khám chữa bệnh an toàn trong mùa Covid với trang thiết bị hiện đại.',
   detail: 'Chi tiết',
   medical_service_sapo: 'Đặt các gói dịch vụ tại bệnh viện, phòng khám hoặc tại gia như dịch vụ chăm sóc vết thương, xét nghiệm tại nhà, chăm sóc cho người cao tuổi',
   learn_more: 'Xem',
   medical_online_sapo: 'Đặt hẹn và nhận tư vấn, khám bệnh với bác sĩ từ xa thông qua video chat',
   company_name: 'CÔNG TY CỔ PHẦN CÔNG NGHỆ VIVMEDIC',
   company_add: 'Số 24 Mỹ Thái 2A, Phường Tân Phú, Quận 7, Thành phố Hồ Chí Minh, Việt Nam',
   service: 'Dịch vụ',
   customer_service: 'Hỗ trợ khách hàng',
   support: 'Hỗ trợ',
   partner_registration: 'Đăng ký đối tác',
   partner_login: 'Đăng nhập đối tác',
   user_terms_condition: 'Điều khoản sử dụng',
   telemedicine_terms_condition: 'Điều kiện tư vấn từ xa',
   privacy_policy: 'Chính sách bảo mật thông tin',
   transportation_policy: 'Chính sách vận chuyển',
   service_guideline: 'Hướng dẫn sử dụng dịch vụ',
   adjustment_claim_complaint_resolution: 'Quy trình hỗ trợ giải quyết khiếu nại',
   operating_rule_regulation: 'Quy chế hoạt động',
   frequently_asked_question: 'Câu hỏi thường gặp',
   contact_hotline: 'Liên hệ Hotline',
   social_media: 'Kết nối',
   web_is_dev: 'Website đang trong giai đoạn phát triển và chạy thử nghiệm.',
   all: 'Tất cả',
   search_medicine: 'Tìm kiếm thuốc',
   category_product: 'Danh mục sản phẩm',
   no_data: 'Không có dữ liệu',
   travel_medical: "Du lịch Y tế"
}