import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, Breadcrumb, Form, Input, message, Button, Row, Col,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import * as Services from "../../../store/services";
import cookie from 'react-cookies';
// import component
import AccountMenu from './components/menu';
import './account.css';
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 6 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 16 },
        sm: { span: 10 },
    },
};

const ChangePass = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    useEffect(() => {
        loadUser();
    }, []);
    const loadUser = () => {
        let currentUser = JSON.parse(localStorage.getItem(CONSTANT.STORAGE_USER));
        if (currentUser) {
            setUser({ _id: currentUser._id, active: 'cp', avatar: currentUser.avatar, email: currentUser.email });
        }
        else{
            window.location.href = '/';
        }
    }
    const onFinish = async (values) => {
        setLoading(true);
        let email = user.email;
        await Services.post(`${CONSTANT.API.USER.CHANGE_PASS}`, {
            email: email, password: values.password, newpassword: values.newpassword, role: 'user'
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookie.load(CONSTANT.STORAGE_TOKEN)
            }
        })
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Thay đổi mật khẩu thành công !')
                }
                else {
                    message.error('Thay đổi mật khẩu thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            }).catch(error => {
                if (error.response.data.code === 400) {
                    message.error('Mật khẩu cũ không đúng !');
                }
                else if (error.response.data.code === 401) {
                    message.error('Phiên đã hết hạn vui lòng đăng nhập lại để thay đổi mật khẩu !');
                }
                setLoading(false);
            });
    };
    return (
        <>
            <div className="wrap-breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/user/account/profile">Thông tin tài khoản</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="page-profile">
                <div className="container">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {/* Begin col menu */}
                        <Col xs={24} md={7}>
                            <AccountMenu data={user} />
                        </Col>

                        {/* Begin col info */}
                        <Col xs={24} md={17}>
                            <Card title={
                                <>
                                    <div>Thay đổi mật khẩu</div>
                                    <div style={{ fontSize: 14, fontWeight: 400 }}>Quản lý thay đổi mật khẩu để bảo mật tài khoản</div>
                                </>
                            }>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {/* begin form info */}
                                    <Col xs={24} md={24}>
                                        <Form
                                            {...formItemLayout} form={form} onFinish={onFinish}                >
                                            <Form.Item name="password" label="Mật khẩu cũ"
                                                rules={[{ required: true, message: "Mật khẩu cũ không được để trống" }, {
                                                    type: 'string', max: 100, message: "Mật khẩu cũ quá 100 ký tự"
                                                },]}>
                                                <Input.Password placeholder="Nhập mật khẩu cũ" />
                                            </Form.Item>
                                            <Form.Item name="newpassword" label="Mật khẩu mới"
                                                rules={[{ required: true, message: "Mật khẩu mới không được để trống" }, {
                                                    type: 'string', max: 100, message: "Mật khẩu mới quá 100 ký tự"
                                                },]}>
                                                <Input.Password placeholder="Nhập mật khẩu mới" />
                                            </Form.Item>
                                            <Form.Item
                                                name="confirm"
                                                label="Nhập lại mật khẩu mới"
                                                dependencies={['newpassword']}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập lại mật khẩu mới!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('newpassword') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Nhập lại mật khẩu mới không đúng!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password placeholder="Nhập lại mật khẩu mới" />
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
                                                <Button type="primary" icon={<SaveOutlined />}
                                                    htmlType="submit"
                                                >
                                                    Lưu thông tin
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
export default ChangePass;
