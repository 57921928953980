import React, { useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Breadcrumb, Result
} from 'antd';

import CONSTANT from '../../config/constant';

import './user-style.css';
const LoginSuccess = () => {
    let history = useHistory();

    useEffect(()=>{
        let user = localStorage.getItem(CONSTANT.STORAGE_USER);
        if(!user){
            history.push('/');
        }
    })

    
    return (
        <React.Fragment>
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Đăng nhập thành công</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container login-success">
                <Result
                    status="success"
                    title="Đăng nhập thành công"
                    subTitle="Bạn đã đăng nhập hệ thống thành công. Bạn có thể sử dụng tài khoản này để đặt các dịch vụ tại Tatinta Medical"
                    extra={[
                        <Link className="btn-global bg btn-book" to="/" key="1">
                            Đặt dịch vụ
                        </Link>,
                        <Link className="btn-global bg" to="/thong-tin-tai-khoan" key="2">Quản lý tài khoản</Link>,
                    ]}
                />
            </div>
        </React.Fragment>

    )
}

export default LoginSuccess;