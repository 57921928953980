import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Steps, Typography, Spin, Result, Divider } from 'antd';

import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import moment from 'moment';

const { Step } = Steps;

const DoctorBookingComplete = () => {
    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);
    const [feeDoctor, setFeeDoctor] = useState(CONSTANT.FEE.DOCTOR);
    // lấy query url
    const urlQueryParams = useLocation().search;

    useEffect(() => {
        if (urlQueryParams) {
            Services.get(`${CONSTANT.API.DOCTOR.CHECKOUT}/payment/pay-verify${urlQueryParams}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        let data = res.data.data;
                        let order = data.data_order;
                        let result = { isSucceed: data.isSucceed, message: data.message }
                        setResult(result);
                        if (order) {
                            if (order?.items[0]?.service?.is_online === false)
                                setFeeDoctor(0);
                            setOrder(order);
                        }

                    }
                    setLoading(false);
                })
        }
    }, [dispatch, urlQueryParams]);

    return (
        <div className="page-checkout service-complete">
            <div className="container">
                <div className="wrap-step">
                    <Steps size="small" current={2}>
                        <Step title="Chọn dịch vụ" />
                        <Step title="Thanh toán" />
                        <Step title="Hoàn thành" />
                    </Steps>
                </div>
                <Spin spinning={loading}>
                    <div className="payment-success">
                        {
                            result && result.isSucceed &&
                            <Result
                                status="success"
                                title={`TUYỆT VỜI! BẠN ĐÃ THANH TOÁN CHO LỊCH HẸN MÃ #VIVMEDIC-D${order?.uid}`}
                                subTitle={<div>Chúng tôi đã gửi email xác nhận đến địa chỉ email <strong>{order?.contact?.email}</strong> của bạn</div>}
                            />
                        }
                        {
                            result && !result.isSucceed &&
                            <>
                                {
                                    (() => {
                                        switch (result.message) {
                                            case 'Wrong checksum':
                                                return <Result
                                                    status="warning"
                                                    title="Thông tin xác thực thanh toán không hợp lệ. Chúng tôi sẽ hủy lịch hẹn này. Xin cảm ơn!"
                                                />
                                            case 'Order not exist':
                                                return <Result
                                                    status="404"
                                                    title="404"
                                                    subTitle="Lịch hẹn không tồn tại"
                                                />
                                            default:
                                                return <Result
                                                    status="500"
                                                    title="500"
                                                    subTitle={<span>{result.message}. Chúng tôi sẽ hủy lịch hẹn này. Xin cảm ơn!</span>}
                                                />
                                        }
                                    })()
                                }
                            </>

                        }
                        {
                            order && order._id &&
                            <div className="box-info">
                                <Row>
                                    <Col xs={8} lg={8} className="fw-bold">Thông tin của bạn</Col>
                                    <Col xs={16} lg={16}>
                                        <div className="fw-bold">{order?.contact?.fullname_orderer}</div>
                                        <Typography.Text>{order?.contact?.email}</Typography.Text><br />
                                        <Typography.Text>{order?.contact?.phone && `+${order?.contact?.dial_code}${order?.contact?.phone}`}</Typography.Text>
                                    </Col>
                                </Row>



                                <div className="fw-bold block-title" style={{ marginTop: 8 }}>Thông tin lịch hẹn</div>
                                <div className="list-product" style={{ marginBottom: 0 }}>
                                    {
                                        order?.items?.length > 0 &&
                                        <Row className="item">
                                            <Col xs={8} lg={4}>
                                                <div className="box-cover">
                                                    <img src={order.items[0]?.doctor?.image ? COMMON.getImage(order.items[0].doctor.image, 'doctor') : COMMON.getImage('')} alt={order.items[0].doctor.slug} />
                                                </div>
                                            </Col>
                                            <Col xs={16} lg={20} className="info">
                                                <div className="title" style={{ marginBottom: 0 }}>
                                                    {COMMON.createDoctorName(order.items[0]?.doctor?.academic_rank, order.items[0]?.doctor?.degree, order.items[0]?.doctor?.category?.short_name)} {order.items[0]?.doctor?.fullname}
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Dịch vụ: </span>
                                                    <span className="fw-medium">{order.items[0]?.service?.name}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Giá: </span>
                                                    <span className="fw-medium">{COMMON.currencyFormat(order?.items[0]?.service?.price)} {COMMON.getCurrency(order?.currency)}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Ngày khám bệnh: </span>
                                                    <span className="fw-medium">{`${order?.date?.day}/${order?.date?.month}/${order?.date?.year}`}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Giờ: </span>
                                                    <span className="fw-medium">{moment(`${order?.time?.time_h}:${order?.time?.time_m}`, "HH:mm").format("HH:mm")}</span>
                                                </div>
                                                {
                                                    order?.items?.length > 0 && order.items[0]?.service?.is_online === false &&
                                                    <div style={{ color: '#777' }}>
                                                        <span>Địa điểm: </span>
                                                        <span className="fw-medium">
                                                            {order?.items?.length > 0 && order?.items[0]?.doctor?.addresses?.length > 0 && order.items[0].doctor.addresses.filter(item => item._id === order?.time?.address)[0].name}
                                                        </span>
                                                    </div>
                                                }
                                                {
                                                    order?.items?.length > 0 && order.items[0]?.service?.is_online === true &&
                                                    <div style={{ color: '#777' }}>
                                                        <span className="fw-medium">
                                                            Quý khách vui lòng truy cập liên kết <a target="_blank" href={`${CONSTANT.BASE_MEET}/${order?.verify_code}`}>Vivmedic Meet</a> để được &nbsp;
                                                            {COMMON.createDoctorName(order?.items[0]?.doctor?.academic_rank, order?.items[0]?.doctor?.degree, order?.items[0]?.doctor?.category?.short_name)} {order?.items[0]?.doctor?.fullname} tư vấn từ xa.
                                                        </span>
                                                    </div>

                                                }
                                                <div style={{ color: '#777' }}>
                                                    <span>Phương thức thanh toán: </span>
                                                    <span className="fw-medium">{order.payment_method === 'onepayDomestic' ? 'Thẻ ATM nội địa' : 'Thẻ tín dụng (VISA, Master)'}</span>
                                                </div>
                                                <div style={{ color: '#777' }}>
                                                    <span>Mã xác nhận: </span>
                                                    <span className="fw-medium">{order.verify_code}</span>
                                                </div>
                                                {
                                                    order?.items[0]?.service?.price_note &&
                                                    <>
                                                        <div style={{ color: '#777' }}>
                                                            <span className="fw-medium">Lưu ý giá dịch vụ:</span>
                                                        </div>
                                                        <div style={{ fontStyle: 'italic', color: "#777", fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: `${order?.items[0]?.service?.price_note.replace(/(?:\r\n|\r|\n)/g, '<br />')}` }}></div>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    }
                                    <Divider />
                                    <Row>
                                        <Col xs={16} md={8}>Phí dịch vụ</Col>
                                        <Col xs={8} md={16} className="fw-medium text-end">{COMMON.currencyFormat(feeDoctor)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={16} md={14}>VAT 10% (Giá + Phí dịch vụ)</Col>
                                        <Col xs={8} md={10} className="text-end fw-medium">{order?.items?.length > 0 && COMMON.currencyFormat((order?.items[0]?.service?.price + feeDoctor) * CONSTANT.FEE.VAT)} {COMMON.getCurrency(order?.currency)}</Col>
                                    </Row>
                                    <div className="order-total">
                                        <span>Tổng cộng</span>
                                        <span className="price-total">{order?.total && COMMON.currencyFormat(order?.total)} {COMMON.getCurrency(order?.currency)}</span>
                                    </div>
                                </div>

                                <div className="text-end" style={{ marginTop: 30 }}>
                                    <Link to="/" className="btn-global bg" style={{ padding: '10px 50px' }}>Về trang chủ</Link>
                                </div>
                            </div>
                        }

                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default DoctorBookingComplete;