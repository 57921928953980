// Get list
export const GET_LIST = "GET_LIST_HOSPITAL";
export const GET_LIST_SUCCESS = "GET_LIST_HOSPITAL_SUCCESS";
export const GET_LIST_FAILURE = "GET_LIST_HOSPITAL_FAILURE";

// Get detail
export const GET_DETAIL = "GET_DETAIL_HOSPITAL";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_HOSPITAL_SUCCESS";
export const GET_DETAIL_FAILURE = "GET_DETAIL_HOSPITAL_FAILURE";

//get specialties
export const GET_SPECIALTIES = 'GET_SPECIALTIES'
export const GET_SPECIALTIES_SUCCESS = 'GET_SPECIALTIES_SUCCESS'
export const GET_SPECIALTIES_FAILURE= 'GET_SPECIALTIES_FAILURE'
//get types
export const GET_TYPES = 'GET_TYPES'
export const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS'
export const GET_TYPES_FAILURE= 'GET_TYPES_FAILURE'