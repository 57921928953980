import React, { useState, useCallback, useEffect } from 'react';
import {
    Form, Modal, Input, Checkbox, Radio, message
} from 'antd';
import CONSTANT from '../../../../config/constant';
import * as Services from "../../../../store/services";
import cookie from 'react-cookies';
const ModalAddAddress = (props) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    // Hủy
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel();
    }, [form]);


    // Xử lý lưu
    const onSubmit = useCallback(async (values) => {
        setConfirmLoading(true);
        if (props.data) {
            values.act_address = 'edit';
            values.address_id = props.data._id
        }
        else {
            values.act_address = 'add';
        }
        await Services.patch(`${CONSTANT.API.USER.UPDATE}/${props.userId}`, values, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookie.load(CONSTANT.STORAGE_TOKEN)
            }
        })
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    if (props.data) {
                        message.success('Sửa địa chỉ thành công!');
                    }
                    else
                        message.success('Thêm địa chỉ thành công!');
                    localStorage.setItem(CONSTANT.STORAGE_USER, JSON.stringify(res.data.data));
                }
                else {
                    if (props.data) {
                        message.error('Sửa địa chỉ thất bại! Vui lòng thử lại sau.');
                    }
                    else
                        message.error('Thêm địa chỉ thất bại! Vui lòng thử lại sau.');

                }
            });
        setTimeout(() => {
            setConfirmLoading(false);
            // đóng modal
            handleCancel();
            // load lai danh sách địa chỉ
            props.reload()
        }, 2000);
    }, [props]);

    useEffect(() => {
        loadForm();
    }, [props.data]);

    useEffect(() => {
        loadForm();
    })
    const loadForm = () => {
        form.setFieldsValue({
            fullname: props.data ? props.data.fullname : '',
            phone: props.data ? props.data.phone : '',
            city: props.data ? props.data.city : '',
            district: props.data ? props.data.district : '',
            ward: props.data ? props.data.ward : '',
            detail: props.data ? props.data.detail : '',
            addtype: props.data ? props.data.addtype : '',
            is_default: props.data ? props.data.is_default : false,
        });
    }
    return (
        <Modal title="Thêm địa chỉ mới"
            visible={props.isModalVisible}
            onOk={form.submit}
            onCancel={handleCancel}
            confirmLoading={confirmLoading}
            cancelText="Hủy"
            okText="Lưu thông tin"
        >
            <Form
                name="basic"
                layout="vertical"

                onFinish={onSubmit}
                onFinishFailed={() => { }}
                form={form}
            >
                <Form.Item
                    label="Họ tên"
                    name="fullname"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập họ tên!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập số điện thoại!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="city" label="Tỉnh/Thành phố">
                    <Input placeholder="Nhập Tỉnh/Thành phố" />
                </Form.Item>
                <Form.Item name="district" label="Quận/Huyện">
                    <Input placeholder="Nhập Quận/Huyện" />
                </Form.Item>
                <Form.Item name="ward" label="Phường/Xã">
                    <Input placeholder="Nhập Phường/Xã" />
                </Form.Item>
                <Form.Item name="detail" label="Đại chỉ cụ thể">
                    <Input placeholder="Số nhà, tên hẻm, thôn, tổ..." />
                </Form.Item>
                <Form.Item name="addtype">
                    <Radio.Group value={'home'}>
                        <Radio value='home'>Nhà riêng</Radio>
                        <Radio value='office'>Văn phòng</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="is_default" valuePropName="checked">
                    <Checkbox>Đặt làm địa chỉ mặc định</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalAddAddress;