import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, message, Row, Col, Spin, Divider } from 'antd';
import moment from 'moment';

import * as Services from "../../store/services";
import * as COMMON from '../../helper/common';
import CONSTANT from '../../config/constant';
import MetaSeo from '../../component/meta-seo';

const PAY_METHOD = {
    bank_transfer: 'Thanh toán chuyển khoản',
    onepayDomestic: 'Thanh toán qua cổng thanh toán OnePay nội địa',
    onepayInternational: 'Thanh toán qua cổng thanh toán OnePay quốc tế',
}

const MyOrder = () => {
    const [order, setOrder] = useState({});

    const [loading, setLoading] = useState(true);

    // lấy token từ query url
    const urlQueryParams = useLocation().search;
    const _token = new URLSearchParams(urlQueryParams).get('token');

    useEffect(() => {
        // load detail order
        const loadOrder = async () => {
            if (typeof _token !== 'undefined') {
                return await Services.get(`${CONSTANT.API.PRODUCT.ORDER}/${_token}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin đơn hàng!');
                    });

            }
        }
        let mounted = true;

        if (mounted) {
            setLoading(true);
            loadOrder().then(data => {
                if (data) {
                    setOrder(data);
                }
                setLoading(false);
            });
        }


        return () => mounted = false;
    }, [_token]);

    return (
        <div className="page-checkout" style={{ paddingTop: 0 }}>
            <MetaSeo title="Đơn hàng của bạn" />
            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Trang chủ</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Đơn hàng của bạn</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="container">
                {loading && <div className="text-center" style={{ paddingTop: 30 }}> <Spin /></div>}
                {
                    order?._id &&
                    <div className="bank-transfer">
                        <div className="fw-bold">Cảm ơn Quý khách đã đặt hàng của Vivmedic</div>
                        <div className="fw-bold block-title" style={{ marginTop: 20 }}>Thông tin đơn hàng</div>
                        <div className="list-product">
                            {
                                order && order.shop.map((shop) => (
                                    shop.items.map((item) => (
                                        <div className="item" key={item._id}>
                                            <div className="box-cover">
                                                <img src={COMMON.getImageVariant(item)} alt={item.product.slug} />
                                            </div>
                                            <div className="info">
                                                <div className="title">
                                                    {item.product.name}<br></br>
                                                    <p className="product-attribute">
                                                        {
                                                            item.variant && item.variant.attributes && item.variant.attributes.length > 0 && item.variant.attributes.map((attr, indexAttr) => {
                                                                let str = attr.options[0].name;
                                                                if (indexAttr < item.variant.attributes.length - 1)
                                                                    str += " / ";
                                                                return str;
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="quantity-price">
                                                    <span>x{item.quantity}</span>
                                                    <span className="price" style={{fontSize:16}}>{COMMON.currencyFormat(parseFloat(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2))} {COMMON.getCurrency(item.product.currency)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )))
                            }
                            <div className="other-price">
                                <span>Tổng giá sản phẩm</span>
                                <span className="price">{COMMON.currencyFormat(order?.subtotal)} ₫</span>
                            </div>
                            <div className="other-price">
                                <span>Phí vận chuyển</span>
                                <span >Quý khách tự thanh toán</span>
                            </div>
                            <div className="other-price">
                                <span>Giảm giá</span>
                                <span className="price">{COMMON.currencyFormat(order?.discount_total)} ₫</span>
                            </div>
                            <div className="order-total">
                                <span>Tổng cộng</span>
                                <span className="price-total">{COMMON.currencyFormat(order?.subtotal - (order?.discount_total))} ₫</span>
                            </div>
                        </div>
                        <div>
                            Phương thức thanh toán: <span className="fw-medium">{PAY_METHOD[`${order?.payment_method}`]}</span>
                        </div>
                        <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                            <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                        </div>
                    </div>
                }


            </div>
        </div>

    )
}

export default MyOrder;