import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import * as Services from "../../store/services";
import CONSTANT from '../../config/constant';
import * as COMMON from '../../helper/common';
import moment from 'moment';
const ServiceBookingConfirm = () => {
    const [order, setOrder] = useState();
    const [textConfirm, setTextConfirm] = useState();
    const { status, code } = useParams();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (status && code) {
            let curr_status = '';
            switch (status) {
                case 'confirmed':
                    setTextConfirm('Xác nhận chấp nhận lịch hẹn thành công');
                    curr_status = 'paid';
                    break;
                case 'agency-begin':
                    setTextConfirm('Xác nhận bắt đầu dịch vụ thành công ! Quý đại lý vui lòng yêu khách hàng xác nhận bắt đầu qua email, cảm ơn Quý đại lý !');
                    curr_status = 'confirmed';
                    break;
                case 'begin':
                    setTextConfirm('Quý khách đã xác nhận bắt đầu dịch vụ thành công');
                    curr_status = 'agency-begin';
                    break;
                case 'agency-completed':
                    setTextConfirm('Quý đại lý Xác nhận đã hoàn thành dịch vụ thành công !');
                    curr_status = 'begin';
                    break;
                case 'completed':
                    setTextConfirm('Xác nhận đã hoàn thành dịch vụ thành công! Cảm ơn Quý khách !');
                    curr_status = 'agency-completed';
                    break;
                default: break;
            }
            Services.get(`${CONSTANT.API.SERVICE.ORDER}?verify_code=${code}&status=${curr_status}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200 && res.data.data.docs.length > 0) {
                        await setOrder(res.data.data.docs[0]);
                        let upd = { actUpd: 'udpStatus', status: status };
                        Services.patch(`${CONSTANT.API.SERVICE.ORDER}/${res.data.data.docs[0]._id}`, upd)
                            .then(async res1 => {
                                if (res1.data.code === 200) {
                                    message.success(textConfirm, 5);
                                }
                                setLoading(false);
                            })
                    }
                    setLoading(false);
                })
        }
    }, []);

    return (

        <div className="page-checkout service-complete">
            {
                order ? <div className="container">
                    <Spin spinning={loading}>
                        <div className="fw-bold text-center">{textConfirm}</div>
                        <div className="bank-transfer">
                            <div>Tên dịch vụ: <span className="fw-medium"> {order?.items && order?.items.length > 0 && order?.items[0].service.name}</span></div>
                            <div>Ngày thực hiện: <span className="fw-medium">{order?.date && `${moment(order?.date).format('DD/MM/YYYY')}`}</span></div>
                            <div>Vào lúc: <span className="fw-medium">{order?.time && `${moment(order?.time).format('HH:mm')}`}</span></div>
                            <div>Địa điểm thực hiện: <span className="fw-medium">
                                {(order?.work_place === 'at_guest' ? 'Tại nhà khách hàng, ' : `${order?.place_name}, `) + order?.address_work}
                            </span></div>
                            <div>Phương thức thanh toán: <span className="fw-medium">
                                {(order?.payment_method && order?.payment_method === 'pay_on_spot') ? 'Thanh toán tại nơi thực hiện' : 'Thanh toán trực tuyến'}
                            </span>
                            </div>
                            <div>Tổng tiền: <span className="price">{COMMON.currencyFormat(order?.total)} {COMMON.getCurrency('VND')}</span></div>

                            <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                                <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                            </div>
                        </div>
                    </Spin>
                </div> :
                    <div className="container" style={{ height: 200 }}>
                        <div className="text-center" style={{ margin: '30px 0px 10px 0px' }}>
                            <div className="bank-transfer">
                                <div className="fw-bold text-center">Liên kết xác nhận đã được thực hiện hoặc không đúng.<br></br> Vui lòng kiểm tra lại, cảm ơn Quý khách !</div><br></br>
                                <Link to="/" className="btn-global bg btn-buy">Về trang chủ</Link>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default ServiceBookingConfirm;