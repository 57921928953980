import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import {
    Breadcrumb, Col, Row, Pagination, Spin, Empty
} from 'antd';
import { } from '@ant-design/icons';


// import common
import * as COMMON from '../../helper/common';
import MetaSeo from '../../component/meta-seo';

import './shop-style.css';
import { useDispatch, useSelector } from 'react-redux';

import { ProductAction } from '../../store/root-action';

import MenuLeft from './components/menu-left';
import ItemProduct from './components/item-product';

const ProductCategory = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [currentCategory, setCurrentCategory] = useState({});
    const [siteMapCategories, setSiteMapCategories] = useState([]);
    const loading = useSelector(state => state.productReducer.loading);
    const listProducts = useSelector(state => state.productReducer.list);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;
    const listTotal = useSelector(state => state.productReducer.listTotal);
    //  onloaded menu
    const onLoadedMenu = (data) => {
        setSiteMapCategories(data.siteMapCategories);
        setCurrentCategory(data.currentCategory);
    }
    // Load product
    const loadProduct = (page) => {
        dispatch(ProductAction.getList({
            perPage: pageSize, page, category: id, status: 'publish'
        }));
    }
    useEffect(() => {
        loadProduct(currentPage);
    }, [id])

    // Change pagination
    const onChangePagination = (page) => {
        setCurrentPage(page);
        loadProduct(page);
    }
    return (
        <React.Fragment>
            <MetaSeo
                title={currentCategory?.name}
                description={currentCategory?.description || currentCategory?.name}
                keyword={currentCategory?.name}
            />

            <div className="breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">{t('home')}</Link></Breadcrumb.Item>
                        {
                            siteMapCategories && siteMapCategories.length > 0 && siteMapCategories.map(item => (
                                <Breadcrumb.Item key={item.uid}>
                                    <Link title={item.name} to={COMMON.createLinkProductCategory(item.slug, item.uid)}>
                                        {item.name}
                                    </Link>
                                </Breadcrumb.Item>
                            ))
                        }
                    </Breadcrumb>
                </div>
            </div>
            {/* Begin medical shopping */}
            <div className="container">
                <div className="product-category">
                    <Row gutter={[{ xs: 0, lg: 32 }, { xs: 0, lg: 32 }]}>
                        <Col xs={24} lg={6} className="col-left">
                            <MenuLeft onLoaded={onLoadedMenu} />
                        </Col>
                        <Col xs={24} lg={18}>
                            <div className="list-header">{currentCategory && currentCategory.name}</div>
                            <Spin spinning={loading} tip="Đang tải dữ liệu...">
                                {
                                    !loading && listProducts && listProducts.length > 0 &&
                                    <React.Fragment>
                                        <div className="list-product">
                                            <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]}>
                                                {
                                                    listProducts.map(item => (
                                                        <ItemProduct data={item} key={item.uid} />
                                                    ))
                                                }

                                            </Row>
                                        </div>
                                        <div className="text-end pagination">
                                            <Pagination
                                                pageSize={pageSize} current={currentPage} total={listTotal}
                                                hideOnSinglePage={true}
                                                showLessItems={true}
                                                onChange={onChangePagination}
                                            />
                                        </div>
                                    </React.Fragment>

                                }
                                {
                                    !loading && listProducts.length === 0 &&
                                    <Empty description={<span style={{ color: '#999' }}>{t('no_data')}</span>} />
                                }

                            </Spin>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ProductCategory;