import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from 'react-router-dom';

import CONSTANT from "../config/constant";

const MetaSeo = ({ title, description, keyword, type, image, extra }) => {
	const location = useLocation();
	const _title =
		(title && `${title} - ${CONSTANT.PAGE_TITLE}`) || CONSTANT.PAGE_TITLE;
	const _title_og = title || CONSTANT.PAGE_TITLE;
	const url = `${CONSTANT.BASE_URL}${location.pathname}`;
	return (
		<Helmet prioritizeSeoTags>
			<title>{_title}</title>
			<meta name="description" content={description || CONSTANT.PAGE_DESCRIPTION} />
			<meta name="keywords" content={keyword || CONSTANT.PAGE_KEYWORD} />
			{/* Begin for facebook */}
			<meta property="og:site_name" content={CONSTANT.SITE_NAME} />
			<meta property="og:type" content={type || "website"} />
			<meta property="og:rich_attachment" content="true" />
			<meta property="og:url" itemprop="url" content={url} />
			<meta property="og:image" itemprop="thumbnailUrl"
				content={image || `${CONSTANT.BASE_URL}/images/logo-vivmedic.png`}
			/>
			<meta property="og:image:width" content="800" />
			<meta property="og:image:height" content="354" />
			<meta property="og:image:alt" content={_title_og} />
			<meta property="og:title" content={_title_og} itemprop="headline" />
			<meta
				property="og:description"
				content={description || CONSTANT.PAGE_DESCRIPTION}
				itemprop="description"
			/>
			{/* End for facebook */}

			{/* Begin for twitter */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:url" content={url} />
			<meta name="twitter:title" content={_title} />
			<meta name="twitter:site" content="@Vivmedic" />
			<meta
				name="twitter:description"
				content={description || CONSTANT.PAGE_DESCRIPTION}
			/>
			<meta
				name="twitter:image"
				content={image || `${CONSTANT.BASE_URL}/images/logo-vivmedic.png`}
			/>
			<meta name="twitter:creator" content="@Vivmedic" />
			{/* End for twitter */}

			<link rel="canonical" href={url} />
			<link rel="alternate" href={url} hreflang="vi-vn" />

			{extra}
		</Helmet>
	);
};
export default MetaSeo;
