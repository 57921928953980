import React, { useState, useCallback } from 'react';
import { Modal, Form, Row, Col, Input, Select, DatePicker, Radio, message } from 'antd';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import * as Services from "../../../store/services";
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../helper/common';
const ModalBookFacility = (props) => {
    const [form] = Form.useForm();
    const currentUser = COMMON.getCurrentUser();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [phones, setPhones] = useState({
        phone: '',
        dial_code: 84,
        country_code: 'vn'
    });

    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel();
    }, [form]);

    // Xử lý lưu
    const onSubmit = useCallback(async (values) => {
        setConfirmLoading(true);
        let dataSave = {
            agency: props.hospitalId,
            status: 'pending',
            contact: {
                order_fullname: values.order_fullname,
                patient_fullname: values.patient_fullname,
                patient_dob: values.patient_dob,
                gender: values.gender,
                email: values.email,
                phone: values.phone.replace(phones.dial_code, ''),
                dial_code: phones?.dial_code,
                country_code: phones?.country_code
            },
            specialty: values.specialty,
            examination_date: values.examination_date,
            examination_time: values.examination_time,
            created_by: currentUser ? currentUser._id : '',
        }
        let save = await Services.post(CONSTANT.API.HOSPITAL.ORDER, dataSave);

        if (save.status === 200) {
            message.success('Đặt lịch hẹn thành công ! ' + props.hospitalName + ' sẽ lên lịch hẹn và liên lạc lại với bạn !', 7);
            handleCancel();
        }
        else {
            message.error('Đặt lịch hẹn không thành công ! Quý khách vui lòng quay lại sau, cảm ơn !', 4);
        }
        setConfirmLoading(false);
    }, [props]);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }
    return (
        <Modal title="Đặt hẹn"
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Đặt lịch"
            confirmLoading={confirmLoading}
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            getContainer={false}
        >
            <Form
                name="basic"
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={() => message.warning('Vui lòng nhập đầy đủ thông tin')}
                form={form}
            >
                <Row gutter={[24]} align="middle">
                    <Col span={24}>
                        <Form.Item label="Họ tên người đăng ký"
                            name="order_fullname"
                            required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập họ tên người đăng ký' }]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Số điện thoại"
                            name="phone"
                            required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                        >
                            <PhoneInput
                                country='vn'
                                value={phones.phone}
                                onChange={(value, data) => {
                                    setPhones({
                                        phone: value,
                                        dial_code: data.dialCode,
                                        country_code: data.countryCode
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Email" name="email"
                            rules={[{ type: "email", message: 'Email chưa đúng định dạng! (Ví dụ: vivmedic@gmail.com)' }]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} span={24}>
                        <Form.Item label="Họ tên người đến khám bệnh"
                            name="patient_fullname" required tooltip="Thông tin này bắt buộc nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập họ tên người đến khám bệnh' }]}
                        >
                            <Input className="rounded-pill" size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} lg={12}>
                        <Form.Item label="Ngày sinh" name="patient_dob">
                            <DatePicker
                                format="DD/MM/YYYY"
                                showNow={false}
                                className="select-date"
                                placeholder="Chọn ngày sinh"
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} lg={12}>
                        <Form.Item name="gender">
                            <Radio.Group className="gender">
                                <Radio value='male'>Nam</Radio>
                                <Radio value='female'>Nữ</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Col>
                    <Col span={24}><div style={{ marginBottom: '20px', fontWeight: 500 }}>Thông tin lịch hẹn</div></Col>
                    <Col xs={24} lg={24}>
                        <Form.Item label="Chọn chuyên khoa" name="specialty"
                            required tooltip="Vui lòng chọn trường này"
                            rules={[{ required: true, message: 'Vui lòng chọn chuyên khoa' }]}
                        >
                            <Select placeholder="Chọn chuyên khoa" className="select-location" size='large'>
                                {
                                    props?.specialties?.length > 0 &&
                                    props.specialties.map((item, index) => (
                                        <Select.Option key={index} value={item?.specialty?._id}>{item?.specialty?.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Chọn ngày"
                            name="examination_date"
                            required tooltip="Vui lòng chọn trường này"
                            rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
                        >
                            <DatePicker
                                format="DD/MM/YY"
                                showNow={false}
                                className="select-date"
                                placeholder="Chọn ngày"
                                size='large'
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} span={12}>
                        <Form.Item label="Chọn thời gian"
                            name="examination_time"
                            required tooltip="Vui lòng chọn trường này"
                            rules={[{ required: true, message: 'Vui lòng chọn thời gian' }]}
                        >
                            <Select
                                placeholder="Chọn thời gian"
                                className="select-location"
                                size='large'
                                dropdownClassName="dropdown-time"
                                allowClear={true}
                                virtual={false}
                            >
                                <Select.Option value="7:00">7:00</Select.Option>
                                <Select.Option value="7:30">7:30</Select.Option>
                                <Select.Option value="8:00">8:00</Select.Option>
                                <Select.Option value="8:30">8:30</Select.Option>
                                <Select.Option value="9:00">9:00</Select.Option>
                                <Select.Option value="9:30">9:30</Select.Option>
                                <Select.Option value="10:00">10:00</Select.Option>
                                <Select.Option value="10:30">10:30</Select.Option>

                                <Select.Option value="13:00">13:00</Select.Option>
                                <Select.Option value="13:30">13:30</Select.Option>
                                <Select.Option value="14:00">14:00</Select.Option>
                                <Select.Option value="14:30">14:30</Select.Option>
                                <Select.Option value="15:00">15:00</Select.Option>
                                <Select.Option value="15:30">15:30</Select.Option>
                                <Select.Option value="16:00">16:00</Select.Option>
                                <Select.Option value="16:30">16:30</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

        </Modal>
    )
}
export default ModalBookFacility;