import React from 'react';
import {Link} from 'react-router-dom';
import { Result } from 'antd';

const PageNotFound = () => (
    <Result
        status="404"
        title="404"
        subTitle="Xin lỗi, trang bạn truy cập không tồn tại!"
        extra={<Link className="btn-global bg" style={{padding: '10px 30px', textTransform:'uppercase'}} to="/">Về trang chủ</Link>}
    />
)
export default PageNotFound;