import produce from 'immer';

import {
    GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE,
    GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE,
    GET_TREE_CATEGORY, GET_TREE_CATEGORY_SUCCESS, GET_TREE_CATEGORY_FAILURE,
    ADD_ORDER, ADD_ORDER_SUCCESS, ADD_ORDER_FAILURE,
    GET_ORDER, GET_ORDER_SUCCESS, GET_ORDER_FAILURE,
    GET_ORDER_ITEM, GET_ORDER_ITEM_SUCCESS, GET_ORDER_ITEM_FAILURE,
    GET_LIST_HOT, GET_LIST_HOT_FAILURE, GET_LIST_HOT_SUCCESS

} from './type';

const initialState = {
    list: [],
    listTotal: 0,
    tree_category: [],
    detail: {},
    order: {},
    orderItem: [],
    subTotal: 0,
    discountTotal: 0,
    totalOrder: 0,
    loading: false,
    loadingCategory: false,
    error: false,
    listHot: [],
}

function productReducer(state = initialState, action) {
    const { payload } = action;
    return produce(state, draft => {
        switch (action.type) {
            // List
            case GET_LIST:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_LIST_SUCCESS:
                draft.list = payload.docs;
                draft.listTotal = payload.total;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_LIST_FAILURE:
                draft.loading = false;
                draft.list = [];
                draft.error = payload;
                break;
            // Detail product
            case GET_DETAIL:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_DETAIL_SUCCESS:
                draft.detail = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_DETAIL_FAILURE:
                draft.detail = {};
                draft.loading = false;
                draft.error = false;
                break;
            // Tree category
            case GET_TREE_CATEGORY:
                draft.loadingCategory = true;
                draft.error = false;
                break;
            case GET_TREE_CATEGORY_SUCCESS:
                draft.tree_category = payload;
                draft.loadingCategory = false;
                draft.error = false;
                break;
            case GET_TREE_CATEGORY_FAILURE:
                draft.loadingCategory = false;
                draft.tree_category = [];
                draft.error = payload;
                break;
            //Get Order            
            case GET_ORDER:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_ORDER_SUCCESS:
                draft.order = payload;
                draft.loading = false;
                draft.error = false;
                draft.subTotal = 0;
                for (let item of payload.shop) {
                    draft.subTotal += item.price * item.quantity;
                };
                draft.discountTotal = payload.discount_total;
                draft.totalOrder = draft.subTotal - draft.discountTotal;
                break;
            case GET_ORDER_FAILURE:
                draft.order = {};
                draft.loading = false;
                draft.error = false;
                break;
            //Get detail Order            
            case GET_ORDER_ITEM:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_ORDER_ITEM_SUCCESS:
                draft.orderItem = payload;
                draft.loading = false;
                draft.error = false;
                draft.subTotal = 0;
                for (let item of payload) {
                    draft.subTotal += item.price * item.quantity;
                };
                break;
            case GET_ORDER_ITEM_FAILURE:
                draft.order = {};
                draft.loading = false;
                draft.error = false;
                break;
            //Add Order            
            case ADD_ORDER:
                draft.loading = true;
                draft.error = false;
                break;
            case ADD_ORDER_SUCCESS:
                draft.order = payload;
                draft.loading = false;
                draft.error = false;
                break;
            case ADD_ORDER_FAILURE:
                draft.order = {};
                draft.loading = false;
                draft.error = false;
                break;
            // List hot
            case GET_LIST_HOT:
                draft.loading = true;
                draft.error = false;
                break;
            case GET_LIST_HOT_SUCCESS:
                draft.listHot = payload.docs;
                draft.listTotal = payload.total;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_LIST_HOT_FAILURE:
                draft.loading = false;
                draft.listHot = [];
                draft.error = payload;
                break;
            default:
                break;
        }
    })

}

export default productReducer;