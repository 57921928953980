import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { Breadcrumb, Spin, Empty, message, Collapse, List } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./qa.css";
import { axiosInstance } from "../../config/api";

import MetaSeo from "../../component/meta-seo";
import CONSTANT from "../../config/constant";
import * as COMMON from "../../helper/common";

const { Panel } = Collapse;

function Qa() {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    total: 0,
    hideOnSinglePage: true,
    showLessItems: true,
  });

  // Load list QA
  const loadList = async (queryParam) => {
    let query = Object.keys(queryParam)
      .map((key) => key + "=" + queryParam[key])
      .join("&");
    setLoading(true);
    await axiosInstance
      .get(`${CONSTANT.API.QA}?${query}`)
      .then((res) => {
        if (res && res.data && res.data.code === 200) {
          // Set giá trị cho pagination
          let _pagination = { ...pagination };
          _pagination.total = res.data.data.total;
          setPagination(_pagination);
          setList(res.data.data.docs);
          setLoading(false);
        } else {
          message.warning("Lỗi lấy thông tin!").then(() => history.push("/"));
        }
      })
      .catch((error) => {
        message.warning("Lỗi lấy thông tin!").then(() => history.push("/"));
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadList({ page: 1, perPage: pagination.pageSize });
    }
    return () => (mounted = false);
  }, []);

  const onPaginationChange = async (page) => {
    loadList({ page, perPage: pagination.pageSize });
  };

  return (
    <React.Fragment>
      <MetaSeo
        title="Câu hỏi thường gặp"
        keyword={`câu hỏi thường gặp, câu hỏi y tế, câu hỏi bác sĩ, câu hỏi vivmedic, ${CONSTANT.PAGE_KEYWORD_EXT}`}
        description="Các câu hỏi thường gặp trong y tế, khám, tư vấn, bác sĩ"
      />

      <div className="page-qa">
        <div className="breadcrumb">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Trang chủ</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Câu hỏi thường gặp</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className="container">
          <div className="block-header">
            <div className="text-uppercase title">Câu hỏi thường gặp</div>
            <div className="line"></div>
          </div>
          <div className="list-qa">
            <List
              dataSource={list}
              renderItem={(item) => (
                <Collapse
                  expandIconPosition="right"
                  className="item"
                  key={item?._id}
                >
                  <Panel
                    header={
                      <div className="title">
                        <QuestionCircleOutlined style={{ marginRight: 10 }} />
                        {item?.question}
                      </div>
                    }
                    key={item?._id}
                  >
                    {parse(item?.answer)}
                  </Panel>
                </Collapse>
              )}
              pagination={{
                ...pagination,
                onChange: onPaginationChange,
              }}
              loading={loading}
              locale={{
                emptyText: <Empty description="Nội dung đang cập nhật" />,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Qa;
